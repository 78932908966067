var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "feedback-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "feedback" }, [
      _c("div", { staticClass: "feedback__wrap" }, [
        _c("div", { staticClass: "feedback__item" }, [
          _vm.feedback.worth
            ? _c("div", { staticClass: "feedback__row" }, [
                _c("strong", [_vm._v("Достоинства: ")]),
                _c("span", [_vm._v(_vm._s(_vm.feedback.worth))])
              ])
            : _vm._e(),
          _vm.feedback.limitations
            ? _c("div", { staticClass: "feedback__row" }, [
                _c("strong", [_vm._v("Недостатки: ")]),
                _c("span", [_vm._v(_vm._s(_vm.feedback.limitations))])
              ])
            : _vm._e(),
          _vm.feedback.comment
            ? _c("div", { staticClass: "feedback__row" }, [
                _c("strong", [_vm._v("Комментарий: ")]),
                _c("span", [_vm._v(_vm._s(_vm.truncateText("comment")))]),
                _vm.feedback.comment.length > 100 && !_vm.feedback.expanded
                  ? _c(
                      "div",
                      {
                        staticClass: "feedback__expand",
                        on: {
                          click: function($event) {
                            return _vm.expandText()
                          }
                        }
                      },
                      [_c("span", [_vm._v("показать полностью")])]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.feedback.date
            ? _c("div", { staticClass: "feedback__date" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm._f("date")(_vm.feedback.date, "DD.MM.YYYY")) +
                    "\n        "
                )
              ])
            : _vm._e()
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "feedback-paginate" },
      _vm._l(_vm.feedbackItems, function(item, i) {
        return _c("div", {
          key: i,
          staticClass: "feedback-paginate__dot",
          class: { "feedback-paginate__dot_active": i === _vm.currentIndex },
          on: {
            click: function($event) {
              _vm.currentIndex = i
            }
          }
        })
      }),
      0
    ),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "feedback-ya",
        attrs: {
          href: "https://market.yandex.ru/shop/538132/reviews?sort_by=date",
          target: "_blank"
        }
      },
      [
        _c("img", {
          staticClass: "feedback-ya__logo",
          attrs: {
            src: "/images/icons/yandex-market.svg",
            alt: "Яндекс Маркет"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "feedback-link" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://market.yandex.ru/shop/538132/reviews?sort_by=date",
            target: "_blank"
          }
        },
        [_vm._v("\n      Оставить отзыв\n    ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }