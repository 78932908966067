var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product-item product-item_purchased",
      style:
        _vm.item.default.product_name_ru === "Отчет о рисках" ? "order: -1" : ""
    },
    [
      _c(
        "div",
        {
          staticClass: "product-item__left product-item__icon",
          class: {
            "text-success": _vm.isCompleted,
            "text-warning": !_vm.isCompleted
          }
        },
        [_c("svg", [_c("use", { attrs: { "xlink:href": _vm.iconStatus } })])]
      ),
      _c("div", { staticClass: "product-item__body" }, [
        _c("div", { staticClass: "product-item__name" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.item.default.product_name_ru) + "\n    "
          )
        ]),
        _c(
          "div",
          { staticClass: "product-item__desc" },
          [
            _vm.isCompleted
              ? [
                  _vm._v(
                    "\n        Выполнено " +
                      _vm._s(
                        _vm._f("date")(
                          _vm.getReadyDate(_vm.item.default.statuses),
                          "DD.MM.YYYY HH:mm"
                        )
                      ) +
                      "\n      "
                  )
                ]
              : [
                  _vm._v(
                    "\n        Ожидайте " +
                      _vm._s(_vm.remainingTimeMessage) +
                      "\n      "
                  )
                ]
          ],
          2
        )
      ]),
      _c("div", { staticClass: "product-item__right" }, [
        _vm.isCompleted && !_vm.isRiskProduct
          ? _c(
              "div",
              { staticClass: "product-item__buttons d-flex" },
              [
                _c(
                  "app-button",
                  {
                    staticClass: "text-nowrap mr-2",
                    attrs: { variant: "danger-light", size: "sm" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("risk-click")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-bolt" }),
                    _vm._v(" Риски\n      ")
                  ]
                ),
                _c(
                  "app-dropdown",
                  { attrs: { direction: "right", "close-on-select": "" } },
                  [
                    _c(
                      "app-button",
                      {
                        staticClass: "text-nowrap",
                        attrs: {
                          "data-dropdown-trigger": "",
                          variant: "primary-light",
                          size: "sm"
                        }
                      },
                      [
                        _c("i", { staticClass: "fas fa-cloud-download-alt" }),
                        _vm._v(" Скачать\n        ")
                      ]
                    ),
                    _c(
                      "app-dropdown-menu",
                      { staticClass: "product-item__download-menu" },
                      [
                        _c(
                          "app-menu-item",
                          {
                            nativeOn: {
                              click: function($event) {
                                return _vm.download(
                                  _vm.item.default.pdf_link,
                                  "pdf"
                                )
                              }
                            }
                          },
                          [
                            _c("svg", [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-pdf" }
                              })
                            ]),
                            _vm._v("\n            pdf документ\n          ")
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _c(
              "b",
              { staticClass: "text-nowrap" },
              [
                _vm.isRiskProduct && !_vm.formWasSended && !_vm.isCompleted
                  ? [
                      _c(
                        "app-risk-form",
                        {
                          attrs: { "item-id": _vm.item.default.order_item_id }
                        },
                        [_vm._v("\n          Заполнить форму\n        ")]
                      )
                    ]
                  : !_vm.isCompleted
                  ? _c("div", { staticClass: "text-sm-right" }, [
                      _vm._v("\n        В работе\n        "),
                      _vm.item.default.rosreestr_request_id
                        ? _c("div", { staticClass: "small text-muted" }, [
                            _vm._v(
                              "\n          Номер в РР " +
                                _vm._s(_vm.item.default.rosreestr_request_id) +
                                "\n        "
                            )
                          ])
                        : _vm._e()
                    ])
                  : [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center flex-wrap" },
                        [
                          _c(
                            "app-risk-form",
                            {
                              staticClass: "mr-3 mt-2",
                              attrs: {
                                "item-id": _vm.item.default.order_item_id,
                                "request-users": true
                              }
                            },
                            [
                              _vm._v(
                                "\n            Добавить данные собственника\n          "
                              )
                            ]
                          ),
                          _c(
                            "app-dropdown",
                            {
                              attrs: {
                                direction: "right",
                                "close-on-select": ""
                              }
                            },
                            [
                              _c(
                                "app-button",
                                {
                                  staticClass: "text-nowrap mt-2",
                                  attrs: {
                                    variant: "primary-light",
                                    size: "sm",
                                    "data-dropdown-trigger": ""
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-cloud-download-alt"
                                  }),
                                  _vm._v(" Скачать\n            ")
                                ]
                              ),
                              _c(
                                "app-dropdown-menu",
                                [
                                  _c(
                                    "app-menu-item",
                                    {
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.download(
                                            _vm.item.default.pdf_link,
                                            "pdf"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Полный отчёт\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "app-menu-item",
                                    {
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.download(
                                            _vm.item.default.pdf_link,
                                            "pdf",
                                            { type: "compact" }
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Без заключения\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "app-menu-item",
                                    {
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.download(
                                            _vm.item.default.pdf_link,
                                            "pdf",
                                            { type: "owner" }
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Только собственники\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
              ],
              2
            ),
        _vm.isRiskProduct && _vm.formWasSended && !_vm.isCompleted
          ? _c(
              "div",
              [
                _c(
                  "app-risk-form",
                  {
                    attrs: {
                      "item-id": _vm.item.default.order_item_id,
                      "request-users": true
                    }
                  },
                  [_vm._v("\n        Добавить данные собственника\n      ")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }