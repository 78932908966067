import Vue from 'vue';
import _ from 'lodash';
import { Module } from 'vuex';
import { Api } from 'shared/api';

export const settingsModule: Module<AppSettings, RootState> = {
  namespaced: true,

  state: {
    items: {},
  },

  actions: {
    async getItems({ commit }, key: string) {
      try {
        const items = await Api.settings.GetItems(key);

        commit('setItems', items);
      } catch (error) {
        console.error(error);
      }
    },

    removeItem({ commit }, key: string) {
      return Api.settings.RemoveItem(key).then(() => commit('removeItem', key));
    },

    updateTextValue({ commit }, body: ApiTypes.Settings.UpdateTextBody) {
      return Api.settings.UpdateTextValue(body).then(() => commit('setItem', body));
    },

    toggleEnabled({ commit }, body: ApiTypes.Settings.ToggleEnabledBody) {
      return Api.settings.ToggleEnabled(body).then(() => commit('setItem', body));
    },

    createItem({ commit }, body: ApiTypes.Settings.CreateItemBody) {
      return Api.settings.CreateItem(body).then(() => commit('setItem', body));
    },
  },

  mutations: {
    setItems(state, items: Admin.Settings.Item[]) {
      state.items = _.merge({}, state.items, _.keyBy(items, 'key'));
    },

    setItem(state, item: Admin.Settings.Item) {
      if (!state.items[item.key]) {
        Vue.set(state.items, item.key, item);
      } else {
        state.items[item.key] = _.merge({}, state.items[item.key], item);
      }
    },

    removeItem(state, key: string) {
      if (key in state.items) {
        Vue.delete(state.items, key);
      }
    },
  },
};
