import { RealtyCloudApi } from '../api';
import { AxiosRequestConfig } from 'axios';

import { environment } from 'shared/environment';

const token = 'cG9zaGVsX25haHVpX3BpZG9yYXNfaGFja2VybWFu';

export interface ApiAdvertMethods {
  List<T = AdvertsListResponse>(
    params: AdvertsListParams,
    config?: AxiosRequestConfig
  ): Promise<T>;
  Info<T = RealtyAdvert>(id: number, config?: AxiosRequestConfig): Promise<T>;
  GetCadastralInfo<T = RealtyAdvert>(
    number: string,
    config?: AxiosRequestConfig
  ): Promise<T>;
  GetReforma<T = RealtyAdvert>(
    address: string,
    config?: AxiosRequestConfig
  ): Promise<T>;
  SimilarList<T = RealtyAdvert>(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<T>;
  GetAveragePrice<T = AveragePriceData>(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<T>;
  GetAveragePriceByParams<T = AveragePriceData>(
    params: AveragePriceByParamsData,
    config?: AxiosRequestConfig
  ): Promise<T>;
  GetAdvertsByIds<T = AveragePriceData>(
    params: AdvertsGetByIdsParams,
    config?: AxiosRequestConfig
  ): Promise<T>;
  GetAdvertsByAddress<T = number[]>(
    params: AdvertsListParams,
    config?: AxiosRequestConfig
  ): Promise<T>;
  GetPriceChange<T = AveragePriceData>(
    params: PriceChangeParams,
    config?: AxiosRequestConfig
  ): Promise<T>;
  GetPriceChangeByParams<T = AveragePriceData>(
    params: PriceChangeByParams,
    config?: AxiosRequestConfig
  ): Promise<T>;
  GetIDAdvertByURL<T = number>(url: string): Promise<T>;
  GetPropertyDataFromAdvert<T = RealtyAdvert>(advertId: number): Promise<T>;
}

export function advertMethods(this: RealtyCloudApi): ApiAdvertMethods {
  return {
    List: (params: AdvertsListParams, config?: AxiosRequestConfig): any => {
      const data = params;

      return this.request({
        baseURL: environment.advertApiUrl,
        url: '/adverts/list',
        method: 'POST',
        data,
        ...config,
      }).then((data: AdvertsListResponse) => {
        return data;
      });
    },

    SimilarList: (id: number, config?: AxiosRequestConfig): any => {
      const params = { id };
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/api/adverts/getSimilarList',
        method: 'POST',
        params,
        ...config,
      }).then((data: any) => {
        return data;
      });
    },

    Info: (id: number, config?: AxiosRequestConfig): any => {
      const params = { id };
      return this.request({
        baseURL: environment.advertApiUrl,
        url: '/adverts/object',
        params,
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetCadastralInfo: (number: string, config?: AxiosRequestConfig): any => {
      const params = { kad_number: number };
      return this.request({
        baseURL: environment.advertApiUrl,
        url: '/adverts/object/kad',
        method: 'POST',
        data: params,
        ...config,
      }).then((data: CadastralInfoResponse) => {
        return data || [];
      });
    },

    GetReforma: (address: string, config?: AxiosRequestConfig): any => {
      const params = { address };
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/api/search/getRefByA',
        method: 'POST',
        params,
        ...config,
      }).then((data: ReformaResponse) => {
        return data;
      });
    },

    GetAveragePrice: (id, config?: AxiosRequestConfig): any => {
      const params = { id };
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/history/trend/getAverage',
        method: 'POST',
        data: params,
        headers: {
          token,
        },
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetAveragePriceByParams: (params, config?: AxiosRequestConfig): any => {
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/history/trend/getAverageParam',
        method: 'POST',
        data: params,
        headers: {
          token,
        },
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetPriceChange: (params, config?: AxiosRequestConfig): any => {
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/history/trend/getPriceChange',
        method: 'POST',
        data: params,
        headers: {
          token,
        },
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetPriceChangeByParams: (params, config?: AxiosRequestConfig): any => {
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/history/trend/getPriceChangeParam',
        method: 'POST',
        data: params,
        headers: {
          token,
        },
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetAdvertsByIds: ({ ids, fields }, config?: AxiosRequestConfig): any => {
      const data = { ids };
      const params = { fields };
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/api/dom/getByIDs',
        method: 'POST',
        data,
        params,
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetAdvertsByAddress: (params, config?: AxiosRequestConfig): any => {
      return this.request({
        baseURL: environment.rcDataUrl,
        url: '/api/search/getByAddress',
        method: 'POST',
        params,
        ...config,
      }).then((data: any) => {
        return data || [];
      });
    },

    GetIDAdvertByURL: async (url: string) => {
      const requestUrl: string = `${environment.rcDataUrl}/api/adverts/getIDAdvertByURL?url=${url}`;
      const { data } = await this._axios.post(requestUrl);
      return data.id[0];
    },

    GetPropertyDataFromAdvert: async (advertId: number) => {
      const params = { ids: [ advertId ] };
      const requestUrl: string = `${environment.rcDataUrl}/api/adverts/getByIDs?fields=address`;
      const { data } = await this._axios.post(requestUrl, params);
      return data.data[0];
    },
  };
}
