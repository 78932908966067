import { RealtyCloudApi } from 'shared/api/api';

export interface ApiUserMethods {
  UpdatePassword<T = User>(data: UpdatePasswordData): Promise<T>;
  UpdateInfo<T>(data: User): Promise<T>;
  OrdersList<T = UserOrderResponse[]>(params?: any): Promise<T>;
  CreateBillPdf<T = any>(params: BillData): Promise<T>;
  SendVerificationCode<T = any>(params?: SimpleObject): Promise<T>;
  VerifyPhone<T = any>(data: VerifyPhoneData): Promise<T>;
  GetUserSettings<T = any>(params?: UserSettingsParams): Promise<T>;
  SetUserSettings<T = any>(data: SetUserSettingsData): Promise<T>;
  UpdateUserSettings<T = any>(data: SetUserSettingsData): Promise<T>;
  DeleteUserSettings<T = any>(data: DeleteUserSettingsData): Promise<T>;
}

export function userMethods(this: RealtyCloudApi): ApiUserMethods {
  return {
    UpdateInfo: (data: User) => {
      return this.request({
        url: '/person',
        method: 'PUT',
        data,
      });
    },

    UpdatePassword: (data: UpdatePasswordData) => {
      return this.request({
        url: '/person/update_password',
        method: 'PUT',
        data,
      });
    },

    GetUserSettings: (params: UserSettingsParams = {}) => {
      return this.request({ url: '/person/settings', params });
    },

    SetUserSettings: (data: SetUserSettingsData) => {
      return this.request({
        url: '/person/settings/add',
        method: 'POST',
        data,
      });
    },

    UpdateUserSettings: (data: SetUserSettingsData) => {
      return this.request({
        url: '/person/settings/update',
        method: 'PUT',
        data,
      });
    },

    DeleteUserSettings: (data: DeleteUserSettingsData) => {
      return this.request({
        url: '/person/settings/remove',
        method: 'DELETE',
        data,
      });
    },

    OrdersList: (params?: any) => {
      return this.request({ url: '/orders', params });
    },

    CreateBillPdf: (params: BillData) => {
      return this.request({ url: '/invoice', params });
    },

    SendVerificationCode: (params?: SimpleObject) => {
      return this.request({ url: '/person/phone/send_verification_code', params });
    },

    VerifyPhone: (data: VerifyPhoneData) => {
      return this.request({
        url: '/person/phone/verify',
        method: 'POST',
        data,
      });
    },
  };
}
