import { RealtyCloudApi } from 'shared/api/api';

export interface ApiPartnerMethods {
  /** Список заказов партнёра */
  Orders<T = PartnerOrderItem[]>(params: AdminOrdersParamsBase): Promise<T>;
  Stats<T = PartnerStats>(): Promise<T>;
}

export function partnerMethods(this: RealtyCloudApi): ApiPartnerMethods {
  return {
    Orders: (params: AdminOrdersParamsBase) => {
      return this.request({
        url: '/partner/orders',
        params,
      });
    },

    Stats: () => {
      return this.request({ url: '/partner/stats' });
    },
  };
}
