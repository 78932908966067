




































































































import Vue from 'vue';

export default Vue.extend({
  name: 'AppObjectDetailInfo',

  props: {
    objectInfo: Object as () => RealtyObject,
    hasManualProducts: Boolean,
    infoLoading: Boolean,
  },

  // computed: {
  //   iconByObjectType(): string {
  //     switch (this.objectInfo.ObjectType.toLowerCase()) {
  //       case 'здание':
  //         return '#icon-building';
  //       case 'помещение':
  //         return '#icon-room';
  //       default:
  //         return '#icon-area';
  //     }
  //   },
  // },
});
