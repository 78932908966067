import { Store } from 'vuex';

interface HandlerItem {
  type: 'mutation' | 'action' | string;
  name: string;
}

interface ListenerConfig {
  handlers: HandlerItem[];
  actionListeners?: string[];
  mutationListeners?: string[];
}

export default function storeListener(config: ListenerConfig[]) {
  return (store: Store<any>) => {
    // вызывается после инициализации хранилища
    const actions: SimpleObject<HandlerItem[]> = {};
    const mutations: SimpleObject<HandlerItem[]> = {};

    for (const item of config) {
      if (item.actionListeners) {
        for (const actionName of item.actionListeners) {
          actions[actionName] = item.handlers;
        }
      }

      if (item.mutationListeners) {
        for (const mutationName of item.mutationListeners) {
          mutations[mutationName] = item.handlers;
        }
      }
    }

    store.subscribe((mutation) => {
      const mutationListeners = mutations[mutation.type];
      if (mutationListeners) {
        mutationListeners.forEach((item: HandlerItem) => {
          if (item.type === 'mutation') {
            store.commit(item.name);
          }

          if (item.type === 'action') {
            store.dispatch(item.name);
          }
        });
      }
    });

    store.subscribeAction((action) => {
      const actionListeners = actions[action.type];
      if (actionListeners) {
        actionListeners.forEach((item: HandlerItem) => {
          if (item.type === 'mutation') {
            store.commit(item.name);
          }

          if (item.type === 'action') {
            store.dispatch(item.name);
          }
        });
      }
    });
  };
}
