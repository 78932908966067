import Component, { mixins } from 'vue-class-component';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import forIn from 'lodash/forIn';
import _ from 'lodash';
// import mapKeys from 'lodash/mapKeys';
// import countBy from 'lodash/countBy';
// import flatMap from 'lodash/flatMap';
// import uniq from 'lodash/uniq';
import values from 'lodash/values';
import { AxiosRequestConfig } from 'axios';
import { saveAs } from 'file-saver';
import pluralize from '@/core/utils/pluralize';
import LocStorage from '@/core/utils/localStorage';
import { RISK_EXAMPLES } from 'shared/utils/constants/examples';
import VideoServiceDialog from '@/components/layout/Dialogs/VideoService.vue';
import AppProductItem from 'shared/components/Product/Item/ProductItem';
import AppRiskForm from '@/views/ObjectDetail/components/RiskForm.vue';
import BaseOrderMixin from '../BaseOrder.mixin';
import AppReviewDialog from '@/components/layout/Dialogs/ReviewDialog.vue';
import AppReportExample from 'shared/components/Risk/ReportExample.vue';
// import AppRiskReport from 'shared/components/RiskNew/UserReport/Report.vue';
import AppProductInfoDialog from 'shared/components/Product/ProductInfoDialog.vue';
import AppConclusions from 'shared/components/Risk/Conclusions.vue';
import AppRiskConclusionGeneralStatus from 'shared/components/Risk/RiskConclusionGeneralStatus.vue';
import AppRiskConclusionItem from 'shared/components/Risk/RiskConclusionItem.vue';

// import AppStatementEncumbrance from 'shared/components/Statement/StatementEncumbrance.vue';
// import AppStatementEgrnOwners from 'shared/components/Statement/StatementEgrnOwners.vue';
// import AppStatementRightListHistory from 'shared/components/Statement/StatementRightListHistory.vue';
import isUnderage from 'shared/utils/isUnderage';

import ProductEmitter, { ProductEmitterEvents } from 'shared/components/Product/productEmitter';

@Component({
  name: 'AppRisk',

  components: {
    AppProductItem,
    AppRiskForm,
    AppReportExample,
    AppConclusions,
    AppRiskConclusionGeneralStatus,
    AppRiskConclusionItem,
  },
  // props: {
  //   item: Object,
  //   // products: Array,
  // },
})
export default class RcRisk extends mixins(BaseOrderMixin) {
  // data()
  productName: string = 'RiskAssessment';
  report: RiskReport = null;

  // computed
  get riskAssessment(): Product {
    if (
      !this.$store.getters['details/purchasedItem']({
        product_name: 'RiskAssessment',
      })
    ) {
      return this.$store.getters['details/purchasedItem']({
        product_name: 'RiskAssessmentFast',
      } as Product);
    }
    return this.$store.getters['details/purchasedItem']({
      product_name: 'RiskAssessment',
    } as Product);
  }

  get egrnObject(): Product {
    return this.$store.getters['details/purchasedItem']({
      product_name: 'EgrnObject',
      status: 'done',
    } as Product);
  }

  get egrnRightList(): Product {
    return this.$store.getters['details/purchasedItem']({
      product_name: 'EgrnRightList',
      status: 'done',
    } as Product);
  }

  get encumbrance(): any {
    const encumbrance = get(this.egrnObject, 'data.extract_object.rights[0].encumbrance');
    if (encumbrance) {
      return encumbrance;
    }

    return null;
  }

  get rights(): any {
    const rights = get(this.egrnObject, 'data.extract_object.rights[0].entities');

    if (rights) {
      const registraitonItem = get(this.egrnObject, 'data.extract_object.rights[0].registration');
      return groupBy(rights, (item) => {
        if (!item.registration || !item.registration.reg_date) {
          item.registration = registraitonItem;
        }

        return item.registration.reg_date;
      });
    }

    return null;
  }

  get rightsCount(): number {
    let rightsCount = 0;
    if (this.rights) {
      forIn(this.rights, (value) => {
        rightsCount += values(value.orgsCount).length + values(value.personsCount).length;
      });
    }
    return rightsCount;
  }

  get rightsHistory(): any {
    const rights = get(this.egrnRightList, 'data.extract_object.rights[0].entities');
    if (rights) {
      const items = groupBy(rights, 'registration.reg_date');
      const reg = /(\d{2})\.(\d{2})\.(\d{4})/;
      const replaceString = '$3-$2-$1';
      const dates = Object.keys(items).sort((a, b) => {
        const dateA = a.replace(reg, replaceString);
        const dateB = b.replace(reg, replaceString);

        return new Date(dateA).getTime() - new Date(dateB).getTime();
      });

      return { dates, items };
    }

    return null;
  }
  get hasOwners() {
    return _.size(_.get(this.report, 'owners')) !== 0;
  }

  get hasConclusion(): boolean {
    return _.size(_.get(this.report, 'conclusion.parsed')) !== 0;
  }
  // } computed end

  // lifecycle hooks
  created() {
    if (this.riskAssessment) {
      this.getReport();
    }
  }

  mounted(): void {
    ProductEmitter.on(ProductEmitterEvents.showDialog, this.showRiskInfo);
    ProductEmitter.on(ProductEmitterEvents.pay, this.checkout);
  }

  beforeDestroy(): void {
    ProductEmitter.off(ProductEmitterEvents.pay, this.checkout);
    ProductEmitter.off(ProductEmitterEvents.showDialog, this.showRiskInfo);
  }

  // methods
  async download(url: string, fileType: string, params?: AxiosRequestConfig['params']) {
    const fileName = `${this.riskAssessment.product_name_ru}`;
    let response: Blob;

    try {
      response = await this.$api.raw.files.GetFileBlob(url, params);

      await saveAs(response, `${fileName}.${fileType}`);

      if (!LocStorage.hasItem('review')) {
        this.$dialog.open({ component: AppReviewDialog });
      }
    } catch (error) {
      this.$noty.error({ text: 'Произошла ошибка при скачивании, пожалуйста, попробуйте еще раз' });
      return this.$api.HttpError(error);
    }
  }

  async getReport() {
    try {
      this.report = await this.$api.risk.GetReport(this.riskAssessment.order_item_id);
    } catch (error) {
      console.error(error);
    }
  }

  isEmpty(path: string) {
    return _.isEmpty(_.get(this.report, path));
  }

  pluralizeMsg(type: string, count: number) {
    let words: [string, string, string];

    switch (type) {
      case 'encumbrance':
        words = [ 'обременение', 'обременения', 'обременений' ];
        break;
      case 'owner':
        words = [ 'собственник', 'собственника', 'собственников' ];
        break;
      case 'rights':
        words = [ 'право', 'права', 'прав' ];
        break;
    }

    return pluralize(count, ...words);
  }

  sortByDate(dateKeyName: string = 'created_date') {
    return (prev: any, next: any) =>
      new Date(get(prev, dateKeyName)).getTime() - new Date(get(next, dateKeyName)).getTime();
  }

  showVideoDialog() {
    this.$dialog.open({ component: VideoServiceDialog });
  }

  showExamples() {
    this.$photoSwipe(RISK_EXAMPLES);
  }

  showRiskInfo(productName: ProductName): void {
    if (_.startsWith(productName, 'RiskAssessment')) {
      this.$dialog.open({
        component: AppProductInfoDialog,
        props: {
          product: this.product,
        },
      });
    }
  }

  isUnderage = isUnderage
}
