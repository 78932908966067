import { Api } from 'shared/api';
import { RealtyCloudApi } from 'shared/api/api';

export interface ApiTariffMethods {
  Items<T = Api.Tariff.Response.GetItems>(category?: any): Promise<T>;
  GetCurrent<T = Model.Tariff.Item>(): Promise<T>;
  GetCurrentForUser<T = Model.Tariff.Item>(params: Model.GetCurrentForUserData): Promise<T>;
  Activate<T = any>(id: number): Promise<T>;
  ActivateForUser<T = any>(data: Model.ActivateTariffForUserBody): Promise<T>;
  UpdateForUser<T = any>(data: Model.UpdateTariffForUserBody): Promise<T>;
  DeleteForUser<T = any>(data: Model.DeleteTariffForUserBody): Promise<T>;
}

export function tariffMethods(this: RealtyCloudApi): ApiTariffMethods {
  return {
    Items: (category: string = '') => {
      return this.request({
        url: '/tariff/get_all',
        params: {
          category: category,
        },
      });
    },

    GetCurrent: () => {
      return this.request({ url: '/tariff/get_current' });
    },

    GetCurrentForUser: (params: Model.GetCurrentForUserData) => {
      return this.request({ url: '/tariff/get_current_for_user/', params });
    },

    Activate: (id: number) => {
      return this.request({
        url: '/tariff/activate',
        method: 'POST',
        data: { tariff_simple_id: id },
      });
    },

    ActivateForUser: (data) => {
      return this.request({
        url: '/tariff/activate_for_user',
        method: 'POST',
        data,
      });
    },

    UpdateForUser: (data) => {
      return this.request({
        url: '/tariff/update_for_person',
        method: 'PUT',
        data,
      });
    },

    DeleteForUser: (data) => {
      return this.request({
        url: '/tariff/delete_for_person',
        method: 'DELETE',
        data,
      });
    },
  };
}
