var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth" },
    [
      _c(
        "div",
        {
          staticClass: "auth-close",
          on: {
            click: function($event) {
              return _vm.$dialog.close(_vm.index)
            }
          }
        },
        [
          _c("svg", [
            _c("use", { attrs: { "xlink:href": "#icon-arrow-right" } })
          ])
        ]
      ),
      _c(_vm.currentFormComponent, {
        tag: "component",
        attrs: { "change-form": _vm.changeForm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }