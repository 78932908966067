






















































































import Component, { mixins } from 'vue-class-component';

import ChooseRoleForm from 'shared/components/User/ChooseRoleForm.vue';

@Component
export default class AppChooseRoleFormAlt extends mixins(ChooseRoleForm) {

}
