var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "collapsible__item",
      class: {
        collapsible__item_active: _vm.visible,
        collapsible__item_disabled: _vm.disabled
      }
    },
    [
      _vm.title || _vm.$slots.title
        ? _c(
            "div",
            {
              staticClass: "collapsible__trigger",
              class: { collapsible__trigger_active: _vm.visible },
              on: {
                click: function($event) {
                  return _vm.toggleCollapse()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "collapsible__title" },
                [
                  _vm._t("title", [
                    _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
                  ])
                ],
                2
              ),
              _c("client-only", [
                !_vm.isServer && !_vm.disabled
                  ? _c(
                      "div",
                      { staticClass: "collapsible__icon" },
                      [
                        _vm._t("icon", [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z"
                                }
                              })
                            ]
                          )
                        ])
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "app-collapse-content",
        { attrs: { visible: _vm.visible } },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }