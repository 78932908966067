var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "popup__body py-3 px-4" },
      [
        _c("app-dialog-close"),
        _vm._m(0),
        _c(
          "app-form",
          {
            attrs: {
              "request-handler": _vm.onSubmit,
              model: _vm.model,
              rules: _vm.validationRules
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function(ref) {
                  var loading = ref.loading
                  return [
                    _c(
                      "app-button",
                      {
                        attrs: {
                          type: "submit",
                          disabled: _vm.sendSuccess,
                          loading: loading,
                          block: ""
                        }
                      },
                      [_vm._v("\n          Получить предложение\n        ")]
                    )
                  ]
                }
              }
            ])
          },
          [
            _c(
              "app-form-group",
              { attrs: { label: "Ваш e-mail", "model-name": "email" } },
              [
                _c("app-input", {
                  attrs: { type: "email", placeholder: "E-mail" },
                  model: {
                    value: _vm.model.email,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "email", $$v)
                    },
                    expression: "model.email"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              { attrs: { label: "Номер телефона", "model-name": "phone" } },
              [
                _c("app-phone-number-input", {
                  model: {
                    value: _vm.model.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "phone", $$v)
                    },
                    expression: "model.phone"
                  }
                })
              ],
              1
            ),
            _vm.sendSuccess
              ? _c(
                  "div",
                  { staticClass: "alert alert-success text-center small" },
                  [
                    _vm._v(
                      "\n        Запрос успешно отправлен, ожидайте, мы скоро с вами свяжемся\n      "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "alert alert-info text-center small rounded-0 border-left-0 border-right-0 border-bottom-0 mb-0"
      },
      [
        _vm._v('\n    Нажимая "Получить предложение" вы соглашаетесь с '),
        _c("br"),
        _c(
          "router-link",
          {
            attrs: { to: { name: "privacy" } },
            nativeOn: {
              click: function($event) {
                return _vm.$dialog.close($event)
              }
            }
          },
          [_vm._v("\n      политикой о персональных данных\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("h4", [_vm._v("Отчеты из ЕГРН")]),
      _c("h4", [_vm._v("оптом от 15 руб./шт.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }