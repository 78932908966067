import Vue from 'vue';
import currency from 'currency.js';

export default function currencyFilter(_Vue: typeof Vue) {
  _Vue.filter(
    'currency',
    (value: number | string, options?: currency.Options) => {
      let defaultOptions = { symbol: '', separator: ' ', precision: 0 };

      if (typeof value === 'string') {
        const parsedValue = parseFloat(value);

        if (isNaN(parsedValue)) {
          return value;
        } else {
          value = parsedValue;
        }
      }

      if (options) {
        defaultOptions = { ...defaultOptions, ...options };
      }

      value = currency(value, defaultOptions).format();

      return `${value} ₽`;
    },
  );
}
