

































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  popupOptions: {
    size: 'sm',
  },

  props: {
    text: {
      type: String,
      default: '<h5>Платёж успешно прошёл <br /> Ваш заказ принят в обработку</h5>',
    },
  },
})
export default class PaymentSuccess extends Vue {}
