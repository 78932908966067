var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body text-center" },
    [
      _c("app-dialog-close"),
      _vm._m(0),
      _c(
        "app-button",
        {
          attrs: { loading: _vm.loading, size: "lg" },
          on: { click: _vm.getDiscount }
        },
        [
          _vm._v("\n    Скачать выписку за " + _vm._s(_vm.discountPrice) + " "),
          _c("i", { staticClass: "fa fa-rub" })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "mb-4" }, [
      _vm._v("\n    А вот и скидка "),
      _c("br"),
      _vm._v("\n    Только сегодня и только для Ваc "),
      _c("br"),
      _vm._v("\n    -20%\n  ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }