


















import Vue from 'vue';

export default Vue.extend({
  name: 'AppDiscountDialog',
  popupOptions: { size: 'sm' },
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    discountPrice(): number {
      return Math.floor(this.egrnObjectPrice - this.egrnObjectPrice * 0.2);
    },

    egrnObjectPrice(): number {
      const egrnObject: Product = this.$store.getters.getProduct({
        product_name: 'EgrnObject',
      } as Product);

      if (egrnObject) {
        return parseInt(egrnObject.product_price as any, 10);
      }

      return 249;
    },
  },

  methods: {
    getDiscount() {
      this.loading = true;
      location.replace(location.pathname + '?utm_campaign=discount20egrn');
    },
  },
});
