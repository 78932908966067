







import Vue from 'vue';
import noop from 'lodash/noop';
import AppChooseRoleFormAlt from 'shared/components/User/ChooseRoleFormAlt.vue';

export default Vue.extend({
  name: 'AppChooseRoleDialog',

  components: { AppChooseRoleFormAlt },

  props: {
    firstShow: {
      type: Boolean,
      default: false,
    },
    onRoleChanged: {
      type: Function,
      default: noop,
    },
  },

  popupOptions: {
    name: 'popup_default popup_chooseRole',
  },

  methods: {

  },
});
