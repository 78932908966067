


























import Vue from 'vue';
import AppUserMenu from '@/components/layout/UserMenu/UserMenu.vue';

export default Vue.extend({
  components: { AppUserMenu },

  data() {
    return {
      showWidget: false,
      showSidebar: true,
    };
  },

  computed: {
    user(): any {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    const w: any = window;
    (w.adsbygoogle = w.adsbygoogle || []).push({});
  },

  watch: {
    $route: {
      handler(currentRoute) {
        this.showWidget = currentRoute.name === 'object-detail';
        this.showSidebar = currentRoute.name !== 'usecases';
      },
      immediate: true,
    },
  },
});
