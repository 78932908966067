import {
  likeCadastralNumber,
  isCadastralNumber,
  CADASTRAL_NUMBER_WITH_WRONG_SEPARATORS_REGEX,
  SEPARATORS,
} from './helpers';

// Примеры неверных кадастровых номеров
// 71:08: 050101: 633
// 50;20;0090503;163
// 50:23:005 05 20:0074
// 50 : 10 : 0010317 : 35
// 47^16^0101006^2126
// 23 : 02 : 407004 : 6
// 23:43:0303067::212
// 50  10  0010317  35
// 44310104061288
// 90220103152096

//  Статья из вики https://ru.wikipedia.org/wiki/Кадастровый_номер
//  кадастровый номер земельного участка выглядит так: АА:ВВ:CCCCСCC:КК, где:
//  АА — кадастровый округ.
//  ВВ — кадастровый район.
//  CCCCCCС — кадастровый квартал состоит из 6 или 7 цифр.
//  КК — номер земельного участка.

interface FormattedCadastralResponse {
  /** То же значение которое приходит в параметре функции */
  raw: string;

  /** Результат форматирования, если ничего не получилось, то будет null */
  result: string | null;

  /** Даём знать, получилось ли у нас отформатировать или нет */
  success: boolean;
}

/**
 * Функция преобразует неправильные кадастровые номера в нормальные
 *
 * Принимает кадастровый номер, или любую строку, но если она не похожа на кадастр, то свойство result будет null
 */
export default function formatter(value: string): FormattedCadastralResponse {
  // Проверяем, похоже ли это на кадастровый номер, если нет, то игнорим форматирование
  if (likeCadastralNumber(value)) {
    let formattedCadastralNumber: string;
    // Убираем все пробелы
    const withoutSpacesCadastralNumber = value.replace(/\s/g, '');

    const SEPARATORS_REGEX = new RegExp(`(.*${SEPARATORS}.*){3}`);

    // Проверяем, есть ли в строке разделители
    if (SEPARATORS_REGEX.test(withoutSpacesCadastralNumber)) {
      // Если они есть, то у нас просто неправильные разделители 47^16^0101006^2126

      // Форматируем
      formattedCadastralNumber = withoutSpacesCadastralNumber.replace(
        CADASTRAL_NUMBER_WITH_WRONG_SEPARATORS_REGEX,
        '$1:$2:$3:$4',
      );
    } else {
      // Если их нет, значит кадастровый может иметь такой вид 50  10  0010317  35 или 90220103152096

      if (!/\s/.test(value)) {
        // Это случай если кадастровый номер имеет вид без разделителей и пробелов 90220103152096 или 44310104061288
        // Тут на самом деле уже тотальная ошибка самого пользователя, но мы всё равно преобразуем это в кадастр, хоть и неверный

        // Форматируем в короткий номер т.е. где $3 это шесть символов
        formattedCadastralNumber = value.replace(/(\d{2})(\d{2})(\d{7})(\d{1,})/, '$1:$2:$3:$4');
      } else {
        // Иначе просто добавляем разделители, т.к. они отсутсвуют
        value = value.replace(
          /(\d{2})\s+(\d{2})\s+(\d{6,7})\s+(\d{1})/,
          '$1:$2:$3:$4',
        );
        formattedCadastralNumber = value.replace(/\s/g, '');
      }
    }

    // На крайний случай проверяем валидность отформатированного кадастрового номера
    if (isCadastralNumber(formattedCadastralNumber)) {
      return {
        raw: value,
        result: formattedCadastralNumber,
        success: true,
      };
    }
  }

  // Пустой результат, если это вообще не было похоже на кадастровый номер
  return {
    raw: value,
    result: null,
    success: false,
  };
}
