import Vue from 'vue';

import DialogRoot from './DialogRoot.vue';
import DialogClose from './DialogClose.vue';
import DialogEventBus from './dialogEventBus';
import RegisteredDialogsStore from './globalRegisteredDialogs';

import { DialogOptions } from './dialog-plugin';

const DEFAULT_OPTIONS: DialogOptions = {
  registerGlobalDialogs: {},
};

export default function initDialogPlugin<T extends DialogOptions>(_Vue: typeof Vue, config: T) {
  const options = { ...DEFAULT_OPTIONS, ...config };

  for (const componentName in options.registerGlobalDialogs) {
    const component = options.registerGlobalDialogs[componentName];
    RegisteredDialogsStore[componentName] = component;
  }

  _Vue.component('app-dialog-close', DialogClose);
  _Vue.component('app-dialog-root', DialogRoot);

  _Vue.prototype.$dialog = {
    open: DialogEventBus.emit.bind(DialogEventBus, 'open'),
    close: DialogEventBus.emit.bind(DialogEventBus, 'close'),
  };
}
