













































import Vue from 'vue';
import Component from 'vue-class-component';
import findIndex from 'lodash/findIndex';

import AppPollItem from './PollItem.vue';

@Component({
  name: 'Apppoll',
  components: { AppPollItem },
  props: {
    items: Array,
  },
})
export default class Apppoll extends Vue {
  // props
  items: PollItem[];

  // data()
  selected: AnswerSelect = null;
  step: number = 0;
  polls: PollItem[] = [];

  // computed
  get user(): User {
    return this.$store.state.auth.user;
  }

  // lifecycle hooks
  created() {
    this.polls = this.items.map(this.mapPropItems);
  }

  mounted() {
    this.setWrapperHeight(this.$refs.wrapper as HTMLElement);
  }

  // methods
  answerSelected(answerSelect: AnswerSelect) {
    this.selected = answerSelect;
  }

  next() {
    if (!this.selected) {
      return;
    }

    if (this.user) {
      const data: PollJSONData = {
        person_id: this.user.id,
        question: this.selected.question,
        answer: this.selected.answer.text,
      };

      this.$api.other.Poll(data);
    }

    let step = this.step + 1;
    const { goTo } = this.selected.answer;

    if (goTo != null) {
      if (goTo === 'end') {
        step = this.polls.length;
      } else {
        step = findIndex(this.polls, { id: goTo });
      }
    }

    this.step = step;
    this.selected = null;
  }

  animationEnter(el: HTMLDivElement) {
    this.setWrapperHeight(el);
  }

  setWrapperHeight(target: HTMLElement) {
    const wrapper: HTMLDivElement = this.$refs.wrapper as HTMLDivElement;
    if (wrapper) {
      wrapper.style.height = `${target.offsetHeight}px`;
    }
  }

  mapPropItems(poll: PollItem): PollItem {
    const item: PollItem = { ...poll };
    let answers: AnswerItem[] = [];

    poll.answers.forEach((answer: any) => {
      if (typeof answer === 'string') {
        answers.push({ text: answer });
      } else {
        answers.push(answer);
      }
    });

    item.answers = answers;

    return item;
  }
}
