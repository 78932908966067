var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "pswp",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c("div", { staticClass: "pswp__bg" }),
        _c("div", { staticClass: "pswp__scroll-wrap" }, [
          _c("div", { staticClass: "pswp__container" }, [
            _c("div", { staticClass: "pswp__item" }),
            _c("div", { staticClass: "pswp__item" }),
            _c("div", { staticClass: "pswp__item" })
          ]),
          _c("div", { staticClass: "pswp__ui pswp__ui--hidden" }, [
            _c("div", { staticClass: "pswp__top-bar" }, [
              _c("div", { staticClass: "pswp__counter" }),
              _c("button", {
                staticClass: "pswp__button pswp__button--close",
                attrs: { title: "Close (Esc)" }
              }),
              _c("button", {
                staticClass: "pswp__button pswp__button--share",
                attrs: { title: "Share" }
              }),
              _c("button", {
                staticClass: "pswp__button pswp__button--fs",
                attrs: { title: "Toggle fullscreen" }
              }),
              _c("button", {
                staticClass: "pswp__button pswp__button--zoom",
                attrs: { title: "Zoom in/out" }
              }),
              _c("div", { staticClass: "pswp__preloader" }, [
                _c("div", { staticClass: "pswp__preloader__icn" }, [
                  _c("div", { staticClass: "pswp__preloader__cut" }, [
                    _c("div", { staticClass: "pswp__preloader__donut" })
                  ])
                ])
              ])
            ]),
            _c(
              "div",
              {
                staticClass:
                  "pswp__share-modal pswp__share-modal--hidden pswp__single-tap"
              },
              [_c("div", { staticClass: "pswp__share-tooltip" })]
            ),
            _c("button", {
              staticClass: "pswp__button pswp__button--arrow--left",
              attrs: { title: "Previous (arrow left)" }
            }),
            _c("button", {
              staticClass: "pswp__button pswp__button--arrow--right",
              attrs: { title: "Next (arrow right)" }
            }),
            _c("div", { staticClass: "pswp__caption" }, [
              _c("div", { staticClass: "pswp__caption__center" })
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }