



























































import Vue from 'vue';
import { Validators } from 'shared/utils/validator';
export default Vue.extend({
  name: 'AppMobilePhoneProveDialog',

  popupOptions: {
    size: 'xs',
  },

  props: {
    onApproved: Function,
  },

  data() {
    return {
      model: {
        phone: '',
        smsCode: '',
      },
      phoneEntered: false,
      error: null,
      loading: false,
    };
  },

  methods: {
    async onSubmit() {
      this.error = null;
      this.loading = true;
      if (!this.phoneEntered) {
        this.phoneEntered = true;
        try {
          if (this.model.phone !== this.user.phone) {
            await this.$api.user.SendVerificationCode({
              phoneNumber: this.model.phone,
            });
          } else {
            await this.$api.user.SendVerificationCode();
          }
        } catch (error) {
          let text = this.$api.getErrorMessage(error);
          if (/существует/.test(text) || /занят/.test(text)) {
            text = 'Данный номер телефона уже есть в системе и привязан к другому аккаунту. Для уточнения деталей, обратитесь в техническую поддержку с указанием текущего аккаунта и номера телефона.';
          }
          this.error = text;
          this.phoneEntered = false;
        }
        this.loading = false;
      } else {
        try {
          const res = await this.$api.user.VerifyPhone({ code: this.model.smsCode });
          if (res.data.status === 'OK') {
            // if (this.model.phone !== this.user.phone) {
            //   try {
            //     await this.$store.dispatch('user/updateInfo', {
            //       email: this.user.email,
            //       id: this.user.id,
            //       name: this.user.name,
            //       phone: this.model.phone,
            //       surname: this.user.surname,
            //     });
            //     this.$noty.info({ text: 'Профиль успешно обновлен' });
            //     this.onApproved();
            //   } catch (error) {
            //     const text = (error as Error).message;
            //     this.$noty.error({ text });
            //   }
            // }
            this.$dialog.close();
          } else {
            throw new Error(res.data.status);
          }
        } catch (error) {
          this.error = this.$api.getErrorMessage(error);
        }
        this.loading = false;
      }
    },
    validationRules(): ValidationConfig {
      const config: ValidationConfig = {};
      if (!this.phoneEntered) {
        config.phone = [ Validators.required, Validators.phone ];
      } else {
        config.smsCode = [ Validators.required, Validators.positiveNumber, Validators.min(4, 'должно быть 4 цифры'), Validators.max(4, 'должно быть 4 цифры') ];
      }
      return config;
    },
  },

  computed: {
    user(): User {
      return this.$store.state.auth.user;
    },
  },

  created() {
    this.model.phone = this.user.phone;
  },
});

