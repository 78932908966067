












import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppDropzone',
})
export default class Dropzone extends Vue {
  // data()
  entered: boolean = false;

  // methods
  onEnter() {
    this.entered = true;
    this.$emit('enter');
  }

  onLeave() {
    this.entered = false;
    this.$emit('leave');
  }

  onDrop(event: DragEvent) {
    const files = event.dataTransfer.files;
    this.entered = false;
    this.$emit('drop', files);
  }
}
