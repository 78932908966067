












































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

function randomName() {
  return Math.random()
    .toString(36)
    .substring(7);
}

@Component({
  name: 'AppCollapseItem',

  inject: [ 'Collapse' ],

  props: {
    title: [ String, Number ],
    disabled: Boolean,
    name: {
      type: [ String, Number ],
      default: randomName,
    },
    opened: Boolean,
  },
})
export default class CollapseItem extends Vue {
  // props types
  title!: string;

  disabled!: boolean;

  name!: string;

  opened!: boolean;
  // props types end

  // inject type
  Collapse!: any;
  // inject type end

  @Watch('opened')
  changedOpened(value: boolean): void {
    this.toggleCollapse(value);
  }

  get isServer() {
    return this.$isServer;
  }

  get visible(): boolean {
    return (this as any).Collapse.activeItems.some((name: string) => name === this.name);
  }

  // lifecycle
  created() {
    if (this.opened) {
      this.toggleCollapse(true);
    }
  }

  // methods
  toggleCollapse(forceOpen?: boolean) {
    if (!this.disabled || forceOpen) {
      this.Collapse.setActiveItem(this.name);
    }
  }
}
