import removeTypesNamespace from 'shared/utils/storeNamespaceHelper';

export const ProductTypes = {
  namespace: 'products',

  getters: {
    GET_PRODUCT: 'getProduct',
    FILTER_PRODUCTS: 'filterProducts',
    GET_MIN_PRICE_OBJECT_PRODUCT: 'getMinPriceObjectProduct',
  },

  actions: {
    GET_PRODUCTS: 'getProducts',
    REMOVE_USED_COUPONS: 'removeUsedCoupons',
  },

  mutations: {
    SET_PRODUCTS: 'setProducts',
  },
};

export const _ProductTypes = removeTypesNamespace<typeof ProductTypes>('products/', ProductTypes);
