var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin" },
    [
      _c(
        "div",
        { staticClass: "admin-header" },
        [
          _c("app-header"),
          _c("div", { staticClass: "admin-navs" }, [
            _c("div", { staticClass: "container" }, [
              _c(
                "ul",
                { staticClass: "nav nav-tabs nav-tabs_underline" },
                [
                  _vm.isAdmin || _vm.isPartner
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                "active-class": "active",
                                to: { name: "admin-orders" },
                                exact: ""
                              }
                            },
                            [_vm._v("\n              Заказы\n            ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPartner
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                "active-class": "active",
                                to: { name: "admin-partner-finance" }
                              }
                            },
                            [_vm._v("\n              Финансы\n            ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPartner
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                "active-class": "active",
                                to: { name: "admin-partner-instruments" }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Инструменты\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdmin || _vm.isManager
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                "active-class": "active",
                                to: { name: "admin-search" }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Поиск пользователей\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdmin
                    ? [
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "active-class": "active",
                                  to: { name: "admin-users" }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Пользователи\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "active-class": "active",
                                  to: { name: "admin-stats" }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Статистика\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "active-class": "active",
                                  to: { name: "admin-coupons" }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Купоны\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "active-class": "active",
                                  to: { name: "admin-control" }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Управление\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "active-class": "active",
                                  to: { name: "admin-price-component" }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Прайс компонент\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "active-class": "active",
                                  to: { name: "admin-settings" }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Настройки\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "active-class": "active",
                                  to: { name: "admin-bills" }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Счета\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        ],
        1
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }