import { Module } from 'vuex';
import differenceInDays from 'date-fns/difference_in_days';
import omit from 'lodash/omit';
import find from 'lodash/find';
import { Api } from 'shared/api';
import { OFFICIAL_DOCUMENTS } from '@/core/utils/constants';
import LocStorage from '@/core/utils/localStorage';
import Analytics from 'shared/utils/analytics';
// import getRoistatMetadata from '@/core/utils/roistat';
import { AuthTypes } from 'shared/store/modules/auth/types';

export const orderModule: Module<OrderState, RootState> = {
  namespaced: true,

  state: {
    items: [],
    itemsComment: {},
    itemsData: {},
    loading: false,
    objectKey: null,
    objectAddress: null,
    orderType: 'default',
    owners: [],
    riskInsurance: false,
    riskInsurancePrice: 1000,
  },

  getters: {
    totalAmount(state, _, { productsByKey }) {
      let totalAmount = 0;

      for (const productId of state.items) {
        const product = productsByKey[productId];
        if (product) {
          const productPrice = parseInt(product.product_price as any, 10);
          if (product.coupons) {
            /** Скидку купонов вычитаем из базового прайс компонента */
            const basePriceComponent: PriceComponent = find(
              product.price_components,
              {
                price_component_type: { name: 'Base' },
              }
            );
            const coupon = product.coupons[0];
            const productPriceBase = parseInt(basePriceComponent.price, 10);

            const productPriceWithCoupon = productPriceBase - parseInt(coupon.discount, 10);
            /** Если цена по купону больше, чем цена по прайс-компонету, то берём цену по прайс-компоненту */
            if (productPriceWithCoupon <= productPrice) {
              totalAmount += productPriceWithCoupon;
            } else {
              totalAmount += productPrice;
            }
          } else {
            totalAmount += productPrice;
          }
        }
      }
      return totalAmount;
    },

    itemsCount(state) {
      return state.items.length;
    },
  },

  actions: {
    async send({ state, rootState, commit, dispatch, getters }, orderOptions: OrderOptions = {}) {
      if (!state.objectKey || !getters.itemsCount) {
        return;
      }
      let yandexClientId: string;
      const w: any = window;
      const auth = rootState.auth;
      const useBalance: boolean =
        auth.balance > 0 && auth.balance >= getters.totalAmount && orderOptions.use_account_balance;
      const orderItems: OrderItem[] = await dispatch('_getOrderItems');
      let order: OrderRequestBody = {
        order_items: orderItems,
        metadata: {
          hasAdb: typeof w.__META.hasAds === 'undefined',
          orderType: state.orderType,
        },
        ...orderOptions,
      };

      if (rootState.auth.partner.id) {
        order.partner_id = rootState.auth.partner.id;
      }

      commit('toggleLoading', true);

      // Включить аналитику только в продакшене
      if (process.env.NODE_ENV === 'production') {
        // Если используется баланс, то игнорим аналитику
        if (!useBalance) {
          // await new Promise((resolve) => {
          //   setTimeout(resolve, 3000);
          // });

          // const roistatMetadata = await getRoistatMetadata();

          // if (roistatMetadata.visitId) {
          //   order.metadata.roistatID = roistatMetadata.visitId;
          //   order.metadata.roiLoad = `${roistatMetadata.timeLoad}ms`;
          // }

          // if (roistatMetadata.status) {
          //   order.metadata.roiStatus = roistatMetadata.status;
          // }

          Analytics.ym('getClientID', (clientId: string) => {
            yandexClientId = clientId;
          });

          if (yandexClientId !== undefined) {
            order.metadata.yandexClientID = yandexClientId;
          }
        }
      }

      if (!useBalance) {
        order = omit(order, 'use_account_balance');
      }

      try {
        const orderResponse = await Api.order.Send(order);
        const { total_amount, order_items: orderItems, payment_data } = orderResponse;

        const totalAmount: number = parseInt(total_amount, 10);

        if (totalAmount > 0 && orderItems.length !== 0 && !orderResponse.use_account_balance) {
          const redirectUrl = payment_data.confirmation.confirmation_url;
          dispatch('saveToLocalStorage', orderResponse);
          location.assign(redirectUrl);
          return false;
        } else {
          const purchasedProducts: Product[] = [];

          for (const orderItem of orderItems) {
            const productItem = rootState.productsByKey[orderItem.product_id];

            // if (orderItem.product_type === OFFICIAL_DOCUMENTS) {
            purchasedProducts.push({
              ...productItem,
              ...orderItem,
              status: 'requested',
            });
            // }
          }

          commit('details/pushProducts', purchasedProducts, { root: true });
          if (orderResponse.use_account_balance) {
            commit(AuthTypes.mutations.SET_BALANCE, auth.balance - getters.totalAmount, { root: true });
          }
        }

        dispatch('removeUsedCoupons', orderItems, { root: true });
        commit('changeItem', []);
        commit('clearComments');
        return true;
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit('toggleLoading', false);
      }
    },

    async regOrder({ commit, dispatch, getters, rootState }, regOrderOptions: RegOrderOptions) {
      if (!getters.itemsCount) {
        return;
      }

      const orderItems: OrderItem[] = await dispatch('_getOrderItems');

      commit('toggleLoading', true);

      const body: RegorderRequestPayload = {
        phone: regOrderOptions.phone,
        email: regOrderOptions.email,
        order_items: orderItems,
        registartion_url: regOrderOptions.registartion_url,
        redirect_url: `${location.href}?regorder=true`,
      };

      if (rootState.auth.partner.id && rootState.auth.utmCampaign) {
        body.registartion_url = `${location.origin}/?partner_id=${rootState.auth.partner.id}&utm_campaign=${rootState.auth.utmCampaign}`;
        if (rootState.auth.utmSource) {
          body.registartion_url = `${body.registartion_url}&utm_source=${rootState.auth.utmSource}`;
        }

        if (rootState.auth.utmMedium) {
          body.registartion_url = `${body.registartion_url}&utm_medium=${rootState.auth.utmMedium}`;
        }
      } else {
        if (rootState.auth.utmCampaign) {
          body.registartion_url = `${location.origin}/?utm_campaign=${rootState.auth.utmCampaign}`;
          if (rootState.auth.utmMedium) {
            body.registartion_url = `${body.registartion_url}&utm_medium=${rootState.auth.utmMedium}`;
          }
          if (rootState.auth.utmSource) {
            body.registartion_url = `${body.registartion_url}&utm_source=${rootState.auth.utmSource}`;
          }
        }
        if (rootState.auth.partner.id) {
          body.partner_id = rootState.auth.partner.id;
          body.registartion_url = `${location.origin}/?partner_id=${rootState.auth.partner.id}`;
        }
      }

      if (rootState.auth.utmContent) {
        body.registartion_url = `${body.registartion_url}&utm_content=${rootState.auth.utmContent}`;
      }

      if (rootState.auth.utmTerm) {
        body.registartion_url = `${body.registartion_url}&utm_term=${rootState.auth.utmTerm}`;
      }

      try {
        const { data } = await Api.raw.order.RegOrder<ApiRawResponse<RegOrderResponse>>(body);

        const { order } = data;
        const { payment_data, total_amount, order_items } = order;
        const totalAmount = parseInt(total_amount, 10);

        if (totalAmount > 0 && order_items.length !== 0) {
          const redirectUrl = payment_data.confirmation.confirmation_url;
          dispatch('saveToLocalStorage', order);
          LocStorage.setItem('regorder_id', order.id);
          location.assign(redirectUrl);
          return false;
        }
      } catch (error) {
        return Api.HttpError(error);
      }

      commit('toggleLoading', false);
    },

    saveToLocalStorage(_, order: OrderResponse) {
      const orderItems: OrderItem[] = [ ...order.order_items ];
      let oldOrderItems: OrderItem[] = LocStorage.getItem('orders', []);

      orderItems.forEach((item: OrderItem) => {
        item.created_at = new Date();
        item.id = order.payment_data.id;
      });

      oldOrderItems = oldOrderItems.filter(
        (item: OrderItem) => differenceInDays(Date.now(), item.created_at) < 3,
      );

      LocStorage.setItem('orders', [ ...oldOrderItems, ...orderItems ]);
    },

    _getOrderItems({ state, rootState }): OrderItem[] {
      const objectKey = state.objectKey;
      const productIds = state.items;
      const productsByKey = rootState.productsByKey;
      const data = state.itemsData;

      const orderItems: OrderItem[] = [];

      for (const productId of productIds) {
        let orderItem: OrderItem = {
          product_id: productId,
          object_key: objectKey,
        };

        if (state.objectAddress) {
          orderItem.object_address = state.objectAddress;
        }

        if (data[productId]) {
          orderItem = { ...data[productId], ...orderItem };
        }

        const productItem = productsByKey[productId];

        if (productItem.coupons && productItem.coupons.length) {
          const [ coupon ] = productItem.coupons;
          orderItem.coupon_id = coupon.coupon_id;
        }

        orderItems.push(orderItem);
      }

      return orderItems;
    },
  },

  mutations: {
    toggleLoading(state, loadingState: boolean) {
      state.loading = loadingState;
    },

    changeItem(state, productIds: string[]) {
      state.items = productIds;
    },

    setObjectKey(state, objectKey: string) {
      if (objectKey) {
        objectKey = decodeURIComponent(objectKey);
      }

      state.objectKey = objectKey;
    },

    setObjectAddress(state, objectAddress: string) {
      if (objectAddress) {
        objectAddress = decodeURIComponent(objectAddress);
      }

      state.objectAddress = objectAddress;
    },

    setOrderType(state, orderType: OrderState['orderType']) {
      state.orderType = orderType;
    },

    addComment(state: any, { id, comment }: { id: string; comment: string }) {
      state.itemsComment = {
        ...state.itemsComment,
        [id]: comment,
      };
    },

    clearComments(state: any) {
      state.itemsComment = {};
    },

    setData(state, data: OrderState['itemsData']) {
      state.itemsData = data;
    },

    mergeData(state, data: OrderState['itemsData']) {
      state.itemsData = {
        ...state.itemsData,
        ...data,
      };
    },

    clearData(state) {
      state.itemsData = {};
    },
  },
};
