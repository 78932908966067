import { RealtyCloudApi } from 'shared/api/api';

export interface ApiUploadMethods {
  Attachment<T = UploadAttachmentResponse>(formData: FormData): Promise<T>;
  GetAttachmentList<T = AttachmentItem[]>(groupId: string): Promise<T>;
}

export function uploadMethods(this: RealtyCloudApi): ApiUploadMethods {
  return {
    Attachment: (formData: FormData) => {
      return this.request({
        url: '/attachment',
        method: 'POST',
        data: formData,
      });
    },

    GetAttachmentList: (groupID: string) => {
      return this.request({ url: '/attachment', params: { groupID } });
    },
  };
}
