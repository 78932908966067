
































































import Vue from 'vue';

import AppWholesaleOffer from '@/components/layout/Dialogs/WholesaleOffer.vue';

export default Vue.extend({
  name: 'AppFooter',

  methods: {
    openDialog() {
      this.$dialog.open({ component: AppWholesaleOffer });
    },
  },
});
