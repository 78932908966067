















import Vue from 'vue';

export default Vue.extend({
  name: 'AppDialog',

  props: {
    index: Number,
  },

  provide() {
    return {
      Dialog: {
        index: this.index,
      },
    };
  },
});
