




































































































































import Vue from 'vue';
import omit from 'lodash/omit';
import { Validators } from '@/core/utils/validator';
import { RoleNames, Role } from 'shared/utils/constants';
import { AuthTypes } from 'shared/store/modules/auth/types';

export default Vue.extend({
  name: 'AppAuthRegister',

  props: {
    changeForm: Function,
  },

  data() {
    return {
      model: {
        name: '',
        email: '',
        phone: '',
        password: '',
        person_role: null,
        privacy: true,
        registartion_url: location.href,
      },
      passwordConfirm: '',
      roles: {
        ...omit(RoleNames, Role.Admin, Role.Client),
        [Role.Client]: 'Другое',
      },
    };
  },

  methods: {
    validationRules(): ValidationConfig {
      return {
        name: [ Validators.required ],
        email: [ Validators.required, Validators.email ],
        phone: [ Validators.required, Validators.phone ],
        person_role: [ Validators.required ],
        password: [ Validators.required, Validators.eq(this.passwordConfirm, 'Пароли не совпадают') ],
        privacy: [ Validators.eq(true, 'Чтобы продолжить, установите этот флажок') ],
      };
    },

    async onSubmit() {
      try {
        await this.$store.dispatch(AuthTypes.actions.REGISTRATION, { user: omit(this.model, 'privacy') });
        this.$noty.info({ text: 'Вы успешно зарегистрированы' });
        this.$dialog.close();
      } catch (error) {
        const text = (error as Error).message;
        this.$noty.error({ text });
      }
    },
  },
});
