const ROOT_URL = 'realtycloud.ru';

const analyticsConfig: SimpleObject = {
  in_object: {
    config: [ { page_title: 'object', page_path: '/in-object' } ],
    hit: [ `${ROOT_URL}/object/` ],
  },

  registration: {
    config: [ { page_title: 'registration', page_path: '/registration' } ],
  },

  result: {
    hit: [ `${ROOT_URL}/result` ],
  },

  search_focus: {
    config: [ { page_title: 'search', page_path: '/push-on-field-of-search' } ],
  },

  search_start: {
    config: [ { page_title: 'search', page_path: '/push-of-button-search' } ],
  },

  search_keyup: {
    config: [ { page_title: 'search', page_path: '/start-input' } ],
  },

  search_notfind: {
    config: [ { page_title: 'register', page_path: '/search-without-result' } ],
  },

  search_success: {
    config: [ { page_title: 'register', page_path: '/search-with-result' } ],
  },

  search_error: {
    config: [ { page_title: 'object', page_path: '/search-error' } ],
  },

  pay_statement: {
    config: [ { page_title: 'payment', page_path: '/pay_statement' } ],
  },

  pay_risk: {
    config: [ { page_title: 'payment', page_path: '/pay_risk' } ],
  },

  pay_assessment: {
    config: [ { page_title: 'payment', page_path: '/pay_assessment' } ],
  },

  payment_success: {
    config: [ { page_title: 'payment_success', page_path: '/payment_success' } ],
  },

  push_of_button_assessment: {
    config: [ { page_title: 'push button', page_path: '/push_of_button_assessment' } ],
  },

  token_login: {
    config: [ { page_title: 'login with token', page_path: '/token_login' } ],
  },

  download: {
    config: [ { page_title: 'download', page_path: '/download' } ],
  },

  tab_push_risk: {
    config: [ { page_title: 'tab_change', page_path: '/tab_push_risk' } ],
  },

  tab_push_assessment: {
    config: [ { page_title: 'tab_change', page_path: '/tab_push_assessment' } ],
  },

  tab_push_about_object: {
    config: [ { page_title: 'tab_change', page_path: '/tab_push_about_object' } ],
  },

  risk_tab_visit: {
    config: [ { page_title: 'risk_tab_visit', page_path: '/risk_tab_visit' } ],
  },

  order_maintenance: {
    config: [ { page_title: 'order_maintenance', page_path: '/order_maintenance' } ],
  },

  mortgage_selection: {
    config: [ { page_title: 'mortgage_selection', page_path: '/mortgage_selection' } ],
  },
};

export default analyticsConfig;
