var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h4", { staticClass: "text-center mb-3" }, [
        _vm._v("\n    Оформление заказа\n  ")
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showInfo,
              expression: "showInfo"
            }
          ],
          staticClass: "alert alert-info text-center small"
        },
        [
          _vm._v(
            "\n    Полученные из Росреестра выписки, будут отправлены на указанный Вами адрес электронной почты.\n  "
          )
        ]
      ),
      _c(
        "app-form",
        {
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.model,
            rules: _vm.validationRules
          }
        },
        [
          _c(
            "app-form-group",
            { attrs: { label: _vm.emailLabel, "model-name": "email" } },
            [
              _c("app-input", {
                attrs: { size: "lg", placeholder: "Адрес эл. почты" },
                model: {
                  value: _vm.model.email,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "email", $$v)
                  },
                  expression: "model.email"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { attrs: { label: _vm.phoneLabel, "model-name": "phone" } },
            [
              _c("app-phone-number-input", {
                attrs: { size: "lg" },
                model: {
                  value: _vm.model.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "phone", $$v)
                  },
                  expression: "model.phone"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { attrs: { "model-name": "privacy" } },
            [
              _c(
                "app-checkbox",
                {
                  model: {
                    value: _vm.model.privacy,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "privacy", $$v)
                    },
                    expression: "model.privacy"
                  }
                },
                [
                  _vm._v("\n        Согласен с\n        "),
                  _c(
                    "router-link",
                    {
                      attrs: { to: { name: "privacy" }, tabindex: "-1" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.$dialog.close($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          политикой о персональных данных.\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "app-button",
            {
              attrs: {
                loading: _vm.loading,
                type: "submit",
                size: "lg",
                block: ""
              }
            },
            [_vm._v("\n      Оформить заказ\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }