import removeTypesNamespace from 'shared/utils/storeNamespaceHelper';

export const AdvertTypes = {
  namespace: 'advert',

  getters: {
    GET_AVERAGE_PRICE_STATS: 'advert/getPriceChangeStats',
  },

  actions: {
    GET_ADVERTS_LIST: 'advert/getAdvertsList',
    GET_ADVERT_INFO: 'advert/getAdvertInfo',
    GET_AVERAGE_PRICE: 'advert/getAveragePrice',
    GET_AVERAGE_PRICE_BY_PARAMS: 'advert/getAveragePriceByParams',
    GET_REFORMA_INFO: 'advert/getReformaInfo',
    GET_SIMILAR_LIST: 'advert/getSimilarList',
    GET_ADVERTS_BY_IDS: 'advert/getAdvertsByIds',
    GET_ADVERTS_DUPLICATES: 'advert/getDuplicateAdverts',
    GET_ADVERTS_BY_ADDRESS: 'advert/getAdvertsByAddress',
    GET_ADVERTS_IDS_BY_ADDRESS: 'advert/getAdvertsIdsByAddress',
    GET_PRICE_CHANGE: 'advert/getPriceChange',
    GET_PRICE_CHANGE_BY_PARAMS: 'advert/getPriceChangeByParams',
    GET_CADASTRAL_INFO: 'advert/getCadastralInfo',
  },

  mutations: {
    SET_LIST: 'advert/setAdvertsList',
    UPDATE_LIST: 'advert/updateAdvertsList',
    SET_INFO: 'advert/setAdvertInfo',
    TOGGLE_LOADING: 'advert/toggleLoading',
    SET_AVERAGE_PRICE: 'advert/setAveragePrice',
    SET_FILTERS: 'advert/setFilters',
    SET_REFORMA_INFO: 'advert/setReformaInfo',
    SET_LAST_SEARCH_PARAMS: 'advert/setLastSearchParams',
    SET_SIMILAR_LIST: 'advert/setSimilarList',
    SET_UNIQUE: 'advert/setUnique',
    SET_ADVERTS_DUPLICATES: 'adverts/setAdvertsDuplicates',
    SET_PRICE_CHANGE_HOUSE: 'adverts/setPriceChangeHouse',
    SET_PRICE_CHANGE_AREA: 'adverts/setPriceChangeArea',
    SET_CADASTRAL_INFO: 'adverts/setCadastralInfo',
  },
};

export const _AdvertTypes = removeTypesNamespace<typeof AdvertTypes>('advert/', AdvertTypes);
