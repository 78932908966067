type SortDirection = 'asc' | 'desc';

type SortIterator<T, TResult> = (item: T) => TResult;

function sorter<T>(direction: number, iteratees?: SortIterator<T, any> | string) {
  return function(this: T[], a: T, b: T) {
    let valA = a;
    let valB = b;

    if (typeof iteratees === 'string') {
      valA = (a as SimpleObject)[iteratees];
      valB = (b as SimpleObject)[iteratees];
    }

    if (typeof iteratees === 'function') {
      valA = iteratees(a);
      valB = iteratees(b);
    }

    if (valA < valB) return -direction;
    if (valA == null) return 1;
    if (valB == null) return -1;

    return direction;
  };
}

export default function orderBy<T = any>(
  array: T[],
  iteratees?: SortIterator<T, any> | string,
  direction?: SortDirection,
) {
  if (!Array.isArray(array)) {
    return array;
  }

  return [ ...array ].sort(sorter<T>(direction === 'asc' ? 1 : -1, iteratees));
}
