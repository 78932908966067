var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.input,
        _vm.$slots.default && _vm.$style["input_icon-" + _vm.iconPosition]
      ]
    },
    [
      _c("vue-the-mask", {
        class: [
          _vm.$style["form-control"],
          _vm.size && _vm.$style["form-control-" + _vm.size]
        ],
        attrs: {
          value: _vm.maskValue,
          mask: ["+7 (###) ###-##-##"],
          placeholder: _vm.placeholder,
          type: "tel"
        },
        on: { input: _vm.onInput }
      }),
      _c("label", { class: [{ active: _vm.value }] }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm.$slots.default
        ? _c(
            "div",
            {
              class: [
                _vm.$style["input__icon"],
                _vm.size && _vm.$style["input__icon_" + _vm.size]
              ]
            },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }