var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("div", { staticClass: "icon" }, [
        _c("svg", { attrs: { viewBox: "0 0 305.002 305.002" } }, [
          _c("g", [
            _c("path", {
              attrs: {
                d:
                  "M152.502.001C68.412.001 0 68.412 0 152.501s68.412 152.5 152.502 152.5c84.089 0 152.5-68.411 152.5-152.5S236.591.001 152.502.001zm0 280C82.197 280.001 25 222.806 25 152.501c0-70.304 57.197-127.5 127.502-127.5 70.304 0 127.5 57.196 127.5 127.5 0 70.305-57.196 127.5-127.5 127.5z"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M218.473 93.97l-90.546 90.547-41.398-41.398c-4.882-4.881-12.796-4.881-17.678 0-4.881 4.882-4.881 12.796 0 17.678l50.237 50.237c2.441 2.44 5.64 3.661 8.839 3.661 3.199 0 6.398-1.221 8.839-3.661l99.385-99.385c4.881-4.882 4.881-12.796 0-17.678-4.882-4.882-12.797-4.882-17.678-.001z"
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "m-0 text-center mt-4" }, [
        _vm._v(
          "\n    Купон скидка 20% на Отчет об объекте активирован. Оформите новый заказ\n  "
        )
      ]),
      _c(
        "div",
        { staticClass: "text-center mt-3" },
        [
          _c(
            "app-button",
            {
              attrs: { variant: "light", size: "sm" },
              on: { click: _vm.closeDialog }
            },
            [_vm._v("\n      Закрыть\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }