const propertyConstants = {
  get OBJECT_TYPES(): PropertyObjectType[] {
    return [
      {
        id: 1,
        name: 'Вторичка',
      },
      {
        id: 2,
        name: 'Новостройка',
      },
    ];
  },

  get BUILDING_TYPES(): PropertyBuildingType[] {
    return [
      {
        id: 0,
        name: 'Панельный',
      },
      {
        id: 1,
        name: 'Кирпичный',
      },
      {
        id: 2,
        name: 'Монолитный',
      },
      {
        id: 3,
        name: 'Блочный',
      },
      {
        id: 4,
        name: 'Деревянный',
      },
    ];
  },

  get STATE_OF_APARTMENTS(): StateOfApartmentItem[] {
    return [
      {
        id: 1,
        name: 'Не требует ремонта',
      },
      {
        id: 2,
        name: 'Требует серьезного ремонта',
      },
      {
        id: 3,
        name: 'Требует косметического ремонта',
      },
      {
        id: 4,
        name: 'Сделан косметический ремонт',
      },
      {
        id: 5,
        name: 'Сделан евроремонт',
      },
      {
        id: 6,
        name: 'Новостройка (без отделки)',
      },
    ];
  },
};

export default propertyConstants;
