



































import Vue from 'vue';
import { formatter as cadastralFormatter } from 'shared/utils/cadastralNumber';

const checkIfNumberRegex = /^(\d+(?:\s*[:|/|-|;|^\s]\s*)?){1,}$/;

export default Vue.extend({
  name: 'AppSearch',

  data() {
    return {
      // searchQuery: '',
      isNumber: false,
    };
  },

  computed: {
    searchQuery: {
      get(): string {
        return this.$store.state.search.query;
      },

      set(query: string) {
        this.isNumber = checkIfNumberRegex.test(query.trim());
        this.$store.commit('search/setQuery', query);
      },
    },
  },

  // watch: {
  //   searchQuery: {
  //     immediate: true,
  //     handler(value) {
  //       this.isNumber = /^(\d+[:|/|-]?){1,}$/.test(value.trim());
  //     },
  //   },
  // },

  // created() {
  //   if (this.$route.query.q && !this.searchQuery) {
  //     this.searchQuery = this.$route.query.q as string;
  //   }
  // },

  methods: {
    onSubmit() {
      let q = this.searchQuery.trim();

      if (!q) {
        return;
      }

      // Форматирум кадастровый номер, если пользователь ввёл его с очепятками
      // Функция игнорит обычные адреса
      const formatterResult = cadastralFormatter(q);

      // Если получилось преобразовать, то меняем query на нормальный
      if (formatterResult.success) {
        this.searchQuery = q = formatterResult.result;
      }

      this.$store.dispatch('searchObjects', q);
      this.$router.push({
        name: 'search',
        query: { q },
      });
    },

    onFocus() {},

    handleSubmit() {
      const isValidAddress = /д.?[0-9A-Za-z]{1,}/.test(this.searchQuery);

      if (this.isNumber || isValidAddress) {
        return this.onSubmit();
      }
    },
  },
});
