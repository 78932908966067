export default class ModelValidator {
  private config: ValidationConfig;

  constructor(config: ValidationConfig) {
    this.config = config;
  }

  public validate(model: ValidationModel): ValidationErrors | null {
    const errors: ValidationErrors = {};

    if (this.config) {
      for (const modelKey in this.config) {
        if (Object.prototype.hasOwnProperty.call(this.config, modelKey)) {
          const validators = this.config[modelKey];
          const modelValue = model[modelKey];

          if (validators) {
            for (const validator of validators) {
              const validationResult = validator(modelValue);

              if (validationResult !== null) {
                errors[modelKey] = validationResult;
                break;
              }
            }
          }
        }
      }
    }

    if (this.errorsIsEmpty(errors)) {
      return null;
    }

    return errors;
  }

  private errorsIsEmpty(errorsObject: ValidationErrors) {
    for (const key in errorsObject) {
      if (Object.prototype.hasOwnProperty.call(errorsObject, key)) {
        return false;
      }
    }

    return true;
  }
}
