var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-collapse",
    [
      _c(
        "app-collapse-item",
        {
          staticClass:
            "risk-report-item statement-item risk-report-item_conclusion mt-3 mb-3 risk-example is-disabled",
          attrs: { disabled: "", opened: "" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "risk-report-item__title-inner row justify-content-between"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "risk-report-item__name col-auto" },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-user-friends text-primary  mr-2"
                          }),
                          _vm._v(" Проверка собственников\n        ")
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "risk-report-item__status col-auto pl-4 pl-md-0"
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-lock mr-2 text-danger"
                          }),
                          _c("span", { staticClass: "d-none d-md-inline" }, [
                            _vm._v("Доступно после заказа")
                          ]),
                          _c("img", {
                            staticClass: "ml-md-4",
                            attrs: { src: "/images/eye.svg" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("icon-click")
                              }
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "pt-3" }, [
            _c(
              "div",
              { staticClass: "risk-example__content" },
              [
                _c("div", { staticClass: "risk-example__tip" }, [
                  _c("span", [
                    _vm._v(
                      "\n            Для полной оценки рисков, уточните данные всех собственников у продавца\n          "
                    )
                  ])
                ]),
                _c(
                  "app-report-item",
                  {
                    attrs: {
                      title: "Действительность паспорта",
                      icon: "passport",
                      status: "done"
                    }
                  },
                  [
                    _vm._v(
                      "\n          В базе недействительных паспортов:\n          "
                    ),
                    _c("i", { staticClass: "fas fa-lock fa-xs fa-fw mr-1" }),
                    _vm._v(" доступно после заказа\n        ")
                  ]
                ),
                _c(
                  "app-report-item",
                  {
                    attrs: {
                      title: "Нахождение в розыске",
                      icon: "fingerprint",
                      status: "done"
                    }
                  },
                  [
                    _vm._v("\n          В базе данных розыска:\n          "),
                    _c("i", { staticClass: "fas fa-lock fa-xs fa-fw mr-1" }),
                    _vm._v(" доступно после заказа\n        ")
                  ]
                ),
                _c(
                  "app-report-item",
                  {
                    attrs: {
                      title: "Федеральный реестр сведений о банкротстве",
                      icon: "university",
                      status: "done"
                    }
                  },
                  [
                    _vm._v("\n          В реестре Банкротов:\n          "),
                    _c("i", { staticClass: "fas fa-lock fa-xs fa-fw mr-1" }),
                    _vm._v(" доступно после заказа\n        ")
                  ]
                ),
                !_vm.showMoreSources
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "app-button",
                          {
                            attrs: { variant: "link", size: "sm" },
                            on: {
                              click: function($event) {
                                _vm.showMoreSources = true
                              }
                            }
                          },
                          [_vm._v("\n            Показать ещё (6)\n          ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showMoreSources
                  ? [
                      _c(
                        "app-report-item",
                        {
                          attrs: {
                            title: "Налоговые задолженности",
                            icon: "coins",
                            status: "done"
                          }
                        },
                        [
                          _vm._v("\n            Задолженности\n            "),
                          _c("i", {
                            staticClass: "fas fa-lock fa-xs fa-fw mr-1"
                          }),
                          _vm._v(" доступно после заказа\n          ")
                        ]
                      ),
                      _c(
                        "app-report-item",
                        {
                          attrs: {
                            title: "Залоги",
                            icon: "key",
                            status: "done"
                          }
                        },
                        [
                          _vm._v("\n            Залоги\n            "),
                          _c("i", {
                            staticClass: "fas fa-lock fa-xs fa-fw mr-1"
                          }),
                          _vm._v(" доступно после заказа\n          ")
                        ]
                      ),
                      _c(
                        "app-report-item",
                        {
                          attrs: {
                            title: "Сведения о долгах у судебных приставов",
                            icon: "file-invoice",
                            status: "done"
                          }
                        },
                        [
                          _vm._v(
                            "\n            В базе данных ФССП исполнительные производства\n            "
                          ),
                          _c("i", {
                            staticClass: "fas fa-lock fa-xs fa-fw mr-1"
                          }),
                          _vm._v(" доступно после заказа\n          ")
                        ]
                      ),
                      _c(
                        "app-report-item",
                        {
                          attrs: {
                            title: "Самозанятый",
                            icon: "briefcase",
                            status: "done"
                          }
                        },
                        [
                          _vm._v(
                            "\n            В реестре самозанятых\n            "
                          ),
                          _c("i", {
                            staticClass: "fas fa-lock fa-xs fa-fw mr-1"
                          }),
                          _vm._v(" доступно после заказа\n          ")
                        ]
                      ),
                      _c(
                        "app-report-item",
                        {
                          attrs: {
                            title:
                              "Руководитель или учредитель юридического лица",
                            icon: "user-tie",
                            status: "done"
                          }
                        },
                        [
                          _vm._v(
                            "\n            Среди руководителей и учредителей\n            "
                          ),
                          _c("i", {
                            staticClass: "fas fa-lock fa-xs fa-fw mr-1"
                          }),
                          _vm._v(" доступно после заказа\n          ")
                        ]
                      ),
                      _c(
                        "app-report-item",
                        {
                          attrs: {
                            title: "Судебные акты Российской Федерации",
                            icon: "gavel",
                            status: "done"
                          }
                        },
                        [
                          _vm._v(
                            "\n            В базе ГАС «Правосудие» совпадений по фамилии и инициалам\n            "
                          ),
                          _c("i", {
                            staticClass: "fas fa-lock fa-xs fa-fw mr-1"
                          }),
                          _vm._v(" доступно после заказа\n          ")
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }