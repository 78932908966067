var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer d-print-none" }, [
    _c("div", { staticClass: "container footer-container" }, [
      _c("div", { staticClass: "footer-company" }, [
        _c("img", {
          staticClass: "footer-company__logo",
          attrs: { alt: "Realty Cloud", src: "/images/logo-gray.svg" }
        }),
        _c(
          "div",
          { staticClass: "footer-company__desc" },
          [
            _c(
              "router-link",
              {
                staticClass: "footer-company__wholesale-offer btn-link",
                attrs: { to: { name: "package" } }
              },
              [_vm._v("\n          Оптовое предложение\n        ")]
            ),
            _c("br"),
            _vm._v('\n        ООО "Реалти Клауд"\n        '),
            _c(
              "div",
              { staticClass: "footer-link-pages" },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "mailto:help@realtycloud.ru",
                      target: "_blank"
                    }
                  },
                  [_vm._v("help@realtycloud.ru")]
                ),
                _c("span", [_vm._v("|")]),
                _c("router-link", { attrs: { to: { name: "privacy" } } }, [
                  _vm._v(
                    "\n            Политика о персональных данных\n          "
                  )
                ]),
                _c("span", [_vm._v("|")]),
                _c("router-link", { attrs: { to: { name: "offer" } } }, [
                  _vm._v("\n            Оферта\n          ")
                ]),
                _c("span", [_vm._v("|")]),
                _c("router-link", { attrs: { to: { name: "price-list" } } }, [
                  _vm._v("\n            Прайс-лист\n          ")
                ]),
                _c("span", [_vm._v("|")]),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://documenter.getpostman.com/view/3991362/RWguwGNB?version=latest",
                      target: "_blank"
                    }
                  },
                  [_vm._v("\n            Наш API\n          ")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-card" }, [
      _c("div", { staticClass: "footer-card__text" }, [
        _vm._v("\n        Принимаем\n      ")
      ]),
      _c("img", {
        staticClass: "footer-card__img",
        attrs: { alt: "Visa", src: "/images/icons/visa.svg" }
      }),
      _c("img", {
        staticClass: "footer-card__img",
        attrs: { alt: "MasterCard", src: "/images/icons/mastercard.svg" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }