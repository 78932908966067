


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TheMask } from 'vue-the-mask';
import format from 'date-fns/format';
import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

@Component({
  name: 'AppDatePicker',
  components: { TheMask },
})
export default class DatePicker extends Vue {
  // non reactive properties
  private picker!: flatpickr.Instance;

  // props
  @Prop({ type: Object }) options: flatpickr.Options.Options;
  @Prop({ type: String, default: 'ДД.ММ.ГГГГ' }) placeholder: string;
  @Prop({ type: String }) size: string;
  @Prop({ type: String, default: '' }) value: string;
  @Prop({ type: String, default: '' }) label: string;
  @Prop({ type: Boolean, default: true }) iso: boolean;
  // props end
  // data()
  date: string;
  // data() end
  // lifecycle hooks

  created() {
    this.date = format(this.value, 'DD.MM.YYYY');
  }

  mounted() {
    const container: any = (this.$refs.pickerContainer as any).$el;

    this.picker = flatpickr(container, {
      locale: Russian,
      disableMobile: true,
      allowInput: true,
      // altInput: true,
      // altFormat: 'd.m.Y',
      // dateFormat: 'Y-m-d',
      dateFormat: 'd.m.Y',
      ariaDateFormat: 'd.m.Y',
      ...this.options,

      onChange: (dates: Date[], dateString: string) => {
        const formatedDateString = this.formatDate(dateString);
        const date = this.iso ? new Date(formatedDateString).toISOString() : formatedDateString;
        this.$emit('input', date);
        this.picker.close();
      },
      onOpen: () => {
        this.$emit('open');
      },
      onClose: () => {
        this.$emit('close');
      },
    });
  }

  beforeDestroy() {
    if (this.picker) {
      this.picker.destroy();
    }
  }

  // methods
  onInput(value: string) {
    const formatedDateString = this.formatDate(value);

    if (formatedDateString) {
      this.picker.setDate(new Date(formatedDateString), true);
    }

    if (value === '') {
      this.$emit('input', '');
    }
  }

  onFocus() {
    this.$emit('focus', '');
  }

  formatDate(value: string): string | null {
    const dateReg = /(\d{2})\.(\d{2})\.(\d{4})/;
    const isDate: boolean = dateReg.test(value);
    if (isDate) {
      return value.replace(dateReg, '$3-$2-$1');
    }

    return null;
  }

  closePicker() {
    console.log('close');
  }
}
