var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.riskAssessment
      ? _c(
          "div",
          {
            staticClass: "product-list__items wrapper-padding_sm border-bottom"
          },
          [
            _c("div", { staticClass: "product-item product-item_default" }, [
              _vm._m(0),
              _c("div", { staticClass: "product-item__right" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "product-item__info product-item__price d-flex align-items-center"
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "product-item__price-original" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.product.product_price) +
                            "\n            "
                        ),
                        _c("i", { staticClass: "fas fa-ruble-sign" })
                      ]
                    ),
                    _c("div", { staticClass: "product-item__time mt-0 ml-3" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: {
                                content:
                                  "Отчет формируется в течение дня, но иногда нужно подождать до трёх дней."
                              },
                              expression:
                                "{\n                content:\n                  'Отчет формируется в течение дня, но иногда нужно подождать до трёх дней.',\n              }"
                            }
                          ],
                          staticClass: "d-inline-block"
                        },
                        [
                          _c("span", { staticClass: "align-middle" }, [
                            _vm._v("~ 24 ч.")
                          ]),
                          _c(
                            "svg",
                            {
                              staticClass: "align-middle",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: { d: "M0 0h24v24H0z", fill: "none" }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "product-item__price" },
                  [
                    _c(
                      "app-button",
                      {
                        staticClass: "rounded-pill font-weight-500 px-3",
                        attrs: { loading: _vm.orderRequest, size: "sm" },
                        on: {
                          click: function($event) {
                            return _vm.checkout("pay_risk")
                          }
                        }
                      },
                      [_vm._v("\n            Заказать\n          ")]
                    )
                  ],
                  1
                )
              ])
            ]),
            _c("p", { staticClass: "text-center alert alert-danger mb-0" }, [
              _vm._v(
                "\n      Отчет ЕГРН содержит малую часть информации об объекте, её недостаточно для полной оценки\n      рисков при покупке. Рекомендуем заказать отчет о рисках.\n    "
              )
            ])
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "wrapper-padding_sm" },
      [
        _vm.riskAssessment
          ? _c("div", { staticClass: "list-info" }, [
              _vm.riskAssessment.status === "done"
                ? _c(
                    "div",
                    { staticClass: "list-info-item w-100 border-bottom pb-3" },
                    [
                      _c("div", { staticClass: "list-info-item__icon" }, [
                        _c("svg", { staticClass: "text-success" }, [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-checkmark-circle" }
                          })
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "list-info-item__text flex-grow-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "list-info-item__title" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.product.product_name_ru) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "list-info-item__body text-muted"
                                  },
                                  [
                                    _vm._v(
                                      "\n                проверка объекта недвижимости\n              "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "app-dropdown",
                                    {
                                      attrs: {
                                        direction: "right",
                                        "close-on-select": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "app-button",
                                        {
                                          staticClass: "text-nowrap mt-2",
                                          attrs: {
                                            variant: "primary-light",
                                            size: "sm",
                                            "data-dropdown-trigger": ""
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-cloud-download-alt"
                                          }),
                                          _vm._v(" Скачать\n                ")
                                        ]
                                      ),
                                      _c(
                                        "app-dropdown-menu",
                                        [
                                          _c(
                                            "app-menu-item",
                                            {
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.download(
                                                    _vm.riskAssessment.pdf_link,
                                                    "pdf"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Полный отчёт\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "app-menu-item",
                                            {
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.download(
                                                    _vm.riskAssessment.pdf_link,
                                                    "pdf",
                                                    { type: "compact" }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Без заключения\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "app-menu-item",
                                            {
                                              nativeOn: {
                                                click: function($event) {
                                                  return _vm.download(
                                                    _vm.riskAssessment.pdf_link,
                                                    "pdf",
                                                    { type: "owner" }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Только собственники\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "list-info-item w-100 border-bottom pb-3" },
                    [
                      _c("div", { staticClass: "list-info-item__icon" }, [
                        _c("svg", { staticClass: "text-warning" }, [
                          _c("use", { attrs: { "xlink:href": "#icon-time" } })
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "list-info-item__text flex-grow-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "list-info-item__title" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.product.product_name_ru) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "list-info-item__body text-muted"
                                  },
                                  [
                                    _vm._v(
                                      "\n                В работе. Ожидайте\n              "
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._m(1)
                    ]
                  ),
              _c("div", { staticClass: "w-100 pb-3" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center" },
                  [
                    _c(
                      "app-risk-form",
                      {
                        attrs: {
                          variant: "primary",
                          size: "lg",
                          "item-id": _vm.riskAssessment.order_item_id,
                          "request-users": true
                        },
                        on: { updateOwners: _vm.getReport }
                      },
                      [
                        _vm._v(
                          "\n            Добавить данные собственника для проверки\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        !_vm.riskAssessment
          ? _c("div", { staticClass: "alert alert-info text-center" }, [
              _vm._v(
                "\n      После выполнения проверки вы сможете скачать отчет и получить доступ к информации ниже.\n    "
              )
            ])
          : _vm._e(),
        _c(
          "app-collapse",
          [
            _c(
              "app-collapse-item",
              {
                staticClass: "risk-collapse",
                attrs: {
                  disabled: _vm.report && _vm.isEmpty("encumbrance"),
                  opened: _vm.report === null && !_vm.riskAssessment
                }
              },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { staticClass: "fas fa-gavel fa-fw text-primary" }),
                  _c("span", { staticClass: "risk-collapse__title-text" }, [
                    _vm._v("Обременения")
                  ]),
                  !_vm.isEmpty("encumbrance")
                    ? _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n            найдено " +
                            _vm._s(_vm.report.encumbrance.length) +
                            "\n            " +
                            _vm._s(
                              _vm.pluralizeMsg(
                                "encumbrance",
                                _vm.report.encumbrance.length
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "ml-auto" }, [
                    _vm.report && _vm.isEmpty("encumbrance")
                      ? _c("b", { staticClass: "text-success" }, [
                          _vm._v("\n              не найдено\n            ")
                        ])
                      : _vm.report
                      ? _c("span", { staticClass: "risk-collapse__more" }, [
                          _vm._v("подробнее")
                        ])
                      : _vm._e()
                  ])
                ])
              ],
              2
            ),
            _c("app-collapse-item", {
              staticClass: "risk-collapse",
              attrs: { disabled: _vm.isEmpty("egrn") },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("i", {
                        staticClass: "far fa-address-card text-primary"
                      }),
                      _c("span", { staticClass: "risk-collapse__title-text" }, [
                        _vm._v("Собственники")
                      ]),
                      !_vm.isEmpty("egrn")
                        ? _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.report.egrn.length) +
                                " " +
                                _vm._s(
                                  _vm.pluralizeMsg(
                                    "owner",
                                    _vm.report.egrn.length
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "ml-auto mr-2" }, [
                        !_vm.report
                          ? _c(
                              "span",
                              {
                                directives: [
                                  { name: "tippy", rawName: "v-tippy" }
                                ]
                              },
                              [
                                _c("svg", [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href": "#icon-outline-info"
                                    }
                                  })
                                ]),
                                _c(
                                  "div",
                                  { attrs: { "data-tippy-content": "hide" } },
                                  [
                                    _vm._v(
                                      "\n                Доступно после заказа\n                "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        "Отчеты из ЕГРН об объекте недвижимости"
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          : _vm.isEmpty("egrn")
                          ? _c("b", { staticClass: "text-warning" }, [
                              _vm._v("отсутствуют")
                            ])
                          : _c("span", { staticClass: "risk-collapse__more" }, [
                              _vm._v("подробнее")
                            ])
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _c(
              "app-collapse-item",
              { staticClass: "risk-collapse" },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { staticClass: "far fa-address-card text-primary" }),
                  _c("span", { staticClass: "risk-collapse__title-text" }, [
                    _vm._v("История по правообладателям")
                  ]),
                  _c("div", { staticClass: "ml-auto mr-2" }, [
                    !_vm.report
                      ? _c(
                          "span",
                          {
                            directives: [{ name: "tippy", rawName: "v-tippy" }]
                          },
                          [
                            _c("svg", [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-outline-info" }
                              })
                            ]),
                            _c(
                              "div",
                              { attrs: { "data-tippy-content": "hide" } },
                              [
                                _vm._v(
                                  "\n                Доступно после заказа\n                "
                                ),
                                _c("b", [
                                  _vm._v(
                                    "Выписки о переходе прав на объект недвижимости"
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      : !_vm.isEmpty("rightList")
                      ? _c("span", { staticClass: "risk-collapse__more" }, [
                          _vm._v("\n              подробнее\n            ")
                        ])
                      : _vm._e()
                  ])
                ])
              ],
              2
            ),
            _c(
              "app-collapse-item",
              { staticClass: "risk-collapse" },
              [
                _vm.hasOwners
                  ? [
                      _c("template", { slot: "title" }, [
                        _c("i", {
                          staticClass: "far fa-address-card text-primary fa-sm"
                        }),
                        _c(
                          "span",
                          { staticClass: "risk-collapse__title-text" },
                          [_vm._v("Проверка продавца - собственников")]
                        )
                      ]),
                      _c(
                        "app-collapse",
                        _vm._l(_vm.report.owners, function(report) {
                          return _c(
                            "app-collapse-item",
                            {
                              key: report.ownerId,
                              staticClass: "risk-report-item mb-3",
                              class: {
                                "is-underage": _vm.isUnderage(
                                  report.owner.birthday
                                )
                              },
                              attrs: {
                                disabled: _vm.isUnderage(report.owner.birthday)
                              }
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "risk-report-item__title-inner"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "risk-report-item__name" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-user-tie text-primary fa-sm mr-2"
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(report.owner.surname) +
                                            "  " +
                                            _vm._s(report.owner.name) +
                                            " " +
                                            _vm._s(report.owner.patronymic) +
                                            "\n                    "
                                        ),
                                        _vm.isUnderage(report.owner.birthday)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-muted small ml-2"
                                              },
                                              [_vm._v("несовершеннолетний")]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "risk-report-item__status"
                                      },
                                      [
                                        report.status === "complete"
                                          ? [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-check-circle text-success fa-sm align-middle fa-fw mr-1"
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "align-middle" },
                                                [_vm._v("Выполнено")]
                                              )
                                            ]
                                          : report.status !== "complete"
                                          ? [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-info-circle text-info fa-sm"
                                              }),
                                              _vm._v(
                                                " В работе\n                    "
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ]),
                              !_vm.isUnderage(report.owner.birthday)
                                ? _c("div", {
                                    staticClass:
                                      "risk-report-item__content pt-3"
                                  })
                                : _vm._e()
                            ],
                            2
                          )
                        }),
                        1
                      )
                    ]
                  : [
                      _c("template", { slot: "title" }, [
                        _c("svg", [
                          _c("use", { attrs: { "xlink:href": "#icon-lock" } })
                        ]),
                        _c(
                          "span",
                          { staticClass: "risk-collapse__title-text" },
                          [_vm._v("Проверка продавца - собственников")]
                        )
                      ]),
                      _c("app-report-example")
                    ]
              ],
              2
            ),
            _c(
              "app-collapse-item",
              { staticClass: "risk-collapse" },
              [
                _vm.hasConclusion
                  ? [
                      _c("template", { slot: "title" }, [
                        _c(
                          "div",
                          { staticClass: "risk-report-item__title-inner" },
                          [
                            _c(
                              "div",
                              { staticClass: "risk-report-item__name" },
                              [
                                _c("i", {
                                  staticClass:
                                    "far fa-address-card text-primary mr-2 fa-sm"
                                }),
                                _vm._v(
                                  " Заключение и рекомендации\n              "
                                )
                              ]
                            ),
                            _c("AppRiskConclusionGeneralStatus", {
                              staticClass: "risk-report-item__status",
                              attrs: { conclusion: _vm.report.conclusion }
                            })
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "pt-3" },
                        _vm._l(_vm.report.conclusion.parsed, function(
                          conclusion,
                          index
                        ) {
                          return _c("app-risk-conclusion-item", {
                            key: index,
                            attrs: { conclusion: conclusion }
                          })
                        }),
                        1
                      )
                    ]
                  : [
                      _c("template", { slot: "title" }, [
                        _c("svg", [
                          _c("use", { attrs: { "xlink:href": "#icon-lock" } })
                        ]),
                        _c(
                          "span",
                          { staticClass: "risk-collapse__title-text" },
                          [_vm._v("Заключение и рекомендации")]
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          "\n            Результат проверки на риски, рекомендации по их уменьшению "
                        ),
                        _c("br"),
                        _c(
                          "span",
                          {
                            staticClass: "text-muted font-weight-500",
                            on: {
                              click: function($event) {
                                return _vm.showRiskInfo("RiskAssessment")
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-lock fa-xs fa-fw mr-1"
                            }),
                            _vm._v(" доступно после заказа\n            ")
                          ]
                        )
                      ])
                    ]
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "my-5 text-center" },
          [
            _c(
              "app-button",
              {
                staticClass: "m-2",
                attrs: { size: "lg" },
                on: { click: _vm.showExamples }
              },
              [_vm._v("\n        Пример отчета\n      ")]
            ),
            _c(
              "app-button",
              {
                staticClass: "m-2",
                attrs: { variant: "white", size: "lg" },
                on: { click: _vm.showVideoDialog }
              },
              [_vm._v("\n        Видео о сервисе\n      ")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "risk-block" }, [
          _c("div", { staticClass: "risk-block__head" }, [
            _c("div", { staticClass: "risk-block__icon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 511.97 511.97"
                  }
                },
                [
                  _c("g", { attrs: { fill: "#2281f9" } }, [
                    _c("path", {
                      attrs: {
                        d:
                          "M204.59 115.28a6.48 6.48 0 0 0-.92-1.45 5.42 5.42 0 0 0-1.05-1.24 12.5 12.5 0 0 0-1.37-1.03 8.96 8.96 0 0 0-6.46-1.02 8.41 8.41 0 0 0-4.8 13.35 8.54 8.54 0 0 0 2.41 2.27 8.57 8.57 0 0 0 12.75-9.34l-.56-1.54z"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M287.13 56.06a36.4 36.4 0 0 0-62.21 0l-17.7 29.19a8.53 8.53 0 1 0 14.6 8.85l17.7-29.2a19.33 19.33 0 0 1 33.02 0l219.6 362.32a19.2 19.2 0 0 1-16.51 29.12H36.42a19.2 19.2 0 0 1-16.52-29.11l166.53-274.75a8.54 8.54 0 0 0-7.11-12.96 8.53 8.53 0 0 0-7.48 4.11L5.32 418.37l-.01.01a35.78 35.78 0 0 0-.58 36.53 35.94 35.94 0 0 0 31.69 18.5h439.2a36.27 36.27 0 0 0 31.12-55.03L287.13 56.06z"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M47.99 430.58a17.09 17.09 0 0 0 14.87 8.7H449.2a17.07 17.07 0 0 0 14.59-25.92L270.62 94.67a17.06 17.06 0 0 0-29.19 0L48.27 413.36a17.08 17.08 0 0 0-.28 17.22zm14.87-8.37l193.16-318.7 193.17 318.7-386.35.04.02-.04z"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M256.02 174.74a34.17 34.17 0 0 0-34.13 34.13v76.8a34.13 34.13 0 0 0 68.27 0v-76.8a34.17 34.17 0 0 0-34.14-34.13zm17.07 110.93a17.07 17.07 0 1 1-34.13 0v-76.8a17.07 17.07 0 1 1 34.13 0v76.8zm-17.07 51.2a34.13 34.13 0 1 0 0 68.27 34.13 34.13 0 0 0 0-68.27zm0 51.2a17.07 17.07 0 1 1 0-34.13 17.07 17.07 0 0 1 0 34.13z"
                      }
                    })
                  ])
                ]
              )
            ]),
            _c("h5", { staticClass: "risk-block__title" }, [
              _vm._v(
                "\n          Последствия для нового владельца, если без проверки:\n        "
              )
            ])
          ]),
          _vm._m(2)
        ]),
        _c("div", { staticClass: "risk-block" }, [
          _c("div", { staticClass: "risk-block__head" }, [
            _c("div", { staticClass: "risk-block__icon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    viewBox: "0 0 480 480",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M408 72V40c0-17.672 14.328-32 32-32H104C86.328 8 72 22.328 72 40v72h336zm0 0M344 440c0 17.672 14.328 32 32 32s32-14.328 32-32v-72H72v40h272zm0 0M336 240c0 53.02-42.98 96-96 96s-96-42.98-96-96 42.98-96 96-96 96 42.98 96 96zm0 0",
                      fill: "rgba(34, 129, 249, .2)"
                    }
                  }),
                  _c("g", { attrs: { fill: "#2281f9" } }, [
                    _c("path", {
                      attrs: {
                        d:
                          "M472 80a8 8 0 0 0 8-8V40c-.027-22.082-17.918-39.973-40-40H104C81.918.027 64.027 17.918 64 40v64H24c-13.254 0-24 10.746-24 24v224c0 13.254 10.746 24 24 24h40v24H8a8 8 0 0 0-8 8v32c.027 22.082 17.918 39.973 40 40h336c22.082-.027 39.973-17.918 40-40v-64h40c13.254 0 24-10.746 24-24V128c0-13.254-10.746-24-24-24h-40V80zm-8-40v24h-48V40c0-13.254 10.746-24 24-24s24 10.746 24 24zM40 464c-13.254 0-24-10.746-24-24v-24h320v24a39.604 39.604 0 0 0 8.207 24zm360-24c0 13.254-10.746 24-24 24s-24-10.746-24-24v-32a8 8 0 0 0-8-8H80v-24h320zm64-312v224a8 8 0 0 1-8 8H24a8 8 0 0 1-8-8V128a8 8 0 0 1 8-8h432a8 8 0 0 1 8 8zM80 104V40c0-13.254 10.746-24 24-24h304.207A39.604 39.604 0 0 0 400 40v64zm0 0"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M240 344c57.438 0 104-46.563 104-104s-46.563-104-104-104-104 46.563-104 104c.066 57.41 46.59 103.934 104 104zm0-192c48.602 0 88 39.398 88 88s-39.398 88-88 88-88-39.398-88-88c.059-48.578 39.422-87.941 88-88zm0 0M48 152h16v16H48zm0 0M72 152h48v16H72zm0 0M48 176h16v16H48zm0 0M72 176h48v16H72zm0 0M360 288h16v16h-16zm0 0M384 288h48v16h-48zm0 0M360 312h16v16h-16zm0 0M384 312h48v16h-48zm0 0"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M206.055 296.566a7.923 7.923 0 0 0 5.656-2.343l90.512-90.512-11.313-11.313-84.855 84.86-16.965-16.969-11.313 11.313 22.621 22.62a8.001 8.001 0 0 0 5.657 2.344zm0 0"
                      }
                    })
                  ])
                ]
              )
            ]),
            _c("h5", { staticClass: "risk-block__title" }, [
              _vm._v("\n          Что включает проверка:\n        ")
            ])
          ]),
          _vm._m(3)
        ]),
        _c(
          "h4",
          { staticClass: "text-center mb-4 mt-5", attrs: { id: "risk-order" } },
          [_vm._v("\n      Проверка недвижимости позволит\n    ")]
        ),
        _c(
          "app-collapse",
          [
            _c("app-collapse-item", [
              _c(
                "h6",
                { staticClass: "m-0", attrs: { slot: "title" }, slot: "title" },
                [_vm._v("\n          Покупателю\n        ")]
              ),
              _c("ul", { staticClass: "list-unstyled mb-0 text-muted" }, [
                _c("li", [
                  _vm._v(
                    "\n            1. Сохранить деньги и не предотвратить приобретение неблагонадежного объекта.\n          "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    "\n            2. Выгодно договориться имея под рукой полную информацию об объекте и собственнике.\n          "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    "\n            3. Избежать судебных процессов, которые могут возникнуть после регистрации объекта.\n          "
                  )
                ])
              ])
            ]),
            _c("app-collapse-item", [
              _c(
                "h6",
                { staticClass: "m-0", attrs: { slot: "title" }, slot: "title" },
                [_vm._v("\n          Продавцу\n        ")]
              ),
              _c("ul", { staticClass: "list-unstyled mb-0 text-muted" }, [
                _c("li", [
                  _vm._v(
                    "\n            1. Вызвать доверие покупателя, имея на руках отчет с положительной экспертизой от\n            независимого юриста.\n          "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    "\n            2. Вовремя выявить и устранить неблагоприятные моменты для проведения сделки.\n          "
                  )
                ])
              ])
            ])
          ],
          1
        ),
        !_vm.riskAssessment
          ? _c(
              "div",
              { ref: "bottom", staticClass: "text-center mt-5" },
              [
                _c("p", { staticClass: "small" }, [
                  _vm._v(
                    "\n        Стоимость " +
                      _vm._s(_vm.product.product_price) +
                      " руб. Подготовка 1-3 дня.\n      "
                  )
                ]),
                _c(
                  "app-button",
                  {
                    attrs: { size: "lg", loading: _vm.orderRequest },
                    on: {
                      click: function($event) {
                        return _vm.checkout("pay_risk")
                      }
                    }
                  },
                  [_vm._v("\n        Заказать\n      ")]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-item__body" }, [
      _c("div", { staticClass: "product-item__name" }, [
        _vm._v("\n          Отчет о рисках\n        ")
      ]),
      _c("div", { staticClass: "product-item__desc" }, [
        _c("span", [
          _vm._v(
            "Проверка собственников в базах МВД, ФНС, ФССП, РосРеестр, Суды"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-sm-right" }, [
      _c("b", { staticClass: "text-nowrap" }, [_vm._v(" В работе ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-block__text" }, [
      _c("div", [
        _vm._v("- Риск утраты права собственности после проведения сделки")
      ]),
      _c("div", [
        _vm._v(
          "\n          - Наличие лица, имеющего право пожизненного проживания в квартире\n        "
        )
      ]),
      _c("div", [
        _vm._v(
          "\n          - Риск остаться должником по кредиту при утрате права собственности\n        "
        )
      ]),
      _c("div", [
        _vm._v(
          "\n          - Риски судебных тяжб с родственниками, чьи права были нарушены в прошлом\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk-block__text" }, [
      _c("div", [
        _vm._v(
          "\n          - Сбор и анализ информации по квартире, история перехода прав собственности\n        "
        )
      ]),
      _c("div", [
        _vm._v("- Выявление невидимых, но существенных рисков по сделке")
      ]),
      _c("div", [
        _vm._v(
          "\n          - Проверка соблюдения прав третьих лиц, не являющимися сторонами сделки\n        "
        )
      ]),
      _c("div", [
        _vm._v(
          "\n          - Проверка документов на соответствие законам, а также существование и подлинность\n          нотариальных документов\n        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }