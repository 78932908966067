import Vue from 'vue';
import Component from 'vue-class-component';
import addDays from 'date-fns/add_days';
import groupBy from 'lodash/groupBy';
import last from 'lodash/last';

import orderBy from '@/core/utils/orderBy';

import AppPropertyImage from '@/views/ObjectDetail/components/PropertyImage.vue';

@Component({
  name: 'AppSaleHistory',
  components: { AppPropertyImage },
  props: {
    info: Object,
  },
})
export default class SaleHistory extends Vue {
  // props
  info: RealtyObject;
  // data()
  loading: boolean = false;
  historyItems: PropertyHistoryItem[] = [];
  // computed
  // lifecycle hooks
  async created() {
    let address: any = this.info.Address.split(',');
    address.pop();
    address = address.join('');

    this.loading = true;

    try {
      const r = await this.$api.request({
        baseURL: 'https://api.realtycloud.ru',
        url: '/property/info/history',
        params: { address },
      });

      const properties = groupBy(r.properties, 'ads_id');
      const filteredProperties = [];

      for (const id in properties) {
        const items: PropertyHistoryItem[] = properties[id];
        let item: PropertyHistoryItem;

        if (items.length > 1) {
          const priceChanges: PriceChangeItem[] = [];

          const sortedSamples: PropertyHistoryItem[] = orderBy(
            items,
            (i) => new Date(i.upload_at).getTime(),
            'asc',
          );

          for (let index = 0, len = sortedSamples.length; index < len; index++) {
            const sample = sortedSamples[index];
            const prevSample = sortedSamples[index - 1];
            const priceChangeItem: PriceChangeItem = {
              date: sample.upload_at,
              price: parseInt(sample.price),
              difference: 0,
            };

            if (prevSample) {
              priceChangeItem.difference = parseInt(sample.price) - parseInt(prevSample.price);
            }

            priceChanges.push(priceChangeItem);
          }

          item = last(sortedSamples);

          item.date_end = new Date(item.upload_at);
          item.date_start = sortedSamples[0].upload_at;
          item.price_start = sortedSamples[0].price;
          item.price_end = item.price;

          if (!priceChanges.every((i) => i.difference === 0)) {
            item.price_changes = priceChanges;
          }
        } else {
          item = items[0];
          item.price_start = item.price_end = item.price;
          item.date_start = item.date_end = new Date(item.upload_at);
        }

        const dateEndWithFuture = addDays(item.date_end, 31);

        if (dateEndWithFuture.getTime() < Date.now()) {
          item.date_end = dateEndWithFuture;
        }

        filteredProperties.push(item);
      }

      this.historyItems = orderBy(
        filteredProperties,
        (i) => {
          if (i.date_end) {
            return i.date_end.getTime();
          }

          return Date.now();
        },
        'desc',
      );
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  // methods
  getLastPrice(item: PropertyHistoryItem) {
    if (item.price_changes) {
      return last(item.price_changes).price;
    }

    return item.price;
  }
}
