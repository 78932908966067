






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import noop from 'lodash/noop';

import { Validators } from '@/core/utils/validator';

@Component({
  name: 'AppShortRegistration',

  popupOptions: {
    size: 'md',
  },

  computed: {
    ...mapState('order', [ 'loading' ]),
  },

  props: {
    onRegistration: {
      type: Function,
      default: noop,
    },
    showInfo: {
      type: Boolean,
      default: true,
    },
    emailLabel: {
      type: String,
      default: 'E-mail куда будет отправлена выписка:',
    },
    phoneLabel: {
      type: String,
      default: 'Телефон для SMS уведомления:',
    },
  },
})
export default class ShortRegistrationDialog extends Vue {
  // props
  onRegistration: () => void;
  // data() {
  model = {
    email: '',
    phone: '',
    privacy: true,
  };

  async onSubmit() {
    try {
      // const response = await this.$store.dispatch('auth/registration', omit(this.model, 'privacy'));
      await this.$store.dispatch('order/regOrder', {
        email: this.model.email,
        phone: this.model.phone,
      });
      this.$dialog.close();
      this.onRegistration();
    } catch (error) {
      const text = (error as Error).message;
      this.$noty.error({ text });
      console.error(error);
    }
  }

  validationRules(): ValidationConfig {
    return {
      email: [ Validators.required, Validators.email ],
      phone: [ Validators.required, Validators.phone ],
      privacy: [ Validators.eq(true, 'Чтобы продолжить, установите этот флажок') ],
    };
  }
}
