var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        },
        change: _vm.onChange
      }
    },
    [_vm._t("default"), _vm._t("button", null, { loading: _vm.loading })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }