var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-picker" },
    [
      _c("the-mask", {
        ref: "pickerContainer",
        class: [_vm.size && "form-control-" + _vm.size],
        attrs: {
          mask: ["##.##.####"],
          placeholder: _vm.placeholder,
          masked: ""
        },
        on: { input: _vm.onInput, forminput: _vm.closePicker },
        model: {
          value: _vm.date,
          callback: function($$v) {
            _vm.date = $$v
          },
          expression: "date"
        }
      }),
      _vm.label
        ? _c("label", { class: [{ active: _vm.value }] }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }