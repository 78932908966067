























































































































































































































import Vue from 'vue';

import { RIGHT_LIST_EXAMPLES, EGRN_EXAMPLES } from '@/core/utils/constants/examples';
import store from '@/store';
import AppSearch from '@/components/Search.vue';
import AppChooseRoleDialog from 'shared/components/User/ChooseRoleDialog.vue';
import LocStorage from 'shared/utils/localStorage';

export default Vue.extend({
  components: { AppSearch },

  data() {
    return {
      answerList: [
        {
          title: 'Перед покупкой недвижимости',
          text: [
            'Самый распространенный случай, при котором заказывается выписка.',
            'Она позволяет проверить технические характеристики помещения,',
            'обременения или понять, кто является собственником',
          ].join(' '),
        },
        {
          title: 'После покупки недвижимости',
          text: [
            'Иногда бывает, что Росреестр может допустить ошибку при регистрации,',
            'поэтому не лишнем будет проверить данные в выписке.',
          ].join(' '),
        },
        {
          title: 'Продажа недвижимости',
          text:
            'Выписка позволяет подтвердить право владения недвижимостью перед покупателем и продать быстрее.',
        },
        {
          title: 'Аренда недвижимости',
          text:
            'Получить информацию о собственнике и об объекте, чтобы не попасться на сделку с мошенником.',
        },
        {
          title: 'Оформление ипотеки в банке',
        },
        {
          title: 'Раздел имущества',
        },
        {
          title: 'При приватизации жилья',
        },
        {
          title: 'Судебные разбирательства',
        },
        {
          title: 'При постановке земельного участка или гаража на учет',
        },
        {
          title: 'Обмен или дарение',
        },
      ],
    };
  },

  methods: {
    showExamples() {
      this.$photoSwipe([ ...EGRN_EXAMPLES, ...RIGHT_LIST_EXAMPLES ]);
    },
    showChooseRoleDialog() {
      if (!LocStorage.hasItem('chooseRoleDialogHasShown')) {
        if (store.state.auth.user && store.state.auth.user.person_role === 1) {
          this.$dialog.open({
            component: AppChooseRoleDialog,
            props: {
              firstShow: true,
            },
          });
          LocStorage.setItem('chooseRoleDialogHasShown', true);
        }
      }
    },
  },
  mounted() {
    this.$nextTick(this.showChooseRoleDialog);
  },
});
