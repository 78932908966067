import { RealtyCloudApi } from '../../api';
import { ApiAdminTariffMethods, apiAdminTariffMethods } from './tariff';

export interface ApiAdminMethods {
  Users<T = any[]>(params?: AdminUsersParams): Promise<T>;
  Orders<T = AdminOrderInfo[]>(params?: AdminOrdersParams): Promise<T>;
  CreateCoupon<T>(data: CreateCouponData): Promise<T>;
  GetSourceList<T = SourceItem[]>(): Promise<T>;
  GetRolesList<T = RoleItem[]>(): Promise<T>;
  GetPromocodes<T>(params?: any): Promise<T>;
  UpdatePromocode<T>(params?: any): Promise<T>;
  CreatePromocode<T>(params?: any): Promise<T>;
  DeletePromocode<T>(params?: any): Promise<T>;
  GetCoupons<T>(params?: AdminCouponsParams): Promise<T>;
  GetUserInfo<T>(userID: string): Promise<T>;
  GetCampaigns<T>(params?: AdminCouponsParams): Promise<T>;
  CreateCampaign<T>(params?: any): Promise<T>;
  DeleteCampaign<T>(params?: any): Promise<T>;
  UpdateCampaign<T>(params?: any): Promise<T>;
  ManualUpdate<T>(data: ManualUpdateData): Promise<T>;
  ManualAttachOrder<T>(params: ManualAttachOrderParams): Promise<T>;
  ManualAttachOrderItem<T>(params: ManualAttachOrderItemParams): Promise<T>;
  SearchUsers<T = any[]>(params?: AdminOrdersParams): Promise<T>;
  Stats<T = any>(params: StatsDateParams): Promise<T>;
  TopDonators<T = StatsDonator[]>(params: StatsDateParams): Promise<T>;
  TopDonatorsResponsible<T = any>(params: StatsDateParams): Promise<T>;
  OrderItemsList<T = AdminOrderItem2[]>(params: AdminOrdersParams): Promise<T>;
  StatusesList<T = { statuses: string[] }>(): Promise<T>;
  UpdateOrderItemKadnetStatus<T = any>(
    body: UpdateKadnetStatusRequestBody
  ): Promise<T>;
  UpdateSpvOrderItemStatus<T = any>(orderItemId: OrderItemID): Promise<T>;
  UpdateOrderItemStatus<T = any>(data: UpdateOrderItemStatusParams): Promise<T>;
  DownloadSpvOrderItem<T = any>(orderItemId: OrderItemID): Promise<T>;
  SendEmailWithAttachment<T = any>(orderItemID: OrderItemID): Promise<T>;
  Emails<T = any>(params: AdminEmailsParams): Promise<T>;
  PartnerStats<T = AdminPartnerStatsInfoResponse>(
    params: StatsDateParams & PaginationParams
  ): Promise<T>;
  StatsGraphic<T = any>(params: any): Promise<T>;

  /** Добавление ответственного к пользователю */
  ResponsibleUser<T = any>(
    body: Api.Admin.RequestBody.ResponsibleUser
  ): Promise<T>;

  /** Получение ответственного за пользователя */
  GetResponsibleUser<T = any>(controlledPersonId: string): Promise<T>;

  /** Удаление ответственнного */
  RemoveResponsible<T = RemoveResponsibleResponse>(
    data: Api.Admin.RequestBody.RemoveResponsible
  ): Promise<T>;

  /** Возвращение денег за заказ */
  Refunds<T = Api.Admin.Response.Refunds>(orderItemID: OrderItemID): Promise<T>;

  /** Снятие средств с баланса партнёра */
  PartnerWithdrawMoney<T = any>(
    data: Api.Admin.RequestBody.PartnerWithdrawMoney
  ): Promise<T>;

  /** Снятие средств с баланса аккаунта */
  AccountWithdrawMoney<T = any>(
    data: Api.Admin.RequestBody.AccountWithdrawMoney
  ): Promise<T>;

  /** Восстановление удаленного аккаунта */
  RecoverAccount<T = RecoverAccountResponse>(
    personId: RecoverAccountData
  ): Promise<T>;

  /** Получение списка счетов пользователя */
  GetBills<T = any>(data: AdminGetBillsParams): Promise<T>;
  GetLogs<T = any>(params: AdminUserLogsParams): Promise<T>;

  DownloadOrder<T = any>(orderItemID: string, fileType: string): Promise<T>;

  Metriks<T = any>(params?: AdminMetriksParams & PaginationParams): Promise<T>;

  SendLinkEnterToEmail<T = any>(params: SendLinkEnterToEmailParams): Promise<T>;

  /** Получение информации о купоне который был применён при покупке */
  GetCouponInfo<T>(couponID: string): Promise<T>;
  tariff: ApiAdminTariffMethods;
  UserTariffes<T = any[]>(params?: PaginationParams): Promise<T>;
  UpdateOrderStatus<T = any>(OrderID: string, Paid: boolean): Promise<T>;
}

export function adminMethods(this: RealtyCloudApi): ApiAdminMethods {
  return {
    Users: (params?: AdminUsersParams) => {
      return this.request({ url: '/admin/users', params });
    },

    Orders: (params?: AdminOrdersParams) => {
      return this.request({ url: '/admin/orders', params });
    },

    CreateCoupon: (data: CreateCouponData) => {
      return this.request({
        url: '/admin/coupon',
        method: 'POST',
        data,
      });
    },

    GetSourceList: () => {
      return this.request({ url: '/admin/source' });
    },

    GetRolesList: () => {
      return this.request({ url: '/admin/users/role' });
    },

    GetCoupons: (params?: AdminCouponsParams) => {
      return this.request({ url: '/admin/coupon', params });
    },

    GetCampaigns: (params?: AdminCouponsParams) => {
      return this.request({ url: '/admin/campaign', params });
    },

    CreateCampaign: (params?: any) => {
      return this.request({ url: '/admin/campaign', data: params, method: 'POST' });
    },

    UpdateCampaign: (params?: any) => {
      return this.request({ url: '/admin/campaign', data: params, method: 'PUT' });
    },

    DeleteCampaign: (params?: any) => {
      return this.request({ url: '/admin/campaign', data: params, method: 'DELETE' });
    },

    GetPromocodes: (params?: any) => {
      return this.request({ url: '/admin/promocode', params });
    },

    CreatePromocode: (params?: any) => {
      return this.request({ url: '/admin/promocode', data: params, method: 'POST' });
    },

    UpdatePromocode: (params?: any) => {
      return this.request({ url: '/admin/promocode', data: params, method: 'PUT' });
    },

    DeletePromocode: (params?: any) => {
      return this.request({ url: '/admin/promocode', data: params, method: 'DELETE' });
    },

    GetUserInfo: (userID: string) => {
      return this.request({ url: `/person/${userID}` });
    },

    ManualUpdate: (data: ManualUpdateData) => {
      return this.request({ url: '/admin/manual_update', method: 'PUT', data });
    },

    ManualAttachOrder: (data: ManualAttachOrderParams) => {
      return this.request({
        url: '/admin/orders/attach_order',
        method: 'POST',
        data,
      });
    },

    ManualAttachOrderItem: (data: ManualAttachOrderItemParams) => {
      return this.request({
        url: '/admin/orders/attach_product',
        method: 'POST',
        data,
      });
    },

    SearchUsers: (params: AdminOrdersParams) => {
      return this.request({ url: '/admin/users', params });
    },

    Stats: ({ from, to }: StatsDateParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
      };

      return this.request({ url: '/admin/stats', params });
    },

    TopDonators: ({ from, to }: StatsDateParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
      };
      return this.request({ url: '/admin/top_donators', params });
    },

    TopDonatorsResponsible: ({ from, to }: StatsDateParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
      };
      return this.request({ url: '/admin/top_donators_responsible', params });
    },

    OrderItemsList: (params: AdminOrdersParams) => {
      return this.request({
        url: '/admin/order_item/list',
        params,
      });
    },

    StatusesList: () => {
      return this.request({
        url: '/admin/order_item/all_statuses',
      });
    },

    UpdateOrderItemKadnetStatus: data => {
      return this.request({
        url: '/admin/order_item/update_order_item_kadnet_status',
        method: 'PUT',
        data,
      });
    },

    UpdateSpvOrderItemStatus: (orderItemId: OrderItemID) => {
      return this.request({
        url: '/admin/order_item/update_order_item_spv_status',
        method: 'PUT',
        data: { order_item_id: orderItemId },
      });
    },

    UpdateOrderItemStatus: (data: UpdateOrderItemStatusParams) => {
      return this.request({
        url: '/admin/order_item/update_order_item_status',
        method: 'PUT',
        data,
      });
    },

    DownloadSpvOrderItem: (orderItemId: OrderItemID) => {
      return this.request({
        url: '/admin/order_item/download_order_item_spv',
        method: 'PUT',
        data: { order_item_id: orderItemId },
      });
    },

    SendEmailWithAttachment: (orderItemID: OrderItemID) => {
      return this.request({
        url: '/admin/order_item/send_email_with_attachment',
        params: { orderItemID },
      });
    },

    Emails: (params: AdminEmailsParams) => {
      return this.request({
        url: '/admin/email',
        params,
      });
    },

    PartnerStats: ({
      from,
      to,
      ...pagination
    }: StatsDateParams & PaginationParams) => {
      const params = {
        'date-from': from,
        'date-to': to,
        ...pagination,
      };
      return this.request({
        url: '/admin/partner_account_sum',
        params,
      });
    },

    PartnerWithdrawMoney: (
      data: Api.Admin.RequestBody.PartnerWithdrawMoney
    ) => {
      return this.request({
        url: '/admin/partner/withdraw_money',
        method: 'POST',
        data,
      });
    },

    AccountWithdrawMoney: (
      data: Api.Admin.RequestBody.AccountWithdrawMoney
    ) => {
      return this.request({
        url: '/admin/users/subtract_account_balance',
        method: 'PUT',
        data,
      });
    },

    Refunds: (orderItemId: string) => {
      return this.request({
        url: '/return_product',
        method: 'POST',
        data: { order_item_id: orderItemId },
      });
    },

    StatsGraphic: params => {
      return this.request({
        url: '/admin/stats_graphic',
        method: 'GET',
        params,
      });
    },

    ResponsibleUser: (data: Api.Admin.RequestBody.ResponsibleUser) => {
      return this.request({
        url: '/admin/responsible_user/add',
        method: 'POST',
        data,
      });
    },

    RecoverAccount: (personId: RecoverAccountData) => {
      return this.request({
        url: '/admin/users/reset_removed_person',
        method: 'POST',
        data: personId,
      });
    },

    GetResponsibleUser: (controlledPersonId: string) => {
      return this.request({
        url: `/admin/users?controlledPersonID=${controlledPersonId}`,
      });
    },

    RemoveResponsible: (data: Api.Admin.RequestBody.RemoveResponsible) => {
      return this.request({
        url: '/admin/responsible_user/remove',
        method: 'DELETE',
        data,
      });
    },

    GetBills: (params: AdminGetBillsParams) => {
      return this.request({
        url: '/invoice/list',
        params,
      });
    },

    GetLogs: (params: AdminUserLogsParams) => {
      return this.request({
        url: '/admin/user_logs',
        params,
      });
    },

    DownloadOrder: (orderItemID: string, fileType: string) => {
      return this.request({
        url: `/download?orderID=${orderItemID}&fileType=${fileType}`,
      });
    },

    Metriks: (params: AdminMetriksParams) => {
      return this.request({
        url: '/admin/metrics',
        params,
      });
    },

    SendLinkEnterToEmail: ({ person_id, order_item_id, kadnum }) => {
      return this.request({
        url: '/admin/users/request_auth',
        method: 'POST',
        data: {
          person_id,
          order_item_id,
          kadnum: kadnum || null,
        },
      });
    },

    GetCouponInfo: (couponID: string) => {
      return this.request({
        url: `/admin/coupon/${couponID}`,
        method: 'GET',
      });
    },

    UserTariffes: (params?: PaginationParams) => {
      return this.request({ url: '/admin/person_tariffs', params });
    },

    UpdateOrderStatus: (OrderID: string, Paid: boolean) => {
      return this.request({
        url: '/admin/orders/update_order',
        method: 'PUT',
        data: {
          OrderID,
          Paid,
        },
      });
    },

    tariff: apiAdminTariffMethods.call(this),
  };
}
