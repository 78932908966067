var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "dropdown" } }, [
    _vm.Menu.isActive
      ? _c(
          "div",
          {
            staticClass: "dropdown-menu show",
            class: [
              _vm.Menu.direction && "dropdown-menu-" + _vm.Menu.direction
            ],
            on: { click: _vm.handleClick }
          },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }