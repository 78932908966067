import { RealtyCloudApi } from 'shared/api/api';

export interface ApiPayersMethods {
  AddPayer<T>(payer: Payer): Promise<T>;
  RemovePayer<T>(payerId: PayerID): Promise<T>;
  GetPayers<T = string[]>(): Promise<T>;
  GetPayerInfo<T = Payer>(payerId: PayerID): Promise<T>;
  UpdatePayerInfo<T>(payer: Payer): Promise<T>;
}

export function payersMethods(this: RealtyCloudApi): ApiPayersMethods {
  return {
    AddPayer: (payer: Payer) => {
      return this.request({
        url: '/payer/add_payer',
        method: 'POST',
        data: payer,
      });
    },
    RemovePayer: (payerId: PayerID) => {
      return this.request({
        url: '/payer/remove_payer',
        method: 'DELETE',
        data: payerId,
      });
    },
    GetPayers: () => {
      return this.request({ url: '/payer/get_payers' });
    },
    GetPayerInfo: (payerId: PayerID) => {
      return this.request({ url: '/payer/get_payer_info', params: payerId });
    },
    UpdatePayerInfo: (payer: Payer) => {
      return this.request({
        url: '/payer/update_payer_info',
        method: 'POST',
        data: payer,
      });
    },
  };
}
