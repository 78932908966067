var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "carousel" }, [
    _c(
      "div",
      {
        staticClass: "carousel__nav carousel__nav_left",
        on: { click: _vm.prev }
      },
      [
        _c("div", { staticClass: "carousel__nav-arrow" }, [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 9.3 17",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink"
              }
            },
            [
              _c("polyline", {
                attrs: {
                  fill: "none",
                  stroke: "#000000",
                  "stroke-linejoin": "butt",
                  "stroke-linecap": "butt",
                  "stroke-width": "1",
                  points: "0.5,0.5 8.5,8.5 0.5,16.5"
                }
              })
            ]
          )
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass: "carousel__nav carousel__nav_right",
        on: { click: _vm.next }
      },
      [
        _c("div", { staticClass: "carousel__nav-arrow" }, [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 9.3 17",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink"
              }
            },
            [
              _c("polyline", {
                attrs: {
                  fill: "none",
                  stroke: "#000000",
                  "stroke-linejoin": "butt",
                  "stroke-linecap": "butt",
                  "stroke-width": "1",
                  points: "0.5,0.5 8.5,8.5 0.5,16.5"
                }
              })
            ]
          )
        ])
      ]
    ),
    _c("div", { staticClass: "carousel__items" }, [_vm._t("default")], 2),
    _c(
      "div",
      { staticClass: "carousel__dots" },
      _vm._l(_vm.Carousel.itemsCount, function(i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "carousel__dot",
            class: { carousel__dot_active: _vm.Carousel.current === i - 1 }
          },
          [_c("div", { staticClass: "carousel__dot-body" })]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }