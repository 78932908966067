var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-owner-item" },
    [
      _c("div", { staticClass: "row no-gutters add-owner-item__checkbox" }, [
        _c("div", { staticClass: "col-auto" }, [
          _vm.owner.owner_type === 0
            ? _c("span", { staticClass: "text-muted" }, [
                _vm._v("\n        Введите ФИО полностью*\n      ")
              ])
            : _c("span", { staticClass: "text-muted" }, [
                _vm._v("\n        Введите данные*\n      ")
              ])
        ]),
        _c(
          "div",
          { staticClass: "col-auto ml-auto" },
          [
            _vm.isCheckPersonPage
              ? _c(
                  "a",
                  {
                    attrs: { target: "_blank", href: "/proverka-kontragenta" }
                  },
                  [_vm._v("\n        Юридическое лицо\n      ")]
                )
              : !_vm.onlyPhysical
              ? _c(
                  "app-checkbox",
                  {
                    attrs: { "false-value": 0, "true-value": 1 },
                    model: {
                      value: _vm.owner.owner_type,
                      callback: function($$v) {
                        _vm.$set(_vm.owner, "owner_type", $$v)
                      },
                      expression: "owner.owner_type"
                    }
                  },
                  [_vm._v("\n        Юридическое лицо\n      ")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "app-owner-form-item-base",
        {
          attrs: {
            owner: _vm.owner,
            index: _vm.index,
            "is-check-person": _vm.isCheckPersonPage,
            "is-form-on-landing": true
          }
        },
        [
          _vm._l(_vm.$slots, function(_, slot) {
            return [_c("template", { slot: slot }, [_vm._t(slot)], 2)]
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "form-row align-items-center mt-2 checkbox-wrapper" },
        [
          !_vm.isFormOnLanding
            ? _c("div", { staticClass: "col-4 text-right ml-auto" }, [
                _c(
                  "span",
                  {
                    staticClass: "add-owner-item__remove",
                    on: {
                      click: function($event) {
                        return _vm.$emit("remove", _vm.owner)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "far fa-trash-alt" }),
                    _vm._v(" Удалить\n      ")
                  ]
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }