import { RoistatState } from '@/core/utils/roistat';

(function(w: any, document: Document, tag: string, host: string, id: string) {
  w.roistatCookieDomain = 'realtycloud.ru';
  w.roistatProjectId = id;
  w.roistatHost = host;
  const protocol = document.location.protocol === 'https:' ? 'https://' : 'http://';
  const url = /^.*roistat_visit=[^;]+(.*)?$/.test(document.cookie)
    ? '/dist/module.js'
    : '/api/site/1.0/' + id + '/init';
  const script = document.createElement(tag) as HTMLScriptElement;

  script.charset = 'UTF-8';

  script.onload = function() {
    if (typeof w.roistat === 'undefined') {
      RoistatState.status = 'error';
    } else {
      const visitId = roistat.getVisit();

      if (visitId === null) {
        w.roistatVisitCallback = function() {
          RoistatState.status = 'callback';
        };
      } else {
        RoistatState.status = 'cache';
      }
    }
  };

  script.onerror = function() {
    RoistatState.status = 'error';
  };

  setTimeout(() => {
    if (RoistatState.status === 'loading') {
      console.log('roistat error');
      RoistatState.status = 'error';
    }
  }, 5000);

  script.async = true;
  script.src = protocol + host + url;
  const js2 = document.getElementsByTagName(tag)[0];
  js2.parentNode.insertBefore(script, js2);
})(window, document, 'script', 'cloud.roistat.com', '70e5fab2818b93bc53e3c9955912017d');
