import { RealtyCloudApi } from 'shared/api/api';

export interface ApiPriceComponentMethods {
  items: {
    All<T = PriceComponent.Item[]>(): Promise<T>;

    CreateItem<T = any>(data: PriceComponent.Request.Create): Promise<T>;

    UpdateItem<T = any>(data: PriceComponent.Request.Update): Promise<T>;

    DestroyItem<T = any>(priceComponentId: UUID): Promise<T>;
  };

  user: {
    All<T = PriceComponent.UserItem[]>(params: PriceComponent.Request.UserItemsParams): Promise<T>;

    CreateItem<T = any>(data: PriceComponent.Request.UserCreateItem): Promise<T>;

    DestroyItem<T = any>(data: PriceComponent.Request.UserDestroyItem): Promise<T>;

    UpdateItem<T = any>(data: PriceComponent.Request.UserUpdateItem): Promise<T>;
  };
}

export function priceComponentMethods(this: RealtyCloudApi): ApiPriceComponentMethods {
  return {
    items: {
      All: () => {
        return this.request({ url: '/admin/price_component/list' });
      },

      CreateItem: (data: PriceComponent.Request.Create) => {
        return this.request({
          url: '/admin/price_component/add',
          method: 'POST',
          data,
        });
      },

      UpdateItem: (data: PriceComponent.Request.Update) => {
        return this.request({
          url: '/admin/price_component/update',
          method: 'PUT',
          data,
        });
      },

      DestroyItem: (priceComponentId: UUID) => {
        return this.request({
          url: '/admin/price_component/delete',
          method: 'DELETE',
          data: { price_component_id: priceComponentId },
        });
      },
    },

    user: {
      All: (params: PriceComponent.Request.UserItemsParams) => {
        return this.request({ url: '/admin/price_component_owner/list', params });
      },

      CreateItem: (data: PriceComponent.Request.UserCreateItem) => {
        return this.request({
          url: '/admin/price_component_owner/add',
          method: 'POST',
          data,
        });
      },

      DestroyItem: (data: PriceComponent.Request.UserDestroyItem) => {
        return this.request({
          url: '/admin/price_component_owner/delete',
          method: 'DELETE',
          data,
        });
      },

      UpdateItem: (data: PriceComponent.Request.UserUpdateItem) => {
        return this.request({
          url: '/admin/price_component_owner/update',
          method: 'PUT',
          data,
        });
      },
    },
  };
}
