























import Vue from 'vue';
import Component from 'vue-class-component';

import AppPoll from '@/components/Poll/Poll.vue';
import DialogEventBus from 'shared/components/ui/Dialog/dialogEventBus';
import LocStorage from '@/core/utils/localStorage';

@Component({
  popupOptions: {
    size: 'md',
  },

  name: 'AppPollRiskDialog',

  components: { AppPoll },

  props: {
    query: String,
  },
})
export default class QuestionnaireRisk extends Vue {
  // props
  // props end
  // data()
  polls: PollItem[] = [
    {
      question: 'В данный момент вы в процессе покупки недвижимости?',
      description: '(Квартира/дом/участок)',
      answers: [ 'Да', 'Нет' ],
    },
    {
      question: 'Уверены ли вы в юридической чистоте объекта?',
      description: 'При любой покупке всегда есть риск остаться и без денег и без квартиры',
      answers: [ 'Нет', 'Да, проверил самостоятельно', 'Да проверяет риэлтор' ],
    },
    {
      question: 'Знаете ли вы, что можно заказать Отчет о рисках по интересующему объекту?',
      description:
        'В этом отчете отражены главные риски при покупке недвижимости. Проверка в базах МВД, ФНС, ФССП, Росреестр',
      answers: [ 'Да, знаю об этой услуге', 'Нет, впервые слышу' ],
    },
  ];

  // lifecycle hooks
  created() {
    DialogEventBus.once('close', this.dialogCloseCallback);
  }

  // methods
  dialogCloseCallback() {
    LocStorage.setItem('_q', Math.random());
  }

  goToArticle() {
    this.$dialog.close();
  }
}
