





















import Vue from 'vue';
import { TheMask as VueTheMask } from 'vue-the-mask';

export default Vue.extend({
  name: 'AppPhoneNumberInput',

  components: { VueTheMask },

  props: {
    size: String,
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '+7 (xxx) xxx-xx-xx',
    },
    iconPosition: {
      type: String,
      default: 'right',
    },
    label: {
      type: String,
      default: '',
    },
  },

  data() {
    return {};
  },

  computed: {
    maskValue() {
      return this.value === null ? '' : this.value;
    },
  },

  methods: {
    onInput(rawValue: string) {
      const value = !rawValue ? '' : `+7${rawValue}`;

      this.$emit('input', value);
    },
  },
});
