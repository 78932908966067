var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "auth-head" }, [
        _c("svg", { staticClass: "auth-head__icon" }, [
          _c("use", { attrs: { "xlink:href": "#icon-key" } })
        ]),
        _c("h4", { staticClass: "auth-head__title" }, [
          _vm._v("\n      Восстановление пароля\n    ")
        ])
      ]),
      !_vm.success
        ? _c(
            "app-form",
            {
              attrs: {
                model: _vm.model,
                rules: _vm.validationRules,
                "request-handler": _vm.onSubmit
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "button",
                    fn: function(ref) {
                      var loading = ref.loading
                      return [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "app-button",
                              {
                                attrs: {
                                  loading: loading,
                                  type: "submit",
                                  size: "lg",
                                  variant: "info",
                                  block: ""
                                }
                              },
                              [_vm._v("\n          Восстановить\n        ")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2098605470
              )
            },
            [
              _c(
                "app-form-group",
                { attrs: { "model-name": "username" } },
                [
                  _c("app-input", {
                    attrs: {
                      type: "email",
                      placeholder: "Ваш e-mail для сброса пароля",
                      size: "lg"
                    },
                    model: {
                      value: _vm.model.username,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "username", $$v)
                      },
                      expression: "model.username"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "form-group text-center" },
            [
              _c("p", [
                _vm._v(
                  "\n      На ваш E-mail отправлено письмо с новым паролем.\n    "
                )
              ]),
              _c(
                "app-button",
                {
                  attrs: { variant: "info", size: "sm" },
                  on: {
                    click: function($event) {
                      return _vm.$dialog.close()
                    }
                  }
                },
                [_vm._v("\n      Закрыть\n    ")]
              )
            ],
            1
          ),
      _c("div", { staticClass: "text-center" }, [
        _c(
          "span",
          {
            staticClass: "auth-link",
            on: {
              click: function($event) {
                return _vm.changeForm("register")
              }
            }
          },
          [_vm._v("Регистрация")]
        ),
        _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
        _c(
          "span",
          {
            staticClass: "auth-link",
            on: {
              click: function($event) {
                return _vm.changeForm("login")
              }
            }
          },
          [_vm._v("Вход в аккаунт")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }