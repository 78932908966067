var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "collapse-slide" },
      on: {
        enter: _vm.start,
        "after-enter": _vm.end,
        "before-leave": _vm.start,
        "after-leave": _vm.end
      }
    },
    [
      _vm.visible
        ? _c("div", { staticClass: "collapsible__wrapper" }, [
            _c(
              "div",
              { staticClass: "collapsible__content" },
              [_vm._t("default")],
              2
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }