













import isEmpty from 'lodash/isEmpty';
import Vue from 'vue';

export default Vue.extend({
  props: {
    conclusion: Object as () => RiskReport['conclusion'],
  },

  computed: {
    conclusionStatus(): ConclusionStatuses {
      const conclusions = this.conclusion.parsed;

      if (isEmpty(conclusions)) {
        return null;
      }

      if (conclusions.some(this.checkStatus('high'))) {
        return 'high';
      }

      if (conclusions.some(this.checkStatus('medium'))) {
        return 'medium';
      }

      if (conclusions.some(this.checkStatus('low'))) {
        return 'low';
      }

      return null;
    },

    textByStatus(): string {
      switch (this.conclusionStatus) {
        case 'low': return 'Низкий';
        case 'medium': return 'Средний';
        case 'high': return 'Высокий';
        default: return null;
      }
    },
  },
  methods: {
    checkStatus(status: ConclusionStatuses) {
      return (item: ConclusionItemParsed | ConclusionItemRaw) => {
        return item.status === status;
      };
    },
  },
});
