import Vue from 'vue';
// import Noty from 'noty';

interface NotyPlugin {
  show(options: Noty.Options): Noty;
  success(options: Noty.Options): Noty;
  error(options: Noty.Options): Noty;
  warning(options: Noty.Options): Noty;
  info(options: Noty.Options): Noty;
}

declare module 'vue/types/vue' {
  interface Vue {
    $noty: NotyPlugin;
  }
}

let NotyClass: any;

export default function notyPlugin(_Vue: typeof Vue) {
  _Vue.prototype.$noty = {
    show(options: Noty.Options) {
      if (NotyClass) {
        return new NotyClass(options).show();
      } else {
        import('noty').then((notyModule) => {
          NotyClass = notyModule.default;
          NotyClass.overrideDefaults({
            layout: 'bottomRight',
            theme: 'rc',
            timeout: 4000,
            animation: {
              open: 'rc-open',
            },
          });
          this.show(options);
        });
      }
    },

    success(options: Noty.Options) {
      options.text = `<i class="fas fa-check-circle"></i> ${options.text}`;
      return this.show({ type: 'success', ...options });
    },

    error(options: Noty.Options) {
      options.text = `<i class="fas fa-exclamation-circle"></i> ${options.text}`;
      return this.show({ type: 'error', ...options });
    },

    warning(options: Noty.Options) {
      options.text = `<i class="fas fa-exclamation-circle"></i> ${options.text}`;
      return this.show({ type: 'warning', ...options });
    },

    info(options: Noty.Options) {
      options.text = `<i class="fas fa-info-circle"></i> ${options.text}`;
      return this.show({ type: 'info', ...options });
    },
  };
}
