import { Module } from 'vuex';
import { _AdvertTypes } from './types';
import { Api } from 'shared/api';
import { isArray, isEmpty } from 'lodash';

interface AdvertInfoResponse {
  object_info: RealtyAdvert;
  average_price: number;
}

interface AveragePriceResponse {
  status: string;
  data: AveragePriceData;
}

export const advertModule: Module<AdvertState, RootState> = {
  namespaced: true,

  state: {
    list: [],
    unique: 0,
    info: null,
    loading: false,
    averagePrice: null,
    lastSearchParams: null,
    filters: {
      area1: null,
      area2: null,
      price1: null,
      price2: null,
      object_type: null,
      rooms: null,
      id_site: null,
    },
    reformaInfo: null,
    similarListIds: [],
    similarList: [],
    advertsDuplicates: [],
    priceChangeHouse: null,
    priceChangeArea: null,
    cadastralInfo: null,
  },

  getters: {
    [_AdvertTypes.getters.GET_AVERAGE_PRICE_STATS]: (state) => {
      let highPrice: string | number = 0;
      let lowPrice: string | number = 0;
      let midPrice: string | number = 0;
      if (state.averagePrice.list.length > 0) {
        state.averagePrice.list.forEach((elem: any) => {
          if (elem[2] > highPrice) {
            highPrice = elem[2];
          }
          if (elem[2] < lowPrice || lowPrice === 0) {
            lowPrice = elem[2];
          }
        });
        const prices: any = state.averagePrice.list.map((elem: any) => {
          return elem[2];
        });
        midPrice = Math.round(prices.reduce((a: number, b: number) => (a + b)) / prices.length);
      }
      return { highPrice, midPrice, lowPrice };
    },
  },

  actions: {
    async [_AdvertTypes.actions.GET_ADVERTS_LIST]({ commit }, params): Promise<void> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        const response = await Api.advert.List<AdvertsListResponse>(params);
        // если есть параметр offset то не сетаем массив обьявлений, а добавляем в него новые обьявления
        if (params.offset > 0) {
          commit(_AdvertTypes.mutations.UPDATE_LIST, response.adverts_list);
        } else {
          commit(_AdvertTypes.mutations.SET_LIST, response.adverts_list);
          commit(_AdvertTypes.mutations.SET_UNIQUE, response.unique);
        }
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

    async [_AdvertTypes.actions.GET_ADVERT_INFO]({ commit }, id: number): Promise<void> {
      try {
        const response = await Api.advert.Info<AdvertInfoResponse>(id);
        commit(_AdvertTypes.mutations.SET_INFO, response.object_info);
      } catch (error) {
        return Api.HttpError(error);
      }
    },

    async [_AdvertTypes.actions.GET_CADASTRAL_INFO]({ commit }, number: string): Promise<void> {
      try {
        const response = await Api.advert.GetCadastralInfo<AdvertInfoResponse>(number);
        commit(_AdvertTypes.mutations.SET_CADASTRAL_INFO, response);
      } catch (error) {
        return Api.HttpError(error);
      }
    },

    async [_AdvertTypes.actions.GET_REFORMA_INFO]({ commit }, address: string): Promise<void> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        const response = await Api.advert.GetReforma<AdvertInfoResponse>(address);
        if (!isEmpty(response)) {
          commit(_AdvertTypes.mutations.SET_REFORMA_INFO, response);
        }
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

    async [_AdvertTypes.actions.GET_ADVERTS_DUPLICATES]({ commit }, id: number): Promise<void> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        // несмотря на название метод возвращает не похожие обьявления, а дублирующееся на других источниках(?)
        const response = await Api.advert.SimilarList<AdvertInfoResponse>(id);
        commit(_AdvertTypes.mutations.SET_ADVERTS_DUPLICATES, response);
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

    async [_AdvertTypes.actions.GET_AVERAGE_PRICE]({ commit, state }, id: number): Promise<void> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        const response = await Api.advert.GetAveragePrice<AveragePriceData>(id);
        if (response.list && response.list.length) {
          for (const advert of response.list) {
            state.similarListIds.push(advert[0] as number);
          }
          commit(_AdvertTypes.mutations.SET_AVERAGE_PRICE, response);
        }
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

    async [_AdvertTypes.actions.GET_AVERAGE_PRICE_BY_PARAMS]({ commit, state }, params: AveragePriceByParamsData): Promise<void> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        const response = await Api.advert.GetAveragePriceByParams<AveragePriceData>(params);
        if (response.list && response.list.length) {
          for (const advert of response.list) {
            state.similarListIds.push(advert[0] as number);
          }
        }
        commit(_AdvertTypes.mutations.SET_AVERAGE_PRICE, response);
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

    async [_AdvertTypes.actions.GET_PRICE_CHANGE]({ commit }, params): Promise<void> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        const response = await Api.advert.GetPriceChange<AveragePriceData>(params);
        if (response.list && response.list.length) {
          switch (params.type) {
            case 'house_month':
              commit(_AdvertTypes.mutations.SET_PRICE_CHANGE_HOUSE, response);
              break;
            case 'area_month':
              commit(_AdvertTypes.mutations.SET_PRICE_CHANGE_AREA, response);
              break;
          }
        }
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

    async [_AdvertTypes.actions.GET_PRICE_CHANGE_BY_PARAMS]({ commit }, params): Promise<void> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        const response = await Api.advert.GetPriceChangeByParams<AveragePriceData>(params);
        if (response.list && response.list.length) {
          switch (params.type) {
            case 'house_month':
              commit(_AdvertTypes.mutations.SET_PRICE_CHANGE_HOUSE, response);
              break;
            case 'area_month':
              commit(_AdvertTypes.mutations.SET_PRICE_CHANGE_AREA, response);
              break;
          }
        }
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

    async [_AdvertTypes.actions.GET_ADVERTS_BY_IDS]({ commit }, params): Promise<RealtyAdvertItem[]> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        return await Api.advert.GetAdvertsByIds<RealtyAdvertItem[]>(params);
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

    async [_AdvertTypes.actions.GET_ADVERTS_IDS_BY_ADDRESS]({ commit }, params): Promise<number[]> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        return await Api.advert.GetAdvertsByAddress<number[]>(params);
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

    async [_AdvertTypes.actions.GET_ADVERTS_BY_ADDRESS]({ commit, dispatch }, params): Promise<number[]> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        const ids = await dispatch(_AdvertTypes.actions.GET_ADVERTS_IDS_BY_ADDRESS, params);
        await dispatch(_AdvertTypes.actions.GET_ADVERTS_BY_IDS, ids.slice(0, 100));
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

    async [_AdvertTypes.actions.GET_SIMILAR_LIST]({ commit, dispatch }, params): Promise<void> {
      commit(_AdvertTypes.mutations.TOGGLE_LOADING, true);
      try {
        const response = await dispatch(_AdvertTypes.actions.GET_ADVERTS_BY_IDS, params);
        commit(_AdvertTypes.mutations.SET_SIMILAR_LIST, response);
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit(_AdvertTypes.mutations.TOGGLE_LOADING, false);
      }
    },

  },

  mutations: {
    [_AdvertTypes.mutations.SET_LIST](state, list: RealtyAdvertItem[]): void {
      if (!isArray(list)) {
        list = [];
      }
      state.list = list;
    },

    [_AdvertTypes.mutations.UPDATE_LIST](state, list: RealtyAdvertItem[]): void {
      if (!isArray(list)) {
        list = [];
      }
      state.list = [ ...state.list, ...list ];
    },

    [_AdvertTypes.mutations.SET_SIMILAR_LIST](state, list: RealtyAdvertItem[]): void {
      if (!isArray(list)) {
        list = [];
      }
      state.similarList = list;
    },

    [_AdvertTypes.mutations.SET_ADVERTS_DUPLICATES](state, list: RealtyAdvertItem[]): void {
      if (!isArray(list)) {
        list = [];
      }
      state.advertsDuplicates = list;
    },

    [_AdvertTypes.mutations.SET_INFO](state, info: RealtyAdvert): void {
      state.info = info;
    },

    [_AdvertTypes.mutations.SET_UNIQUE](state, value: number): void {
      state.unique = value;
    },

    [_AdvertTypes.mutations.SET_AVERAGE_PRICE](state, data: AveragePriceData): void {
      state.averagePrice = data;
    },

    [_AdvertTypes.mutations.SET_PRICE_CHANGE_HOUSE](state, data: AveragePriceData): void {
      state.priceChangeHouse = data;
    },

    [_AdvertTypes.mutations.SET_PRICE_CHANGE_AREA](state, data: AveragePriceData): void {
      state.priceChangeArea = data;
    },

    [_AdvertTypes.mutations.TOGGLE_LOADING](state, loading: boolean): void {
      state.loading = loading;
    },

    [_AdvertTypes.mutations.SET_FILTERS](state, filters: AdvertFilters): void {
      state.filters = filters;
    },

    [_AdvertTypes.mutations.SET_LAST_SEARCH_PARAMS](state, params: AdvertsListParams): void {
      state.lastSearchParams = params;
    },

    [_AdvertTypes.mutations.SET_REFORMA_INFO](state, params: ReformaResponse): void {
      state.reformaInfo = params;
    },

    [_AdvertTypes.mutations.SET_CADASTRAL_INFO](state, params: CadastralInfoResponse): void {
      state.cadastralInfo = params;
    },
  },
};
