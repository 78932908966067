var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "tariff-updown" } }, [
    _c("div", { staticClass: "tariff-noty" }, [
      _vm.hasRoleComponent
        ? _c("div", { staticClass: "tariff-noty-text" }, [
            _vm._v(
              "\n      Для вас установлены специальные цены. После оформления заказов на сумму 1500 рублей, тариф поменяется на "
            ),
            _c("b", [_vm._v("Базовый")]),
            _vm._v(". Сохраните или выберите выгодные цены на странице "),
            _c("a", { attrs: { href: "https://realtycloud.ru/tariff/" } }, [
              _vm._v("Смотреть тарифы")
            ])
          ])
        : _c("div", { staticClass: "tariff-noty-text" }, [
            _vm._v("\n      Аккаунт на "),
            _c("b", [_vm._v("Базовом тарифе")]),
            _vm._v(
              ", чтобы получить выгодные цены - активируйте подходящий тариф. "
            ),
            _c("a", { attrs: { href: "https://realtycloud.ru/tariff/" } }, [
              _vm._v("Смотреть тарифы")
            ])
          ]),
      _c("span", {
        staticClass: "tariff-noty-close",
        on: { click: _vm.closeTariffOffer }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }