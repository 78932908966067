import Vue from 'vue';
import Router, { Route, RawLocation } from 'vue-router';
import AbstractRoute from './views/AbstractRoute.vue';
import AbstractRouteWithSidebar from './views/AbstractRouteWithSidebar.vue';
import AdminAbstractRoute from './views/AdminAbstractRoute.vue';
import ObjectDetail from './views/ObjectDetail/ObjectDetail.vue';
import LoginForAdmins from './views/LoginForAdmins.vue';
// import Home from './views/Home.vue';
import StartPage from './views/StartPage/StartPage.vue';

import { AuthTypes } from 'shared/store/modules/auth/types';

import { roleGuard, isTestPageGuard } from './core/utils/guards';
import { Role } from 'shared/utils/constants';
import store from './store';

Vue.use(Router);

declare module 'vue/types/vue' {
  interface Vue {
    beforeRouteEnter?(
      to: Route,
      from: Route,
      next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
    ): void;

    beforeRouteLeave?(
      to: Route,
      from: Route,
      next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
    ): void;

    beforeRouteUpdate?(
      to: Route,
      from: Route,
      next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
    ): void;
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      // name: 'home',
      component: AbstractRoute,
      children: [
        {
          path: '',
          name: 'home',
          beforeEnter: isTestPageGuard,
          component: StartPage,
        },
        {
          path: '/risk',
          name: 'risk',
          beforeEnter: isTestPageGuard,
          component: () =>
            import(/* webpackChunkName: "risk" */ './views/RiskPage/RiskArticle.vue'),
        },
        {
          path: '/package',
          name: 'package',
          beforeEnter: isTestPageGuard,
          component: () =>
            import(/* webpackChunkName: "package" */ './views/PackageOffers/PackageOffers.vue'),
        },
        {
          path: '/free',
          name: 'free',
          beforeEnter: isTestPageGuard,
          component: () =>
            import(/* webpackChunkName: "egrn-free" */ './views/FreeEgrn/FreeEgrn.vue'),
        },
        {
          path: '/25rub',
          name: '25rub',
          beforeEnter: isTestPageGuard,
          component: () =>
            import(/* webpackChunkName: "b2b-landing" */ './views/B2BLanding/B2BLanding.vue'),
        },
        {
          path: '/search',
          name: 'search',
          beforeEnter: isTestPageGuard,
          component: () =>
            import(/* webpackChunkName: "search" */ './views/SearchPage/SearchPage.vue'),
        },
        // {
        //   path: '/webinar',
        //   name: 'webinar',
        //   component: () =>
        //     import(/* webpackChunkName: "webinar" */ './views/StaticPages/Webinar.vue'),
        // },
        {
          path: '/static/offer',
          name: 'offer',
          beforeEnter: isTestPageGuard,
          component: () =>
            import(/* webpackChunkName: "static" */ './views/StaticPages/Offer.vue'),
        },
        {
          path: '/static/privacy',
          name: 'privacy',
          beforeEnter: isTestPageGuard,
          component: () =>
            import(/* webpackChunkName: "static" */ './views/StaticPages/Privacy.vue'),
        },
        {
          path: '/static/price-list',
          name: 'price-list',
          beforeEnter: isTestPageGuard,
          component: () =>
            import(/* webpackChunkName: "static" */ './views/StaticPages/PriceList.vue'),
        },
        {
          path: '/wholesale',
          name: 'wholesale-order',
          beforeEnter: roleGuard([ Role.Admin ]),
          component: () =>
            import(/* webpackChunkName: "wholesale" */ './views/Wholesale.vue'),
        },
        {
          path: '',
          component: AbstractRouteWithSidebar,
          children: [
            {
              path: 'object/:objectKey',
              name: 'object-detail',
              beforeEnter: isTestPageGuard,
              props: true,
              component: ObjectDetail,
            },
            {
              path: 'riski',
              name: 'risk-questionnaire',
              component: () =>
                import(/* webpackChunkName: "risk" */ './views/RiskPage/RiskQuestionnaireArticle.vue'),
            },
            {
              path: 'usecases',
              name: 'usecases',
              beforeEnter: isTestPageGuard,
              component: () =>
                import(/* webpackChunkName: "usecases" */ './views/Usecases/Usecases.vue'),
            },
            {
              path: 'mortgage-selection',
              name: 'mortgage-selection',
              beforeEnter: isTestPageGuard,
              component: () =>
                import(/* webpackChunkName: "extra-products" */ './views/MortgageSelection/MortgageSelection.vue'),
            },
            {
              path: 'deal',
              name: 'deal',
              beforeEnter: isTestPageGuard,
              component: () =>
                import(/* webpackChunkName: "extra-products" */ './views/Deal/Deal.vue'),
            },
            {
              path: 'user',
              name: 'user-profile',
              beforeEnter: isTestPageGuard,
              component: () =>
                import(/* webpackChunkName: "user" */ './views/UserProfile/UserOrders/UserOrders.vue'),
            },
            {
              path: 'user/settings',
              name: 'user-settings',
              beforeEnter: isTestPageGuard,
              component: () =>
                import(/* webpackChunkName: "user" */ './views/UserProfile/UserSettings/UserSettings.vue'),
            },
          ],
        },
      ],
    },

    {
      path: '/estimate',
      name: 'estimate',
      component: () =>
        import(/* webpackChunkName: "estimate" */ './views/Estimate/Estimate.vue'),
    },

    {
      path: '/object-demo',
      name: 'object-demo',
      component: () =>
        import(/* webpackChunkName: "demo" */ './views/ObjectDetailDemo/DemoObjectDetailWrapper.vue'),
    },

    {
      path: '/admin-login',
      name: 'LoginForAdmins',
      component: LoginForAdmins,
    },

    {
      path: '/admin',
      component: AdminAbstractRoute,
      children: [
        {
          path: '',
          name: 'admin-orders',
          beforeEnter: roleGuard([ Role.Admin, Role.Partner, Role.PartnerRisk ]),
          component: () => {
            if (store.getters[AuthTypes.getters.IS_ROLE](Role.Partner)) {
              return import(/* webpackChunkName: "admin" */ './views/Admin/AdminOrders/AdminPartnerOrders.vue');
            }

            return import(/* webpackChunkName: "admin" */ './views/Admin/AdminOrders/AdminOrders.vue');
          },
        },
        {
          path: 'users',
          name: 'admin-users',
          beforeEnter: roleGuard([ Role.Admin ]),
          component: () =>
            import(/* webpackChunkName: "admin" */ './views/Admin/AdminUsers.vue'),
        },
        {
          path: 'search',
          name: 'admin-search',
          beforeEnter: roleGuard([ Role.Admin, Role.Manager ]),
          component: () =>
            import(/* webpackChunkName: "admin" */ './views/Admin/AdminSearchUsers.vue'),
        },
        {
          path: 'stats',
          name: 'admin-stats',
          beforeEnter: roleGuard([ Role.Admin ]),
          component: () =>
            import(/* webpackChunkName: "admin" */ './views/Admin/AdminStats/AdminStats.vue'),
        },
        {
          path: 'coupons',
          name: 'admin-coupons',
          beforeEnter: roleGuard([ Role.Admin ]),
          component: () =>
            import(/* webpackChunkName: "admin" */ './views/Admin/AdminCoupons.vue'),
        },
        {
          path: 'control',
          name: 'admin-control',
          beforeEnter: roleGuard([ Role.Admin ]),
          component: () =>
            import(/* webpackChunkName: "admin" */ './views/Admin/AdminControl/AdminControl.vue'),
        },
        {
          path: 'price-component',
          name: 'admin-price-component',
          beforeEnter: roleGuard([ Role.Admin ]),
          component: () =>
            import(/* webpackChunkName: "admin" */ './views/Admin/AdminPriceComponent/AdminPriceComponent.vue'),
        },
        {
          path: 'settings',
          name: 'admin-settings',
          beforeEnter: roleGuard([ Role.Admin ]),
          component: () =>
            import(/* webpackChunkName: "admin" */ './views/Admin/Settings/AppSettings.vue'),
        },
        {
          path: 'bills',
          name: 'admin-bills',
          beforeEnter: roleGuard([ Role.Admin ]),
          component: () =>
            import(/* webpackChunkName: "admin" */ './views/Admin/AdminBills.vue'),
        },
        {
          path: 'finance',
          name: 'admin-partner-finance',
          beforeEnter: roleGuard([ Role.Admin, Role.Partner ]),
          component: () =>
            import(/* webpackChunkName: "partner" */ './views/Admin/Partner/Finance/Finance.vue'),
        },
        {
          path: 'instruments',
          name: 'admin-partner-instruments',
          beforeEnter: roleGuard([ Role.Admin, Role.Partner ]),
          component: () =>
            import(/* webpackChunkName: "partner" */ './views/Admin/Partner/Instruments/Instruments.vue'),
        },
        {
          path: 'user/:userID',
          beforeEnter: roleGuard([
            Role.Admin,
            Role.Partner,
            Role.PartnerRisk,
            Role.Manager,
          ]),
          component: () =>
            import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminUserInfo.vue'),
          props: true,
          children: [
            {
              path: '',
              name: 'admin-user-orders',
              props: true,
              beforeEnter: roleGuard([ Role.Admin, Role.Partner, Role.Manager ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminUserOrders/AdminUserOrders.vue'),
            },
            {
              path: 'orderv2',
              name: 'admin-user-orders-new',
              props: true,
              beforeEnter: roleGuard([ Role.Admin, Role.Partner, Role.Manager ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminUserOrdersNew/AdminUserOrdersNew.vue'),
            },
            {
              path: 'emails',
              name: 'admin-user-emails',
              beforeEnter: roleGuard([ Role.Admin, Role.Partner, Role.Manager ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminUserEmails/AdminUserEmails.vue'),
            },
            {
              path: 'finance',
              name: 'admin-user-finance',
              props: true,
              beforeEnter: roleGuard([ Role.Admin ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminUserFinance/AdminUserFinance.vue'),
            },
            {
              path: 'price-component',
              name: 'admin-user-price-component',
              props: true,
              beforeEnter: roleGuard([ Role.Admin, Role.Manager ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminUserPriceComponent/AdminUserPriceComponent.vue'),
            },
            {
              path: 'tariff-tools',
              name: 'admin-user-tariff-tools',
              props: true,
              beforeEnter: roleGuard([ Role.Admin ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminPartnerTariffTools/AdminPartnerTariffTools.vue'),
            },
            {
              path: 'bills',
              name: 'admin-user-bills',
              props: true,
              beforeEnter: roleGuard([ Role.Admin ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminUserBills/AdminUserBills.vue'),
            },
            {
              path: 'metriks',
              name: 'admin-user-metriks',
              props: true,
              beforeEnter: roleGuard([ Role.Admin ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminUserMetriks/AdminUserMetriks.vue'),
            },
            {
              path: 'user-logs',
              name: 'admin-user-logs',
              props: true,
              beforeEnter: roleGuard([ Role.Admin, Role.Partner, Role.Manager ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminUserLogs/AdminUserLogs.vue'),
            },
            {
              path: ':orderID',
              name: 'admin-order-detail',
              props: true,
              beforeEnter: roleGuard([ Role.Admin, Role.Partner, Role.Manager ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/AdminOrderDetail/AdminOrderDetail.vue'),
            },
            {
              path: 'risk/:orderItemID',
              name: 'admin-risk-report',
              props: true,
              beforeEnter: roleGuard([
                Role.Admin,
                Role.Partner,
                Role.PartnerRisk,
                Role.Manager,
              ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/RiskReport/RiskReport.vue'),
            },
            {
              path: 'riskV2/:orderItemID',
              name: 'admin-risk-report-v2',
              props: true,
              beforeEnter: roleGuard([
                Role.Admin,
                Role.Partner,
                Role.PartnerRisk,
                Role.Manager,
              ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/RiskReportV2/RiskReport.vue'),
            },
            {
              path: 'creditRating/:orderItemID',
              name: 'admin-credit-rating',
              props: true,
              beforeEnter: roleGuard([
                Role.Admin,
                Role.Partner,
                Role.PartnerRisk,
                Role.Manager,
              ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/CreditRating/AdminCreditRating.vue'),
            },
            {
              path: 'counterAgent/:orderItemID',
              name: 'admin-counter-agent',
              props: true,
              beforeEnter: roleGuard([
                Role.Admin,
                Role.Partner,
                Role.PartnerRisk,
                Role.Manager,
              ]),
              component: () =>
                import(/* webpackChunkName: "admin" */ './views/Admin/AdminUserInfo/CounterAgent/AdminCounterAgent.vue'),
            },
          ],
        },
      ],
    },

    {
      path: '*',
      redirect: { name: 'home' },
    },
  ],
});
