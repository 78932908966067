import { Module } from 'vuex';
import queryString from 'query-string';
import intersectionBy from 'lodash/intersectionBy';
import differenceBy from 'lodash/differenceBy';
import LocStorage from '@/core/utils/localStorage';
import Analytics from 'shared/utils/analytics';
import { Api } from 'shared/api';

export const marketingModule: Module<MarketingState, RootState> = {
  namespaced: true,

  state: {
    campaign: undefined,
    discountWasShown: '_dsc' in localStorage,
  },

  actions: {
    checkCampaign({ commit }) {
      const query = queryString.parse(location.search);

      if (query.utm_campaign && query.utm_campaign !== 'freecloud') {
        commit('setCampaign', query.utm_campaign);
      }
    },

    checkTransactions(_, purchasedProducts: Product[]) {
      const orderItems: OrderItem[] = LocStorage.getItem('orders', []);

      // Исключить из данных всех до 3 Апреля 2019
      // orderItems = orderItems.filter(
      //   (item: OrderItem) =>
      //     new Date(item.created_at).getTime() - new Date('04.03.2019').getTime() > 0,
      // );

      const intersectedOrderItems = intersectionBy(orderItems, purchasedProducts, 'order_item_id');

      const removeOrderFromStorage = () => {
        const diffOrderItems = differenceBy(orderItems, intersectedOrderItems, 'order_item_id');
        LocStorage.setItem('orders', diffOrderItems);
      };

      if (intersectedOrderItems.length > 0) {
        const totalAmount = intersectedOrderItems.reduce((t, i) => t + parseInt(i.price, 10), 0);
        const transactionId = intersectedOrderItems[0].id;
        // const yandexCounterParams = {
        //   id: transactionId,
        //   currency: 'RUB',
        //   order_price: totalAmount.toFixed(2),
        // };

        // analytics.dataLayerPush({
        //   ecommerce: {
        //     currencyCode: 'RUB',
        //     purchase: {
        //       actionField: {
        //         id: transactionId,
        //         affiliation: 'egrn.realtycloud',
        //         revenue: totalAmount,
        //       },
        //       products: intersectedOrderItems.map((item) => {
        //         return {
        //           id: item.product_id,
        //           name: item.product_name,
        //         };
        //       }),
        //     },
        //   },
        //   'event': 'gtm-ee-event',
        //   'gtm-ee-event-category': 'Enhanced Ecommerce',
        //   'gtm-ee-event-action': 'Purchase',
        //   'gtm-ee-event-non-interaction': 'False',
        // });
        removeOrderFromStorage();
        Analytics.gtag('event', 'conversion', {
          send_to: 'AW-752053985/g39BCNP76pkBEOHdzeYC',
          value: totalAmount,
          currency: 'RUB',
          transaction_id: transactionId,
        });
        // Analytics.send('payment_success' /*, { ya: yandexCounterParams } */);
        Api.measurement.Conversion({
          transactionId,
          items: intersectedOrderItems,
          totalAmount: totalAmount.toString(),
        });
      }

      return intersectedOrderItems.length;
    },
  },

  mutations: {
    setCampaign(state, campaign: string) {
      state.campaign = campaign;
    },

    setDiscountStatus(state, discountWasShown: boolean) {
      state.discountWasShown = discountWasShown;
      localStorage.setItem('_dsc', Math.random().toString());
    },
  },
};
