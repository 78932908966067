import regions from './regions.json';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';

// interface RegionIds {
//   fssp?: string;
//   bankrot?: string;
// }

interface Region {
  id: string;
  name: string;
}

function getRegionNameById(id: string): string | undefined {
  const region = find<Region>(regions, { id });

  return region ? region.name : undefined;
}

const regionsSortByName = orderBy(regions, (region) => region.name.toLowerCase());

export {
  regions,
  regionsSortByName,
  getRegionNameById,
  Region,
};
