var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "poll-item" }, [
    _c("div", { staticClass: "poll-item__question" }, [
      _vm._v("\n    " + _vm._s(_vm.item.question) + "\n  ")
    ]),
    _c("div", { staticClass: "poll-item__desc" }, [
      _vm._v("\n    " + _vm._s(_vm.item.description) + "\n  ")
    ]),
    _c(
      "div",
      { staticClass: "poll-item__answers" },
      _vm._l(_vm.item.answers, function(answer, index) {
        return _c(
          "div",
          {
            key: answer.text,
            staticClass: "poll-item__answer",
            class: { "is-selected": index === _vm.selectedAnswer },
            on: {
              click: function($event) {
                return _vm.select(index, answer)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(answer.text) + "\n    ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }