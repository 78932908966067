

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Item } from 'photoswipe';

@Component({
  props: {
    photos: Array,
  },
})
export default class PropertyImage extends Vue {
  // props
  photos: string[];

  // data()
  loaded: boolean = false;

  // methods
  imgLoaded(event: Event) {
    const img = event.currentTarget as HTMLImageElement;

    if (img.naturalWidth !== 1 && img.naturalHeight !== 1) {
      this.loaded = true;
    }
  }

  showLightbox() {
    const photos: Item[] = [];
    for (const photo of this.photos) {
      photos.push({
        src: photo.replace('640x480', '1280x960'),
        w: 0,
        h: 0,
      });
    }
    this.$photoSwipe(photos, true);
  }
}
