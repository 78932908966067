var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-3" },
    [
      _c("app-dialog-close"),
      _vm.user
        ? _c(
            "div",
            { staticClass: "user-menu-header user-menu-header_mobile" },
            [
              _c("svg", { staticClass: "user-menu-header__icon" }, [
                _c("use", { attrs: { "xlink:href": "#icon-user-thin" } })
              ]),
              _c("div", { staticClass: "user-menu-header__info" }, [
                _c(
                  "ul",
                  { staticClass: "user-menu-header__name list-unstyled" },
                  [
                    _c("li", [_vm._v(_vm._s(_vm.username))]),
                    _c("li", [_vm._v(_vm._s(_vm.user.surname))])
                  ]
                ),
                _c("div", { staticClass: "user-menu-header__role" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("currency")(_vm.balance)) +
                      "\n      "
                  )
                ])
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "m-menu" },
        [
          _c(
            "router-link",
            {
              staticClass: "m-menu__item",
              attrs: { to: { name: "user-profile" } },
              nativeOn: {
                click: function($event) {
                  return _vm.$dialog.close()
                }
              }
            },
            [_vm._v("\n      Мои заказы\n    ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "m-menu__item",
              attrs: { to: { name: "user-settings" } },
              nativeOn: {
                click: function($event) {
                  return _vm.$dialog.close()
                }
              }
            },
            [_vm._v("\n      Настройки\n    ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "m-menu__item",
              attrs: { to: { name: "package" } },
              nativeOn: {
                click: function($event) {
                  return _vm.$dialog.close()
                }
              }
            },
            [_vm._v("\n      Выписки оптом\n    ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "m-menu__item",
              attrs: { to: { name: "mortgage-selection" } },
              nativeOn: {
                click: function($event) {
                  return _vm.$dialog.close()
                }
              }
            },
            [_vm._v("\n      Подбор ипотеки\n    ")]
          ),
          _c(
            "a",
            {
              staticClass: "m-menu__item",
              attrs: { href: "https://realtycloud.ru/seller", target: "_blank" }
            },
            [_vm._v("\n      Продать квартиру\n    ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "m-menu__item",
              attrs: { to: { name: "usecases" } },
              nativeOn: {
                click: function($event) {
                  return _vm.$dialog.close()
                }
              }
            },
            [_vm._v("\n      Документы для сделки\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "m-menu__item",
              on: { click: _vm.showActivatePromoCodeDialog }
            },
            [_vm._v("\n      Активировать купон\n    ")]
          ),
          _c(
            "div",
            { staticClass: "m-menu__item", on: { click: _vm.logout } },
            [_vm._v("\n      Выход\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }