var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-list" }, [
    _vm.purchases && _vm.purchases.length
      ? _c(
          "div",
          {
            staticClass:
              " product-list__items wrapper-padding_sm border-bottom py-3"
          },
          [
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              _vm._l(_vm.purchasesSlice, function(p) {
                return _c("app-purchased-item", {
                  key: p.default.order_item_id,
                  attrs: { item: p },
                  on: {
                    "risk-click": function($event) {
                      return _vm.$emit("change-tab", "risk")
                    }
                  }
                })
              }),
              1
            ),
            _vm.purchases.length > 4
              ? _c("div", { staticClass: "text-center" }, [
                  _c(
                    "span",
                    {
                      staticClass: "btn-link",
                      on: { click: _vm.toggleExpand }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.showAllPurchases
                              ? "Свернуть"
                              : "Показать все заказы"
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ])
              : _vm._e()
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: " product-list__items wrapper-padding_sm" },
      [
        _vm._l(_vm.officialDocuments, function(p) {
          return _c("app-product-item", {
            key: p.default.product_id,
            attrs: {
              "allowed-fast-order": _vm.allowedFastOrder,
              item: p,
              "selected-products": _vm.items
            },
            on: {
              change: _vm.selectProducts,
              checkout: function($event) {
                return _vm.checkout("pay_risk")
              }
            }
          })
        }),
        _c(
          "div",
          { staticClass: "text-center product-list__btn" },
          [
            _c(
              "app-button",
              {
                attrs: {
                  size: "lg",
                  disabled: _vm.itemsCount === 0,
                  loading: _vm.orderRequest
                },
                on: {
                  click: function($event) {
                    return _vm.checkout("pay_statement")
                  }
                }
              },
              [
                _c("span", [_vm._v("Заказать и оплатить")]),
                _c("span", { staticClass: "ml-2" }, [
                  _vm._v(_vm._s(_vm.totalAmount))
                ]),
                _c("i", { staticClass: "fas fa-ruble-sign fa-sm ml-1" })
              ]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }