var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conclusion-item row no-gutters" }, [
    _vm.statusColor
      ? _c("div", { staticClass: "col-auto mr-3" }, [
          _c("span", [
            _c("i", {
              staticClass: "fas fa-fw",
              class: [
                _vm.statusColor,
                {
                  "fa-check-circle": _vm.conclusion.status === "check",
                  "fa-info-circle": _vm.conclusion.status === "info",
                  "fa-circle fa-xs":
                    _vm.conclusion.status !== "check" &&
                    _vm.conclusion.status !== "info"
                }
              ]
            })
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "conclusion-item__body col" }, [
      _vm.conclusion.title
        ? _c("div", {
            staticClass: "conclusion-item__title",
            domProps: { innerHTML: _vm._s(_vm.conclusion.title) }
          })
        : _vm._e(),
      _vm.conclusion.text
        ? _c("div", {
            staticClass: "conclusion-item__text",
            domProps: { innerHTML: _vm._s(_vm.conclusion.text) }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }