






























































import Vue from 'vue';
import Component from 'vue-class-component';
import cloneDeep from 'lodash/cloneDeep';
import times from 'lodash/times';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { mapState } from 'vuex';

import { Validators } from 'shared/utils/validator';
import isUnderage from 'shared/utils/isUnderage';
import AppAddOwnerFormItem from './AddOwnerFormItem.vue';
import { AuthTypes } from 'shared/store/modules/auth/types';
import { OrderTypes } from 'shared/store/modules/order/types';

@Component({
  components: { AppAddOwnerFormItem },
  props: {
    orderItemId: String,
    initialFormsCount: Number,
    submitHandler: Function,
    isPdf: {
      default: false,
      type: Boolean,
    },
    isFormOnLanding: {
      type: Boolean,
      default: false,
    },
    isFizCheck: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('order', [ 'owners' ]),
  },
})
export default class AddOwnerFormComponent extends Vue {
  // props
  orderItemId: string;
  initialFormsCount: number;
  isPdf: boolean;
  submitHandler: (data: LawInformationOwner[]) => Promise<any>;
  isFizCheck: boolean;

  // data
  owners: any;

  // computed
  // get owners(): any {
  //   return this.$store.state.order.owners;
  // }

  // lifecycle
  mounted() {
    if (this.initialFormsCount) {
      times(this.initialFormsCount, this.addOwner);
    }
    if (this.$route.query.first) {
      this.owners[0].first = this.$route.query.first;
    }
    if (this.$route.query.surname) {
      this.owners[0].surname = this.$route.query.surname;
    }
    if (this.$route.query.patronymic) {
      this.owners[0].patronymic = this.$route.query.patronymic;
    }
    if (this.$route.query.passport) {
      this.owners[0].passport = this.$route.query.passport;
    }
    if (this.$route.query.birthday) {
      console.log(this.$route.query.birthday);
      // const date = parse('24.11.1996', 'dd.MM.yyyy', new Date());
      // console.log(date);
      this.owners[0].birthday = this.$route.query.birthday;
    }
    if (this.$route.query.region) {
      this.owners[0].region = this.$route.query.region;
    }
    if (this.$route.query.submit) {
      setTimeout(() => {
        this.onSubmit();
      }, 1000);
    }
  }

  beforeDestroy() {
    this.$store.commit(OrderTypes.mutations.SET_OWNERS, []);
  }

  get isFizCheckDomain() {
    return this.$store.getters[AuthTypes.getters.IS_FIZCHECK];
  }

  // methods
  validationConfig(model: LawInformationOwner): ValidationConfig {
    if (model.owner_type === 0) {
      let config: ValidationConfig = {
        first: [ Validators.required, Validators.regex(/^[-а-яА-ЯёЁ\s]+$/, 'Только кириллица') ],
        surname: [ Validators.required, Validators.regex(/^[-а-яА-ЯёЁ\s]+$/, 'Только кириллица') ],
        patronymic: [ Validators.regex(/^[-а-яА-ЯёЁ\s]+$/, 'Только кириллица') ],
        passport: [ Validators.min(10, 'минимальная длина 10') ],
        birthday: [ Validators.date('Неверный формат даты') ],
      };

      // if (!this.isFizCheckDomain) {
      //   config.region = [ Validators.required ];
      // }

      if (isEmpty(model.passport)) {
        config = omit(config, 'passport');
      }

      if (isEmpty(model.birthday)) {
        config = omit(config, 'birthday');
      }

      if (isUnderage(model.birthday)) {
        config = omit(config, 'passport', 'region');
      }

      return config;
    } else {
      return {
        first: [ Validators.required ],
        inn: [ Validators.required, Validators.min(10, 'Обязательное поле') ],
        region: [ Validators.required ],
      };
    }
  }

  async onSubmit() {
    if (this.submitHandler) {
      await this.submitHandler(this.getOwnersBody());
    } else {
      await this.fetchCreateOwner();
    }
  }

  async fetchCreateOwner() {
    console.log(this.getOwnersBody(), 'addOwner');
    try {
      await this.$api.risk.AddOwnerV2(this.getOwnersBody());

      this.$emit('owners-added', cloneDeep(this.owners));
      this.$noty.success({ text: 'Данные успешно отправлены, ожидайте готовности отчёта' });
      this.$dialog.close();
      this.$store.commit(OrderTypes.mutations.SET_OWNERS, []);
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Произошла ошибка при отправке формы' });
    }
  }

  addOwner() {
    this.owners.push({
      owner_type: 0,
      first: '',
      surname: '',
      patronymic: '',
      passport: '',
      birthday: '',
      region: '',
      inn: '',
    });
    this.$store.commit(OrderTypes.mutations.SET_OWNERS, this.owners);
  }

  getOwnersBody(): LawInformationOwner[] {
    return this.owners.map((owner: LawInformationOwner) => {
      const body = {
        ...omit(owner, 'name', 'metadata'),
        first: owner.first,
        order_item_id: this.orderItemId,
      };

      if (owner.birthday) {
        body.birthday = new Date(owner.birthday).toISOString();
      } else {
        body.birthday = '';
      }

      return body;
    });
  }

  removeOwnerItem(target: LawInformationOwner) {
    const owners = this.owners.filter((owner: LawInformationOwner) => owner !== target);
    this.$store.commit(OrderTypes.mutations.SET_OWNERS, owners);
  }
}
