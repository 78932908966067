
































































import Vue from 'vue';
import Component from 'vue-class-component';
// import { TheMask } from 'vue-the-mask';
// import flatpickr from 'flatpickr';
// import differenceInYears from 'date-fns/difference_in_years';

// import { regionsSortByName, Region } from 'shared/utils/regions';
import AppOwnerFormItemBase from './OwnerFormItemBase.vue';

// const now = new Date();

@Component({
  components: { AppOwnerFormItemBase },

  props: {
    owner: Object,
    index: [ Number, String ],
    onlyPhysical: Boolean,
    isFormOnLanding: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AddOwnerFormItemComponent extends Vue {
  // props
  owner!: OwnerRequestBody;
  onlyPhysical!: boolean;

  get isCheckPersonPage() {
    return this.$route.name === 'check-person';
  }
}
