





import Vue from 'vue';
import Component from 'vue-class-component';
import { Provide, Watch } from 'vue-property-decorator';

@Component({
  name: 'AppDropdown',

  props: {
    direction: String,
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    outsideClose: {
      type: Boolean,
      default: true,
    },
  },
})
export default class Dropdown extends Vue {
  // props
  direction: string;
  closeOnSelect: boolean;
  outsideClose: boolean;

  @Provide()
  Menu: DropdownProvider = {
    isActive: false,
    closeOnSelect: this.closeOnSelect,
    direction: this.direction,
    $el: null,
  };

  @Watch('Menu.isActive')
  activeMenuWatcher(isActive: boolean) {
    if (!this.$isServer) {
      if (isActive) {
        document.addEventListener('click', this.outsideClickHandler);
      } else {
        document.removeEventListener('click', this.outsideClickHandler);
      }
    }
  }

  mounted() {
    this.Menu.$el = this.$el;
    const trigger = this.$el.querySelector('[data-dropdown-trigger]');

    if (trigger) {
      trigger.addEventListener('click', this.toggle);
    }
  }

  beforeDestroy() {
    if (!this.$isServer) {
      document.removeEventListener('click', this.outsideClickHandler);
    }
  }

  closeMenu() {
    if (this.outsideClose) {
      this.Menu.isActive = false;
    }
  }

  toggle() {
    this.Menu.isActive = !this.Menu.isActive;
  }

  outsideClickHandler(event: MouseEvent) {
    if (this.$el.contains(event.target as Element)) {
      return;
    }

    if (this.outsideClose) {
      this.toggle();
    }
  }
}
