






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { Route } from 'vue-router';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import includes from 'lodash/includes';
import differenceInMilliseconds from 'date-fns/difference_in_milliseconds';
import differenceInHours from 'date-fns/difference_in_hours';

import mousePageLeave from '@/core/utils/mousePageLeave';
import LocStorage from '@/core/utils/localStorage';
import { NULL_UUID, Role } from '@/core/utils/constants';
import sleep from 'shared/utils/sleep';

import AppDiscountDialog from '@/components/layout/Dialogs/Discount.vue';
import AppShortRegistration from '@/components/layout/Dialogs/ShortRegistration.vue';
import AppRiskPollDialog from '@/components/layout/Dialogs/PollRisk.vue';
import AppManualKadastrDialog from 'shared/components/ManualKadastrUpdate.vue';
import AppInfrastructureMap from '@/components/layout/Dialogs/InfrastructureMap.vue';
import AppPaymentSuccess from '@/components/layout/Dialogs/PaymentSuccess.vue';
import AppRiskUserInfoForm from 'shared/components/RiskNew/AddOwnerForm/AddOwnerDialog.vue';

import AppObjectDetailInfo from './components/ObjectDetailInfo.vue';
import AppObjectDetailFooter from './components/ObjectDetailFooter.vue';

import AppAssessment from './Tabs/Assessment/Assessment';
import AppDetailsList from './Tabs/DetailsList.vue';
import AppProductList from './Tabs/ProductList/ProductList.vue';
import AppRisk from './Tabs/Risk/Risk';
import AppSalesHistory from './Tabs/SalesHistory/SalesHistory';
import loadExternalScript from '../../core/utils/loadExternalScript';

// import AppInfrastructure from './Tabs/Infrastructure.vue';

interface TabItem {
  key: string;
  text: string;
  hidden?: boolean;
}

@Component({
  components: {
    AppObjectDetailInfo,
    AppObjectDetailFooter,
    AppAssessment,
    AppDetailsList,
  },

  props: {
    objectKey: String,
  },

  computed: {
    ...mapState('details', [ 'info', 'infoLoading', 'products', 'loading', 'purchases' ]),
    ...mapState([ 'products' ]),
    ...mapState('auth', [ 'user', 'balance' ]),
  },

  metaInfo() {
    const info: RealtyObject = (this as any).info;
    let description = 'Заказать выписку из ЕГРН (ЕГРП) онлайн';
    let title = 'Отчет из ЕГРН';

    if (info) {
      if (info.TypeOKC) {
        description += ` на ${info.TypeOKC}`;
      }

      if (info.Address) {
        description += ` по адресу ${info.Address}`;
      }

      if (info.Number) {
        description += ` с кадастровым номером ${info.Number}`;
        title += ` на ${info.Number}`;

        if (info.Address) {
          title += ` ${info.Address}`;
        }
      }

      description += '.';
    }

    return {
      title,
      meta: [
        {
          name: 'description',
          content: description,
        },
      ],
    };
  },
})
export default class ObjectDetail extends Vue {
  // props
  objectKey: string;
  purchases: Product[];
  info: RealtyObject;
  user: User;
  balance: number;

  // data()
  hasManualProducts: boolean = false;
  tab: string = 'statements';
  showHiddenTabs: boolean = false;
  tabs: TabItem[] = [
    {
      key: 'details',
      text: 'Об объекте',
    },
    {
      key: 'statements',
      text: 'Отчеты',
    },
    {
      key: 'assessment',
      text: 'Оценка',
      hidden: true,
    },
    {
      key: 'risk',
      text: 'Риски',
    },
    {
      key: 'sales-history',
      text: 'История продаж',
    },
    {
      key: 'infrastructure',
      text: 'Инфраструктура',
    },
  ];
  // data() end

  get currentTabComponent(): any {
    const componentByName: any = {
      assessment: AppAssessment,
      details: AppDetailsList,
      statements: AppProductList,
      risk: AppRisk,
      'sales-history': AppSalesHistory,
      // infrastructure: AppInfrastructure,
    };

    return componentByName[this.tab] || componentByName.statements;
  }

  get showManualUpdateAlert(): boolean {
    const products = cloneDeep(this.purchases);

    // Сортируем по дате заказа
    const sortedProductsByDate = products.sort((a, b) => {
      return differenceInMilliseconds(b.created_date, a.created_date);
    });

    if (!this.info.Number && this.hasManualProducts) {
      const [ productItem ] = sortedProductsByDate;

      // Показать предупреждение только через час после заказа
      return differenceInHours(Date.now(), productItem.created_date) >= 1;
    }

    return false;
  }

  @Watch('$route')
  routeChanged(oldRoute: Route, newRoute: Route) {
    if (oldRoute.params.objectKey !== newRoute.params.objectKey) {
      this.destroyHook();
      this.init();
    }
  }

  // lifecycle
  created() {
    this.init();
  }

  mounted() {
    loadExternalScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js');
  }

  beforeDestroy() {
    this.destroyHook();
  }

  // methods
  async init() {
    window.scroll(0, 0);

    if (this.$route.hash) {
      this.tab = this.$route.hash.slice(1);
    }
    const jwtToken: string = this.$store.state.query.jwt_token as string;
    if (jwtToken) {
      this.$store.commit('auth/setToken', jwtToken);
      this.$store.dispatch('auth/checkToken');
    }
    this.$store.commit('details/toggleLoading', true);
    this.$store.commit('details/toggleInfoLoading', true);
    this.$store.commit('order/setObjectKey', this.objectKey);

    await this.$store.dispatch('details/getObjectInfo', {
      objectKey: this.objectKey,
    });

    if (this.info.Address) {
      this.$store.commit('order/setObjectAddress', this.info.Address);
    }

    if (this.info.Number) {
      this.showHiddenTabs = /^(16|77):/.test(this.info.Number);
    }

    if (this.user && this.user.person_role === Role.Customer && !LocStorage.hasItem('_q')) {
      if (!this.purchases.some((p) => includes(p.product_name, 'RiskAssessment'))) {
        setTimeout(() => {
          this.$dialog.open({
            component: AppRiskPollDialog,
            props: {
              query: this.info.Number || this.info.Address,
            },
          });
        }, 15e3);
      }
    }

    if (/(?:\d{2}:){2}\d{6,7}:\d{1,}/.test(this.info.Number)) {
      this.$store.dispatch('details/getObjectFullInfo', this.info.Number);
    }

    this.initShowDiscount();

    this.hasManualProducts = this.purchases.some((p: Product) => {
      return p.product_type === 'official_documents' && p.status === 'manual';
    });

    if ('update_kadastr' in this.$route.query && this.hasManualProducts) {
      this.showKadastrUpdateDialog();
    }

    await this.$store.dispatch('marketing/checkTransactions', this.purchases);
    const payedRiskAssessmentItem = find(this.purchases, (p: Product) => {
      return includes(p.product_name, 'RiskAssessment') && p.transaction_id !== NULL_UUID;
    });

    if (payedRiskAssessmentItem != null) {
      if (
        payedRiskAssessmentItem.status === 'waiting_for_owners' &&
        !includes(LocStorage.getItem('riskForm', []), payedRiskAssessmentItem.order_item_id)
      ) {
        this.$dialog.open({
          component: AppRiskUserInfoForm,
          props: {
            orderItemId: payedRiskAssessmentItem.order_item_id,
          },
        });
      }
    }

    // if (paymentsCount > 0) {
    //   this.$dialog.open({ component: AppPaymentSuccess });
    // }

    this.checkOrderPayment();
  }

  destroyHook() {
    mousePageLeave.off(this.showDiscountDialog);
    this.$store.commit('order/changeItem', []);
    this.$store.commit('order/setObjectAddress', null);
    this.$store.commit('details/setInfo', { info: null, averageTime: 0 });
  }

  async checkout(analyticsTarget: string, orderType: string = 'default') {
    if (!this.user) {
      return this.$dialog.open({
        component: AppShortRegistration,
        props: {
          onRegistration: this.checkout,
        },
      });
    }

    this.$store.commit('order/setOrderType', orderType);
    const balanceBefore = this.balance;
    const isUsedBalance = await this.$store.dispatch('order/send', {
      use_account_balance: true,
    } as OrderOptions);

    if (isUsedBalance) {
      this.$dialog.open({
        component: AppPaymentSuccess,
        props: {
          text: `Заказ на сумму ${balanceBefore - this.balance} руб. успешно оформлен <br><br>
          Текущий баланс ${this.balance} руб.`,
        },
      });
    }
  }

  initShowDiscount() {
    const { campaign, discountWasShown }: MarketingState = this.$store.state.marketing;
    const hasTransaction = this.purchases.some((p) => p.transaction_id !== NULL_UUID);

    if (!campaign && !discountWasShown && !hasTransaction) {
      mousePageLeave.on(this.showDiscountDialog);
    }
  }

  changeTab(tabName: string) {
    if (tabName === 'infrastructure') {
      return this.$dialog.open({
        component: AppInfrastructureMap,
        props: {
          info: this.info,
        },
      });
    }

    if (!this.showHiddenTabs) {
      if (tabName === 'assessment') {
        return;
      }
    }

    this.tab = tabName;
    this.$router.push({ hash: tabName });
  }

  showKadastrUpdateDialog() {
    this.$dialog.open({
      component: AppManualKadastrDialog,
      props: {
        purchases: this.purchases,
      },
    });
  }

  showDiscountDialog() {
    this.$dialog.open({ component: AppDiscountDialog });
    this.$store.commit('marketing/setDiscountStatus', true);
    return true;
  }

  private async checkOrderPayment() {
    if (this.$route.query.check_order || this.$route.query.regorder) {
      let orderId: string = this.$route.query.check_order as string;
      if (this.$route.query.regorder) {
        orderId = LocStorage.getItem('regorder_id');
      }

      if (orderId) {
        try {
          await sleep(1);

          const { is_order_paid: isOrderPaid } = await this.$api.order.CheckOrderPayment(orderId);

          if (isOrderPaid) {
            this.$dialog.open({
              component: AppPaymentSuccess,
              props: {
                text:
                  '<h5>Платёж успешно прошёл <br /> Ваш заказ принят в обработку</h5> <p>Проверьте вашу почту или войдите в систему</p>',
              },
            });

            const params: any = {};

            if (this.$store.state.auth.user) {
              params.user_id = this.$store.state.auth.user.id;
            }

            this.$analytics.send('payment_success_test', params);
          }

          LocStorage.removeItem('regorder_id');
        } catch (error) {
          console.error(error);
        }
      }
    }
  }
}
