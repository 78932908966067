var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "object-detail-assessment wrapper-padding_sm" }, [
      _c(
        "div",
        { staticClass: "list-info" },
        [
          _vm.isProductPurchased
            ? [
                _c(
                  "div",
                  {
                    staticClass: "list-info-item px-0 w-100 border-bottom pb-3"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "list-info-item__icon text-warning" },
                      [
                        _c("svg", [
                          _c("use", { attrs: { "xlink:href": "#icon-time" } })
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "list-info-item__text d-flex justify-content-between flex-grow-1"
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "list-info-item__title" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.product.product_name_ru) +
                                "\n              "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "list-info-item__body text-muted" },
                            [
                              _vm._v(
                                "\n                расчет стоимости объекта\n              "
                              )
                            ]
                          )
                        ]),
                        _vm._m(0)
                      ]
                    )
                  ]
                )
              ]
            : _vm._e(),
          _c("div", { staticClass: "list-info-item px-0 w-100" }, [
            _c("div", { staticClass: "list-info-item__icon text-success" }, [
              _c("svg", [
                _c("use", { attrs: { "xlink:href": "#icon-checkmark-circle" } })
              ])
            ]),
            _c("div", { staticClass: "list-info-item__text" }, [
              _c("div", { staticClass: "list-info-item__title" }, [
                _vm._v("\n            Кадастровая стоимость\n            "),
                _vm.info && _vm.info.KadPrice
                  ? _c("span", [
                      _vm._v(
                        ": " + _vm._s(_vm._f("currency")(_vm.info.KadPrice))
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "list-info-item__body" }, [
                _vm._v("\n            информация из Росреестра\n            "),
                _vm.info && _vm.info.PriceApprovalDate
                  ? _c("span", [
                      _vm._v(
                        "\n              ( дата утверждения " +
                          _vm._s(_vm.info.PriceApprovalDate) +
                          " )\n            "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _c("div", { staticClass: "list-info-item px-0 w-100 mb-0" }, [
            _c("div", { staticClass: "list-info-item__icon text-dark" }, [
              _c("svg", [_c("use", { attrs: { "xlink:href": "#icon-lock" } })])
            ]),
            _vm._m(1)
          ])
        ],
        2
      )
    ]),
    !_vm.isProductPurchased
      ? _c(
          "div",
          { staticClass: "wrapper-padding_sm assessment-form border-top" },
          [
            _c("h4", { staticClass: "text-center mb-3" }, [
              _vm._v("\n      Расчет стоимости квартиры\n    ")
            ]),
            _c(
              "app-form",
              {
                attrs: { rules: _vm.validationRules, model: _vm.model },
                on: { submit: _vm.onSubmit }
              },
              [
                _c(
                  "app-form-group",
                  {
                    staticClass:
                      "assessment-form__field assessment-form__field_full",
                    attrs: {
                      "model-name": "address",
                      label: "Адрес",
                      required: ""
                    }
                  },
                  [
                    _c("app-input", {
                      model: {
                        value: _vm.model.address,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "address", $$v)
                        },
                        expression: "model.address"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "assessment-form__row row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "app-form-group",
                        {
                          staticClass: "assessment-form__field",
                          attrs: {
                            "model-name": "area",
                            label: "Площадь",
                            tooltip: "",
                            required: ""
                          }
                        },
                        [
                          _c(
                            "app-input",
                            {
                              model: {
                                value: _vm.model.area,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "area", _vm._n($$v))
                                },
                                expression: "model.area"
                              }
                            },
                            [
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("м"),
                                _c("sup", [_vm._v("2")])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "app-form-group",
                        {
                          staticClass: "assessment-form__field",
                          attrs: {
                            "model-name": "rooms",
                            label: "Кол-во комнат",
                            tooltip: "",
                            required: ""
                          }
                        },
                        [
                          _c("app-input", {
                            model: {
                              value: _vm.model.rooms,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "rooms", _vm._n($$v))
                              },
                              expression: "model.rooms"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "app-form-group",
                        {
                          staticClass: "assessment-form__field",
                          attrs: {
                            "model-name": "level",
                            label: "Этаж",
                            tooltip: "",
                            required: ""
                          }
                        },
                        [
                          _c("app-input", {
                            model: {
                              value: _vm.model.level,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "level", _vm._n($$v))
                              },
                              expression: "model.level"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "app-form-group",
                        {
                          staticClass: "assessment-form__field",
                          attrs: {
                            "model-name": "levels",
                            label: "Этажность",
                            tooltip: "",
                            required: ""
                          }
                        },
                        [
                          _c("app-input", {
                            model: {
                              value: _vm.model.levels,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "levels", _vm._n($$v))
                              },
                              expression: "model.levels"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "app-form-group",
                        { attrs: { label: "Год постройки" } },
                        [
                          _c("app-input", {
                            model: {
                              value: _vm.model.built_at,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "built_at", _vm._n($$v))
                              },
                              expression: "model.built_at"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "assessment-form__row row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "app-form-group",
                        {
                          staticClass: "assessment-form__field",
                          attrs: {
                            "model-name": "object_type",
                            label: "Тип дома",
                            tooltip: "",
                            required: ""
                          }
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.object_type,
                                  expression: "model.object_type"
                                }
                              ],
                              staticClass: "custom-select form-control",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.model,
                                    "object_type",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v(
                                  "\n                Не выбрано\n              "
                                )
                              ]),
                              _vm._l(_vm.buildingTypes, function(buildType) {
                                return _c(
                                  "option",
                                  {
                                    key: buildType.id,
                                    domProps: { value: buildType.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(buildType.name) +
                                        "\n              "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "app-form-group",
                        {
                          attrs: {
                            label: "Вид объекта",
                            "model-name": "property_object_type_id",
                            tooltip: "",
                            required: ""
                          }
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.property_object_type_id,
                                  expression: "model.property_object_type_id"
                                }
                              ],
                              staticClass: "custom-select",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.model,
                                    "property_object_type_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v(
                                  "\n                Не выбрано\n              "
                                )
                              ]),
                              _vm._l(_vm.objectTypes, function(objectType) {
                                return _c(
                                  "option",
                                  {
                                    key: objectType.id,
                                    domProps: { value: objectType.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(objectType.name) +
                                        "\n              "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "app-form-group",
                        { attrs: { label: "Состояние квартиры" } },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.state_of_apartment,
                                  expression: "model.state_of_apartment"
                                }
                              ],
                              staticClass: "custom-select",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.model,
                                    "state_of_apartment",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v(
                                  "\n                Не выбрано\n              "
                                )
                              ]),
                              _vm._l(_vm.stateOfApartments, function(state) {
                                return _c(
                                  "option",
                                  {
                                    key: state.id,
                                    domProps: { value: state.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(state.name) +
                                        "\n              "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "small" }, [
                  _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  _vm._v("\n        - Обязательные поля для заполнения\n      ")
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "assessment-form__buttons pt-3 justify-content-center"
                  },
                  [
                    _c(
                      "app-button",
                      {
                        staticClass: "assessment-form__submit",
                        attrs: { type: "submit", loading: _vm.orderRequest }
                      },
                      [
                        _vm._v(
                          "\n          Заказать оценку " +
                            _vm._s(
                              _vm._f("currency")(_vm.product.product_price)
                            ) +
                            "\n        "
                        )
                      ]
                    ),
                    _c("div", { staticClass: "mt-3" }, [
                      _c(
                        "span",
                        {
                          staticClass: "btn-link small",
                          on: { click: _vm.showExamples }
                        },
                        [_vm._v("Пример отчета")]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-right" }, [
      _c("b", [
        _vm._v("\n                Запрос "),
        _c("br"),
        _vm._v("\n                отправлен\n              ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-info-item__text" }, [
      _c("div", { staticClass: "list-info-item__title" }, [
        _vm._v("\n            Рыночная стоимость: ********* руб.\n          ")
      ]),
      _c("div", { staticClass: "list-info-item__body" }, [
        _c("div", { staticClass: "mt-3" }, [
          _c("b", [
            _vm._v(
              "\n                Формируется на основе данных из Авито, Циан, Яндекс.Недвижимости по квартирам со\n                схожими характеристиками за последние 2 года. Алгоритм учитывает более 40\n                параметров:\n              "
            )
          ])
        ]),
        _c("ul", { staticClass: "list-unstyled my-4" }, [
          _c("li", [
            _vm._v(
              "- характеристики дома: год постройки, материал стен, этажность"
            )
          ]),
          _c("li", [
            _vm._v(
              "- характеристики квартиры: количество комнат, общая площадь"
            )
          ]),
          _c("li", [
            _vm._v("- местоположение: широта, долгота, район, адрес и другие")
          ])
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _c("b", [_vm._v("Для клиента:")]),
          _vm._v(
            "\n              отчет об оценке позволяет Клиенту удостовериться, что он покупает объект оценки по\n              оптимальной стоимости и не переплачивает.\n            "
          )
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _c("b", [_vm._v("Для продавца:")]),
          _vm._v(
            "\n              отчет об оценке позволяет убедиться продавцу, что он продает по рыночной стоимости с\n              разумным торгом.\n            "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }