import { Module } from 'vuex';
import includes from 'lodash/includes';
import { Api } from 'shared/api';

export const userModule: Module<UserState, RootState> = {
  namespaced: true,

  state: {
    items: [],
    loading: true,
  },

  actions: {
    async loadOrders({ commit }) {
      try {
        let items = await Api.user.OrdersList();

        items = items.filter((order: UserOrder) => {
          return order.OrderItem.some((orderItem: UserOrderItem) => {
            return !includes(
              [ 'OrderMaintenance', 'package_offers', 'balance' ],
              orderItem.ItemMetadata.address,
            );
          });
        });

        const formatedOrders: UserOrderFormatted[] = items.map(
          (order: UserOrder): UserOrderFormatted => {
            const formatedOrderItem: UserOrderFormatted = {
              ...order,
              Address: '',
              CompleteCount: 0,
              KadastrNumber: '',
            };

            order.OrderItem.forEach(({ status, ItemMetadata }: UserOrderItem) => {
              const { address, kadastr_number: kadastrNumber } = ItemMetadata;
              if (status === 'done') {
                formatedOrderItem.CompleteCount += 1;
              }

              if (address) {
                formatedOrderItem.Address = address;
              }

              if (kadastrNumber) {
                formatedOrderItem.KadastrNumber = kadastrNumber;
              }
            });

            return formatedOrderItem;
          },
        );

        commit('setItems', formatedOrders);
      } catch (error) {
        console.error(error);
      }
    },

    async changePassword({ commit }, passwordsData: UpdatePasswordData) {
      try {
        const user = await Api.user.UpdatePassword(passwordsData);

        commit('auth/setUser', user, { root: true });
      } catch (error) {
        return Api.HttpError(error);
      }
    },

    async updateInfo({ commit }, user: User) {
      try {
        const { data, jwt } = await Api.raw.user.UpdateInfo<ApiRawResponse<User>>(user);

        commit('auth/setToken', jwt.token, { root: true });
        commit('auth/setUser', data, { root: true });
      } catch (error) {
        const message = Api.getErrorMessage(error);
        return Api.HttpError(new Error(message));
      }
    },
  },

  mutations: {
    setItems(state, items: UserOrderFormatted[]) {
      state.items = items;
      state.loading = false;
    },
  },
};
