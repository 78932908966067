var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["loader", _vm.size && "loader_" + _vm.size] }, [
    _vm.text
      ? _c("span", { class: [_vm.textColor && "loader__" + _vm.textColor] }, [
          _vm._v(_vm._s(_vm.text))
        ])
      : _vm._e(),
    _c(
      "div",
      { class: ["loader__indicator", "loader__" + _vm.color] },
      _vm._l(4, function(n) {
        return _c("div", { key: n, staticClass: "loader__dot" })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }