var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper object-detail" },
    [
      !_vm.loading && _vm.products.length
        ? [
            _c(
              "div",
              {
                staticClass: "navigation-back text-white d-sm-block d-none",
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [
                _c("svg", [
                  _c("use", {
                    attrs: { "xlink:href": "#icon-navigation-back" }
                  })
                ])
              ]
            ),
            _vm.info
              ? _c("app-object-detail-info", {
                  attrs: {
                    "object-info": _vm.info,
                    "has-manual-products": _vm.hasManualProducts,
                    "info-loading": _vm.infoLoading
                  },
                  on: { "manual-update": _vm.showKadastrUpdateDialog }
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "object-detail-tabs wrapper-padding_lg py-0" },
              _vm._l(_vm.tabs, function(t) {
                return _c(
                  "div",
                  {
                    key: t.key,
                    staticClass: "object-detail-tabs__tab",
                    class: { "is-active": t.key === _vm.tab },
                    attrs: { "data-hidden": t.hidden && !_vm.showHiddenTabs },
                    on: {
                      click: function($event) {
                        return _vm.changeTab(t.key)
                      }
                    }
                  },
                  [_vm._v("\n        " + _vm._s(t.text) + "\n      ")]
                )
              }),
              0
            ),
            _vm.showManualUpdateAlert
              ? _c(
                  "div",
                  {
                    staticClass:
                      "alert alert-danger mb-0 text-center rounded-0 border-top-0"
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "\n        У этого объекта не указан кадастровый номер, пожалуйста, добавьте кадастровый номер\n        вручную\n      "
                      )
                    ]),
                    _c(
                      "app-button",
                      {
                        attrs: { variant: "danger", size: "sm" },
                        on: { click: _vm.showKadastrUpdateDialog }
                      },
                      [_vm._v("\n        Добавить кадастровый номер\n      ")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(_vm.currentTabComponent, {
              tag: "component",
              attrs: { info: _vm.info, checkout: _vm.checkout },
              on: { "change-tab": _vm.changeTab }
            }),
            _c("app-object-detail-footer")
          ]
        : [
            _c(
              "div",
              { staticClass: "p-4 font-weight-500 text-muted" },
              [
                _c("app-loader", {
                  attrs: {
                    text: "Пожалуйста подождите, идет запрос данных об объекте"
                  }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }