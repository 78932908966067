






















import Vue from 'vue';

export default Vue.extend({
  name: 'AppObjectProp',

  props: {
    title: String,
    value: String,
    icon: String,
  },
});
