





































































import Vue from 'vue';

import LocStorage from '@/core/utils/localStorage';
import { Validators } from '@/core/utils/validator';
import { AuthTypes } from 'shared/store/modules/auth/types';
import { Role } from 'shared/utils/constants';
import _ from 'lodash';

export default Vue.extend({
  name: 'AppAuthLogin',

  props: {
    changeForm: Function,
  },

  data() {
    return {
      remember: true,
      // loading: false,
      model: {
        username: '',
        password: '',
      },
      accessAllowRoles: [ Role.Admin, Role.Partner, Role.Manager ],
    };
  },

  created() {
    const credentials = LocStorage.getItem('cred');

    if (credentials) {
      this.model = credentials;
    }
  },

  methods: {
    async onSubmit() {
      try {
        const { data } = await this.$store.dispatch(AuthTypes.actions.LOGIN, this.model);
        if (this.remember) {
          LocStorage.setItem('cred', this.model);
        } else {
          LocStorage.removeItem('cred');
        }
        if (this.accessAllow(data)) {
          // если менеджер, то роутим на страницу поиска
          if (data.person_role === 12) {
            this.$router.push({ name: 'admin-search' });
          } else {
            this.$router.push({ name: 'admin-orders' });
          }
        } else {
          this.$router.push({ name: 'home' });
        }
      } catch (error) {
        this.$noty.error({ text: 'Неверный логин или пароль' });
        console.error(error);
      }
    },

    validationRules(): ValidationConfig {
      return {
        username: [ Validators.required, Validators.email ],
        password: [ Validators.required ],
      };
    },

    accessAllow(user: User): boolean {
      return _.includes(this.accessAllowRoles, user.person_role);
    },
  },
});
