var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "c6 c39 doc-content" }, [
        _vm._m(0),
        _vm._m(1),
        _c("p", { staticClass: "text-center" }, [
          _vm._v("\n      Постоянный адрес размещения:\n      "),
          _c("a", { attrs: { href: _vm.webSite + "/privacy" } }, [
            _vm._v("\n        " + _vm._s(_vm.webSite) + "/privacy\n      ")
          ])
        ]),
        _vm._m(2),
        _c("p", { staticClass: "c4" }, [
          _c("span", { staticClass: "c9" }, [_vm._v("1.1. ")]),
          _c("span", { staticClass: "c2" }, [
            _vm._v(
              "Термины, определения и сокращения, используемые в настоящей Политике в отношении обработки персональных данных " +
                _vm._s(_vm.company) +
                " (далее – Общество) (далее – Политика):"
            )
          ])
        ]),
        _vm._m(3),
        _c("p", { staticClass: "c4" }, [
          _c("span", { staticClass: "c9" }, [
            _vm._v("Оператор персональных данных ")
          ]),
          _c("span", { staticClass: "c2" }, [
            _vm._v(
              "(далее – Оператор) – " +
                _vm._s(_vm.company) +
                " самостоятельно или совместно с другими лицам организующее и (или) осуществляющее обработку Персональных данных, а также определяющее цели обработки Персональных данных, состав Персональных данных, подлежащих обработке, действия (операции), совершаемые с Персональными делами."
            )
          ])
        ]),
        _vm._m(4),
        _vm._m(5),
        _vm._m(6),
        _vm._m(7),
        _vm._m(8),
        _vm._m(9),
        _vm._m(10),
        _vm._m(11),
        _vm._m(12),
        _vm._m(13),
        _vm._m(14),
        _vm._m(15),
        _c("p", { staticClass: "c4" }, [
          _c("span", { staticClass: "c9" }, [_vm._v("Сайт ")]),
          _c("span", { staticClass: "c10" }, [
            _vm._v("– интернет-ресурс, размещённый по адресу: ")
          ]),
          _c("span", { staticClass: "c10" }, [_vm._v(_vm._s(_vm.webSite))]),
          _c("span", { staticClass: "c2" }, [
            _vm._v(
              ", включая домены всех уровней, доступ к которому обеспечивается с различных пользовательских устройств, подключенных к сети интернет, позволяющий Пользователю просматривать и искать информацию, а также совершать иные действия, предусмотренные функционалом Сайта."
            )
          ])
        ]),
        _vm._m(16),
        _vm._m(17),
        _vm._m(18),
        _vm._m(19),
        _vm._m(20),
        _vm._m(21),
        _vm._m(22),
        _vm._m(23),
        _vm._m(24),
        _vm._m(25),
        _vm._m(26),
        _vm._m(27),
        _vm._m(28),
        _vm._m(29),
        _vm._m(30),
        _vm._m(31),
        _vm._m(32),
        _c("a", {
          attrs: { id: "t.d1a2bb4fb22471edd2f20a3865929c5240dce1e2" }
        }),
        _c("a", { attrs: { id: "t.0" } }),
        _vm._m(33),
        _vm._m(34),
        _c("a", {
          attrs: { id: "t.eb2557c7e2dd29c4afbcc4f3acd458ffaa1e3919" }
        }),
        _c("a", { attrs: { id: "t.1" } }),
        _vm._m(35),
        _vm._m(36),
        _c("a", {
          attrs: { id: "t.73a40974fa5f1f96637c8e18e07fbb448900947d" }
        }),
        _c("a", { attrs: { id: "t.2" } }),
        _vm._m(37),
        _vm._m(38),
        _c("a", {
          attrs: { id: "t.65a837976e3a4fbb5389263a5d4b181106a707d6" }
        }),
        _c("a", { attrs: { id: "t.3" } }),
        _vm._m(39),
        _vm._m(40),
        _c("a", {
          attrs: { id: "t.9db484927992521849daba6d7d33e6fafaec3bd9" }
        }),
        _c("a", { attrs: { id: "t.4" } }),
        _vm._m(41),
        _vm._m(42),
        _c("a", {
          attrs: { id: "t.10ca450d4224d1913e22f3f21022e71896aa3339" }
        }),
        _c("a", { attrs: { id: "t.5" } }),
        _vm._m(43),
        _vm._m(44),
        _c("a", {
          attrs: { id: "t.18a7f92fe57d6f6f7b6ffd63055f2299476496d6" }
        }),
        _c("a", { attrs: { id: "t.6" } }),
        _vm._m(45),
        _vm._m(46),
        _c("a", {
          attrs: { id: "t.23ae2cabaec529411654f902d966c4e2af73680e" }
        }),
        _c("a", { attrs: { id: "t.7" } }),
        _vm._m(47),
        _vm._m(48),
        _c("a", {
          attrs: { id: "t.2a1424a0e3efc51589987ea69972c2968141ab71" }
        }),
        _c("a", { attrs: { id: "t.8" } }),
        _vm._m(49),
        _vm._m(50),
        _c("a", {
          attrs: { id: "t.5233ebca0bd70558b64024bc9b3943a17d789b6e" }
        }),
        _c("a", { attrs: { id: "t.9" } }),
        _vm._m(51),
        _vm._m(52),
        _c("a", {
          attrs: { id: "t.8fdacf99716fc7c423a25d4efdb8c04fa822f415" }
        }),
        _c("a", { attrs: { id: "t.10" } }),
        _vm._m(53),
        _vm._m(54),
        _c("a", {
          attrs: { id: "t.35ac26cbd06095c3f8bd09b70e5aaeebb898270b" }
        }),
        _c("a", { attrs: { id: "t.11" } }),
        _vm._m(55),
        _vm._m(56),
        _c("a", {
          attrs: { id: "t.94f48485361b693144c2cec3bb2902e612e00a98" }
        }),
        _c("a", { attrs: { id: "t.12" } }),
        _vm._m(57),
        _vm._m(58),
        _vm._m(59),
        _vm._m(60),
        _vm._m(61),
        _vm._m(62),
        _vm._m(63),
        _vm._m(64),
        _vm._m(65),
        _vm._m(66),
        _vm._m(67),
        _vm._m(68),
        _vm._m(69),
        _vm._m(70),
        _vm._m(71),
        _vm._m(72),
        _vm._m(73),
        _vm._m(74),
        _vm._m(75),
        _vm._m(76),
        _vm._m(77),
        _vm._m(78),
        _vm._m(79),
        _vm._m(80),
        _vm._m(81),
        _vm._m(82),
        _vm._m(83),
        _vm._m(84),
        _vm._m(85),
        _vm._m(86),
        _vm._m(87),
        _vm._m(88),
        _vm._m(89),
        _vm._m(90),
        _vm._m(91),
        _vm._m(92),
        _vm._m(93),
        _vm._m(94),
        _vm._m(95),
        _vm._m(96),
        _vm._m(97),
        _vm._m(98),
        _vm._m(99),
        _vm._m(100),
        _vm._m(101),
        _vm._m(102),
        _vm._m(103),
        _vm._m(104),
        _vm._m(105),
        _vm._m(106),
        _vm._m(107),
        _vm._m(108),
        _vm._m(109),
        _vm._m(110),
        _vm._m(111),
        _vm._m(112),
        _vm._m(113),
        _vm._m(114),
        _vm._m(115),
        _vm._m(116),
        _vm._m(117),
        _vm._m(118),
        _vm._m(119),
        _vm._m(120),
        _vm._m(121),
        _vm._m(122),
        _vm._m(123),
        _vm._m(124),
        _vm._m(125),
        _vm._m(126),
        _vm._m(127),
        _vm._m(128),
        _vm._m(129),
        _vm._m(130),
        _vm._m(131),
        _vm._m(132),
        _vm._m(133),
        _vm._m(134),
        _c("p", { staticClass: "c4" }, [
          _c("span", { staticClass: "c10" }, [
            _vm._v(
              "9.2. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты "
            )
          ]),
          _c("span", { staticClass: "c2" }, [_vm._v(_vm._s(_vm.email))])
        ]),
        _vm._m(135),
        _vm._m(136)
      ]),
      _c("h5", { staticClass: "pt-0 mt-0" }, [_vm._v("\n    Реквизиты\n  ")]),
      _vm._v("\n  " + _vm._s(_vm.company) + "\n  "),
      _vm.isFizCheck
        ? [
            _c("br"),
            _vm._v("ИНН 1674004902 | КПП 167401001 | ОГРН 1231600023643\n    "),
            _c("br"),
            _vm._v(
              "Адрес: 423825, Республика Татарстан, с. Большая Шильна, ул. Шоссейная, д. 62Б\n  "
            )
          ]
        : [
            _c("br"),
            _vm._v("ИНН 1650367985 | КПП 165001001 | ОГРН 1181690067932\n    "),
            _c("br"),
            _vm._v(
              "Адрес: 423800, г.Набережные Челны, ул.Машиностроительная, 91, пом.59\n  "
            )
          ],
      _c("br"),
      _vm._v("E-mail:\n  "),
      _c("a", { attrs: { href: "mailto:" + _vm.email } }, [
        _vm._v(" " + _vm._s(_vm.email))
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "c20" }, [_c("span", { staticClass: "c18" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c26" }, [
      _c("span", { staticClass: "c22" }, [
        _vm._v("Политика"),
        _c("br"),
        _vm._v("в отношении обработки персональных данных")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c17" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("1. Термины, определения и сокращения")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [_vm._v("Персональные данные")]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          " – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("Обработка персональных данных ")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "– любое действие (операция) или совокупность действий (операций) с Персональными данными, совершаемых с использованием средств автоматизации или без их использования, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("Субъект персональных данных ")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "(далее – Субъект Персональных данных) – физическое лицо, которое прямо или косвенно определено или определяемо с помощью персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [_vm._v("Пользователь ")]),
      _c("span", { staticClass: "c2" }, [
        _vm._v("– физическое лицо, являющееся посетителем Сайта.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [_vm._v("Клиент ")]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "– физическое или юридическое лицо, авторизованное в Личном кабинете Клиента на Сайте и осуществляющее использование Сайта в порядке и на условиях Пользовательского соглашения и иных документов."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("Автоматизированная обработка персональных данных ")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "– обработка Персональных данных с помощью средств вычислительной техники."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("Распространение персональных данных ")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "– действия, направленные на раскрытие Персональных данных неопределенному кругу лиц."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("Предоставление персональных данных ")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "– действия, направленные на раскрытие Персональных данных определенному лицу или определенному кругу лиц."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("Блокирование персональных данных ")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "– временное прекращение обработки Персональных данных (за исключением случаев, если обработка необходима для уточнения Персональных данных)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("Уничтожение персональных данных ")
      ]),
      _c("span", { staticClass: "c10" }, [
        _vm._v(
          "– действия, в результате которых становится невозможным восстановить содержание Персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("Обезличивание персональных данных ")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "– действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("Информационная система персональных данных ")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "– совокупность содержащихся в базах данных Персональных данных и обеспечивающих их обработку информационных технологий и технических средств."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("Трансграничная передача персональных данных ")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "– передача Персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [_vm._v("Cookie-файлы")]),
      _c("span", { staticClass: "c2 c6" }, [
        _vm._v(
          " – небольшие по объему файлы данных, генерируемые Сайтом и сохраняемые на пользовательское устройство. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [_vm._v("IP-адрес")]),
      _c("span", { staticClass: "c10 c6" }, [
        _vm._v(
          " – уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10 c6" }, [
        _vm._v(
          "Иные термины и определения в соответствии с их значениями, как они определены в федеральном законе от 27.07.2006 г. № 152-ФЗ «О персональных данных». "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [_vm._v("2. Общие положения")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [_vm._v("2.1. Назначение политики")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "2.1.1. Настоящая Политика определяет цели и общие принципы обработки Персональных данных Субъектов Персональных данных, а также реализуемые меры защиты Персональных данных во время использования ими любого из сервисов Сайта Оператора."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "2.1.2. Политика является общедоступным документом Оператора и предусматривает возможность ознакомления с ней третьих лиц."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("3. Категории субъектов персональных данных")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v(
          "3.1. Оператор осуществляет на законной основе обработку Персональных данных следующих категорий субъектов персональных данных:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "c5 lst-kix_xlt8jrdz302t-0 start" }, [
      _c("li", { staticClass: "c4 c11 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("Пользователи Сайта;")])
      ]),
      _c("li", { staticClass: "c4 c11 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Клиенты и лица, действующие по поручению, от имени, или представляющие собой интересы Клиента (представители Клиентов);"
          )
        ])
      ]),
      _c("li", { staticClass: "c4 c11 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Представители/работники Клиентов и контрагентов Оператора (юридических лиц)."
          )
        ])
      ]),
      _c("li", { staticClass: "c4 c11 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("Соискателей вакансий;")])
      ]),
      _c("li", { staticClass: "c4 c11 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("Работников Оператора;")])
      ]),
      _c("li", { staticClass: "c4 c11 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v("Уволенных работников Оператора;")
        ])
      ]),
      _c("li", { staticClass: "c4 c11 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Иные физические лица, выразившие согласие на обработку Оператором их Персональных данных или физические лица, обработка Персональных данных, которых необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации, полномочий и обязанностей на Оператора."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("4. Цели обработки персональных данных")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v(
          "4.1. Обработка Персональных данных субъектов персональных данных, может осуществляться в следующих целях:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "c5 lst-kix_9s02m3ggz1yl-0 start" }, [
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "заключение с Субъектом Персональных данных любых договоров и их дальнейшего исполнения;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v(
            "предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v("создание учётной записи (профиля) на Сайте, ")
        ]),
        _c("span", { staticClass: "c10 c6" }, [
          _vm._v(
            " идентификация пользователя, зарегистрированного на Сайте, персонификация учетной записи пользователя. "
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "подбор недвижимости, размещение объявлений о продаже/сдаче в аренду/наем недвижимости;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v("подача заявки на одобрение ипотеки;")
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "осуществление информационного обмена между участниками сделки купли-продажи объекта недвижимости;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v("сопровождение сделок с недвижимостью;")
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "предоставление информации о наличии обременения и/или ограничений, снятии обременения и/или ограничений на недвижимость;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "предоставление услуги правовой экспертизы объекта недвижимости и участников сделки;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "предоставление услуги по проведению оценки объекта недвижимости;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "предоставление посреднических услуг по страхованию имущества/жизни и здоровья;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "направление информационных/рекламно-информационных сообщений;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v(
            "аналитика действий Пользователя на Сайте и функционирование Сайта, в том числе для улучшения работы Сайта и его функционала, внешнего вида, а также для "
          )
        ]),
        _c("span", { staticClass: "c10 c6" }, [
          _vm._v("таргетирования рекламных материалов")
        ]),
        _c("span", { staticClass: "c2" }, [_vm._v(";")])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v("проведение аналитических и статистических исследований;")
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "реализация Оператором своих прав и обязанностей в рамках трудовых"
          ),
          _c("br"),
          _vm._v("правоотношений;")
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "реализация Субъектом персональных данных своих прав и обязанностей в рамках трудовых правоотношений; "
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "деятельность по осуществлению подбора и проверки сотрудника/кандидата на вакантную должность;"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "c5 lst-kix_oyd8gg7bds9v-0 start" }, [
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v("деятельность по проверке данных заемщика;")
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "деятельность по проверке контрагента, поставщика, подрядчика;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "деятельность по проверке данных владельца транспортного средства;"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "c5 lst-kix_9s02m3ggz1yl-0" }, [
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "предоставление услуги по проверке данных по транспортному средству в интересах Субъекта персональных данных,  Покупателя или его представителя;"
          )
        ])
      ]),
      _c("li", { staticClass: "c13 li-bullet-0" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "исполнение требований предусмотренных, действующем законодательством Российской Федерации."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9 c6" }, [_vm._v("4.2 ")]),
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          " Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4 c23" }, [
      _c("span", { staticClass: "c3 c6" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c32" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "3" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c2" }, [
                  _vm._v(
                    "-заключение с Субъектом Персональных данных любых договоров и их дальнейшего исполнения;"
                  )
                ])
              ]),
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c2" }, [
                  _vm._v(
                    "-предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайт;"
                  )
                ])
              ]),
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c10" }, [
                  _vm._v("-создание учётной записи (профиля) на Сайте, ")
                ]),
                _c("span", { staticClass: "c10 c6" }, [
                  _vm._v(
                    " идентификация пользователя, зарегистрированного на Сайте, персонификация учетной записи пользователя. "
                  )
                ])
              ]),
              _c("p", { staticClass: "c4 c23" }, [
                _c("span", { staticClass: "c2" })
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "3" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_69cz86n91a4e-0 start" }, [
                _c("li", { staticClass: "c12 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество;")
                  ])
                ]),
                _c("li", { staticClass: "c12 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c12 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c12 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c12 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата и место рождения; ")
                  ])
                ]),
                _c("li", { staticClass: "c12 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("гражданство; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "данные документа, удостоверяющего личность (наименование, серия, номер, кем и когда выдан);"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("идентификационный номер налогоплательщика; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "адрес регистрации и фактического проживания, дата регистрации по месту жительства; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [
                    _vm._v(
                      "номер страхового свидетельства государственного пенсионного страхования."
                    )
                  ])
                ])
              ])
            ]
          )
        ]),
        _c("tr", { staticClass: "c14" }),
        _c("tr", { staticClass: "c14" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "4.3.Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("p", { staticClass: "c28" }, [
                _c("span", { staticClass: "c2" }, [
                  _vm._v(
                    "-осуществление информационного обмена между участниками сделки купли-продажи объекта недвижимости;"
                  )
                ])
              ]),
              _c("p", { staticClass: "c28" }, [
                _c("span", { staticClass: "c2" }, [
                  _vm._v("- сопровождение сделок с недвижимостью;")
                ])
              ]),
              _c("p", { staticClass: "c28" }, [
                _c("span", { staticClass: "c2" }, [
                  _vm._v(
                    "-предоставление информации о наличии обременения и/или ограничений, снятии обременения и/или ограничений на недвижимость;"
                  )
                ])
              ]),
              _c("p", { staticClass: "c28" }, [
                _c("span", { staticClass: "c2" }, [
                  _vm._v(
                    "-предоставление услуги правовой экспертизы объекта недвижимости и участников сделки;"
                  )
                ])
              ]),
              _c("p", { staticClass: "c7" }, [
                _c("span", { staticClass: "c2" })
              ]),
              _c("p", { staticClass: "c4 c23" }, [
                _c("span", { staticClass: "c3" })
              ]),
              _c("p", { staticClass: "c16" }, [
                _c("span", { staticClass: "c3" })
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_5vg6zydl3kv5-0 start" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество; ")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_69cz86n91a4e-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата и место рождения; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("гражданство; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "данные документа, удостоверяющего личность (наименование, серия, номер, кем и когда выдан); "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("идентификационный номер налогоплательщика; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "адрес регистрации и фактического проживания, дата регистрации по месту жительства; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "номер страхового свидетельства государственного пенсионного страхования; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о государственной регистрации права на недвижимость;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения об объекте недвижимости (даты регистрации, основания перехода права собственности, ограничения, обременения и иные данные по объектам недвижимости);"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("семейное положение;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата регистрации, расторжения брака;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "отношение к воинской обязанности, сведения о воинском учете;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о действительности паспорта;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о банкротстве физического лица;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о наличии задолженности, установленной ФССП;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о нахождении в списках террористов, экстремистов;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о залогах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация о налоговых начислениях;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наличие судебных дел в арбитражных судах, судах общей юрисдикции, мировых судей;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске МВД;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения из Единого федерального реестра сведений о банкротстве;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о нахождении в реестре граждан и организаций, объявленных иностранными агентами;  "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о водительском удостоверении;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о транспортных средствах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения об имеющихся штрафах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация из ЕГРИП;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация из ЕГРЮЛ;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("кредитный рейтинг;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("контактные и иные данные из открытых источников;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения трудовой книжки;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("должность; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наименование организации, которую представляет пользователь; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("платежная информация;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [_vm._v("иные данные, ")]),
                  _c("span", { staticClass: "c10 c6" }, [
                    _vm._v(
                      "предусмотренные типовыми формами, установленным порядком и целями обработки."
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9 c6" }, [_vm._v("4.4.")]),
      _c("span", { staticClass: "c9" }, [
        _vm._v(
          " Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c33" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9" }, [_vm._v("-")]),
                _c("span", { staticClass: "c2" }, [
                  _vm._v("подбор недвижимости,")
                ])
              ]),
              _c("p", { staticClass: "c4 c23" }, [
                _c("span", { staticClass: "c3" })
              ]),
              _c("p", { staticClass: "c16" }, [
                _c("span", { staticClass: "c3 c6" })
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_5vg6zydl3kv5-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество; ")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_69cz86n91a4e-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [
                    _vm._v("место нахождения (регион, город)")
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9 c6" }, [_vm._v("4.5.")]),
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          " Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c10" }, [
                  _vm._v(
                    "-размещение объявлений о продаже/сдаче в аренду/наем недвижимости"
                  )
                ])
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_5vg6zydl3kv5-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество; ")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_69cz86n91a4e-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата и место рождения; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("гражданство; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "данные документа, удостоверяющего личность (наименование, серия, номер, кем и когда выдан); "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о государственной регистрации права на недвижимость;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения об объекте недвижимости (даты регистрации, основания перехода права собственности, ограничения, обременения и иные данные по объектам недвижимости);"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о действительности паспорта;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о банкротстве физического лица;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о наличии задолженности, установленной ФССП;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация о налоговых начислениях;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наличие судебных дел в арбитражных судах, судах общей юрисдикции, мировых судей;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске МВД;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения из Единого федерального реестра сведений о банкротстве;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [
                    _vm._v(
                      "сведения о нахождении в реестре граждан и организаций, объявленных иностранными агентами; "
                    )
                  ])
                ])
              ]),
              _c("p", { staticClass: "c4 c23" }, [
                _c("span", { staticClass: "c3 c6" })
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9 c6" }, [_vm._v("4.6.")]),
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("p", { staticClass: "c28" }, [
                _c("span", { staticClass: "c2" }, [
                  _vm._v("-подача заявки на одобрение ипотеки;")
                ])
              ]),
              _c("p", { staticClass: "c4 c23" }, [
                _c("span", { staticClass: "c3" })
              ]),
              _c("p", { staticClass: "c16" }, [
                _c("span", { staticClass: "c3" })
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_5vg6zydl3kv5-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество; ")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_69cz86n91a4e-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата и место рождения; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("гражданство; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "данные документа, удостоверяющего личность (наименование, серия, номер, кем и когда выдан); "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("идентификационный номер налогоплательщика; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "адрес регистрации и фактического проживания, дата регистрации по месту жительства; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "номер страхового свидетельства государственного пенсионного страхования; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о действительности паспорта;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о банкротстве физического лица;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о наличии задолженности, установленной ФССП;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о нахождении в списках террористов, экстремистов;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация о налоговых начислениях;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("семейное положение;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата регистрации, расторжения брака;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "отношение к воинской обязанности, сведения о воинском учете;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("образование;")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наличие судебных дел в арбитражных судах, судах общей юрисдикции, мировых судей;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске МВД;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения из Единого федерального реестра сведений о банкротстве;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о нахождении в реестре граждан и организаций, объявленных иностранными агентами;  "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о водительском удостоверении;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация из ЕГРИП;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация из ЕГРЮЛ;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("кредитный рейтинг;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("контактные и иные данные из открытых источников;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения трудовой книжки;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("должность; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("платежная информация;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [_vm._v("иные данные, ")]),
                  _c("span", { staticClass: "c10 c6" }, [
                    _vm._v(
                      "предусмотренные типовыми формами, установленным порядком и целями обработки."
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9 c6" }, [_vm._v("4.7.")]),
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_9s02m3ggz1yl-0" }, [
                _c("li", { staticClass: "c13 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "предоставление услуги по проведению автоматической оценки объекта недвижимости;"
                    )
                  ])
                ])
              ]),
              _c("p", { staticClass: "c7" }, [
                _c("span", { staticClass: "c2" })
              ]),
              _c("p", { staticClass: "c16" }, [
                _c("span", { staticClass: "c3" })
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_69cz86n91a4e-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "адрес регистрации и фактического проживания, дата регистрации по месту жительства; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о государственной регистрации права на недвижимость;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения об объекте недвижимости (даты регистрации, основания перехода права собственности, ограничения, обременения и иные данные по объектам недвижимости);"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("контактные и иные данные из открытых источников;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наименование организации, которую представляет пользователь; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("платежная информация;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [_vm._v("иные данные, ")]),
                  _c("span", { staticClass: "c10 c6" }, [
                    _vm._v(
                      "предусмотренные типовыми формами, установленным порядком и целями обработки."
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "4.8.Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_9s02m3ggz1yl-0" }, [
                _c("li", { staticClass: "c13 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [
                    _vm._v(
                      "предоставление посреднических услуг по страхованию имущества/жизни и здоровья;"
                    )
                  ])
                ])
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_5vg6zydl3kv5-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество; ")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_69cz86n91a4e-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата и место рождения; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("гражданство; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "данные документа, удостоверяющего личность (наименование, серия, номер, кем и когда выдан); "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("идентификационный номер налогоплательщика; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "адрес регистрации и фактического проживания, дата регистрации по месту жительства; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "номер страхового свидетельства государственного пенсионного страхования; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о государственной регистрации права на недвижимость;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения об объекте недвижимости (даты регистрации, основания перехода права собственности, ограничения, обременения и иные данные по объектам недвижимости);"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("семейное положение;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата регистрации, расторжения брака;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о действительности паспорта;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о банкротстве физического лица;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о залогах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация о налоговых начислениях;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наличие судебных дел в арбитражных судах, судах общей юрисдикции, мировых судей;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске МВД;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения из Единого федерального реестра сведений о банкротстве;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о водительском удостоверении;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о транспортных средствах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация из ЕГРИП;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация из ЕГРЮЛ;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("кредитный рейтинг;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("контактные и иные данные из открытых источников;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наименование организации, которую представляет пользователь; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("платежная информация;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [_vm._v("иные данные, ")]),
                  _c("span", { staticClass: "c10 c6" }, [
                    _vm._v(
                      "предусмотренные типовыми формами, установленным порядком и целями обработки."
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9 c6" }, [_vm._v("4.9.")]),
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9" }, [_vm._v("Цель: ")])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_9s02m3ggz1yl-0" }, [
                _c("li", { staticClass: "c13 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "направление информационных/рекламно-информационных сообщений;"
                    )
                  ])
                ])
              ]),
              _c("p", { staticClass: "c7 c30" }, [
                _c("span", { staticClass: "c3" })
              ]),
              _c("p", { staticClass: "c16" }, [
                _c("span", { staticClass: "c3" })
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_5vg6zydl3kv5-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество; ")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_69cz86n91a4e-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("гражданство; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о государственной регистрации права на недвижимость;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения об объекте недвижимости (даты регистрации, основания перехода права собственности, ограничения, обременения и иные данные по объектам недвижимости);"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о наличии задолженности, установленной ФССП;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о нахождении в списках террористов, экстремистов;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о залогах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о действительности паспорта;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о банкротстве физического лица;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о залогах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация о налоговых начислениях;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наличие судебных дел в арбитражных судах, судах общей юрисдикции, мировых судей;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске МВД;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения из Единого федерального реестра сведений о банкротстве;"
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9 c6" }, [_vm._v("4.10.")]),
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_9s02m3ggz1yl-0" }, [
                _c("li", { staticClass: "c13 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [
                    _vm._v(
                      "аналитика действий Пользователя на Сайте и функционирование Сайта, в том числе для улучшения работы Сайта и его функционала, внешнего вида, а также для "
                    )
                  ]),
                  _c("span", { staticClass: "c10 c6" }, [
                    _vm._v("таргетирования рекламных материалов")
                  ]),
                  _c("span", { staticClass: "c2" }, [_vm._v(";")])
                ]),
                _c("li", { staticClass: "c13 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "проведение аналитических и статистических исследований;"
                    )
                  ])
                ])
              ]),
              _c("p", { staticClass: "c7" }, [
                _c("span", { staticClass: "c2" })
              ]),
              _c("p", { staticClass: "c7 c30" }, [
                _c("span", { staticClass: "c2" })
              ]),
              _c("p", { staticClass: "c7 c30" }, [
                _c("span", { staticClass: "c2" })
              ]),
              _c("p", { staticClass: "c16" }, [
                _c("span", { staticClass: "c3" })
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_obf23tlq0hc9-0 start" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [_vm._v("IP-адрес;")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [
                    _vm._v("информации из cookie-файлов;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [
                    _vm._v("данные об устройстве;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [
                    _vm._v("информации о браузере и операционной системе; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [
                    _vm._v("времени доступа; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [
                    _vm._v(
                      "адресе запрашиваемой страницы и реферере (адресе предыдущей страницы);"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [
                    _vm._v("данные сессии, ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [
                    _vm._v("настройки профиля")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [_vm._v("пол;")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [
                    _vm._v("год рождения;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2 c6" }, [_vm._v("профессия; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [
                    _vm._v(
                      "наименование организации, которую представляет пользователь; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10 c6" }, [_vm._v("должность.")])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9 c6" }, [_vm._v("4.11.")]),
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_9s02m3ggz1yl-0" }, [
                _c("li", { staticClass: "c25 li-bullet-1" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "реализация Оператором своих прав и обязанностей в рамках трудовых правоотношений;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c25 li-bullet-1" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "реализация Субъектом персональных данных своих прав и обязанностей в рамках трудовых правоотношений; "
                    )
                  ])
                ])
              ]),
              _c("p", { staticClass: "c7 c30" }, [
                _c("span", { staticClass: "c2" })
              ]),
              _c("p", { staticClass: "c16" }, [
                _c("span", { staticClass: "c3" })
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_5vg6zydl3kv5-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество; ")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_69cz86n91a4e-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("гражданство; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения трудовой книжки;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("должность; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наименование организации, которую представляет пользователь; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("платежная информация;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("семейное положение;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата регистрации, расторжения брака")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "отношение к воинской обязанности, сведения о воинском учете;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("образование")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [_vm._v("иные данные, ")]),
                  _c("span", { staticClass: "c10 c6" }, [
                    _vm._v(
                      "предусмотренные типовыми формами, установленным порядком и целями обработки (в т.ч. резюме)."
                    )
                  ])
                ])
              ]),
              _c("p", { staticClass: "c16" }, [
                _c("span", { staticClass: "c3" })
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "4.12.Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("p", { staticClass: "c4 c23" }, [
                _c("span", { staticClass: "c3" })
              ]),
              _c("ul", { staticClass: "c5 lst-kix_9s02m3ggz1yl-0" }, [
                _c("li", { staticClass: "c25 li-bullet-1" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "деятельность по осуществлению подбора и проверки сотрудника/кандидата на вакантную должность;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c25 li-bullet-1" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("деятельность по проверке данных заемщика;")
                  ])
                ]),
                _c("li", { staticClass: "c25 li-bullet-1" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "деятельность по проверке данных владельца транспортного средства;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c25 li-bullet-2" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "деятельность по проверке контрагента, поставщика, подрядчика;"
                    )
                  ])
                ])
              ]),
              _c("p", { staticClass: "c16" }, [
                _c("span", { staticClass: "c3" })
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_5vg6zydl3kv5-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество; ")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_69cz86n91a4e-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата и место рождения; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("гражданство; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "данные документа, удостоверяющего личность (наименование, серия, номер, кем и когда выдан); "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("идентификационный номер налогоплательщика; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "адрес регистрации и фактического проживания, дата регистрации по месту жительства; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "номер страхового свидетельства государственного пенсионного страхования; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о государственной регистрации права на недвижимость;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения об объекте недвижимости (даты регистрации, основания перехода права собственности, ограничения, обременения и иные данные по объектам недвижимости);"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("семейное положение;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата регистрации, расторжения брака;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "отношение к воинской обязанности, сведения о воинском учете;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("образование;")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о действительности паспорта;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о банкротстве физического лица;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о наличии задолженности, установленной ФССП;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о нахождении в списках террористов, экстремистов;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о залогах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация о налоговых начислениях;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наличие судебных дел в арбитражных судах, судах общей юрисдикции, мировых судей;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске МВД;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения из Единого федерального реестра сведений о банкротстве;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о нахождении в реестре граждан и организаций, объявленных иностранными агентами;  "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о водительском удостоверении;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о транспортных средствах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения об имеющихся штрафах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация из ЕГРИП;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация из ЕГРЮЛ;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("кредитный рейтинг;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("контактные и иные данные из открытых источников;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения трудовой книжки;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("должность; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наименование организации, которую представляет пользователь; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("платежная информация;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [_vm._v("иные данные, ")]),
                  _c("span", { staticClass: "c10 c6" }, [
                    _vm._v(
                      "предусмотренные типовыми формами, установленным порядком и целями обработки."
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "4.13.Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_50nbaqqaqlhg-0 start" }, [
                _c("li", { staticClass: "c28 c38 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [
                    _vm._v(
                      "предоставление услуги по проверке данных по транспортному средству в интересах Субъекта персональных данных,  Покупателя или его представителя"
                    )
                  ])
                ])
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_yafx561poxj7-0 start" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество; ")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_x1xvubnl8zkq-0 start" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата и место рождения; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("гражданство; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "данные документа, удостоверяющего личность (наименование, серия, номер, кем и когда выдан); "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("идентификационный номер налогоплательщика; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "адрес регистрации и фактического проживания, дата регистрации по месту жительства; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "номер страхового свидетельства государственного пенсионного страхования; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о государственной регистрации права на недвижимость;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения об объекте недвижимости (даты регистрации, основания перехода права собственности, ограничения, обременения и иные данные по объектам недвижимости);"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о действительности паспорта;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о банкротстве физического лица;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о наличии задолженности, установленной ФССП;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о нахождении в списках террористов, экстремистов;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о залогах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация о налоговых начислениях;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наличие судебных дел в арбитражных судах, судах общей юрисдикции, мировых судей;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о нахождении в розыске МВД;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения из Единого федерального реестра сведений о банкротстве;"
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "сведения о нахождении в реестре граждан и организаций, объявленных иностранными агентами;  "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о водительском удостоверении;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения о транспортных средствах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения об имеющихся штрафах;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация из ЕГРИП;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("информация из ЕГРЮЛ;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("кредитный рейтинг;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("контактные и иные данные из открытых источников;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("сведения трудовой книжки;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("должность; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "наименование организации, которую представляет пользователь; "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("платежная информация;")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [_vm._v("иные данные, ")]),
                  _c("span", { staticClass: "c10 c6" }, [
                    _vm._v(
                      "предусмотренные типовыми формами, установленным порядком и целями обработки."
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "4.14.Объем и категории персональных данных субъектов персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "c0" }, [
      _c("tbody", [
        _c("tr", { staticClass: "c15" }, [
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c3" }, [_vm._v("Цель: ")])
              ]),
              _c("p", { staticClass: "c7" }, [
                _c("span", { staticClass: "c2" })
              ]),
              _c("ul", { staticClass: "c5 lst-kix_4mncdev9bmb-0 start" }, [
                _c("li", { staticClass: "c13 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v(
                      "исполнение требований,  предусмотренных действующем законодательством Российской Федерации"
                    )
                  ])
                ])
              ]),
              _c("p", { staticClass: "c7 c30" }, [
                _c("span", { staticClass: "c2" })
              ]),
              _c("p", { staticClass: "c16" }, [
                _c("span", { staticClass: "c3" })
              ])
            ]
          ),
          _c(
            "td",
            { staticClass: "c8", attrs: { colspan: "1", rowspan: "1" } },
            [
              _c("p", { staticClass: "c4" }, [
                _c("span", { staticClass: "c9 c6" }, [
                  _vm._v("Объем персональных данных Пользователей:")
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_yafx561poxj7-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("фамилия, имя, отчество; ")
                  ])
                ])
              ]),
              _c("ul", { staticClass: "c5 lst-kix_x1xvubnl8zkq-0" }, [
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("адрес электронной почты; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("номер телефона; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("место нахождения (регион, город). ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [
                    _vm._v("дата и место рождения; ")
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c2" }, [_vm._v("гражданство; ")])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10" }, [
                    _vm._v(
                      "данные документа, удостоверяющего личность (наименование, серия, номер, кем и когда выдан); "
                    )
                  ])
                ]),
                _c("li", { staticClass: "c1 li-bullet-0" }, [
                  _c("span", { staticClass: "c10 c6" }, [
                    _vm._v(
                      "-иные категории персональных данных, необходимые для исполнения определенного требования, предусмотренного действующим законодательством РФ."
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4 c23" }, [
      _c("span", { staticClass: "c2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("5. Правовые основания обработки персональных данных")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "5.1. Обработка Персональных данных осуществляется Оператором на следующих основаниях:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- обработка Персональных данных осуществляется с согласия субъекта персональных данных на обработку его Персональных данных (далее – Согласие);"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [
        _vm._v(
          "- обработка Персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем, по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [
        _vm._v(
          "- обработка Персональных данных осуществляется в связи с участием лица в конституционном, гражданском, административном, уголовном судопроизводстве"
        )
      ]),
      _c("span", { staticClass: "c2" }, [_vm._v(".")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("6. Порядок и условия обработки персональных данных")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "6.1. Перечень действий с Персональных данных, осуществляемых Оператором:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.1.1.Оператор осуществляет действия с использованием средств автоматизации или без использования таких средств Персональных данных, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение Персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("6.2. Передача Персональных данных третьим лицам")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.2.1. В соответствии с заранее определенными целями и требованиями законодательства Оператор может передавать Персональных данных следующим категориям третьих лиц:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c19" }, [
      _c("span", { staticClass: "c2" }, [_vm._v("- сервисам веб-аналитики;")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c19" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- иным юридическим лицам, на основании заключаемых с этими лицами договорами;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c28 c37" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "-в государственные или иные органы в целях исполнения требований законодательства."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.2.2. Передача Персональных данных третьим лицам осуществляется с согласия субъекта Персональных данных, если иное не предусмотрено Федеральным законом от 27.07.2006  № 152-ФЗ «О персональных данных» (далее – Закон 152-ФЗ)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.2.3. Оператор и иные лица, получившие доступ к Персональных данных, обязаны не раскрывать третьим лицам и не распространять Персональных данных без согласия субъекта Персональных данных, если иное не предусмотрено Законом 152-ФЗ."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c9" }, [_vm._v("6.3. ")]),
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          " Обработка данных в качестве субподрядчика и привлечение субподрядчиков"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c27" }, [
      _c("span", { staticClass: "c2 c6" }, [
        _vm._v(
          "6.3.1. Общество, помимо деятельности в качестве оператора персональных данных, может выступать как лицо, осуществляющие обработку персональных данных по поручению других операторов персональных данных на основании договоров и иных соглашений. К таким случаям относятся, например, следующие:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "c5 lst-kix_oe7itskavkub-0 start" }, [
      _c("li", { staticClass: "c27 c35 li-bullet-3" }, [
        _c("span", { staticClass: "c2 c6" }, [
          _vm._v(
            "оказание Клиентам Общества услуг, связанных с обработкой данных; "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "c5 lst-kix_7hc6yj16iz86-0 start" }, [
      _c("li", { staticClass: "c27 c35 li-bullet-4" }, [
        _c("span", { staticClass: "c2 c6" }, [
          _vm._v(
            "осуществление совместной со сторонними организациями обработки в рамках партнерства с Обществом."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c24" }, [
      _c("span", { staticClass: "c2 c6" }, [
        _vm._v(
          "6.3.2. Общество при необходимости может привлекать сторонние организации к обработке персональных данных в качестве субподрядчиков при условии соблюдения принципов обработки и наличия с ними соответствующего договора или соглашения. К таким случаям относятся, например, следующие:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "c5 lst-kix_ohbt7qiqwosw-0 start" }, [
      _c("li", { staticClass: "c21 li-bullet-0" }, [
        _c("span", { staticClass: "c2 c6" }, [
          _vm._v(
            "предоставление программных продуктов, товаров, выполнение работ и оказание услуг Общества, а также со сторонними организациями, технологическими и иными партнерами Общества; "
          )
        ])
      ]),
      _c("li", { staticClass: "c21 li-bullet-0" }, [
        _c("span", { staticClass: "c2 c6" }, [
          _vm._v(
            "организация партнерской сети Общества для распространения программных продуктов, товаров, работ и услуг на рынке;"
          )
        ])
      ]),
      _c("li", { staticClass: "c21 li-bullet-0" }, [
        _c("span", { staticClass: "c2 c6" }, [
          _vm._v(
            "использование сторонних услуг, вычислительных ресурсов, приложений и инфраструктуры для обработки информации, связи с пользователями программных продуктов, работ и услуг, приобретателями товаров."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c27" }, [
      _c("span", { staticClass: "c2 c6" }, [
        _vm._v(
          "6.3.3. Обработка персональных данных на основании договоров и иных соглашений Общества, поручений на обработку персональных данных осуществляется в соответствии с условиями этих договоров, соглашений Общества с лицами, которым поручена обработка или которые поручили обработку на законных основаниях. Такие соглашения могут определять, в частности:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "c5 lst-kix_ob5fydv4khdx-0 start" }, [
      _c("li", { staticClass: "c30 c34 li-bullet-0" }, [
        _c("span", { staticClass: "c2 c6" }, [
          _vm._v(
            "цели, условия, действия с персональными данными, сроки обработки персональных данных;"
          )
        ])
      ]),
      _c("li", { staticClass: "c34 c30 li-bullet-0" }, [
        _c("span", { staticClass: "c2 c6" }, [
          _vm._v(
            "роли, функции и обязательства сторон, в том числе, меры по обеспечению конфиденциальности и информационной безопасности; "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("6.4. Обеспечение безопасности Персональных данных")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.4.1. Оператор принимает необходимые и достаточные организационные и технические меры для защиты Персональных данных субъектов персональных данных от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.4.2. Обеспечение безопасности Персональных данных, обрабатываемых Оператором, достигается, в частности следующими мерами:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- назначением ответственных лиц за организацию обработки Персональных данных и за обеспечение безопасности Персональных данных, распределение функций, обязанностей и полномочий;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- ознакомлением работников Оператора с требованиями законодательства и организационно-распорядительными документами по вопросам обработки и защиты Персональных данных;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [
        _vm._v(
          "- определением угроз безопасности Персональных данных при их обработке в "
        )
      ]),
      _c("span", { staticClass: "c10" }, [
        _vm._v("Информационной системе персональных данных")
      ]),
      _c("span", { staticClass: "c2" }, [_vm._v(";")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [
        _vm._v(
          "- применением организационных и технических мер по обеспечению безопасности Персональных данных при их обработке в "
        )
      ]),
      _c("span", { staticClass: "c10" }, [
        _vm._v("Информационной системе персональных данных")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          ", необходимых для выполнения требований к защите Персональных данных, исполнение которых обеспечивает установленные законодательством Российской Федерации уровни защищенности Персональных данных;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [
        _vm._v(
          "- обнаружением фактов несанкционированного доступа к Персональным данным и принятием мер, в том числе м"
        )
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "ер по обнаружению, предупреждению и ликвидации последствий компьютерных атак на Информационные системы персональных данных и по реагированию на компьютерные инциденты в них;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- установлением правил доступа к Персональных данных, обрабатываемым в Информационной системы персональных данных, а также обеспечением регистрации и учета всех действий, совершаемых с Персональных данных в Информационной системе персональных данных;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [
        _vm._v("- контролем за принимаемыми ")
      ]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "мерами по обеспечению безопасности Персональных данных и уровня защищенности Информационной системы персональных данных;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- другие необходимые организационные и технические мероприятия, установленные нормативными актами по вопросам защиты Персональных данных;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.4.3.Базы данных Оператора находятся на территории Российской Федерации."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("6.5. Сроки обработки Персональных данных")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.5.1. Сроки обработки Персональных данных зависят от целей обработки Персональных данных и указываются в согласии на обработку Персональных данных или в договоре, стороной которого либо выгодоприобретателем или поручителем по которому является Субъект Персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.5.2. Персональных данных Субъектов Персональных данных, обрабатываемые Оператором, подлежат уничтожению либо обезличиванию в случае:"
        ),
        _c("br"),
        _vm._v(
          "- истечения срока, предусмотренного законом, договором или согласием субъекта персональных данных на обработку его Персональных данных;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- отзыва субъектом персональных данных согласия на обработку его Персональных данных; "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- отсутствия правовых оснований на обработку, предусмотренных законодательством Российской Федерации;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v("- прекращения деятельности Оператора.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.5.3. В случае отзыва Согласия Персональных данных Субъекта Персональных данных, обрабатываемые Оператором, подлежат уничтожению, если иное не предусмотрено федеральным законодательством."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v(
          "6.6. Условия обработки Персональных данных без использования средств автоматизации"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.6.1. Обработка Персональных данных на Сайте без использования средств автоматизации не осуществляется."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.6.2. Персональные данные при их обработке Персональных данных, осуществляемой без использования средств автоматизации, обособляются от иной информации, в частности путем фиксации их на отдельных материальных носителях Персональных данных (далее – материальные носители), в специальных разделах или на полях форм (бланков)."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.6.3.При фиксации Персональных данных на материальных носителях не допускается фиксация на одном материальном носителе Персональных данных, цели обработки которых заведомо не совместимы. Для обработки различных категорий Персональных данных, осуществляемой без использования средств автоматизации, для каждой категории Персональных данных должен использоваться отдельный материальный носитель."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "6.6.4. Работники Оператора, осуществляющие обработку Персональных данных без использования средств автоматизации, проинформированы о факте обработки ими Персональных данных, категориях обрабатываемых Персональных данных, а также об особенностях и правилах осуществления такой обработки, установленных нормативными правовыми актами федеральных органов исполнительной власти, органов исполнительной власти субъектов Российской Федерации."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("7. Особенности обработки файлов Cookie и счетчиков")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2 c6" }, [
        _vm._v(
          "7.1           Данные, которые автоматически передаются Обществу в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Сайту), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Сайту, адреса запрашиваемых страниц и иная подобная информация, используется Обществом в целях идентификации Пользователя в качестве Клиента."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2 c6" }, [
        _vm._v(
          "7.2           Обработка указанных данных осуществляется с предварительного согласия Пользователя. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2 c6" }, [
        _vm._v(
          "7.3           Пользователь может настроить используемый им браузер таким образом, чтобы отклонять все файлы cookie или оповещать об их отправке, а также удалить ранее полученные файлы cookie. При отказе от получения файлов cookie некоторые функции Сайта могут работать некорректно. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10 c6" }, [
        _vm._v(
          "7.4           Пользователь в любое время может удалить или заблокировать используемые cookie-файлы посредством соответствующей настройки веб-браузера. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c17" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("8. Права и обязанности сторон")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c17" }, [
      _c("span", { staticClass: "c3" }, [
        _vm._v("8.1. Основные права Оператора")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c17" }, [
      _c("span", { staticClass: "c10" }, [_vm._v("8")]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          ".1.1. Оператор вправе обрабатывать Персональных данных в следующих случаях:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- обработка Персональных данных осуществляется с согласия субъекта персональных данных на обработку его Персональных данных;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [
        _vm._v(
          "- обработка Персональных данных необходима для достижения целей, предусмотренных законодательством Российской Федерации для осуществления и выполнения возложенных на Оператора функций, полномочий и обязанностей;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [
        _vm._v(
          "- обработка Персональных данных осуществляется в связи с участием лица в конституционном, гражданском, административном, уголовном судопроизводстве;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- обработка Персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем, по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- обработка Персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц, либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- обработка Персональных данных осуществляется в статистических или иных исследовательских целях."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "8.1.2. Оператор вправе поручить обработку Персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено Законом 152-ФЗ, на основании заключаемого с этим лицом договора."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "8.1.3. Оператор в своей деятельности исходит из того, что субъект персональных данных предоставляет точную и достоверную информацию, во время взаимодействия с Оператором, извещает Оператора об изменении своих Персональных данных. В случае выявления ошибочных или неполных Персональных данных Оператор имеет право прекратить все отношения с субъектом персональных данных, с последующим уничтожением Персональных данных субъекта персональных данных."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [_vm._v(" ")]),
      _c("span", { staticClass: "c3" }, [
        _vm._v("8.2. Основные обязанности Оператора")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c17" }, [
      _c("span", { staticClass: "c10" }, [_vm._v("8")]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          ".2.1. В соответствии с требованиями Закона 152-ФЗ Оператор обязуется:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "-предоставлять субъекту персональных данных или его представителю по его запросу информацию, касающуюся обработки его Персональных данных, либо на законных основаниях предоставить отказ;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "-по требованию субъекта персональных данных или его представителя уточнять обрабатываемые Персональных данных, блокировать или удалять, если Персональных данных являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленных целей обработки;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- в случае отзыва субъектом персональных данных согласия на обработку своих Персональных данных прекратить обработку Персональных данных и уничтожить Персональных данных в срок, не превышающий 30 (тридцать) дней с даты поступления указанного отзыва, если иное не предусмотрено Законом от 27.07.2006  № 152-ФЗ «О персональных данных»;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- не обрабатывать Персональных данных субъектов персональных данных, в том числе не собирать и не передавать Персональных данных субъектов персональных данных третьим лицам без согласия, если иное не предусмотрено законодательством Российской Федерации;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- и иные требования Закона от 27.07.2006  № 152-ФЗ «О персональных данных» ."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c36" }, [
      _c("span", { staticClass: "c9" }, [
        _vm._v("8.3. Основные права субъекта персональных данных"),
        _c("br")
      ]),
      _c("span", { staticClass: "c10" }, [_vm._v("8")]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(".3.1. Субъект персональных данных имеет право:"),
        _c("br"),
        _vm._v(
          "- получать сведения, касающиеся обработки его Персональных данных Оператором;"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [
        _vm._v(
          "-требовать от Оператора уточнения его Персональных данных, их блокирования или уничтожения в случае, если Персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v("- отозвать согласие на обработку Персональных данных;")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "- обжаловать действия или бездействие Оператора в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c17" }, [
      _c("span", { staticClass: "c3" }, [_vm._v("9. Заключительные положения")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c4" }, [
      _c("span", { staticClass: "c10" }, [_vm._v("9.1. ")]),
      _c("span", { staticClass: "c2" }, [
        _vm._v(
          "В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c31" }, [
      _c("span", { staticClass: "c18" }, [_vm._v(" ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c20" }, [_c("span", { staticClass: "c29" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }