






















































































import Vue from 'vue';
import LocStorage from 'shared/utils/localStorage';
import { Validators } from 'shared/utils/validator';

export default Vue.extend({
  name: 'AppAuthResetPassword',

  props: {
    changeForm: Function,
  },

  data() {
    return {
      success: false,
      model: {
        username: '',
      },
    };
  },

  methods: {
    validationRules(): ValidationConfig {
      return {
        username: [ Validators.required, Validators.email ],
      };
    },
    async onSubmit() {
      try {
        const response = await this.$api.auth.ResetPassword(this.model);

        if (response.reset === 'ok') {
          this.success = true;
        }
      } catch (error) {
        console.error(error);
      }

      LocStorage.removeItem('cred');
    },
  },
});
