import EventEmitter from 'shared/utils/eventEmitter';

const ProductEmitter = new EventEmitter();

export const ProductEmitterEvents = {
  pay: 'pay',
  showDialog: 'showDialog',
};

export default ProductEmitter;
