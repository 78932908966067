var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "object-prop" }, [
    _c(
      "div",
      { staticClass: "object-prop__icon" },
      [
        _vm.$slots.icon || _vm.icon
          ? _vm._t("icon", [
              _c("svg", [_c("use", { attrs: { "xlink:href": _vm.icon } })])
            ])
          : _vm._e()
      ],
      2
    ),
    _c("div", { staticClass: "object-prop__text" }, [
      _c("div", { staticClass: "object-prop__type" }, [
        _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
      ]),
      _c(
        "div",
        { staticClass: "object-prop__value" },
        [_vm._t("default", [_vm._v(_vm._s(_vm.value))])],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }