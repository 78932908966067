
import removeTypesNamespace from 'shared/utils/storeNamespaceHelper';

export const ObjectTypes = {
  namespace: 'objectDetail',

  getters: {
    FILTER_PURCHASES: 'objectDetail/filterPurchases',
    PURCHASED_ITEM: 'objectDetail/purchasedItem',
    EGRN_OBJECT_DIVERGENCE: 'objectDetail/egrnObjectDivergence',
  },

  actions: {
    GET_OBJECT_INFO: 'objectDetail/getObjectInfo',
    GET_OBJECT_FULL_INFO: 'objectDetail/getObjectFullInfo',
    GET_RISK_REPORT_V2: 'objectDetail/getRiskReportV2',
  },

  mutations: {
    TOGGLE_LOADING: 'objectDetail/toggleLoading',
    TOGGLE_INFO_LOADING: 'objectDetail/toggleInfoLoading',
    SET_INFO: 'objectDetail/setInfo',
    SET_PRODUCTS: 'objectDetail/setProducts',
    PUSH_PRODUCTS: 'objectDetail/pushProducts',
    SET_RISK_REPORT_V2: 'objectDetail/setRiskReportV2',
  },
};

export const _ObjectTypes = removeTypesNamespace<typeof ObjectTypes>('objectDetail/', ObjectTypes);
