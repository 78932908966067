var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "risk-add-owner-form mt-3" },
    [
      _c(
        "app-form",
        {
          attrs: {
            model: _vm.owners,
            rules: _vm.validationConfig,
            "request-handler": _vm.onSubmit
          },
          scopedSlots: _vm._u(
            [
              {
                key: "button",
                fn: function(ref) {
                  var loading = ref.loading
                  return [
                    _vm._t(
                      "buttons",
                      [
                        _c(
                          "div",
                          {
                            staticClass: "risk-add-owner-form__buttons form-row"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col mb-3",
                                class: {
                                  "col-sm-6": _vm.owners.length
                                }
                              },
                              [
                                !_vm.isPdf
                                  ? _c(
                                      "app-button",
                                      {
                                        attrs: {
                                          id: "risk-add-owner-btn",
                                          variant: "primary",
                                          block: ""
                                        },
                                        on: { click: _vm.addOwner }
                                      },
                                      [
                                        _vm._v(
                                          "\n              Добавить собственника для проверки\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.owners.length
                              ? _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c(
                                      "app-button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          loading: loading,
                                          block: ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              Отправить\n            "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      { loading: loading }
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        _vm._l(_vm.owners, function(owner, index) {
          return _c(
            "app-add-owner-form-item",
            {
              key: index,
              attrs: { index: index, owner: owner, "is-form-on-landing": true },
              on: { remove: _vm.removeOwnerItem }
            },
            [
              _vm._l(_vm.$slots, function(_, slot) {
                return [_c("template", { slot: slot }, [_vm._t(slot)], 2)]
              })
            ],
            2
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }