












































































import Vue from 'vue';
import Component from 'vue-class-component';
import ymaps from 'yandex-maps';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from '@/core/utils/orderBy';
import { calcDistanceByCoords } from '@/core/utils/calcDistanceByCoordinates';

declare var ymap: any;

// const EARTH_RADIUS = 6372795;

let myMap: ymaps.Map;
let searchControl: any;
let objectManager: any;
let id = 0;

@Component({
  name: 'AppInfrastructureMap',
  popupOptions: {
    name: 'popup_full',
  },
  props: {
    info: Object,
  },

  filters: {
    formatMeter: (value: number) => {
      return value >= 1000 ? `${(value / 1000).toFixed(2)} км` : `${value} м`;
    },
  },
})
export default class InfrastructureMap extends Vue {
  info: RealtyObject;

  // data()
  mapLoaded: boolean = document.getElementById('ya-map-init') !== null;
  notFound: boolean = false;
  menuHidden: boolean = true;
  query: string = '';
  cache: SimpleObject<any[]> = {};
  queryList = [
    {
      query: 'Продукты',
      label: 'Магазины',
      results: 50,
    },
    {
      query: 'Школа',
      label: 'Школы',
    },
    {
      query: 'Детский сад',
      label: 'Детские сады',
    },
    {
      query: 'Аптека',
      label: 'Аптеки',
    },
    {
      query: 'Поликлиника',
      label: 'Поликлиники',
    },
  ];

  get results(): any[] {
    if (!this.query || !this.cache[this.query]) {
      return [];
    }

    const results = this.cache[this.query].map((item: any) => cloneDeep(item.properties._data));

    return orderBy(results, 'distance', 'asc');
  }

  // lifecycle hooks
  mounted() {
    if (this.mapLoaded) {
      this.initMap();
    } else {
      this.loadYandexMapScript();
    }
  }

  beforeDestroy() {
    if (myMap) {
      myMap.destroy();
      myMap = searchControl = objectManager = null;
    }
  }

  // methods
  async initMap() {
    const geocodeResponse = await ymap.geocode(this.info.Address, { results: 1 });

    const firstItem = geocodeResponse.geoObjects.get(0);

    if (!firstItem) {
      this.notFound = true;
      return;
    }

    firstItem.options.set('preset', 'islands#redDotIcon');

    myMap = new ymap.Map('map', {
      center: firstItem.geometry.getCoordinates(),
      zoom: 16,
      controls: [],
    });

    myMap.geoObjects.add(geocodeResponse.geoObjects);

    const circle = new ymap.Circle([ myMap.getCenter(), 1000 ], {}, { visible: false });
    myMap.geoObjects.add(circle);

    searchControl = new ymap.control.SearchControl({
      options: {
        provider: 'yandex#search',
        boundedBy: circle.geometry.getBounds(),
        strictBounds: true,
      },
    });

    objectManager = new ymap.ObjectManager({ gridSize: 32 });
    objectManager.objects.options.set('preset', 'islands#blueCircleDotIcon');
    myMap.geoObjects.add(objectManager);
  }

  loadYandexMapScript() {
    const script = document.createElement('script');
    script.id = 'ya-map-init';
    script.src =
      'https://api-maps.yandex.ru/2.1/?apikey=ff82ecae-0fa5-43d9-8793-9b87787697b7&lang=ru_RU&onload=yaMapReady&ns=ymap';
    script.async = true;
    document.head.appendChild(script);

    (window as any).yaMapReady = this.initMap;
  }

  toggleShowObjects(query: string, results: number = 16) {
    if (!myMap) {
      return;
    }

    const isActive = query === this.query;

    this.toggleMenu();

    if (isActive) {
      this.query = '';
      return this.removeMapMarkers(query);
    }

    if (this.query !== '') {
      this.removeMapMarkers(this.query);
    }

    this.query = query;

    myMap.setZoom(14, { duration: 200 });

    if (this.cache[query]) {
      objectManager.add(this.cache[query]);
      return;
    }

    searchControl
      .search(query, { results, rspn: 1 })
      .then((response: any) => {
        this.$set(
          this.cache,
          query,
          this.mapDataToObjectManager(response.geoObjects.toArray(), query),
        );
        objectManager.add(this.cache[query]);
      })
      .catch(console.error);
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  removeMapMarkers(query: string) {
    const removeIds: number[] = [];

    objectManager.objects.each((item: any) => {
      const q = item.properties._data.query;
      if (q !== undefined && q === query) {
        removeIds.push(item.id);
      }
    });

    objectManager.remove(removeIds);
  }

  mapDataToObjectManager(data: any, query: string) {
    return data.map((item: any) => {
      let iconImageHref;

      if (query === 'Детский сад') {
        iconImageHref = '/images/map-icon/kindergarten.svg';
      }

      if (query === 'Аптека' || query === 'Поликлиника') {
        iconImageHref = '/images/map-icon/cross.svg';
      }

      if (query === 'Продукты') {
        iconImageHref = '/images/map-icon/shop.svg';
      }

      if (query === 'Школа') {
        iconImageHref = '/images/map-icon/school.svg';
      }

      const distance = calcDistanceByCoords(myMap.getCenter(), item.geometry.getCoordinates());
      const name = item.properties.get('name');
      const address = item.properties.get('address');

      return {
        type: 'Feature',
        id: id++,
        geometry: {
          type: 'Point',
          coordinates: item.geometry.getCoordinates(),
        },
        properties: {
          balloonContentHeader: name,
          balloonContentBody: address,
          balloonContentFooter: item.properties.get('categoriesText'),
          _data: {
            name,
            address,
            distance,
            query,
            rating: { ...item.properties.get('rating') },
            loc: { ...item.properties.get('loc') },
          },
        },
        options: {
          iconLayout: 'default#image',
          iconImageHref,
          iconImageSize: [ 26, 26 ],
          iconImageOffset: [ -13, -13 ],
        },
      };
    });
  }
}
