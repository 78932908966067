var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-menu" },
    [
      _vm.user
        ? [
            _c("div", { staticClass: "user-menu-header" }, [
              _c("svg", { staticClass: "user-menu-header__icon" }, [
                _c("use", { attrs: { "xlink:href": "#icon-user-thin" } })
              ]),
              _c("div", { staticClass: "user-menu-header__info" }, [
                _c(
                  "ul",
                  { staticClass: "user-menu-header__name list-unstyled" },
                  [
                    _c("li", [_vm._v(_vm._s(_vm.username))]),
                    _c("li", [_vm._v(_vm._s(_vm.user.surname))])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "user-menu-nav mb-3" }, [
              _c(
                "div",
                { staticClass: "user-menu-nav__item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "user-menu-nav__link",
                      attrs: {
                        to: { name: "mortgage-selection" },
                        "active-class": "is-active",
                        exact: "",
                        "data-menu-close": ""
                      }
                    },
                    [_vm._v("\n          Подбор ипотеки\n        ")]
                  )
                ],
                1
              ),
              _vm._m(0),
              _c(
                "div",
                { staticClass: "user-menu-nav__item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "user-menu-nav__link",
                      attrs: {
                        to: { name: "usecases" },
                        "active-class": "is-active",
                        "data-menu-close": ""
                      }
                    },
                    [_vm._v("\n          Документы для сделки\n        ")]
                  )
                ],
                1
              )
            ])
          ]
        : _vm._e(),
      _vm.showWidget
        ? [
            _c(
              "div",
              { staticClass: "article-block", on: { click: _vm.goToArticle } },
              [
                _c("div", { staticClass: "article-block__head" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "article-block__icon",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 58 58"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "#556080",
                          d:
                            "M29 0S22.333 8 7 8v19.085c0 9.966 4.328 19.577 12.164 25.735C21.937 55 25.208 56.875 29 58c3.792-1.125 7.062-3 9.836-5.18C46.672 46.662 51 37.051 51 27.085V8C35.667 8 29 0 29 0z"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          fill: "#4fba6f",
                          d:
                            "M29 51.661c-2.123-.833-4.178-2.025-6.128-3.558C16.69 43.245 13 35.388 13 27.085V13.628c7.391-.943 12.639-3.514 16-5.798 3.361 2.284 8.609 4.855 16 5.798v13.457c0 8.303-3.69 16.16-9.871 21.018-1.951 1.533-4.006 2.725-6.129 3.558z"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          fill: "#fff",
                          d:
                            "M41.659 20.248c-.416-.364-1.047-.321-1.411.094L26.951 35.537l-7.244-7.244c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414l8 8A.999.999 0 0 0 27 38h.033a.9986.9986 0 0 0 .719-.341l14-16c.364-.416.322-1.048-.093-1.411z"
                        }
                      })
                    ]
                  ),
                  _c("h6", [
                    _vm._v(
                      "\n          Важно! Риски при покупке квартиры\n        "
                    )
                  ])
                ]),
                _c("p", [
                  _vm._v(
                    "\n        При покупке квартиры в сделках больше всего рискует покупатель.\n      "
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "\n        В основном такое получается из-за нехватки знаний в области недвижимости...\n      "
                  )
                ]),
                _c("span", { staticClass: "btn-link" }, [
                  _vm._v("\n        Читать далее\n      ")
                ])
              ]
            ),
            _c("div", {
              ref: "vkWidget",
              staticClass: "mt-3",
              attrs: { id: "vk-widget" }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-menu-nav__item" }, [
      _c(
        "a",
        {
          staticClass: "user-menu-nav__link",
          attrs: {
            href: "https://realtycloud.ru/seller",
            target: "_blank",
            "data-menu-close": ""
          }
        },
        [_vm._v("\n          Продать квартиру\n        ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }