var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "auth-head" }, [
        _c("svg", { staticClass: "auth-head__icon" }, [
          _c("use", { attrs: { "xlink:href": "#icon-user-thin" } })
        ]),
        _c("h4", { staticClass: "auth-head__title" }, [
          _vm._v("\n      Вход\n    ")
        ])
      ]),
      _c(
        "app-form",
        {
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.model,
            rules: _vm.validationRules
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "app-button",
                        {
                          attrs: {
                            type: "submit",
                            size: "lg",
                            variant: "info",
                            loading: loading,
                            block: ""
                          }
                        },
                        [_vm._v("\n          Войти\n        ")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "app-form-group",
            { attrs: { "model-name": "username" } },
            [
              _c("app-input", {
                attrs: { type: "email", placeholder: "E-mail", size: "lg" },
                model: {
                  value: _vm.model.username,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "username", $$v)
                  },
                  expression: "model.username"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { attrs: { "model-name": "password" } },
            [
              _c("app-input", {
                attrs: { type: "password", placeholder: "Пароль", size: "lg" },
                model: {
                  value: _vm.model.password,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "password", $$v)
                  },
                  expression: "model.password"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "form-group d-flex justify-content-between align-items-center"
            },
            [
              _c(
                "app-checkbox",
                {
                  model: {
                    value: _vm.remember,
                    callback: function($$v) {
                      _vm.remember = $$v
                    },
                    expression: "remember"
                  }
                },
                [_vm._v("\n        Запомнить\n      ")]
              ),
              _c(
                "span",
                {
                  staticClass: "auth-link",
                  on: {
                    click: function($event) {
                      return _vm.changeForm("resetPass")
                    }
                  }
                },
                [_vm._v("Забыли пароль?")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }