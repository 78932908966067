










































































































































import Vue from 'vue';
import omit from 'lodash/omit';
import clone from 'lodash/clone';
import result from 'lodash/result';

import AppPrivacyDialog from 'shared/components/Static/PrivacyDialog.vue';
import AppOfferDialog from 'shared/components/Static/OfferDialog.vue';
import { Validators } from 'shared/utils/validator';
import { AuthTypes } from 'shared/store/modules/auth/types';
import { RoleNames, Role } from 'shared/utils/constants';

export default Vue.extend({
  name: 'AppAuthRegister',

  props: {
    changeForm: Function,
    registrationSuccess: Function,
  },

  data() {
    return {
      model: {
        name: '',
        email: '',
        phone: '',
        password: '',
        privacy: false,
        person_role: null,
        registartion_url: location.href,
      },
      roles: {
        ...omit(RoleNames, Role.Admin, Role.Client),
        // [Role.Client]: 'Другое',
      },
      passwordConfirm: '',
    };
  },

  methods: {
    validationRules(): ValidationConfig {
      return {
        name: [ Validators.required ],
        email: [ Validators.required, Validators.email ],
        phone: [ Validators.required, Validators.phone ],
        person_role: [ Validators.required ],
        password: [ Validators.required, Validators.eq(this.passwordConfirm, 'Пароли не совпадают') ],
        privacy: [ Validators.eq(true, 'Чтобы продолжить, установите этот флажок') ],
      };
    },

    showDialog() {
      this.$dialog.open({
        component: AppPrivacyDialog,
      });
    },

    showOfferDialog() {
      this.$dialog.open({
        component: AppOfferDialog,
      });
    },

    async onSubmit() {
      try {
        const model = clone(this.model);
        if (this.model.person_role === 12) {
          this.$store.commit('setQuery', { utm_campaign: 'risk50' });
          model.person_role = Role.Client;
        }
        await this.$store.dispatch(AuthTypes.actions.REGISTRATION, {
          user: omit(this.model, 'privacy'),
        });
        this.$noty.info({ text: 'Вы успешно зарегистрированы' });
        const params: any = {};
        params.ya = {
          role: result(this.roles, this.model.person_role),
          email: this.model.email,
        };
        this.registrationSuccess();
        this.$analytics.send('registration_success', params);
        this.$dialog.close();
      } catch (error) {
        const text = (error as Error).message;
        this.$noty.error({ text });
      }
    },
  },
});
