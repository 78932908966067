var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data-table" }, [
    _c(
      "table",
      {
        class: [
          "table mb-0",
          _vm.hover && "table-hover",
          _vm.small && "table-sm",
          _vm.borderless && "table-borderless",
          _vm.bordered && "table-bordered",
          _vm.striped && "table-striped",
          !_vm.pagination && "table-static",
          _vm.loading && _vm.items.length && "table-loading"
        ]
      },
      [
        !_vm.disableHeader
          ? _c(
              "thead",
              { class: [_vm.headColor && "thead-" + _vm.headColor] },
              [
                _c(
                  "tr",
                  { attrs: { "data-sort-direction": _vm.sortBy.direction } },
                  _vm._l(_vm.headers, function(header, i) {
                    return _c(
                      "th",
                      {
                        key: i,
                        class: [
                          header.align && "text-" + header.align,
                          {
                            active:
                              _vm.sortBy.prop &&
                              _vm.sortBy.prop === header.sortProp
                          }
                        ],
                        style: {
                          width: header.width
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggleSort(header)
                          }
                        }
                      },
                      [
                        _c("span", { class: [header.sortProp && "mr-2"] }, [
                          _vm._v(_vm._s(header.text))
                        ]),
                        header.sortProp
                          ? _c("i", {
                              staticClass: "fas",
                              class: {
                                "fa-sort": !_vm.sortBy.prop,
                                "fa-sort-down":
                                  _vm.sortBy.prop &&
                                  _vm.sortBy.direction === "asc",
                                "fa-sort-up":
                                  _vm.sortBy.prop &&
                                  _vm.sortBy.direction === "desc"
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          : _vm._e(),
        _c(
          "tbody",
          [
            _vm.items.length
              ? _vm._l(_vm.sortedItems, function(item, index) {
                  return _c(
                    "tr",
                    { key: item[_vm.itemKey] || index },
                    [_vm._t("default", null, { row: item, index: index })],
                    2
                  )
                })
              : _vm.loading && !_vm.items.length
              ? [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "text-center py-5",
                        attrs: { colspan: _vm.headers.length }
                      },
                      [_vm._m(0)]
                    )
                  ])
                ]
              : _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "data-table-empty",
                      attrs: { colspan: _vm.headers.length }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.emptyText) + "\n        "
                      )
                    ]
                  )
                ])
          ],
          2
        ),
        _c("tfoot", [_vm._t("footer")], 2)
      ]
    ),
    _vm.pagination
      ? _c(
          "div",
          { staticClass: "data-table__pagination" },
          [
            _c("app-pagination", {
              attrs: {
                current: _vm.pagination.page,
                "per-page": _vm.pagination.limit,
                total: _vm.pagination.total
              },
              on: {
                "page-changed": function($event) {
                  return _vm.$emit("page-changed", $event)
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "spinner-border", attrs: { role: "status" } },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }