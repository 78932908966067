import { NavigationGuard } from 'vue-router';
import store from '@/store';
import { AuthTypes } from 'shared/store/modules/auth/types';
import startsWith from 'lodash/startsWith';

export const authGuard: NavigationGuard = (to, from, next) => {
  if (store.state.auth.user) {
    return next();
  }

  return next({ name: 'home' });
};

export const roleGuard = (roleIds: number[]): NavigationGuard => {
  return (to, from, next) => {
    if (store.state.auth.user) {
      if (store.getters[AuthTypes.getters.HAS_ANY_ROLE](roleIds)) {
        return next();
      }
    }

    return next({ name: 'home' });
  };
};

export const isTestPageGuard: NavigationGuard = (to, from, next) => {
  if (!startsWith(window.location.href, 'https://egrn-test') && !startsWith(to.path, '/admin')) {
    window.location.href = 'https://b2b.realtycloud.ru/risk-assessment';
  } else {
    return next();
  }
};
