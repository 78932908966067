import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import merge from 'lodash/merge';
import _ from 'lodash';

import { searchModule } from './modules/search';
import { authModule } from 'shared/store/modules/auth';
import { userModule } from './modules/user';
import { objectDetail } from './modules/object-detail';
import objectDetailModule from 'shared/store/modules/objectDetail';
import { adminModule } from './modules/admin';
import { settingsModule } from './modules/settings';
import { Api } from 'shared/api';
import { orderModule } from './modules/order';
import { NULL_UUID } from '@/core/utils/constants';
import { marketingModule } from './modules/marketing';
import storeListener from './plugins/listeners';
import { AuthTypes } from 'shared/store/modules/auth/types';
import { advertModule } from 'shared/store/modules/advert';

Vue.use(Vuex);

const SHORT_PRODUCT_NAMES_RU: SimpleObject = {
  'RiskAssessment': 'Отчёт о рисках',
  'RiskAssessmentFast': 'Отчёт о рисках (срочный)',
  'EgrnObject': 'ЕГРН об объекте',
  'EgrnObjectFast': 'ЕГРН об объекте (срочный)',
  'EgrnRightList': 'ЕГРН о переходе прав',
  'EgrnRightListFast': 'ЕГРН о переходе прав (срочный)',
};

const Store = new Vuex.Store<RootState>({
  state: {
    products: [],
    productsByKey: {},
    utm_campaign: null,
    ordersDoneCount: 0,
    query: {},
  },

  getters: {
    getProduct: (state) => (filter: Product) => {
      return find(state.products, filter);
    },
  },

  actions: {
    async getProducts({ commit, rootState, state }) {
      try {
        let products = await Api.products.List<Product[]>(state.utm_campaign, { partnerID: rootState.auth.partner.id });

        products.forEach((item: Product) => {
          // Меняем заголовок и описание вручную:
          // для рисков
          if (
            item.product_name === 'RiskAssessment' ||
            item.product_name === 'RiskAssessmentAdvanced'
          ) {
            item.product_name_ru = 'Полная проверка недвижимости. Отчет о рисках ';
            item.product_description = 'Включает проверку собственников в базах МВД, ФНС, ФССП, суды и др. Содержит оба отчета из ЕГРН';
          }

          // для выписки о переходе прав на объект
          if (
            item.product_name === 'EgrnRightList'
          ) {
            item.product_name_ru = 'Отчет из ЕГРН о переходе прав на объект';
            item.product_description = 'История купли-продажи объекта, информация обо всех предыдущих владельцах с 1998 г.';
          }

          // для выписки об объекте
          if (
            item.product_name === 'EgrnObject'
          ) {
            item.product_description = 'Содержит информацию о текущем владельце, наличии обременений (запретов/арестов/ипотеки), кадастровой стоимости';
          }

          if (item.product_name in SHORT_PRODUCT_NAMES_RU) {
            item.product_name_short = SHORT_PRODUCT_NAMES_RU[item.product_name];
          }

          if (item.price_components && item.price_components.length && rootState.auth.user) {
            let foundOwnerPriceComponent = false;

            // Ищем текущего пользователя в PriceComponentOwner
            const searchCurrentUserInOwners = (owner: PriceComponentOwner) => {
              return owner.person_id === rootState.auth.user.id;
            };

            // Обходим каждый PriceComponent и проверяем есть ли текущий пользователь в PriceComponentOwner
            const iteratePriceComponents = (component: PriceComponent) => {
              if (component.price_component_owners) {
                foundOwnerPriceComponent = component.price_component_owners.some(
                  searchCurrentUserInOwners,
                );
              }
            };

            item.price_components.forEach(iteratePriceComponents);

            // Если нашли и цена по купону выше, чем по прайс-компоненту, то убираем купоны
            // Чтобы не было конфликтов с ценой, которая формирутеся через PriceComponent
            if (foundOwnerPriceComponent) {
              if (item.coupons) {
                const basePriceComponent = _.find(
                  item.price_components,
                  {
                    price_component_type: {
                      name: 'Base',
                    },
                  }
                );
                const productPriceBase = parseInt(basePriceComponent.price, 10);
                const productPriceWithCoupon = productPriceBase - parseInt(item.coupons[0].discount, 10);
                if (productPriceWithCoupon >= parseInt(item.product_price)) {
                  item.coupons = null;
                }
              }
            }
          }

          // Если есть купоны, то необходимо их отсортировать
          // Пусть сначала выводятся с наибольшей скидкой
          if (item.coupons) {
            item.coupons = orderBy(
              item.coupons,
              (item: Coupon) => {
                return parseInt(item.discount, 10);
              },
              'desc',
            );
          }
        });

        // Так как продутов становится много, надо отсортировать по типу
        // Чтобы список был всегда в одном порядке
        products = orderBy(products, 'product_name', 'desc');

        commit('setProducts', products);
      } catch (error) {
        return Api.HttpError(error);
      }
    },

    removeUsedCoupons({ state, commit }, orderItems: OrderItem[]) {
      const productsByKey = cloneDeep(state.productsByKey);

      for (const orderItem of orderItems) {
        const productItem = productsByKey[orderItem.product_id];

        if (orderItem.coupon_id && orderItem.coupon_id !== NULL_UUID && productItem.coupons) {
          productItem.coupons = productItem.coupons.filter(
            (coupon) => coupon.id !== orderItem.coupon_id,
          );

          if (!productItem.coupons.length) {
            productItem.coupons = null;
          }
        }
      }

      commit('setProducts', Object.values(productsByKey));
    },

    async getOrdersDoneCount({ commit }) {
      try {
        const { orders_done_count } = await Api.other.OrdersDoneCount();
        commit('setOrdersCount', orders_done_count);
      } catch (error) {
        console.error(error);
      }
    },
  },

  mutations: {
    setQuery(state, query: any) {
      state.query = merge({}, state.query, query);
    },

    setProducts(state, products: Product[]) {
      state.products = products;
      state.productsByKey = keyBy(products, 'product_id');
    },

    setUtmCampaign(state, utmCampaign: string) {
      state.utm_campaign = utmCampaign;
    },

    setOrdersCount(state, count: number) {
      if (Intl && Intl.NumberFormat) {
        state.ordersDoneCount = new Intl.NumberFormat().format(count);
        return;
      }

      state.ordersDoneCount = count;
    },
  },

  modules: {
    search: searchModule,
    auth: authModule,
    user: userModule,
    details: objectDetail,
    order: orderModule,
    marketing: marketingModule,
    admin: adminModule,
    settings: settingsModule,
    objectDetail: objectDetailModule(),
    advert: advertModule,
  },

  plugins: [
    createPersistedState({
      key: 'state',
      paths: [ 'auth.token', 'auth.user', 'auth.balance', 'auth.partner', 'products', 'ordersDoneCount' ],
    }),
    storeListener([
      {
        handlers: [
          {
            type: 'action',
            name: 'getProducts',
          },
          {
            type: 'action',
            name: 'details/getObjectInfo',
          },
        ],
        mutationListeners: [ AuthTypes.mutations.SET_USER ],
      },
    ]),
  ],
});

export default Store;
