import get from 'lodash/get';
import analyticsConfig from './config';
// import store from '@/store';

type YandexCounterMethods =
  | 'addFileExtension'
  | 'extLink'
  | 'file'
  | 'getClientID'
  | 'hit'
  | 'notBounce'
  | 'params'
  | 'reachGoal'
  | 'replacePhones'
  | 'setUserID'
  | 'userParams';

type GoogleEventNames = 'event' | 'config';

interface AnalyticsParams {
  ya?: any;
  user_id?: string;
}

export interface AnalyticsConfig {
  yandexCounters?: string[];
  gtagId?: string;
}

class Analytics {
  public static yandexCounters: string[];
  public static gtagId: string;

  public static setConfig(config: AnalyticsConfig = {}) {
    this.yandexCounters = config.yandexCounters;
    this.gtagId = config.gtagId;
  }

  public static send(targetName: string, params?: AnalyticsParams) {
    let yandexCounterParams = {};

    if (params) {
      if (params.ya) {
        yandexCounterParams = { ...params.ya, ...yandexCounterParams };
      }
    }

    this.gtag('event', targetName);
    this.ym('reachGoal', targetName, yandexCounterParams);

    try {
      const googleConfigArgs = get(analyticsConfig, `${targetName}.config`);
      const yandexHitArgs = get(analyticsConfig, `${targetName}.hit`);

      if (yandexHitArgs) {
        this.ym('hit', ...yandexHitArgs);
      }

      if (googleConfigArgs) {
        this.gtag('config', this.gtagId /* 'UA-122176305-1' */, ...googleConfigArgs);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public static ym(methodName: YandexCounterMethods, ...args: any[]) {
    const w: any = window;
    if (typeof w.ym !== 'undefined') {
      this.yandexCounters.forEach(counter => {
        return w.ym(counter, methodName, ...args);
      });
    }
  }

  public static gtag(eventName: GoogleEventNames, ...args: any[]) {
    const w: any = window;
    if (typeof w.gtag !== 'undefined') {
      return w.gtag(eventName, ...args);
    }
  }

  public static dataLayerPush(items: any) {
    const w: any = window;

    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push(items);
  }
}

export default Analytics;
