

















import Vue from 'vue';

export default Vue.extend({
  name: 'AppLoader',

  props: {
    text: String,
    size: String,
    textColor: String,
    color: {
      type: String,
      default: 'primary',
    },
  },
});
