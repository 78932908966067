var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.user
        ? [
            _c(
              "app-form",
              {
                attrs: {
                  "request-handler": _vm.onSubmit,
                  model: _vm.model,
                  rules: _vm.validationRules
                },
                scopedSlots: _vm._u(
                  [
                    _vm.isModal
                      ? {
                          key: "button",
                          fn: function(ref) {
                            var loading = ref.loading
                            return [
                              !_vm.response
                                ? _c(
                                    "div",
                                    { staticClass: "pt-2" },
                                    [
                                      _c(
                                        "app-button",
                                        {
                                          attrs: {
                                            type: "submit",
                                            loading: loading,
                                            block: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n            Активировать\n          "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      : null
                  ],
                  null,
                  true
                )
              },
              [
                _vm.isModal
                  ? [
                      _c(
                        "app-form-group",
                        {
                          staticClass: "text-center",
                          attrs: {
                            label: "Введите код",
                            "model-name": "promoCode"
                          }
                        },
                        [
                          _c("the-mask", {
                            staticClass: "form-control text-center",
                            attrs: {
                              mask: "FFFFFFFFFF",
                              tokens: _vm.hexTokens
                            },
                            model: {
                              value: _vm.model.promoCode,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "promoCode", $$v)
                              },
                              expression: "model.promoCode"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row flex-column flex-lg-row justify-content-center align-items-center mb-3"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col col-md-6 col-lg-4 mb-3 mb-lg-0"
                            },
                            [
                              _c(
                                "app-form-group",
                                {
                                  staticClass:
                                    "rc-form-group rc-form-group--white mb-0",
                                  attrs: { "model-name": "promoCode" }
                                },
                                [
                                  _c("the-mask", {
                                    staticClass: "form-control",
                                    attrs: {
                                      mask: "FFFFFFFFFF",
                                      tokens: _vm.hexTokens
                                    },
                                    model: {
                                      value: _vm.model.promoCode,
                                      callback: function($$v) {
                                        _vm.$set(_vm.model, "promoCode", $$v)
                                      },
                                      expression: "model.promoCode"
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      class: [{ active: _vm.model.promoCode }]
                                    },
                                    [_vm._v("Введите промокод")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col col-md-6 col-lg-2" },
                            [
                              _c(
                                "app-button",
                                {
                                  attrs: {
                                    type: "submit",
                                    block: "",
                                    size: "lg",
                                    rounded: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Применить\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                _vm.error
                  ? _c(
                      "div",
                      { staticClass: "alert alert-danger small text-center" },
                      [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                    )
                  : _vm._e(),
                _vm.response && !_vm.error
                  ? [
                      _vm.fromDashboard
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-success mb-0 small text-center"
                              },
                              [
                                _vm._v(
                                  "\n            Вы активировали скидку -\n            "
                                ),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.response.responses[0].desc.discount
                                    )
                                  )
                                ]),
                                _c("br"),
                                _vm._v("\n            на "),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(_vm.response.responses[0].desc.name)
                                  )
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "text-center mt-2" }, [
                              _c("a", { attrs: { href: "/risk-assessment" } }, [
                                _vm._v("Заказать сейчас ")
                              ])
                            ])
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-success mb-0 small text-center"
                              },
                              [
                                _vm.get(
                                  _vm.response,
                                  "responses.0.desc.description"
                                )
                                  ? _c("span", [
                                      _vm._v(
                                        "\n              Поздравляем вы получили\n              "
                                      ),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.response.responses[0].desc
                                              .description
                                          )
                                        )
                                      ]),
                                      _vm._v(" скидку"),
                                      _c("br"),
                                      _vm._v(
                                        "\n              на продукты:\n            "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._l(_vm.response.responses, function(
                                  coupon,
                                  i
                                ) {
                                  return _c(
                                    "div",
                                    { key: i },
                                    [
                                      coupon.desc && coupon.desc.name
                                        ? [
                                            _vm._v("\n                - "),
                                            _c("b", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(coupon.desc.name) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        : coupon.data && coupon.data.length
                                        ? _vm._l(coupon.data, function(tariff) {
                                            return _c(
                                              "div",
                                              { key: tariff.tariff_id },
                                              [
                                                _vm._v(
                                                  "\n                  Вам был активирован "
                                                ),
                                                _c("b", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tariff.tariff_name
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ]
                                            )
                                          })
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                })
                              ],
                              2
                            )
                          ]
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        : [
            _c("div", { staticClass: "text-center mb-4" }, [
              _vm._v(
                "\n      Чтобы активировать купон или промокод нажмите Войти или пройдите\n      Регистрацию\n    "
              )
            ]),
            _c(
              "div",
              [
                _c(
                  "app-button",
                  {
                    staticClass: "mb-2",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return _vm.showLoginDialog("register")
                      }
                    }
                  },
                  [_vm._v("\n        Регистрация\n      ")]
                ),
                _c(
                  "app-button",
                  {
                    staticClass: "mb-2",
                    attrs: { variant: "outline-primary", block: "" },
                    on: {
                      click: function($event) {
                        return _vm.showLoginDialog("login")
                      }
                    }
                  },
                  [_vm._v("\n        Войти\n      ")]
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }