<template>
  <transition name="tariff-updown">
    <div class="tariff-noty">
      <div
        v-if="hasRoleComponent"
        class="tariff-noty-text"
      >
        Для вас установлены специальные цены. После оформления заказов на сумму 1500 рублей, тариф поменяется на <b>Базовый</b>. Сохраните или выберите выгодные цены на странице <a href="https://realtycloud.ru/tariff/">Смотреть тарифы</a>
      </div>
      <div
        v-else
        class="tariff-noty-text"
      >
        Аккаунт на <b>Базовом тарифе</b>, чтобы получить выгодные цены - активируйте подходящий тариф. <a href="https://realtycloud.ru/tariff/">Смотреть тарифы</a>
      </div>
      <span
        class="tariff-noty-close"
        @click="closeTariffOffer"
      />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    hasRoleComponent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeTariffOffer() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import "~shared/scss/utils";
.popup-open .tariff-noty {
  display: none;
}
.tariff-noty {
  position: relative;
  top: 0;
  min-height: 41px;
  width: 100%;
  background: #FFF8E5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
  z-index: 999;
  flex-wrap: wrap;
  padding: 5px 0;

  a {
    color: #fff;
    text-decoration: none;
    background: $green;
    display: inline-block;
    padding: 0 0.75rem;
    border-radius: 20px;
    font-weight: 500;
    transition: all 0.3s;
    &:hover {
      background: darken($green, 5%);
    }
  }
}

.tariff-noty-close {
  width: 17px;
  height: 17px;
  background: url('/images/map-icon/cross.svg');
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  cursor: pointer;
  @include media-breakpoint-down(lg) {
    right: 5px;
    top:5px;
    transform: translateY(0) rotate(45deg);
  }
}

.tariff-noty-text {
  padding: 0 1.25rem;
}

.tariff-updown-enter-active,
.tariff-updown-leave-active {
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(-60%);
}
.tariff-updown-leave {
  transform: translateY(-30%);
}

.tariff-updown-enter,
.tariff-updown-leave-to {
  transform: translateY(-100%);
}

</style>
