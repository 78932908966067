










































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { TheMask } from 'vue-the-mask';
import AppActivatePromoCodeMixin from 'shared/components/Product/ActivatePromoCode.mixin';
import get from 'lodash/get';

@Component({
  name: 'AppActivatePromoCodeForm',
  components: { TheMask },
  props: {
    user: Object || undefined,
    onLogin: Function,
    fromDashboard: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppActivatePromoCodeForm extends mixins(AppActivatePromoCodeMixin) {
  // props
  user: User;
  onLogin: any;
  fromDashboard: boolean;
  isModal: boolean;

  // data
  get: any = get;
  model: any = {
    promoCode: '',
  };
}
