// import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import startsWith from 'lodash/startsWith';
import find from 'lodash/find';
import ProductInfoDialog from 'shared/components/Product/ProductInfoDialog.vue';

import ProductItemBase from './ProductItemBase';

@Component({
  name: 'AppProductItem',
  props: {
    allowedFastOrder: Boolean,
    selectedProducts: Array,
    riskInsurance: {
      type: Boolean,
      default: false,
    },
    riskInsurancePrice: {
      type: Number,
      default: 1000,
    },
  },
})
export default class ProductItem extends mixins(ProductItemBase) {
  // props
  allowedFastOrder: boolean;
  selectedProducts: UUID[];
  defaultFastPriceDifference: number;
  riskInsurance: boolean;
  riskInsurancePrice: number;

  // computed
  get coupon(): Coupon | null {
    if (this.product.coupons) {
      return this.product.coupons[0];
    }

    return null;
  }

  get price(): string {
    let price = parseInt(this.product.product_price);
    if (this.coupon) {
      const basePriceComponent = find(
        this.product.price_components,
        {
          price_component_type: {
            name: 'Base',
          },
        }
      );

      const productPriceBase = parseInt(basePriceComponent.price, 10);
      const productPriceWithCoupon = productPriceBase - parseInt(this.coupon.discount, 10);
      /** Если цена по купону больше, чем цена по прайс-компонету, то берём цену по прайс-компоненту */
      if (productPriceWithCoupon <= price) {
        price = productPriceWithCoupon;
      } else {
        this.coupon.description = '';
      }
    }
    // если прожали чекбокс страховка
    if (this.riskInsurance && /RiskAssessment/.test(this.product.product_name)) {
      price += this.riskInsurancePrice;
    }
    return price.toString();
  }

  get product(): Product {
    if (this.checkedStatus === 2) {
      return this.item.fast;
    }

    return this.item.default;
  }

  get tooltipContent(): string {
    const isRiskProduct = startsWith(this.item.default.product_name, 'RiskAssessment');

    if (this.checkedStatus === 2) {
      if (isRiskProduct) {
        return 'Заказ будет обработан в приоритетном порядке. В случае превышения 30 минут - возвратим оплаченную сумму за срочность и заказ будет обработан по сумме обычного заказа.';
      }

      return 'Заказ будет обработан в приоритетном порядке. В случае превышения 30 минут - возвратим оплаченную сумму за срочность и заказ будет обработан по сумме обычного заказа.';
    }

    if (isRiskProduct) {
      return 'Отчёт формируется в течение 40-60 минут (в нерабочее время сроки могут быть увеличены)';
    }

    return 'Отчет готовится от 30 до 1.5 часов (в нерабочее время сроки могут быть увеличены)';
  }

  // methods
  openInfoDialog() {
    this.$dialog.open({
      component: ProductInfoDialog,
      props: {
        product: this.checkedStatus === 2 ? this.item.fast : this.item.default,
        onCheckout: this.payRisk,
      },
    });
  }

  toggleSelect(isFastProduct: boolean) {
    const selected: UUID[] = this.selectedProducts.slice();

    const defaultProductIndex = selected.indexOf(this.item.default.product_id);
    if (defaultProductIndex >= 0) {
      selected.splice(defaultProductIndex, 1);
    }

    const fastProductIndex = selected.indexOf(this.item.fast.product_id);
    if (fastProductIndex >= 0) {
      selected.splice(fastProductIndex, 1);
    }

    if (isFastProduct) {
      if (this.checkedStatus === 2) {
        selected.push(this.item.default.product_id);
      } else {
        selected.push(this.item.fast.product_id);
      }
    } else {
      if (this.checkedStatus < 1) {
        selected.push(this.item.default.product_id);
      }
    }

    this.$emit('change', selected);
  }

  payRisk(product: Product) {
    this.selectedProducts = [];
    this.selectedProducts.push(product.product_id);
    this.$emit('change', this.selectedProducts);
    this.$emit('checkout');
  }

  toggleInsurance() {
    console.log(this.item);
  }
}
