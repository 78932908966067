import { Component, Vue } from 'vue-property-decorator';
import humanInterval from 'human-interval';
import includes from 'lodash/includes';

export const DEFAULT_EXECUTION_TIME_IN_MILLISECONDS: SimpleObject<number> = {
  EgrnRightListFast: humanInterval('5 minutes'),
  EgrnRightList: humanInterval('30 minutes'),
  EgrnObjectFast: humanInterval('5 minutes'),
  EgrnObject: humanInterval('30 minutes'),
  RiskAssessment: humanInterval('1 hours'),
  RiskAssessmentFast: humanInterval('50 minutes'),
  RiskAssessmentAdvanced: humanInterval('2 days'),
  RiskAssessmentV2: humanInterval('1 hours'),
  RiskAssessmentFastV2: humanInterval('15 minutes'),
};

@Component({
  props: {
    item: Object,
  },
})
export default class ProductItemBase extends Vue {
  item: OfficialProductMapItem;
  selectedProducts: UUID[];

  // computed
  get checkedStatus(): number {
    if (includes(this.selectedProducts, this.item.default.product_id)) {
      return 1;
    }

    if (this.item.fast && includes(this.selectedProducts, this.item.fast.product_id)) {
      return 2;
    }

    return 0;
  }

  get executionTime(): number {
    let productName = this.item.default.product_name;
    if (this.checkedStatus === 2) {
      productName = this.item.fast.product_name;
    }

    const defaultAvgTime = DEFAULT_EXECUTION_TIME_IN_MILLISECONDS[productName];
    const realExecutionAvgTime = defaultAvgTime;

    // const executionTime = this.$store.getters['details/executionTime']({
    //   product_name: this.product.product_name,
    // });

    // if (!this.isFastProduct && executionTime && executionTime.avg && executionTime.avg < defaultAvgTime) {
    //   realExecutionAvgTime = executionTime.avg;
    // }

    return Math.min(defaultAvgTime, realExecutionAvgTime);
  }

  get executionTimeString(): string {
    if (this.executionTime > humanInterval('1 hour')) {
      return `от ${this.millisecondsToHours(this.executionTime)} ч.`;
    }

    return `от ${this.millisecondsToMinutes(this.executionTime)} мин.`;
  }

  // lifecycle

  // methods
  private millisecondsToMinutes(milliseconds: number) {
    return milliseconds / 1000 / 60;
  }

  private millisecondsToHours(milliseconds: number) {
    return this.millisecondsToMinutes(milliseconds) / 60;
  }
}
