import { RealtyCloudApi } from 'shared/api/api';

export interface ApiAdminTariffMethods {
  /** Создаём новый тариф */
  Add<T = Api.Admin.Tariff.Response.Base>(data: Api.Admin.Tariff.Request.Create): Promise<T>;

  /** Обновление тарифа */
  Update<T = Api.Admin.Tariff.Response.Base>(data: Api.Admin.Tariff.Request.Update): Promise<T>;

  /** Удаление тарифа */
  Remove<T = any>(data: Api.Admin.Tariff.Request.Remove): Promise<T>;

  /** Список всех тарифов у патнёра */
  List<T = Admin.Tariff.Item[]>(partnerID: UUID): Promise<T>;
}

export function apiAdminTariffMethods(this: RealtyCloudApi): ApiAdminTariffMethods {
  return {
    Add: (data: Api.Admin.Tariff.Request.Create) => {
      return this.request({
        url: '/admin/partner/tariff/add',
        method: 'POST',
        data,
      });
    },

    Update: (data: Api.Admin.Tariff.Request.Update) => {
      return this.request({
        url: '/admin/partner/tariff/update',
        method: 'PUT',
        data,
      });
    },

    Remove: (data) => {
      return this.request({
        url: '/admin/partner/tariff/remove',
        method: 'DELETE',
        data,
      });
    },

    List: (partnerID: UUID) => {
      return this.request({
        url: '/admin/partner/tariff/list_by_partner_id',
        params: { partnerID },
      });
    },
  };
}
