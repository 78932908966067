






































































































































import Component, { mixins } from 'vue-class-component';
import { saveAs } from 'file-saver';
import { AxiosRequestConfig } from 'axios';
import includes from 'lodash/includes';
import addMilliseconds from 'date-fns/add_milliseconds';
import distanceInWords from 'date-fns/distance_in_words';
import LocStorage from '@/core/utils/localStorage';
import ruLocale from 'date-fns/locale/ru';

import AppReviewDialog from '@/components/layout/Dialogs/ReviewDialog.vue';
import AppMobilePhoneProveDialog from 'shared/components/layout/MobilePhoneProveDialog.vue';
import AppRiskForm from '@/views/ObjectDetail/components/RiskForm.vue';

import ProductItemBase from 'shared/components/Product/Item/ProductItemBase';

@Component({
  name: 'AppPurchasedProductItem',
  components: { AppRiskForm },

  props: {
    item: Object,
    objectKey: String,
  },
})
export default class PurchasedProductItem extends mixins(ProductItemBase) {
  // props
  objectKey: string;

  // computed
  get iconStatus(): string {
    return this.isCompleted ? '#icon-checkmark-circle' : '#icon-time';
  }

  get isCompleted(): boolean {
    return this.item.default.status === 'done';
  }

  get isRiskProduct(): boolean {
    return this.item.default.product_name.indexOf('RiskAssessment') !== -1;
  }

  get formWasSended(): boolean {
    if (this.item.default.status === 'manual') {
      return LocStorage.getItem('risk', []).some(
        (orderItemId: string) => orderItemId === this.item.default.order_item_id,
      );
    }

    return !includes([ 'waiting_for_owners', 'manual' ], this.item.default.status);
  }

  get remainingTimeMessage() {
    let requestDate: Date;

    if (!this.item.default.created_date) {
      requestDate = new Date();
    } else {
      requestDate = new Date(this.item.default.created_date);
    }

    const expectedDateOfCompletion = addMilliseconds(requestDate, this.executionTime);
    const now = new Date();

    console.log(this.executionTime, expectedDateOfCompletion);

    if (now > expectedDateOfCompletion) {
      return 'Росреестр перегружен, время выполнения увеличено';
    }

    return distanceInWords(now, expectedDateOfCompletion, { locale: ruLocale });
  }

  // methods
  async download(url: string, fileType: string, params?: AxiosRequestConfig['params']) {
    const fileName = `${this.item.default.product_name_ru}`;
    let response: Blob;

    try {
      response = await this.$api.raw.files.GetFileBlob(url, params);
      await saveAs(response, `${fileName}.${fileType}`);

      if (!LocStorage.hasItem('review')) {
        this.$dialog.open({ component: AppReviewDialog });
      }
    } catch (error) {
      // this.$noty.error({ text: 'Произошла ошибка при скачивании, пожалуйста, попробуйте еще раз' });
      // Если не скачивается pdf, то для выписок пробуем скачать html

      const errorObj = await error.response.data.text();
      const text = JSON.parse(errorObj);
      if (includes(text.error, 'Требуется подтверждение телефона')) {
        this.showMobilePhoneProveDialog();
      } else {
        if (this.item.default.product_name === 'EgrnObject' || this.item.default.product_name === 'EgrnRightList' ||
        this.item.default.product_name === 'EgrnObjectFast' || this.item.default.product_name === 'EgrnRightListFast') {
          this.downloadHtml(fileName);
        } else {
          this.$noty.error({ text: 'Произошла ошибка при скачивании, пожалуйста, попробуйте еще раз' });
        }
      }
    }
  }

  showMobilePhoneProveDialog() {
    this.$dialog.open({
      component: AppMobilePhoneProveDialog,
      props: {
        onApproved: this.download,
      },
    });
  }

  async downloadHtml(fileName: string) {
    let response: Blob;
    const url = this.item.default.html_link;
    try {
      response = await this.$api.raw.files.GetFileBlob(url);
    } catch (error) {
      this.$noty.error({ text: 'Произошла ошибка при скачивании, пожалуйста, попробуйте еще раз' });
      return this.$api.HttpError(error);
    }

    saveAs(response, `${fileName}.html`);
  }

  getReadyDate(itemStatuses: any[]) {
    return itemStatuses[itemStatuses.length - 1].created_at;
  }
}
