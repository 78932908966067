import removeTypesNamespace from 'shared/utils/storeNamespaceHelper';

export const AuthTypes = {
  namespace: 'auth',

  getters: {
    IS_ADMIN: 'auth/isAdmin',
    IS_ROLE: 'auth/isRole',
    HAS_ANY_ROLE: 'auth/hasAnyRole',
    IS_RC: 'auth/isRc',
    IS_FIZCHECK: 'auth/isFizcheck',
    IS_FIZCHECK_RU: 'auth/isFizcheckRu',
    IS_FIZCHECK_PRO: 'auth/isFizcheckPro',
    RESOURCE_NAME: 'auth/resourceName',
    LOGO_PERMISSION: 'auth/logoPermission',
    GET_SETTING: 'auth/getSetting',
    GET_PROCESSING_ORDERS: 'auth/getProcessingOrders',
    GET_PROCESSING_ORDER: 'auth/getProcessingOrder',
  },

  actions: {
    LOGIN: 'auth/login',
    LOGOUT: 'auth/logout',
    REGISTRATION: 'auth/registration',
    GET_USER_INFO: 'auth/getUserInfo',
    CHECK_TOKEN: 'auth/checkToken',
    CHECK_PARTNER_ID: 'auth/checkPartnerId',
    CHECK_URL_QUERY: 'auth/checkUrlQuery',
    RECOVER_ACCOUNT: 'auth/recoverAccount',
    ADD_PAYER: 'auth/addPayer',
    UPDATE_PAYER_INFO: 'auth/updatePayerInfo',
    GET_PAYERS: 'auth/getPayers',
    REMOVE_PAYER: 'auth/removePayer',
    GET_USER_SETTINGS: 'auth/getUserSettings',
    UPDATE_USER_SETTINGS: 'auth/updateUserSettings',
    SAVE_ADMINTAD_UID: 'auth/saveAdmintadUid',
    CHECK_ADMINTAD_UID: 'auth/checkAdmintadUid',
  },

  mutations: {
    SET_USER: 'auth/setUser',
    SET_USER_ID: 'auth/setUserId',
    SET_TOKEN: 'auth/setToken',
    SET_BALANCE: 'auth/setBalance',
    SET_PARTNER_ID: 'auth/setPartnerId',
    SET_UTM_CAMPAIGN: 'auth/setUtmCampaign',
    SET_UTM_MEDIUM: 'auth/setUtmMedium',
    SET_UTM_COUPON: 'auth/setUtmCoupon',
    SET_UTM_SOURCE: 'auth/setUtmSource',
    SET_ADMITAD_UID: 'auth/setAdmitadUid',
    SET_UTM_CONTENT: 'auth/setUtmContent',
    SET_UTM_TERM: 'auth/setUtmTerm',
    SET_PAYERS: 'auth/setPayers',
    SET_ACTION_PAY_IN_COOKIES: 'auth/setActionPayInCookies',
    SET_DOMAIN_URL: 'auth/setDomainUrl',
    SET_USER_SETTINGS: 'auth/setUserSettings',
  },
};

export const _AuthTypes = removeTypesNamespace<typeof AuthTypes>('auth/', AuthTypes);
