var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h5", { staticClass: "text-center mb-3" }, [
        _vm._v("\n    Активировать скидочный купон\n  ")
      ]),
      _c("app-activate-promo-code-form", {
        attrs: {
          "on-login": _vm.onLogin,
          user: _vm.user,
          "from-dashboard": _vm.fromDashboard,
          "is-modal": ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }