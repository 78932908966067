
























import Vue from 'vue';

export default Vue.extend({
  name: 'AppDialogClose',

  inject: [ 'Dialog' ],

  methods: {
    close() {
      this.$dialog.close((this as any).Dialog.index);
    },
  },
});
