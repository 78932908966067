var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _vm.showSidebar || _vm.user
        ? _c(
            "div",
            { staticClass: "col d-none d-lg-block sidebar" },
            [
              _c("app-user-menu", { attrs: { "show-widget": _vm.showWidget } }),
              _c("ins", {
                staticClass: "adsbygoogle",
                staticStyle: { display: "block", height: "200px" },
                attrs: {
                  "data-ad-client": "ca-pub-4318090292618330",
                  "data-ad-slot": "5717968913",
                  "data-ad-format": "auto",
                  "data-full-width-responsive": "true"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "col container_responsive",
          class: { "p-0": !_vm.user && !_vm.showSidebar }
        },
        [_c("router-view")],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }