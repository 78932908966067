type ListenerCallback = (event?: MouseEvent) => boolean | void;

let listeners: ListenerCallback[] = [];

function onMouseout(event: MouseEvent) {
  const relatedTarget = event.relatedTarget;

  if (relatedTarget === null && event.clientY <= 35) {
    listeners = listeners.filter((cb) => {
      const destroyAfterCall = cb(event);
      return !destroyAfterCall;
    });
  }
}

document.addEventListener('mouseout', onMouseout);

function on(callback: ListenerCallback) {
  listeners.push(callback);
}

function off(callback: ListenerCallback) {
  listeners = listeners.filter((cb) => cb !== callback);
}

const mousePageLeave = { on, off };

export default mousePageLeave;
