import Component, { mixins } from 'vue-class-component';
// import Axios from 'axios';
// import pick from 'lodash/pick';
import { Item } from 'photoswipe';
import BaseOrderMixin from '../BaseOrder.mixin';
import { Validators } from '@/core/utils/validator';
// import { calcDistanceByCoords } from '@/core/utils/calcDistanceByCoordinates';
// import yaMapsApi from '@/api/yandex-map';
// import groupBy from 'lodash/groupBy';
// import last from 'lodash/last';
import keyBy from 'lodash/keyBy';
// import orderBy from 'lodash/orderBy';
import omit from 'lodash/omit';

import AppPropertyImage from '@/views/ObjectDetail/components/PropertyImage.vue';
import propertyConstants from '@/core/utils/constants/property';

// const exampleItems: Item[] = [];
// for (let page = 1; page <= 9; page++) {
//   exampleItems.push({
//     src: `/images/assessment_example/${page}.jpg`,
//     w: 1654,
//     h: 2339,
//   });
// }

// let response: EstimateResponse;
// let samples: Sample[];

@Component({
  name: 'AppAssessment',

  components: { AppPropertyImage },

  filters: {
    formatMeter: (value: number) => {
      return value >= 1000 ? `${(value / 1000).toFixed(2)} км` : `${value} м`;
    },

    toPercent: (value: number) => {
      return (value * 100).toFixed(1);
    },
  },
})
export default class AssessmentComponent extends mixins(BaseOrderMixin) {
  // props
  info: RealtyObject;

  // data()
  productName: string = 'CostAssessment';
  objectTypes: ByKey<PropertyObjectType> = keyBy(propertyConstants.OBJECT_TYPES, 'id');
  buildingTypes: ByKey<PropertyBuildingType> = keyBy(propertyConstants.BUILDING_TYPES, 'id');
  stateOfApartments: ByKey<StateOfApartmentItem> = keyBy(
    propertyConstants.STATE_OF_APARTMENTS,
    'id',
  );
  model: EstimateUserModel = {
    area: null,
    level: null,
    levels: null,
    rooms: null,
    address: null,
    object_type: null,
    latitude: null,
    longitude: null,
    built_at: null,
    property_object_type_id: null,
    state_of_apartment: null,
    apartment_condition: 3,
  };

  // computed

  // lifecycle
  async created() {
    if (this.info.Address) {
      this.model.address = this.info.Address;
    }

    if (this.info.Area || this.info.AreaOKC) {
      this.model.area = parseFloat(this.info.Area || (this.info.AreaOKC as any)) as any;
    }

    if (this.info.Level) {
      this.model.level = parseInt(this.info.Level as any);
    }

    const hInfo = this.info.HouseInfo;
    if (hInfo) {
      if (hInfo.levels > 0) {
        this.model.levels = parseInt(hInfo.levels as any) as any;
      }
    }
  }

  beforeDestroy() {
    this.$store.commit('order/clearData');
  }

  // methods
  showExamples() {
    const exampleItems: Item[] = [
      {
        src: '/images/estimate/estimate-1.png',
        w: 2342,
        h: 3312,
      },
      {
        src: '/images/estimate/estimate-2.png',
        w: 2342,
        h: 3312,
      },
      {
        src: '/images/estimate/estimate-3.png',
        w: 2342,
        h: 3312,
      },
    ];
    this.$photoSwipe(exampleItems);
  }

  onSubmit() {
    let body: EstimateModel = { ...this.model };

    if (!body.built_at) {
      body = omit(body, 'built_at') as EstimateModel;
    }

    this.$store.commit('order/setData', {
      [this.product.product_id]: {
        comment: JSON.stringify(body),
      },
    });
    this.checkout('pay_assessment');
  }

  validationRules(): ValidationConfig {
    return {
      area: [ Validators.required ],
      level: [ Validators.required ],
      levels: [ Validators.required ],
      rooms: [ Validators.required ],
      address: [ Validators.required ],
      object_type: [ Validators.required ],
      property_object_type_id: [ Validators.required ],
    };
  }
}
