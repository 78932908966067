






import Vue from 'vue';
import AppPrivacy from 'shared/components/Static/Privacy.vue';
export default Vue.extend({
  components: { AppPrivacy },
});
