import { Item } from 'photoswipe';

declare interface PswpItem extends Item {
  title?: string;
}

export const RISK_EXAMPLES: PswpItem[] = [];

for (let i = 1; i <= 17; i++) {
  let width = 1654;
  let height = 2339;

  if (i >= 5 && i <= 9) {
    [ width, height ] = [ height, width ];
  }

  const item: PswpItem = {
    src: `/images/risk/risk_report/${i}.jpg`,
    w: width,
    h: height,
  };

  RISK_EXAMPLES.push(item);
}

export const EGRN_EXAMPLES: PswpItem[] = [
  {
    src: '/images/statement_example/EGRN-page1.png',
    w: 1260,
    h: 569,
    title: 'Отчет из ЕГРН об объекте недвижимости. Страница - 1',
  },
  {
    src: '/images/statement_example/EGRN-page2.png',
    w: 1262,
    h: 611,
    title: 'Отчет из ЕГРН об объекте недвижимости. Страница - 2',
  },
];

export const RIGHT_LIST_EXAMPLES: PswpItem[] = [
  {
    src: '/images/statement_example/RightList.png',
    w: 594,
    h: 673,
    title: 'Отчет о переходе прав на объект недвижимости',
  },
];

export const EXAMPLE_TYPES: SimpleObject<PswpItem[]> = {
  risk: RISK_EXAMPLES,
  egrn: EGRN_EXAMPLES,
  rightList: RIGHT_LIST_EXAMPLES,
};
