































import Vue from 'vue';
import Component from 'vue-class-component';

import { Validators } from '@/core/utils/validator';

@Component({
  popupOptions: {
    size: 'xxs',
  },
})
export default class FillBalance extends Vue {
  // props
  // props end
  // data()

  // computed
  model = {
    amount: '',
  };
  get product(): Product {
    const product = this.$store.getters.getProduct({
      product_name: 'AccountBalanceReplenishmentCustom',
    } as Product);

    return product;
  }

  // lifecycle hooks
  created() {
    this.resetState();
  }

  beforeDestroy() {
    this.resetState();
  }

  resetState() {
    this.$store.commit('order/changeItem', []);
    this.$store.commit('order/clearData');
  }

  async submit() {
    const redirectUrl = `${location.origin}?showBalanceInfo=true`;

    this.$store.commit('order/changeItem', [ this.product.product_id ]);
    this.$store.commit('order/setObjectKey', 'balance');
    this.$store.commit('order/setData', {
      [this.product.product_id]: {
        amount: parseInt(this.model.amount, 10),
      },
    });
    await this.$store.dispatch('order/send', {
      redirect_url: redirectUrl,
    } as OrderOptions);
  }

  validationConfig(): ValidationConfig {
    return {
      amount: [ Validators.positiveNumber ],
    };
  }
}
