





















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppPollItem',
  props: {
    item: Object,
    active: Boolean,
  },
})
export default class AppPollItem extends Vue {
  // props
  item: PollItem;
  active: boolean;

  // data()
  selectedAnswer: number = -1;

  // methods
  select(index: number, answer: AnswerItem) {
    const emitData: AnswerSelect = {
      question: this.item.question,
      answer,
    };

    this.selectedAnswer = index;
    this.$emit('selected', emitData);
  }
}
