var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      class: [
        _vm.$style["custom-select"],
        _vm.size && _vm.$style["custom-select-" + _vm.size]
      ],
      domProps: { value: _vm.nativeInputValue },
      on: { change: _vm.onChange }
    },
    _vm._l(_vm.items, function(v, key) {
      return _c(
        "option",
        { key: key, domProps: { value: key } },
        [
          _vm._t("default", [_vm._v("\n      " + _vm._s(v) + "\n    ")], {
            value: v,
            keyValue: key
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }