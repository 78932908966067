















































import Vue from 'vue';
import Component from 'vue-class-component';
import AppDropzone from '../Dropzone/Dropzone.vue';

interface PreviewItem {
  base64?: string;
  width?: number;
  height?: number;
}

interface ImageItem extends PreviewItem {
  url: string;
  id: string;
}

@Component({
  components: { AppDropzone },
  props: {
    autoUpload: {
      type: Boolean,
      default: true,
    },
    images: {
      type: Array,
      default: (): ImageItem[] => [],
    },
  },
})
export default class ImageUploader extends Vue {
  // props
  autoUpload!: boolean;
  images!: ImageItem[];

  // props end
  // data()

  // lifecycle hooks
  // methods
  onClick() {
    const input = this.$refs.fileInput as HTMLInputElement;
    input.click();
  }

  async fileInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const files: FileList | null = target.files;
    // const images: ImageItem[] = [];

    this.onFileAdded(files);
  }

  async onFileAdded(files: FileList | null) {
    if (!files) {
      return;
    }

    for (let i = 0, len = files.length; i < len; i++) {
      const file = files[i];
      const uploadResponse = await this.uploadImage(file);
      const previewItem: PreviewItem = await this.createPreview(file);

      this.$emit(
        'update:images',
        this.images.concat({
          ...uploadResponse,
          ...previewItem,
        }),
      );
    }

    // this.$emit('fileAdded', files);
    (this.$refs.fileInput as HTMLInputElement).value = '';
  }

  createPreview(file: File): Promise<PreviewItem> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const img: HTMLImageElement = document.createElement('img');

        img.onload = () => {
          resolve({
            width: img.naturalWidth,
            height: img.naturalHeight,
            base64: reader.result as string,
          });
        };

        img.src = reader.result as string;
      };

      reader.readAsDataURL(file);
    });
  }

  async uploadImage(file: File): Promise<ImageItem> {
    const form = new FormData();
    form.append('file', file);
    const { temp_file_id: id } = await this.$api.upload.Attachment(form);

    return {
      url: `https://download.realtycloud.ru/static/${id}`,
      id,
    };
  }

  removePreview(index: number) {
    const images = [ ...this.images ];

    images.splice(index, 1);
    this.$emit('update:images', images);
  }

  openPreview(index: number) {
    const item = this.images[index];

    this.$photoSwipe([
      {
        src: item.base64,
        w: item.width,
        h: item.height,
      },
    ]);
  }
}
