var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h5", { staticClass: "text-center mb-2" }, [
        _vm._v("\n    Подтвердите телефон\n  ")
      ]),
      _c("div", { staticClass: "font-weight-500 mb-3" }, [
        _vm._v(
          "\n    Чтобы получить заказы, оформленные без оплаты, необходимо подтвердить номер телефона\n  "
        )
      ]),
      _c(
        "app-form",
        {
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.model,
            rules: _vm.validationRules
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    { attrs: { type: "submit", loading: loading, block: "" } },
                    [
                      !_vm.phoneEntered
                        ? [_vm._v("\n          Получить код\n        ")]
                        : [_vm._v("\n          Подтвердить\n        ")]
                    ],
                    2
                  )
                ]
              }
            }
          ])
        },
        [
          !_vm.phoneEntered
            ? _c(
                "app-form-group",
                { attrs: { "model-name": "phone" } },
                [
                  _c("app-phone-number-input", {
                    attrs: {
                      placeholder: "Введите телефон, без 8",
                      size: "lg"
                    },
                    model: {
                      value: _vm.model.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "phone", $$v)
                      },
                      expression: "model.phone"
                    }
                  })
                ],
                1
              )
            : _c(
                "app-form-group",
                { attrs: { "model-name": "smsCode" } },
                [
                  _c("app-input", {
                    attrs: {
                      type: "number",
                      placeholder: "Введите смс-код",
                      size: "lg"
                    },
                    model: {
                      value: _vm.model.smsCode,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "smsCode", $$v)
                      },
                      expression: "model.smsCode"
                    }
                  })
                ],
                1
              ),
          _vm.error
            ? _c(
                "div",
                { staticClass: "alert alert-danger small text-center" },
                [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }