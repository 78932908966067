



















import Vue from 'vue';
import has from 'lodash/has';
import AppDialog from './Dialog.vue';
import DialogEventBus from './dialogEventBus';
import registeredDialogs from './globalRegisteredDialogs';
import { DialogOpenOptions } from './dialog-plugin';

let clickStart: MouseEvent;
let clickStartIsContent: boolean;
let isContent: boolean;

export default Vue.extend({
  name: 'AppDialogRoot',

  components: {
    AppDialog,
  },

  // props: {
  //   dialogItems: Array,
  // },

  data() {
    return {
      dialogs: [] as (string | DialogOpenOptions)[],
    };
  },

  mounted() {
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 27) {
        DialogEventBus.emit('close');
      }
    });

    DialogEventBus.on('open', (dialog: DialogOpenOptions | string) => {
      if (typeof dialog === 'string' && registeredDialogs[dialog]) {
        dialog = { component: registeredDialogs[dialog] };
      }

      this.dialogs.push(dialog);

      if (this.dialogs.length === 1) {
        this.toggleBodyOffset(true);
      }
    });

    DialogEventBus.on('close', (index: number) => {
      if (typeof index !== 'number') {
        index = this.dialogs.length - 1;
      }

      this.dialogs.splice(index, 1);

      if (!this.dialogs.length) {
        setTimeout(() => this.toggleBodyOffset(false), 320);
      }
    });
  },

  methods: {
    // _getPopupClassName(component) {
    //   return component.options.popupName || 'popup_default';
    // },

    getAttributes(component: any) {
      let popupOptions = component.popupOptions || {};

      if (component && component.options && component.options.popupOptions) {
        popupOptions = component.options.popupOptions;
      }

      if (has(component, '_Ctor[0].options.popupOptions')) {
        popupOptions = component._Ctor[0].popupOptions;
      }

      return {
        class: popupOptions.name || 'popup_default',
        'data-size': popupOptions.size,
        'data-disable-backdrop': popupOptions.disableBackdrop,
      };
    },

    outsideClickStart(event: MouseEvent) {
      clickStart = event;
      isContent = (event.target as any).closest('.popup__content') != null;
    },

    outsideClick(event: MouseEvent) {
      const target = event.target as HTMLElement;

      if (
        !target.closest('.popup__content') &&
        clickStart.target === target &&
        !clickStartIsContent &&
        !isContent
      ) {
        if (target.classList.contains('popup') && target.hasAttribute('data-disable-backdrop')) {
          return;
        }

        DialogEventBus.emit('close');
      }
    },

    toggleBodyOffset(isOpen: boolean) {
      const body = document.body;
      const scrollWidth = window.innerWidth - body.offsetWidth;
      body.style.overflow = isOpen ? 'hidden' : '';
      body.style.paddingRight = isOpen ? `${scrollWidth}px` : '';
      body.classList.toggle('popup-open');
    },
  },
});
