var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "object-detail-info wrapper-padding_lg" }, [
    _c("div", { staticClass: "object-detail-info__wrap" }, [
      _c("div", { staticClass: "object-detail-info__body" }, [
        _vm.objectInfo.ObjectType
          ? _c("div", { staticClass: "object-detail-info__type" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.objectInfo.ObjectType) + "\n      "
              )
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "object-detail-info__number",
            class: {
              "object-detail-info__number_empty": !_vm.objectInfo.Number
            }
          },
          [
            _vm.objectInfo.Number
              ? [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.objectInfo.Number) +
                      "\n        "
                  )
                ]
              : [
                  _vm._v(
                    "\n          Кадастровый номер: не указан\n          "
                  ),
                  _vm.hasManualProducts
                    ? _c(
                        "span",
                        {
                          staticClass: "small btn-link text-reset",
                          on: {
                            click: function($event) {
                              return _vm.$emit("manual-update")
                            }
                          }
                        },
                        [_vm._v("\n            [указать]\n          ")]
                      )
                    : _vm._e()
                ]
          ],
          2
        ),
        _c("div", { staticClass: "object-detail-info__address" }, [
          _vm._v("\n        " + _vm._s(_vm.objectInfo.Address) + "\n      ")
        ]),
        _vm.infoLoading
          ? _c(
              "div",
              {
                staticClass:
                  "object-detail-info__props object-props placeholders"
              },
              _vm._l(3, function(n) {
                return _c("app-object-prop", { key: n })
              }),
              1
            )
          : _c(
              "div",
              { staticClass: "object-detail-info__props object-props" },
              [
                _vm.objectInfo.Area || _vm.objectInfo.AreaOKC
                  ? _c(
                      "app-object-prop",
                      {
                        attrs: { title: "Площадь", icon: "#icon-square-meter" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("float")(
                                _vm.objectInfo.Area || _vm.objectInfo.AreaOKC
                              )
                            ) +
                            " м"
                        ),
                        _c("sup", [_vm._v("2")])
                      ]
                    )
                  : _vm._e(),
                _vm.objectInfo.Level
                  ? _c(
                      "app-object-prop",
                      { attrs: { title: "Этаж" } },
                      [
                        _c("template", { slot: "icon" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 480 480"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M472 464h-48V200c0-4.418-3.582-8-8-8H304V8c0-4.418-3.582-8-8-8H88c-4.418 0-8 3.582-8 8v456H8c-4.418 0-8 3.582-8 8s3.582 8 8 8h464c4.418 0 8-3.582 8-8s-3.582-8-8-8zm-304 0h-32v-72c0-4.418 3.582-8 8-8h16c4.418 0 8 3.582 8 8v72zm32-264v264h-16v-72c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v72H96V16h192v176h-80c-4.418 0-8 3.582-8 8zm208 264H216V208h192v256z"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M256 112h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8h48c4.418 0 8-3.582 8-8v-48c0-4.418-3.582-8-8-8zm-8 48h-32v-32h32v32zM256 32h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8h48c4.418 0 8-3.582 8-8V40c0-4.418-3.582-8-8-8zm-8 48h-32V48h32v32zM176 112h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8h48c4.418 0 8-3.582 8-8v-48c0-4.418-3.582-8-8-8zm-8 48h-32v-32h32v32zM176 32h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8h48c4.418 0 8-3.582 8-8V40c0-4.418-3.582-8-8-8zm-8 48h-32V48h32v32zM176 192h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8h48c4.418 0 8-3.582 8-8v-48c0-4.418-3.582-8-8-8zm-8 48h-32v-32h32v32zM176 272h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8h48c4.418 0 8-3.582 8-8v-48c0-4.418-3.582-8-8-8zm-8 48h-32v-32h32v32zM328 368h48c4.418 0 8-3.582 8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8zm8-48h32v32h-32v-32zM328 288h48c4.418 0 8-3.582 8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8zm8-48h32v32h-32v-32zM328 448h48c4.418 0 8-3.582 8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8zm8-48h32v32h-32v-32zM248 368h48c4.418 0 8-3.582 8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8zm8-48h32v32h-32v-32zM248 288h48c4.418 0 8-3.582 8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8zm8-48h32v32h-32v-32zM248 448h48c4.418 0 8-3.582 8-8v-48c0-4.418-3.582-8-8-8h-48c-4.418 0-8 3.582-8 8v48c0 4.418 3.582 8 8 8zm8-48h32v32h-32v-32z"
                                }
                              })
                            ]
                          )
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.objectInfo.Level) +
                            "\n          "
                        ),
                        _vm.objectInfo.HouseInfo &&
                        _vm.objectInfo.HouseInfo.levels > 0
                          ? _c("span", [
                              _vm._v(
                                "\n            из " +
                                  _vm._s(_vm.objectInfo.HouseInfo.levels) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.objectInfo.YearBuilt
                  ? _c("app-object-prop", {
                      attrs: {
                        title: "Дата учёта",
                        icon: "#icon-calendar",
                        value: _vm.objectInfo.YearBuilt
                      }
                    })
                  : _vm._e()
              ],
              1
            )
      ]),
      _c(
        "svg",
        {
          staticClass: "object-detail-info__icon",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 42.93 38.48"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M38.22 3.11h-29a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h29a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5zM2.9 34.29h-.06a.5.5 0 0 1-.43-.56L6.29 4.62a.5.5 0 0 1 .56-.43.5.5 0 0 1 .43.56L3.4 33.86a.51.51 0 0 1-.5.43zM33.78 36.37H4.71a.5.5 0 0 1-.5-.5.51.51 0 0 1 .5-.5h29.07a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5zM36.17 34.29h-.07a.5.5 0 0 1-.43-.56l3.88-29.11a.5.5 0 0 1 1 .13l-3.89 29.11a.5.5 0 0 1-.49.43z"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M35.89 38.48a2.61 2.61 0 1 1 2.61-2.61 2.61 2.61 0 0 1-2.61 2.61zm0-4.21a1.61 1.61 0 1 0 1.61 1.6 1.61 1.61 0 0 0-1.61-1.6zM2.61 38.48a2.61 2.61 0 1 1 2.6-2.61 2.61 2.61 0 0 1-2.6 2.61zm0-4.21a1.61 1.61 0 1 0 1.6 1.6 1.61 1.61 0 0 0-1.6-1.6zM40.33 5.21a2.61 2.61 0 1 1 2.6-2.6 2.61 2.61 0 0 1-2.6 2.6zm0-4.21a1.61 1.61 0 1 0 1.6 1.61A1.61 1.61 0 0 0 40.33 1zM7.06 5.21a2.61 2.61 0 1 1 2.61-2.6 2.61 2.61 0 0 1-2.61 2.6zm0-4.21a1.61 1.61 0 1 0 1.61 1.61A1.61 1.61 0 0 0 7.06 1zM39.21 11.52a.51.51 0 0 1-.36-.15L30.44 3a.5.5 0 0 1 .71-.71l8.41 8.42a.5.5 0 0 1-.35.85zM37.67 23a.52.52 0 0 1-.35-.14L17.41 3a.5.5 0 0 1 .71-.71L38 22.16a.51.51 0 0 1 0 .71.54.54 0 0 1-.33.13z"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M35.89 34.26a.47.47 0 0 1-.35-.15L6.47 5a.5.5 0 0 1 0-.71.5.5 0 0 1 .7 0L36.24 33.4a.5.5 0 0 1 0 .71.47.47 0 0 1-.35.15zM25 36.37a.5.5 0 0 1-.35-.14L4.9 16.5a.5.5 0 0 1 .71-.71l19.72 19.73a.5.5 0 0 1-.35.85zM12 36.37a.54.54 0 0 1-.35-.14L3.37 28a.5.5 0 0 1 .71-.71l8.23 8.23a.51.51 0 0 1 0 .71.54.54 0 0 1-.31.14z"
            }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }