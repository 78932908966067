var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.input,
        _vm.$slots.default && _vm.$style["input_icon-" + _vm.iconPosition]
      ]
    },
    [
      _c(
        "input",
        _vm._g(
          {
            class: [
              _vm.$style["form-control"],
              _vm.size && _vm.$style["form-control-" + _vm.size],
              _vm.error && _vm.$style["form-control_error"],
              _vm.inputClass
            ],
            style: { "text-transform": _vm.transform },
            attrs: {
              placeholder: _vm.placeholder,
              type: _vm.type,
              tabindex: _vm.tabindex,
              min: _vm.min,
              max: _vm.max,
              disabled: _vm.disabled
            },
            domProps: { value: _vm.nativeInputValue }
          },
          _vm.getEvents()
        )
      ),
      _vm.label
        ? _c("label", { class: [{ active: _vm.nativeInputValue }] }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e(),
      _vm.$slots.default
        ? _c(
            "div",
            {
              class: [
                _vm.$style["input__icon"],
                _vm.size && _vm.$style["input__icon_" + _vm.size]
              ]
            },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }