






























import Vue from 'vue';
import Component from 'vue-class-component';
import { Validators } from 'shared/utils/validator';

@Component({
  name: 'AppManualKadastrUpdateDialog',
  popupOptions: { size: 'xs' },
  props: {
    purchases: Array,
    b2b: {
      type: Boolean,
      default: false,
    },
  },
})
export default class ManualKadastrUpdate extends Vue {
  // props
  purchases: Product[];
  b2b: boolean;

  // data()
  model: SimpleObject<string> = {
    kadastrNumber: '',
  };

  // methods
  async onSubmit() {
    try {
      const requestHandlers: Promise<any>[] = this.purchases.map(({ order_item_id }) => {
        return this.$api.admin.ManualUpdate({
          kadastr_number: this.model.kadastrNumber,
          order_item_id,
        });
      });

      await Promise.all(requestHandlers);

      this.$noty.info({ text: 'Кадастровый номер успешно добавлен' });
      this.$dialog.close();
      /* Т.к. на b2b и egrn разные структуры адресных строк проверяем с какого именно сайта открывается попапа */
      let name = '';
      if (this.b2b) {
        name = 'object-objectKey';
      } else {
        name = 'object-detail';
      }
      this.$router.push({
        name,
        params: {
          objectKey: this.model.kadastrNumber,
        },
      });
    } catch (error) {
      this.$noty.error({ text: 'Произошла ошибка при добавлении' });
      this.$dialog.close();
      console.error(error);
    }
  }

  validationRules(): ValidationConfig {
    return {
      kadastrNumber: [ Validators.required ],
    };
  }
}
