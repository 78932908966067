












import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

@Component({
  name: 'AppDropdownMenu',
})
export default class DropdownMenu extends Vue {
  @Inject() Menu: DropdownProvider;

  handleClick() {
    if (this.Menu.closeOnSelect) {
      this.Menu.isActive = false;
    }
  }
}
