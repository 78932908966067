var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body product-info" },
    [
      _c("app-dialog-close"),
      _c("h5", { staticClass: "mb-3" }, [
        _vm._v("\n    " + _vm._s(_vm.productName) + "\n  ")
      ]),
      _c("p", [_vm._v(_vm._s(_vm.descriptionByProduct))]),
      _c(
        "ul",
        { staticClass: "product-info-list list-unstyled" },
        _vm._l(_vm.infoByProduct, function(infoText, i) {
          return _c("li", { key: i }, [
            _c("i", {
              staticClass: "fas fa-check-circle text-success align-middle mr-2"
            }),
            _c("span", { staticClass: "align-middle" }, [
              _vm._v("\n        " + _vm._s(infoText) + "\n      ")
            ]),
            /ФИО/.test(infoText)
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: {
                          placement: "top-end",
                          content: _vm.fioTipContent
                        },
                        expression:
                          "{\n          placement: 'top-end',\n          content: fioTipContent,\n        }"
                      }
                    ],
                    staticClass: "d-inline-block"
                  },
                  [_c("i", { staticClass: "fas fa-info-circle text-muted" })]
                )
              : _vm._e()
          ])
        }),
        0
      ),
      _vm.isRiskProduct
        ? _c("ul", { staticClass: "list-unstyled font-weight-500" }, [
            _c("li", { staticClass: "font-weight-400" }, [
              _vm._v(
                "\n      Проанализировав сведения об объекте недвижимости и собственниках\n    "
              )
            ]),
            _vm._m(0),
            _vm._m(1)
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "product-info-meta d-flex justify-content-between" },
        [
          _c("div", { staticClass: "product-info-meta__item" }, [
            _vm._m(2),
            _c(
              "div",
              {
                staticClass: "product-info-meta__value",
                class: {
                  "product-info-meta__value_discount": _vm.coupon != null
                }
              },
              [
                _vm.coupon != null
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.product.product_price - _vm.coupon.discount
                          ) +
                          " руб.\n        "
                      )
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "product-info-meta__price" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.product.product_price) +
                      " руб.\n        "
                  )
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "product-info-meta__item" }, [
            _vm._m(3),
            _c("div", { staticClass: "product-info-meta__value" }, [
              _vm._v("\n        " + _vm._s(_vm.time) + "\n        "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: {
                        placement: "top-end",
                        content: _vm.tooltipContent
                      },
                      expression:
                        "{\n            placement: 'top-end',\n            content: tooltipContent,\n          }"
                    }
                  ],
                  staticClass: "d-inline-block"
                },
                [_c("i", { staticClass: "fas fa-info-circle text-info" })]
              )
            ])
          ]),
          _c("div", { staticClass: "product-info-meta__item" }, [
            _vm._m(4),
            _c(
              "div",
              { staticClass: "product-info-meta__value" },
              [
                _vm.isRiskProduct
                  ? [
                      _vm._v("\n          PDF документ\n          "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/object/demo",
                            target: "_blank",
                            rel: "noopener noreferrer"
                          }
                        },
                        [
                          _c(
                            "app-button",
                            {
                              staticClass: "p-0",
                              attrs: { variant: "link", size: "sm" }
                            },
                            [_vm._v("\n              Пример\n            ")]
                          )
                        ],
                        1
                      )
                    ]
                  : [_vm._v("\n          Электронный документ\n        ")]
              ],
              2
            )
          ])
        ]
      ),
      _c("div", { staticClass: "product-info-stamp" }, [
        _c(
          "div",
          { staticClass: "product-info-stamp__icon" },
          [
            _vm.isRiskProduct
              ? [
                  _c("div", { staticClass: "product-info-stamp__ofert py-2" }, [
                    _c("span", [
                      _vm._v(
                        "\n            *Для исключения скрытых рисков, необходимо добавить актуальные\n            данные о собственниках, которые\n            "
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: {
                                placement: "top-end",
                                content: _vm.tipContent
                              },
                              expression:
                                "{\n                placement: 'top-end',\n                content: tipContent,\n              }"
                            }
                          ],
                          staticClass: "link"
                        },
                        [_vm._v("\n              узнали\n            ")]
                      ),
                      _vm._v("\n            у продавца.\n          ")
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-sm-flex align-items-center" },
                    [
                      _c(
                        "app-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.checkout }
                        },
                        [
                          _vm._v("\n            Заказать отчет\n            "),
                          _vm.coupon != null
                            ? [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.product.product_price -
                                          _vm.coupon.discount
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            : [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.product.product_price
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("i", { staticClass: "far fa-check-circle mr-2 text-info" }),
      _vm._v(
        " Указываем на риски,\n      которые являются значимыми при совершении сделок\n    "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("i", { staticClass: "far fa-check-circle mr-2 text-info" }),
      _vm._v(
        "\n      Даём рекомендации по уменьшению возможных рисков при их наличии\n    "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-meta__label" }, [
      _c("i", { staticClass: "far fa-credit-card mr-2" }),
      _c("span", [_vm._v("Стоимость:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-meta__label" }, [
      _c("i", { staticClass: "fas fa-hourglass-start mr-2" }),
      _c("span", [_vm._v("Срок исполнения:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-meta__label" }, [
      _c("i", { staticClass: "fas fa-paste mr-2" }),
      _c("span", [_vm._v("Формат:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }