





















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState, mapGetters } from 'vuex';

import { AuthTypes } from 'shared/store/modules/auth/types';
import AppSearch from '@/components/Search.vue';
import AppMobileMenu from '@/components/layout/Dialogs/MobileMenu.vue';
import AppActivatePromoCodeDialog from 'shared/components/Product/ActivatePromoCode.vue';
import AppFillBalanceDialog from '@/components/layout/Dialogs/FillBalance.vue';
import { Role } from 'shared/utils/constants';
import LocStorage from 'shared/utils/localStorage';
import { Watch } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
  name: 'AppHeader',
  components: { AppSearch },
  props: {
    hideSearch: Boolean,
    preventNavigation: Boolean,
  },
  computed: {
    ...mapState('auth', [ 'user', 'balance' ]),
    ...mapGetters('auth', [ 'isAdmin' ]),
  },
})
export default class HeaderComponent extends Vue {
  // props
  preventNavigation: boolean;
  user: User;

  // data
  showTariffOffer: boolean = true;
  tariffId: number = null;
  overLimit: boolean = false;
  rolesWithPriceComponents: number[] = [ 2, 3, 4, 7, 8, 9, 10, 11 ]; // роли у которых есть свои прайс-компоненты
  hasShownTariffNoty: boolean = false;

  get isPartner(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ROLE](Role.Partner);
  }

  get isPartnerRisk(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ROLE](Role.PartnerRisk);
  }

  get isAdmin(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ADMIN];
  }

  get hasRoleComponent(): boolean {
    if (this.user && this.rolesWithPriceComponents.includes(this.user.person_role) && !this.overLimit) {
      return true;
    }
    return false;
  }

  get basicTariff(): boolean {
    return this.tariffId === 0;
  }

  get enabledAlert(): boolean {
    let settings = this.$store.state.settings.items;
    settings = _.values(_.pickBy(settings, (value, key) => {
      return _.startsWith(key, 'alert_');
    }));

    settings = settings.filter((item: Admin.Settings.Item) => item.enabled);
    if (settings.length > 0) {
      return true;
    }
    return false;
  }

  // methods
  logout() {
    this.$store.dispatch(AuthTypes.actions.LOGOUT);
    // LocStorage.removeItem('crossAuth');
  }

  toggleUserMenu() {
    this.$dialog.open({ component: AppMobileMenu });
  }

  toHome() {
    if (this.preventNavigation) {
      return;
    }

    this.$router.push({ name: 'home' });
  }

  showActivatePromoCodeDialog() {
    this.$dialog.open({
      component: AppActivatePromoCodeDialog,
    });
  }

  showBalancePopup() {
    this.$dialog.open({
      component: AppFillBalanceDialog,
    });
  }

  async getToken() {
    if (this.$store.state.auth.user) {
      const token = await this.$api.raw.auth.CheckToken();
      if (token.tariff) {
        this.tariffId = token.tariff.tariff_simple_id;
      }
      if (token.account.sum_of_transactions) {
        this.overLimit = +token.account.sum_of_transactions > 1500;
      }
    }
  }

  closeTariffOffer() {
    this.showTariffOffer = false;
    this.setLocStorage();
  }

  setLocStorage() {
    const now = new Date();
    // дата истечения записи в localstorage - 2 недели
    const item = {
      value: true,
      expiry: now.getTime() + 1209600000,
    };
    LocStorage.setItem('showTariffNoty', item);
  }

  getHasShownTariffNoty() {
    const item = LocStorage.getItem('showTariffNoty');

    if (!item) {
      this.hasShownTariffNoty = false;
      return;
    }
    const now = new Date();

    if (now.getTime() > item.expiry) {
      LocStorage.removeItem('showTariffNoty');
      this.hasShownTariffNoty = false;
    } else {
      this.hasShownTariffNoty = true;
    }
  }

  // lifecycle hooks

  async created() {
    await this.getToken();
  }

  mounted() {
    this.getHasShownTariffNoty();
  }

  @Watch('user')
  async userChanged(prevVal: User, newVal: User) {
    if (newVal !== prevVal) {
      await this.getToken();
      this.showTariffOffer = true;
    }
  }
}

