import { RealtyCloudApi } from 'shared/api/api';

export interface ApiTokenMethods {
  /** Получить текущий api токен */
  Get<T = ApiTokenResponse>(): Promise<T>;

  /** Если токена ещё не существует, то он создаётся, иначе просто обновляется */
  CreateOrUpdate<T = ApiTokenResponse>(): Promise<T>;
}

export function apiTokenMethods(this: RealtyCloudApi): ApiTokenMethods {
  return {
    Get: () => {
      return this.request({
        url: '/api',
      });
    },

    CreateOrUpdate: () => {
      return this.request({
        url: '/api/create_or_renew',
        method: 'POST',
      });
    },
  };
}
