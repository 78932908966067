import Vue from 'vue';
import capitalize from 'lodash/capitalize';
import isString from 'lodash/isString';

export default function capitalizeFilter(_Vue: typeof Vue) {
  _Vue.filter('capitalize', (value: string) => {
    if (isString(value)) {
      return value.split(' ').map(capitalize).join(' ');
    }

    return '';
  });
}
