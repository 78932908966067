import Vue from 'vue';
import Analytics, { AnalyticsConfig } from './index';

declare module 'vue/types/vue' {
  interface Vue {
    $analytics: typeof Analytics;
  }
}

export default function installAnalyticsPlugin(config: AnalyticsConfig) {
  if (config) {
    Analytics.setConfig(config);
  }

  return (_Vue: typeof Vue) => {
    _Vue.prototype.$analytics = Analytics;
  };
}
