import { render, staticRenderFns } from "./Dadata.vue?vue&type=template&id=dee96b64&scoped=true&"
import script from "./Dadata.vue?vue&type=script&lang=ts&"
export * from "./Dadata.vue?vue&type=script&lang=ts&"
import style0 from "./Dadata.style.scss?vue&type=style&index=0&lang=scss&module=%24dadata&"
import style1 from "./Dadata.vue?vue&type=style&index=1&id=dee96b64&lang=scss&scoped=true&"

var cssModules = {}
var disposed = false

function injectStyles (context) {
  if (disposed) return
  
        cssModules["$dadata"] = (style0.locals || style0)
        Object.defineProperty(this, "$dadata", {
          configurable: true,
          get: function () {
            return cssModules["$dadata"]
          }
        })
      
}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })



        module.hot && module.hot.accept(["./Dadata.style.scss?vue&type=style&index=0&lang=scss&module=%24dadata&"], function () {
          var oldLocals = cssModules["$dadata"]
          if (oldLocals) {
            var newLocals = require("./Dadata.style.scss?vue&type=style&index=0&lang=scss&module=%24dadata&")
            if (JSON.stringify(newLocals) !== JSON.stringify(oldLocals)) {
              cssModules["$dadata"] = newLocals
              require("/builds/realtycloud/rcfront/node_modules/vue-hot-reload-api/dist/index.js").rerender("dee96b64")
            }
          }
        })

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "dee96b64",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/realtycloud/rcfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('dee96b64', component.options)
    } else {
      api.reload('dee96b64', component.options)
    }
    module.hot.accept("./Dadata.vue?vue&type=template&id=dee96b64&scoped=true&", function () {
      api.rerender('dee96b64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "rcshare/components/ui/Dadata/Dadata.vue"
export default component.exports