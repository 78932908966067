










import Vue from 'vue';
import { Route } from 'vue-router';

import AppHeader from '@/components/layout/Header/Header.vue';
import AppFooter from '@/components/layout/Footer/Footer.vue';
// import startsWith from 'lodash/startsWith';

export default Vue.extend({
  components: { AppHeader, AppFooter },

  data() {
    return {
      hideSearch: false,
      whiteBg: false,
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler(currentRoute: Route) {
        const isFreePage = currentRoute.name === 'free' || currentRoute.name === '9rub';
        this.whiteBg = isFreePage;
        this.hideSearch = currentRoute.name === 'home' || isFreePage;
      },
    },
  },

  created() {
    // if (!startsWith(window.location.href, 'https://egrn-test') && !startsWith(this.$route.path, '/admin')) {
    //   window.location.href = 'https://b2b.realtycloud.ru/risk-assessment';
    // }
  },
});
