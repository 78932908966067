



























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Provide } from 'vue-property-decorator';

@Component({
  name: 'AppCarousel',
})
export default class Carousel extends Vue {
  @Provide()
  Carousel: CarouselProvider = {
    current: 0,
    itemsCount: 0,
  };

  // props

  // props end

  // data()

  // data() end

  // lifecycle hooks

  // methods
  next() {
    if (this.Carousel.current + 1 === this.Carousel.itemsCount) {
      this.Carousel.current = 0;
    } else {
      this.Carousel.current += 1;
    }
  }

  prev() {
    if (this.Carousel.current - 1 < 0) {
      this.Carousel.current = this.Carousel.itemsCount - 1;
    } else {
      this.Carousel.current -= 1;
    }
  }
}
