







import Vue from 'vue';
// import AppRosreestrError from '@/components/layout/Alerts/RosreestrError.vue';

export default Vue.extend({
  // components: { AppRosreestrError },
});
