import Vue from 'vue';

declare module 'vue/types/vue' {
  interface Vue {
    /** Хелпер для url на статик сервер */
    $static: (path: string) => string;
  }
}

export function staticServerPath(path: string) {
  return `https://download.realtycloud.ru/front${path}`;
}

export function staticServerInstall(_Vue: typeof Vue) {
  _Vue.prototype.$static = staticServerPath;
}
