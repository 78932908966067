

































































































































import Vue from 'vue';
import { AuthTypes } from 'shared/store/modules/auth/types';
import { Role } from 'shared/utils/constants';

import AppHeader from '@/components/layout/Header/Header.vue';
import PartnerModule from '@/store/modules/partner';

declare var Ya: any;

let partnerChatInitied = false;

export default Vue.extend({
  components: { AppHeader },

  computed: {
    isAdmin(): boolean {
      return this.$store.getters[AuthTypes.getters.IS_ADMIN];
    },

    isPartner(): boolean {
      return this.$store.getters[AuthTypes.getters.IS_ROLE](Role.Partner);
    },
    isManager(): boolean {
      return this.$store.getters[AuthTypes.getters.IS_ROLE](Role.Manager);
    },
  },

  created() {
    if (this.isPartner) {
      PartnerModule.fetchStats();

      if (!partnerChatInitied) {
        this.initYandexChatWidget();
      }
    }
  },

  methods: {
    initYandexChatWidget() {
      const w: any = window;
      w.yandexChatWidgetCallback = function() {
        try {
          w.yandexChatWidget = new Ya.ChatWidget({
            guid: 'aa1419be-cc2a-4827-a480-bc914ecff124',
            buttonText: 'Напишите нам, мы в сети!',
            title: 'Поддержка партнеров',
            theme: 'light',
            collapsedDesktop: 'hover',
            collapsedTouch: 'always',
          });
        } catch (e) {
          // empty
        }
      };
      var n = document.getElementsByTagName('script')[0];
      var s = document.createElement('script');
      s.async = true;
      s.charset = 'UTF-8';
      s.src = 'https://chat.s3.yandex.net/widget.js';
      n.parentNode.insertBefore(s, n);

      partnerChatInitied = true;
    },
  },
});
