var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-3" }, [
        _vm._v(
          "\n    Заполните форму и получите скидку до 14% на отчет об объекте\n  "
        )
      ]),
      _c(
        "app-form",
        {
          attrs: {
            model: _vm.model,
            rules: _vm.validationRules,
            "request-handler": _vm.onSubmit
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    {
                      attrs: {
                        type: "submit",
                        size: "lg",
                        loading: loading,
                        block: ""
                      }
                    },
                    [_vm._v("\n        Сохранить и получить скидку\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-6" },
              [
                _c(
                  "app-form-group",
                  {
                    staticClass: "rc-form-group mb-1",
                    attrs: { "model-name": "person_role" }
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.model.person_role,
                            expression: "model.person_role",
                            modifiers: { number: true }
                          }
                        ],
                        staticClass: "custom-select custom-select-lg",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return _vm._n(val)
                              })
                            _vm.$set(
                              _vm.model,
                              "person_role",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }),
                        _vm._l(_vm.roles, function(roleName, roleId) {
                          return _c(
                            "option",
                            { key: roleId, domProps: { value: roleId } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(roleName) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _c(
                      "label",
                      { class: [{ active: _vm.model.person_role }] },
                      [_vm._v("\n            Выберите роль (10%)\n          ")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-md-6" },
              [
                _c(
                  "app-form-group",
                  {
                    staticClass: "rc-form-group",
                    attrs: { "model-name": "phone" }
                  },
                  [
                    _c("app-phone-number-input", {
                      attrs: {
                        label: "Телефон (+4%)",
                        placeholder: "+7 (___) ___ __ __",
                        size: "lg"
                      },
                      model: {
                        value: _vm.model.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "phone", $$v)
                        },
                        expression: "model.phone"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }