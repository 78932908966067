import { RealtyCloudApi } from '../api';
import { AxiosRequestConfig } from 'axios';
import isEmpty from 'lodash/isEmpty';

interface ObjectInfoParams {
  id: string; // ID - OrderItemID нужен для авторизации
}

export interface ApiObjectMethods {
  Info<T = ObjectInfoResponse>(objectKey?: string, params?: ObjectInfoParams): Promise<T>;
  InfoFull<T = RealtyObject>(objectKey?: string, config?: AxiosRequestConfig): Promise<T>;
  Search<T = RealtyObject[]>(query: string, config?: AxiosRequestConfig): Promise<T>;

  /** Данные из реформы */
  ReformaGkhInfo<T = ReformaGkhData>(address: string): Promise<T>;
}

function emptyArea(item: RealtyObject) {
  if (item.Area === 'не определена' || item.Area <= 0) {
    item.Area = '';
  }

  if (item.AreaOKC === 'не определена' || item.AreaOKC <= 0) {
    item.AreaOKC = '';
  }

  if (!isEmpty(item.AreaOKC) && isEmpty(item.Area)) {
    item.Area = item.AreaOKC;
  }

  return item;
}

export function objectMethods(this: RealtyCloudApi): ApiObjectMethods {
  return {
    Info: (objectKey?: string, params?: ObjectInfoParams): any => {
      return this.request({ url: `/object/${objectKey}`, params }).then((item) => {
        if (!this.raw) {
          emptyArea(item.data.object);
        } else {
          emptyArea(item);
        }

        return item;
      });
    },

    InfoFull: (objectKey?: string, config?: AxiosRequestConfig): any => {
      return this.request({ url: `/objectFull/${objectKey}`, ...config }).then((item) => {
        if (!this.raw) {
          emptyArea(item.data);
        } else {
          emptyArea(item);
        }

        return item;
      });
    },

    Search: (query: string, config?: AxiosRequestConfig): any => {
      const params = { query };

      return this.request({ url: '/search', params, ...config }).then((data: RealtyObject[]) => {
        if (Array.isArray(data)) {
          data.forEach(emptyArea);

          return data;
        }

        return data || [];
      });
    },

    ReformaGkhInfo: (address: string) => {
      return this.request({
        url: '/property/info/house_details',
        params: { address },
      });
    },
  };
}
