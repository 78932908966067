










































































































































import { Component, Vue } from 'vue-property-decorator';
import { Validators } from 'shared/utils/validator';
import { TheMask } from 'vue-the-mask';
import AppAuthDialog from 'shared/components/layout/Auth/Auth.vue';
import get from 'lodash/get';

@Component({
  name: 'AppActivatePromoCodeForm',
  components: { TheMask },
  props: {
    user: Object || undefined,
    onLogin: Function,
    fromDashboard: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppActivatePromoCodeForm extends Vue {
  // props
  user: User;
  onLogin: any;
  fromDashboard: boolean;
  isModal: boolean;

  // data
  get: any = get;
  model: any = {
    promoCode: '',
  };

  error: any = null;
  response: any = null;
  hexTokens: any = {
    F: {
      pattern: /\w/,
    },
  };

  async onSubmit() {
    this.error = null;

    try {
      const res = await this.$api.products.ActivatePromoCode(
        this.model.promoCode
      );
      this.response = res;
      this.$store.dispatch('getProducts');
    } catch (error) {
      this.error = this.$api.getErrorMessage(error);
    }
  }

  validationRules(): ValidationConfig {
    return {
      promoCode: [
        Validators.regex(/^\w{4,10}$/, 'Неверный формат промокода'),
      ],
    };
  }

  showLoginDialog(actionName: string) {
    this.$dialog.close();
    this.$dialog.open({
      component: AppAuthDialog,
      props: {
        currentFormName: actionName,
      },
    });
  }
}
