










import Vue from 'vue';
import Component from 'vue-class-component';
import RiskAddOwnerFormDialog from 'shared/components/RiskNew/AddOwnerForm/AddOwnerDialog.vue';

@Component({
  name: 'AppRiskForm',
  props: {
    itemId: {
      type: String,
      default: '',
    },
    requestUsers: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary-light',
    },
    size: {
      type: String,
      default: 'sm',
    },
  },
})

export default class RiskForm extends Vue {
  itemId: string;
  requestUsers: boolean;

  // methods
  showRiskForm() {
    this.$dialog.open({
      component: RiskAddOwnerFormDialog,
      props: {
        orderItemId: this.itemId,
        requestUsers: this.requestUsers,
        onAddOwner: this.addOwner,
      },
    });
  }
  addOwner() {
    this.$emit('updateOwners');
  }
}
