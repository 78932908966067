import Vue from 'vue';
import TheMask from 'vue-the-mask';
import VueMeta from 'vue-meta';
import Multiselect from 'vue-multiselect';
import Pagination from './components/ui/Pagination.vue';
import ObjectProp from './components/ObjectProp.vue';
import Carousel from './components/ui/Carousel/Carousel.vue';
import CarouselItem from './components/ui/Carousel/CarouselItem.vue';

import buttonComponent from 'shared/components/ui/Button';
import collapseComponent from 'shared/components/ui/Collapse';
import checkboxComponent from 'shared/components/ui/Checkbox';
import datePickerComponent from 'shared/components/ui/DatePicker';
import dadataComponent from 'shared/components/ui/Dadata';
import dropdownComponent from 'shared/components/ui/Dropdown';
import dataTableComponent from 'shared/components/DataTable';
import inputComponent from 'shared/components/ui/Input';
import loaderComponent from 'shared/components/ui/Loader';
import phoneNumberInputComponent from 'shared/components/ui/PhoneNumberInput';
import formComponent from 'shared/components/ui/Form';
import initDialogPlugin from 'shared/components/ui/Dialog/dialogPlugin';

import installPhotoSwipePlugin from 'shared/plugins/photoswipe';
import notyPlugin from 'shared/plugins/noty.plugin';
import { installApiPlugin } from 'shared/api';
import installClickOutsideDirective from 'shared/directives/outsideClick';
import installAnalyticsPlugin from 'shared/utils/analytics/analytics-plugin';
import installTippyPlugin from './core/directives/tippy';
import dateFilter from 'shared/filters/date.filter';
import currencyFilter from 'shared/filters/currency.filter';
import floatFilter from 'shared/filters/float.filter';

import Auth from '@/components/layout/Auth/Auth.vue';
import ShortRegistraiton from '@/components/layout/Dialogs/ShortRegistration.vue';
import capitalizeFilter from 'shared/filters/capitalize.filter';

declare module 'vue/types/vue' {
  interface VueConstructor {
    componentName: string;
  }
}

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    componentName?: string;
  }
}

const COMPONENTS = [
  Carousel,
  CarouselItem,
  Pagination,
  ObjectProp,
];

for (const component of COMPONENTS) {
  Vue.component((component as any).options.name, component);
}

Vue.component('ClientOnly', {
  render() {
    return this.$slots.default as any;
  },
});

Vue.component('AppMultiSelect', Multiselect);

Vue.use(buttonComponent);
Vue.use(collapseComponent);
Vue.use(checkboxComponent);
Vue.use(datePickerComponent);
Vue.use(dadataComponent);
Vue.use(dropdownComponent);
Vue.use(dataTableComponent);
Vue.use(inputComponent);
Vue.use(loaderComponent);
Vue.use(phoneNumberInputComponent);
Vue.use(formComponent);
Vue.use(TheMask);
Vue.use(installPhotoSwipePlugin);
Vue.use(notyPlugin);
Vue.use(initDialogPlugin, {
  registerGlobalDialogs: {
    auth: Auth,
    'short-registration': ShortRegistraiton,
  },
});

Vue.use(VueMeta);

Vue.use(installApiPlugin, { source: location.origin });

Vue.use(currencyFilter);
Vue.use(floatFilter);
Vue.use(dateFilter);
Vue.use(capitalizeFilter);

Vue.use(installAnalyticsPlugin({
  yandexCounters: [ '48408542' ],
  gtagId: 'UA-122176305-1',
}));

// directives
Vue.use(installClickOutsideDirective);
Vue.use(installTippyPlugin, {
  animateFill: false,
  theme: 'light',
  arrow: true,
  a11y: false,
});
