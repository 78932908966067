

























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Provide } from 'vue-property-decorator';

import AppReportItem from 'shared/components/RiskNew/ReportItem.vue';

@Component({
  components: {
    AppReportItem,
  },
})
export default class OwnerRiskReportExample extends Vue {
  // props
  @Provide()
  ReportProvider: any = {
    owner: null,
  }

  showMoreSources: boolean = false;

  // lifecycle hooks

  // methods
}
