var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "property-image", class: _vm.loaded && "is-loaded" },
    [
      _c("img", {
        staticClass: "property-image__img",
        attrs: { src: _vm.photos[0] },
        on: {
          load: _vm.imgLoaded,
          click: function($event) {
            $event.stopPropagation()
            return _vm.showLightbox($event)
          }
        }
      }),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "property-image__placeholder fa-stack fa-2x" },
      [
        _c("i", {
          staticClass: "fas fa-camera fa-stack-1x property-image__icon-back"
        }),
        _c("i", {
          staticClass: "fas fa-slash fa-stack-1x property-image__icon-slash"
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }