var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup__close", on: { click: _vm.close } }, [
    _c(
      "svg",
      { staticClass: "popup__close-icon", attrs: { viewBox: "0 0 10 10" } },
      [
        _c("line", { attrs: { x1: "1", y1: "1", x2: "9", y2: "9" } }),
        _c("line", { attrs: { x1: "1", y1: "9", x2: "9", y2: "1" } })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }