var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-uploader" },
    [
      _c(
        "app-dropzone",
        {
          staticClass: "image-uploader__dropzone",
          on: { drop: _vm.onFileAdded },
          nativeOn: {
            click: function($event) {
              return _vm.onClick($event)
            }
          }
        },
        [
          _c("div", { staticClass: "image-uploader__caption" }, [
            _c("div", { staticClass: "image-uploader__icon" }, [
              _c("i", { staticClass: "fas fa-cloud-upload-alt fa-lg" })
            ]),
            _c("span", { staticClass: "image-uploader__drag-text" }, [
              _vm._v(
                "\n        Перетащите изображения или нажмите чтобы загрузить\n      "
              )
            ])
          ]),
          _c("input", {
            ref: "fileInput",
            staticClass: "d-none",
            attrs: {
              type: "file",
              accept: "image/*",
              hidden: "",
              multiple: ""
            },
            on: { change: _vm.fileInputChange }
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "image-uploader-preview" },
        _vm._l(_vm.images, function(img, index) {
          return _c(
            "div",
            { key: img.id, staticClass: "image-uploader-preview__item-wrap" },
            [
              _c(
                "div",
                {
                  staticClass: "image-uploader-preview__item",
                  style: { "background-image": "url(" + img.base64 + ")" },
                  on: {
                    click: function($event) {
                      return _vm.openPreview(index)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "image-uploader-preview__remove",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.removePreview(index)
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa-trash-alt" })]
                  )
                ]
              )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }