































































import Vue from 'vue';
import { Validators } from '@/core/utils/validator';

export default Vue.extend({
  name: 'AppWholesaleDialog',

  popupOptions: {
    size: 'xs',
  },

  data() {
    return {
      model: {
        email: '',
        phone: '',
      },
      sendSuccess: false,
    };
  },

  methods: {
    async onSubmit() {
      try {
        await this.$api.events.WholesaleRequest(this.model);

        this.sendSuccess = true;
      } catch (error) {
        this.$noty.error({ text: 'Произошла ошибка при отправке' });
        return this.$api.HttpError(error);
      }
    },

    validationRules(): ValidationConfig {
      return {
        phone: [ Validators.required, Validators.phone ],
        email: [ Validators.required, Validators.email ],
      };
    },
  },
});
