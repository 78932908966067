import { RealtyCloudApi } from '../../api';

// export interface OrderItem {
//   objectKey: string;
//   product_id: string;
//   coupon_id?: string;
// }

export interface ApiOrderMethods {
  Send<T = OrderResponse>(order: OrderRequestBody): Promise<T>;
  RegOrder<T = RegOrderResponse>(data: RegorderRequestPayload): Promise<T>;
  CheckOrderPayment<T = CheckOdrerPaymentResponse>(orderId: string): Promise<T>;
  UpdateOrderItem<T = any>(data: OrderItem): Promise<T>;
}

export function orderMethods(this: RealtyCloudApi): ApiOrderMethods {
  return {
    Send: (data: OrderRequestBody) => {
      // const data: SendOrderJSON = { order_items };

      // if (paymentToken) {
      //   data.payment_token = paymentToken;
      // }

      return this.request({
        url: '/order',
        method: 'POST',
        headers: this.getSourceSite(),
        data,
      });
    },

    RegOrder: (data: RegorderRequestPayload) => {
      return this.request({
        url: '/regorder',
        method: 'POST',
        headers: this.getSourceSite(),
        data,
      });
    },

    CheckOrderPayment: (orderId: string) => {
      return this.request({
        url: `/check_order_paid/${orderId}`,
      });
    },

    UpdateOrderItem: (data: OrderItem) => {
      return this.request({
        url: '/admin/order_item/update_order_item',
        method: 'PUT',
        data,
      });
    },
  };
}
