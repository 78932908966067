




































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import humanInterval from 'human-interval';
import { Validators } from 'shared/utils/validator';
import LocStorage from 'shared/utils/localStorage';

@Component({
  name: 'AppReviewDialog',

  popupOptions: {
    name: 'popup_default popup_review',
  },
})
export default class ReviewDialog extends Vue {
  form: string = null;
  loading: boolean = false;
  showBtn: boolean = false;
  rating: number = 0;

  model = {
    text: '',
  };

  get auth(): User {
    return this.$store.state.auth.user;
  }

  // Берём одну из двух ссылок с рандомом 50%
  get reviewLink(): string {
    if (Math.random() > 0.5) {
      return 'https://yandex.ru/profile/5111988881?lr=213';
    } else {
      return 'https://market.yandex.ru/shop--realty-cloud/538132/reviews/add?retpath=https%253A%252F%252Fmarket.yandex.ru%252Fshop--realty-cloud%252F538132%252Freviews%253Fclid%253D703&track=rev_sh';
    }
  }

  @Watch('form', { immediate: true })
  formChanged(form: string) {
    let size: string;

    if (!form) {
      size = 'xs';
    } else {
      size = 'md';
    }

    this.$nextTick(() => {
      const popup: HTMLDivElement = document.querySelector('.popup_review');
      if (popup) {
        popup.setAttribute('data-size', size);
      }
    });
  }

  rate(value: number) {
    this.rating = value;
    this.disableReview();

    if (value >= 4) {
      this.sendReview();
      this.form = 'positive';
    } else {
      this.form = 'negative';
    }
  }

  async onSubmit() {
    await this.sendReview();
    this.form = 'complete';
  }

  async sendReview() {
    if (this.rating >= 4) {
      return;
    }

    try {
      await this.$api.events.SendEmail({
        email: 'fazleev@realtycloud.ru',
        title: 'Отзыв',
        template: `<h4>Оценка: ${this.rating}</h4> <p>от: ${this.auth.email}</p> <p>${this.model.text}</p>`,
      });
    } catch (error) {
      console.error(error);
    }
  }

  linkClick() {
    setTimeout(() => {
      this.showBtn = true;
    }, humanInterval('10 seconds'));
  }

  getDiscount() {
    this.loading = true;
    location.replace(location.pathname + '?utm_campaign=discount20egrn');
  }

  disableReview(close?: boolean) {
    LocStorage.setItem('review', true);

    if (close) {
      this.$dialog.close();
    }
  }

  validationConfig(): ValidationConfig {
    return {
      text: [ Validators.required, Validators.min(5, 'Сообщение сллишком короткое') ],
    };
  }
}
