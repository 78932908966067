






















































































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import flatpickr from 'flatpickr';
import { TheMask } from 'vue-the-mask';
import differenceInYears from 'date-fns/difference_in_years';

import { regionsSortByName, Region } from 'shared/utils/regions';
import capitalize from 'lodash/capitalize';

const now = new Date();

@Component({
  props: {
    owner: Object,
    index: Number,
    rightsList: {
      type: Array,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isOdyssey: {
      type: Boolean,
      default: false,
    },
    isFormOnLanding: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isCheckPerson: {
      type: Boolean,
      default: false,
    },
  },

  components: { TheMask },
})
export default class OwnerFormItemBase extends Vue {
  // props
  owner: LawInformationOwner;
  isFormOnLanding: boolean;
  rightsList: any[];
  isOdyssey: boolean;
  isEditing: boolean;
  index: number | string;
  isAdmin: boolean;
  isCheckPerson: boolean;
  // data
  regions: Region[] = regionsSortByName;
  rightSelect: string = null;
  capitalize = capitalize;
  datePickerOptions: flatpickr.Options.Options = {
    maxDate: now,
    minDate: new Date(now.getFullYear() - 120, 0, 0),
    defaultDate: '',
  };

  checkTargets: any[] = [
    {
      text: 'Поиск Родственника или старого друга',
      value: 1,
    },
    {
      text: 'Знакомства/Отношения',
      value: 2,
    },
    {
      text: 'Личное любопытство',
      value: 3,
    },
    {
      text: 'Проверка себя',
      value: 4,
    },
    {
      text: 'Личная безопасность/Мошенничество',
      value: 5,
    },
    {
      text: 'Трудоустройство',
      value: 6,
    },
    {
      text: 'Важная Сделка',
      value: 7,
    },
    {
      text: 'Юридические нужды',
      value: 8,
    },
    {
      text: 'Проверка партнера по Бизнесу',
      value: 9,
    },
    {
      text: 'Другое',
      value: 10,
    },
  ];

  // bitrhdateModel: string = '';

  placeholderForBirthdayInput: string = 'Дата рождения';

  enterPassportData: boolean = false;

  get isUnderage(): boolean {
    if (/\d{4}-\d{2}-\d{2}/.test(this.owner.birthday)) {
      return differenceInYears(Date.now(), this.owner.birthday) < 14;
    }

    return null;
  }

  get placeholderForPassportInput(): string {
    return this.isFormOnLanding ? '' : '__ __ ______';
  }

  changePlaceholder(placeholder: string) {
    this.placeholderForBirthdayInput = placeholder;
  }

  rightSelectHandler() {
    if (![ 'other', 'notRequired' ].includes(this.rightSelect)) {
      this.owner.registration_number = this.rightSelect;
    } else {
      this.owner.registration_number = '';
    }
  }

  // lifecycle hooks
  created() {
    // обработка ответа с сервера при не введёной дате рождения
    if (this.owner.birthday === '0001-01-01' || this.owner.birthday === '31.12.0000') {
      this.owner.birthday = '';
    }
    if (this.rightsList && this.rightsList.length && this.owner.registration_number) {
      const isSelected = this.rightsList.find((elem) => elem.number === this.owner.registration_number);
      if (isSelected) {
        this.rightSelect = this.owner.registration_number;
      } else {
        this.rightSelect = 'other';
      }
    }
  }
}
