var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style["form-group"],
        _vm.errorMessage && _vm.$style["form-group_error"]
      ]
    },
    [
      _vm.label
        ? _c("label", { class: _vm.$style.label }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _vm._t("default"),
      _c("transition", { attrs: { name: "fade-top" } }, [
        _vm.errorMessage
          ? _c("div", { class: _vm.$style["form-input-error"] }, [
              _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")
            ])
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }