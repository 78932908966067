import { RealtyCloudApi } from 'shared/api/api';

export interface ApiSettingsMethods {
  GetItems<T = Admin.Settings.Item[]>(key?: string): Promise<T>;
  UpdateTextValue<T = any>(body: ApiTypes.Settings.UpdateTextBody): Promise<T>;
  ToggleEnabled<T = any>(data: ApiTypes.Settings.ToggleEnabledBody): Promise<T>;
  CreateItem<T = Admin.Settings.Item>(data: ApiTypes.Settings.CreateItemBody): Promise<T>;
  RemoveItem<T = any>(key: string): Promise<T>;
}

export function settingsMethods(this: RealtyCloudApi): ApiSettingsMethods {
  return {
    GetItems: (key?: string) => {
      return this.request({
        url: '/admin/system/info/view',
        params: { key },
      });
    },

    UpdateTextValue: (data: ApiTypes.Settings.UpdateTextBody) => {
      return this.request({
        url: '/admin/system/info/update_value',
        method: 'PUT',
        data,
      });
    },

    ToggleEnabled: (data: ApiTypes.Settings.ToggleEnabledBody) => {
      return this.request({
        url: '/admin/system/info/update_activity',
        method: 'PUT',
        data,
      });
    },

    CreateItem: (data: ApiTypes.Settings.CreateItemBody) => {
      return this.request({
        url: '/admin/system/info/add',
        method: 'POST',
        data,
      });
    },

    RemoveItem: (key: string) => {
      return this.request({
        url: '/admin/system/info/remove',
        method: 'DELETE',
        data: { key },
      });
    },
  };
}
