


















import Vue from 'vue';

import AuthLogin from './AuthLogin.vue';
import AuthRegister from './AuthRegister.vue';
import AuthResetPass from './AuthResetPass.vue';
import noop from 'lodash/noop';

export default Vue.extend({
  name: 'AppAuthDialog',

  popupOptions: {
    name: 'popup_auth',
  },

  components: {
    AuthLogin,
    AuthRegister,
    AuthResetPass,
  },

  props: {
    index: Number,
    currentFormName: {
      type: String,
      default: 'login',
    },
    onRegistrationSuccess: {
      type: Function,
      default: noop,
    },
  },

  data() {
    return {
      currentForm: '',
    };
  },

  computed: {
    currentFormComponent() {
      switch (this.currentForm) {
        case 'register':
          return AuthRegister;
        case 'resetPass':
          return AuthResetPass;
        default:
          return AuthLogin;
      }
    },
  },

  methods: {
    changeForm(formName: string) {
      this.currentForm = formName;
    },
  },

  created() {
    this.currentForm = this.currentFormName;
  },
});
