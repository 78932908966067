import Vue from 'vue';
import Component from 'vue-class-component';

import { Validators } from 'shared/utils/validator';
import AppAuthDialog from 'shared/components/layout/Auth/Auth.vue';

@Component
export default class ActivatePromoCodeMixin extends Vue {
  // data
  model: any = {
    promoCode: '',
  };

  error: any = null;
  response: any = null;

  hexTokens: any = {
    F: {
      pattern: /\w/,
    },
  };

  async onSubmit() {
    this.error = null;

    try {
      const res = await this.$api.products.ActivatePromoCode(
        this.model.promoCode
      );
      this.response = res;
      this.$store.dispatch('getProducts');
    } catch (error) {
      this.error = this.$api.getErrorMessage(error);
    }
  }

  validationRules(): ValidationConfig {
    return {
      promoCode: [
        Validators.regex(/^\w{4,10}$/, 'Неверный формат промокода'),
        Validators.required,
      ],
    };
  }

  showLoginDialog(actionName: string) {
    this.$dialog.close();
    this.$dialog.open({
      component: AppAuthDialog,
      props: {
        currentFormName: actionName,
      },
    });
  }
}
