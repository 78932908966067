import removeTypesNamespace from 'shared/utils/storeNamespaceHelper';

export const OrderTypes = {
  namespace: 'order',

  getters: {
    ITEMS_COUNT: 'order/itemsCount',
    TOTAL_AMOUNT: 'order/totalAmount',
  },

  actions: {
    SEND: 'order/send',
    SAVE_TO_LOCAL_STORAGE: 'order/saveToLocalStorage',
    REGORDER: 'order/regOrder',
    GET_ORDER_ITEMS: 'order/getOrderItems',
  },

  mutations: {
    TOGGLE_LOADING: 'order/toggleLoading',
    CHANGE_ITEM: 'order/changeItem',
    SET_OBJECT_KEY: 'order/setObjectKey',
    SET_OBJECT_ADDRESS: 'order/setObjectAddress',
    SET_ORDER_TYPE: 'order/setOrderType',
    SET_DATA: 'order/setData',
    MERGE_DATA: 'order/mergeData',
    SET_OWNERS: 'order/setOwners',
    SET_MARKUP: 'order/setMarkup',

    /** @deprecated */
    ADD_COMMENT: 'order/addComment',
    CLEAR_COMMENTS: 'order/clearComments',
  },
};

export const _OrderTypes = removeTypesNamespace<typeof OrderTypes>('order/', OrderTypes);
