import { Module } from 'vuex';
import qs from 'query-string';
import includes from 'lodash/includes';
import get from 'lodash/get';
import find from 'lodash/find';
import differenceInDays from 'date-fns/difference_in_days';

import { Api } from 'shared/api';
import { FIZCHECK_DOMAIN, FIZCHECK_PRO_DOMAIN, RC_DOMAIN, Role } from 'shared/utils/constants';
import Analytics from 'shared/utils/analytics';
import Cookies from 'js-cookie';
import { _AuthTypes } from './types';

// Модуль хранилища для авторизации и регистрации
//
export const authModule: Module<AuthModuleState, any> = {
  namespaced: true,

  state: {
    token: null,
    user: null,
    userId: null,
    balance: 0,
    balanceDiff: 0,
    utmCampaign: null,
    utmSource: null,
    admitadUid: null,
    utmMedium: null,
    utmCoupon: null,
    utmContent: null,
    utmTerm: null,
    domainUrl: null,
    partner: {
      id: null,
      storeDate: null,
    },
    payers: [],
    payersId: [],
    settings: null,
  },

  getters: {
    [_AuthTypes.getters.IS_ADMIN](state: AuthModuleState) {
      return state.user && state.user.person_role === Role.Admin;
    },

    [_AuthTypes.getters.IS_ROLE](state: AuthModuleState) {
      return (role: number) => {
        return state.user && state.user.person_role === role;
      };
    },

    [_AuthTypes.getters.HAS_ANY_ROLE](state: AuthModuleState) {
      return (roles: number[]) => {
        return state.user && includes(roles, state.user.person_role);
      };
    },

    [_AuthTypes.getters.IS_RC](state: AuthModuleState) {
      return [ 'www.' + RC_DOMAIN, RC_DOMAIN ].includes(state.domainUrl);
    },

    [_AuthTypes.getters.IS_FIZCHECK](state: AuthModuleState) {
      return [ 'www.' + FIZCHECK_DOMAIN, FIZCHECK_DOMAIN, FIZCHECK_PRO_DOMAIN ].includes(state.domainUrl);
    },

    [_AuthTypes.getters.IS_FIZCHECK_RU](state: AuthModuleState) {
      return [ 'www.' + FIZCHECK_DOMAIN, FIZCHECK_DOMAIN ].includes(state.domainUrl);
    },

    [_AuthTypes.getters.IS_FIZCHECK_PRO](state: AuthModuleState) {
      return [ 'www.' + FIZCHECK_PRO_DOMAIN, FIZCHECK_PRO_DOMAIN ].includes(state.domainUrl);
    },

    [_AuthTypes.getters.RESOURCE_NAME](state: AuthModuleState) {
      return state.domainUrl === FIZCHECK_DOMAIN ? 'Fizcheck' : 'RealtyCloud';
    },

    [_AuthTypes.getters.LOGO_PERMISSION](state, getters, rootState) {
      const permission = getters.getSetting('add_logo_permission');
      if (permission) {
        return permission.value;
      }
      // если это риелтор, агент или рук. агенства включаем загрузку лого
      if ([ 2, 7, 3, 9, 10 ].includes(rootState.auth.user.person_role)) {
        return 'active';
      }
    },
    [_AuthTypes.getters.GET_SETTING]: (state) => (value: string) => {
      if (state.settings) {
        return state.settings.find((elem: any) => {
          return elem.key === value;
        });
      }
    },
    [_AuthTypes.getters.GET_PROCESSING_ORDERS](state, getters) {
      const orders = getters.getSetting('processing_orders');
      if (orders) {
        return orders.value || [];
      }
    },
    [_AuthTypes.getters.GET_PROCESSING_ORDER]: (state, getters) => (orderItemId: string) => {
      const orders = getters.getProcessingOrders();
      return find(orders, (elem: any) => elem.orderItemId === orderItemId);
    },
  },

  actions: {
    async [_AuthTypes.actions.LOGIN]({ commit, dispatch }, credentials: Credentials) {
      try {
        const response = await Api.raw.auth.Login<ApiRawResponse<User> & JWTResponse>(credentials);

        commit(_AuthTypes.mutations.SET_USER, response.data);
        commit(_AuthTypes.mutations.SET_TOKEN, response.jwt.token);
        dispatch(_AuthTypes.actions.CHECK_TOKEN);
        Cookies.set('jwt', response.jwt, { domain: 'realtycloud.ru' });
        return response;
      } catch (error) {
        return Api.HttpError(error);
      }
    },

    async [_AuthTypes.actions.REGISTRATION]({ commit, rootState, state }, { user }: RegistrationPayload) {
      try {
        if (state.partner.id) {
          user.partner_id = state.partner.id;
        }

        const { jwt, data } = await Api.raw.auth.Register<ApiRawResponse<User> & JWTResponse>(user, rootState.query.utm_campaign);

        commit(_AuthTypes.mutations.SET_USER, data);
        commit(_AuthTypes.mutations.SET_TOKEN, jwt.token);
      } catch (error) {
        console.error(error);
        let errorMessage = Api.getErrorMessage(error);

        if (/занят/.test(errorMessage)) {
          errorMessage = `${errorMessage} <br> Войдите или сбросьте пароль`;
        }

        return Api.HttpError<Error>(new Error(errorMessage));
      }
    },

    async [_AuthTypes.actions.CHECK_TOKEN]({ dispatch, state, commit }) {
      Api.setToken(state.token);

      if (state.token) {
        try {
          const { valid, account, person } = await Api.raw.auth.CheckToken<CheckTokenResponse>();

          if (!valid) {
            dispatch(_AuthTypes.actions.LOGOUT);
          } else {
            commit(_AuthTypes.mutations.SET_BALANCE, account.balance);
            commit(_AuthTypes.mutations.SET_USER_ID, account.person_id);
            if (person) {
              commit(_AuthTypes.mutations.SET_USER, person);
            }
          }
        } catch (error) {
          return Api.HttpError(error);
        }
      }
    },

    async [_AuthTypes.actions.GET_USER_INFO]({ commit, state }) {
      if (state.userId) {
        try {
          const user = await Api.admin.GetUserInfo(state.userId);
          commit(_AuthTypes.mutations.SET_USER, user);
        } catch (error) {
          console.error(error);
        }
      }
    },

    [_AuthTypes.actions.LOGOUT]({ commit }) {
      commit(_AuthTypes.mutations.SET_USER, null);
      commit(_AuthTypes.mutations.SET_TOKEN, null);
    },

    /** @deprecated */
    [_AuthTypes.actions.CHECK_PARTNER_ID]({ commit, state }) {
      const { partner_id } = qs.parse(location.search);

      // eslint-disable-next-line @typescript-eslint/camelcase
      if (partner_id) {
        commit(_AuthTypes.mutations.SET_PARTNER_ID, partner_id);
      } else if (state.partner.id) {
        if (differenceInDays(Date.now(), state.partner.storeDate) > 30) {
          commit(_AuthTypes.mutations.SET_PARTNER_ID, null);
        }
      }
    },

    /** Проверяем query параметры из url */
    [_AuthTypes.actions.CHECK_URL_QUERY]({ commit, state, dispatch }) {
      const { partner_id: partnerId, utm_campaign: utmCampaign, utm_source: utmSource, admitad_uid: admitadUid, utm_medium: utmMedium, utm_coupon: utmCoupon, utm_content: utmContent, utm_term: utmTerm, jwt_token: jwtToken, actionpay: actionPay } = qs.parse(location.search);

      /** Партнёрский id */
      if (partnerId) {
        commit(_AuthTypes.mutations.SET_PARTNER_ID, partnerId);
      } else if (state.partner.id) {
        /** Partner id хранится в localStorage, срок хранения 30 дней, если срок прошёл, то удаляем */
        if (differenceInDays(Date.now(), state.partner.storeDate) > 30) {
          commit(_AuthTypes.mutations.SET_PARTNER_ID, null);
        }
      }

      /** Купоны, игнорим купон freecloud */
      if (utmCampaign && typeof utmCampaign === 'string' && utmCampaign !== 'freecloud') {
        commit(_AuthTypes.mutations.SET_UTM_CAMPAIGN, utmCampaign);
      }

      if (utmSource) {
        commit(_AuthTypes.mutations.SET_UTM_SOURCE, utmSource);
      }

      if (admitadUid) {
        dispatch(_AuthTypes.actions.SAVE_ADMINTAD_UID, admitadUid);
      }

      if (utmMedium) {
        commit(_AuthTypes.mutations.SET_UTM_MEDIUM, utmMedium);
      }

      if (utmCoupon && typeof utmCoupon === 'string') {
        commit(_AuthTypes.mutations.SET_UTM_COUPON, utmCoupon);
      }

      if (utmContent) {
        commit(_AuthTypes.mutations.SET_UTM_CONTENT, utmContent);
      }

      if (utmTerm) {
        commit(_AuthTypes.mutations.SET_UTM_TERM, utmTerm);
      }

      if (jwtToken) {
        commit(_AuthTypes.mutations.SET_TOKEN, jwtToken);
      }

      if (actionPay) {
        commit(_AuthTypes.mutations.SET_ACTION_PAY_IN_COOKIES, actionPay);
      }
    },

    async [_AuthTypes.actions.ADD_PAYER]({ dispatch }, payer: Payer) {
      try {
        await Api.payers.AddPayer(payer);
      } catch (error) {
        throw new Error(error);
      }
      await dispatch(_AuthTypes.actions.GET_PAYERS);
    },

    async [_AuthTypes.actions.UPDATE_PAYER_INFO]({ dispatch }, payer: Payer) {
      try {
        await Api.payers.UpdatePayerInfo(payer);
      } catch (error) {
        throw new Error(error);
      }
      await dispatch(_AuthTypes.actions.GET_PAYERS);
    },

    async [_AuthTypes.actions.REMOVE_PAYER]({ dispatch, state }, i: number) {
      try {
        await Api.payers.RemovePayer({ payer_id: state.payersId[i] });
      } catch (error) {
        throw new Error(error);
      }
      await dispatch(_AuthTypes.actions.GET_PAYERS);
    },

    async [_AuthTypes.actions.GET_PAYERS]({ commit, state }) {
      try {
        state.payersId = await Api.payers.GetPayers();
        const payers: Payer[] = [];
        if (state.payersId.length) {
          for (const payerId of state.payersId) {
            try {
              const payer: Payer = await Api.payers.GetPayerInfo({ payer_id: payerId });
              payers.push(payer);
            } catch (error) {
              console.error(error);
            }
          }
        }
        commit(_AuthTypes.mutations.SET_PAYERS, payers);
      } catch (error) {
        console.error(error);
      }
    },

    async [_AuthTypes.actions.GET_USER_SETTINGS]({ commit, state }) {
      try {
        const settings = await Api.user.GetUserSettings({ personID: state.userId });

        commit(_AuthTypes.mutations.SET_USER_SETTINGS, settings);
      } catch (error) {
        return Api.HttpError(error);
      }
    },

    async [_AuthTypes.actions.UPDATE_USER_SETTINGS]({ state, getters, dispatch }, { key, value }) {
      try {
        if (getters.getSetting(key)) {
          await Api.user.UpdateUserSettings({
            key,
            value,
            person_id: state.userId,
          });
        } else {
          await Api.user.SetUserSettings({
            key,
            value,
            person_id: state.userId,
          });
        }
        await dispatch(_AuthTypes.actions.GET_USER_SETTINGS);
      } catch (error) {
        return Api.HttpError(error);
      }
    },

    async [_AuthTypes.actions.CHECK_ADMINTAD_UID]({ commit, dispatch }) {
      const admintadUid = Cookies.get('admitadUid');
      const admitadUidCreatedDate = Cookies.get('admitadUidCreatedDate');
      if (admintadUid && admitadUidCreatedDate) {
        if (differenceInDays(Date.now(), admitadUidCreatedDate) > 30) {
          commit(_AuthTypes.mutations.SET_ADMITAD_UID, null);
          Cookies.remove('admitadUid');
          Cookies.remove('admitadUidCreatedDate');
        } else {
          commit(_AuthTypes.mutations.SET_ADMITAD_UID, admintadUid);
        }
      } else if (admintadUid) {
        await dispatch(_AuthTypes.actions.SAVE_ADMINTAD_UID, admintadUid);
      }
    },

    async [_AuthTypes.actions.SAVE_ADMINTAD_UID]({ commit }, admintadUid) {
      commit(_AuthTypes.mutations.SET_ADMITAD_UID, admintadUid);
      Cookies.set('admitadUid', admintadUid, { expires: 30 });
      Cookies.set('admitadUidCreatedDate', new Date(), { expires: 30 });
    },
  },

  mutations: {
    [_AuthTypes.mutations.SET_USER](state, user: User | null) {
      state.user = user;
      Analytics.ym('setUserID', get(user, 'id', null));
    },

    [_AuthTypes.mutations.SET_TOKEN](state, token: string | null) {
      state.token = token;
      Api.setToken(token);
    },

    [_AuthTypes.mutations.SET_USER_ID](state, userId: string | null) {
      state.userId = userId;
    },

    [_AuthTypes.mutations.SET_BALANCE](state, balance: string | number) {
      if (typeof balance === 'string') {
        balance = parseFloat(balance);
      }

      if (balance > state.balance) {
        state.balanceDiff = balance - state.balance;
      }

      state.balance = balance;
    },

    [_AuthTypes.mutations.SET_PARTNER_ID](state, partnerId: string) {
      state.partner = {
        id: partnerId,
        storeDate: partnerId ? Date.now() : null,
      };
    },

    [_AuthTypes.mutations.SET_PAYERS](state, payers: Payer[]) {
      state.payers = payers;
    },

    [_AuthTypes.mutations.SET_UTM_CAMPAIGN](state, utmCampaign: string) {
      state.utmCampaign = utmCampaign;
    },

    [_AuthTypes.mutations.SET_UTM_SOURCE](state, utmSource: string) {
      state.utmSource = utmSource;
      Cookies.set('utmSource', state.utmSource, { expires: 30 });
    },

    [_AuthTypes.mutations.SET_ADMITAD_UID](state, admitadUid: string) {
      state.admitadUid = admitadUid;
    },

    [_AuthTypes.mutations.SET_UTM_MEDIUM](state, utmMedium: string) {
      state.utmMedium = utmMedium;
    },

    [_AuthTypes.mutations.SET_UTM_COUPON](state, utmCoupon: string) {
      state.utmCoupon = utmCoupon;
    },

    [_AuthTypes.mutations.SET_UTM_CONTENT](state, utmContent: string) {
      state.utmContent = utmContent;
    },

    [_AuthTypes.mutations.SET_UTM_TERM](state, utmTerm: string) {
      state.utmTerm = utmTerm;
    },

    [_AuthTypes.mutations.SET_DOMAIN_URL](state, url: string) {
      state.domainUrl = url;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [_AuthTypes.mutations.SET_ACTION_PAY_IN_COOKIES](state, actionPay: string) {
      Cookies.set('actionPay', actionPay);
    },

    [_AuthTypes.mutations.SET_USER_SETTINGS](state, settings: any): void {
      state.settings = settings;
    },
  },
};
