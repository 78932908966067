var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h2", [_vm._v("Публичная оферта")]),
      _c("ol", [
        _c("li", [
          _c("strong", [_vm._v("Общие положения")]),
          _c("ol", [
            _c("li", [
              _vm._v(
                "\n          Настоящий публичный договор (далее Договор, договор) представляет собой официальное предложение сайта,\n          принадлежащего компании " +
                  _vm._s(_vm.company) +
                  ", далее именуемого «Исполнитель»,\n          направленное на оказание информационных услуг юридическому или дееспособному физическому лицу на условиях,\n          изложенных в настоящем договоре.\n        "
              )
            ]),
            _c("li", [
              _vm._v(
                "\n          В соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации\n          (далее по тексту – ГК РФ) данный документ является публичным Договором\n          (в части условий оказания услуг), а принятие изложенных ниже условий, регистрация на сайте\n          и оплата услуг Исполнителя - акцептом Оферты. В соответствии с пунктом 3 статьи 438 ГК РФ\n          акцепт Оферты равносилен заключению договора на условиях, изложенных в Оферте.\n        "
              )
            ]),
            _c("li", [
              _vm._v(
                "\n          При толковании условий настоящего публичного договора принимается во внимание буквальное значение\n          содержащихся в нем слов и выражений. Буквальное значение условия договора в случае его неясности\n          устанавливается путем сопоставления с другими условиями и смыслом договора в целом. Если правила\n          не позволяют определить содержание договора, до заключения настоящего договора сторонами должна\n          быть выяснена действительная общая воля сторон с учетом цели договора. При этом принимаются во\n          внимание все соответствующие обстоятельства, включая предшествующие договору переговоры и переписку,\n          практику, установившуюся во взаимных отношениях сторон, обычаи, последующее поведение сторон.\n          В случае акцепта оферты и оплаты услуг все условия настоящего договора считаются согласованными сторонами.\n        "
              )
            ])
          ])
        ]),
        _c("li", [
          _c("strong", [_vm._v("Определения и термины")]),
          _c("ol", [
            _c("li", [
              _vm._v(
                "\n          В целях настоящей Оферты нижеприведенные термины используются в следующем значении:\n          "
              ),
              _c("br"),
              _vm._v(
                "— Оферта – предложение заключить настоящий публичный договор на оказание\n          информационных услуг.\n          "
              ),
              _c("br"),
              _vm._v("— Сайт – интернет-сайт\n          "),
              _c("a", { attrs: { href: _vm.webSite } }, [
                _vm._v(_vm._s(_vm.webSite))
              ]),
              _vm._v(
                ", используемый\n          Исполнителем для оказания услуг.\n          "
              ),
              _c("br"),
              _vm._v(
                "— ЕГРН — Единый государственный реестр\n          недвижимости.\n          "
              ),
              _c("br"),
              _vm._v(
                "— Информационные услуги - услуги Исполнителя по предоставлению Заказчику информации, в виде аналитических отчетов на основании данных, полученной из документов, реестров и иных сайтов, в том числе из предоставленной Заказчиком информации.\n          "
              ),
              _c("br"),
              _vm._v(
                "- Аналитические услуги - услуги Исполнителя по предоставлению Заказчику информации, построенной на основании данных, полученных сервисом из официальных или иных открытых источников.\n          "
              ),
              _c("br"),
              _vm._v(
                "— Акцепт Оферты — полное и безоговорочное принятие Оферты путем осуществления действий, указанных в п. 9.1. Согласия.\n          "
              ),
              _c("br"),
              _vm._v(
                "— Заказчик — лицо, осуществившее Акцепт Оферты и являющееся заказчиком Информационных услуг на условиях заключаемого публичного договора.\n          "
              ),
              _c("br"),
              _vm._v(
                "— Договор на оказание информационных услуг (далее – Договор) – договор между Заказчиком и Исполнителем на предоставление Информационных услуг, который заключается посредством Акцепта Оферты.\n        "
              )
            ])
          ])
        ]),
        _vm._m(0),
        _vm._m(1),
        _vm._m(2),
        _vm._m(3),
        _vm._m(4),
        _c("li", [
          _c("strong", [_vm._v("Стоимость услуг и порядок оплаты")]),
          _c("ol", [
            _c("li", [
              _vm._v(
                "\n          Стоимость Информационных услуг, оказываемых Исполнителем, без учета комиссии платежной\n          системы, в зависимости от заказанного документа, определяется на основании\n          утвержденного прайс-листа, опубликованного на сайте\n          "
              ),
              _c("a", { attrs: { target: "_blank", href: _vm.webSite } }, [
                _vm._v(_vm._s(_vm.webSite))
              ]),
              _vm._v(
                ".\n          Исполнитель самостоятельно определяет ценовую политику и вносит сответствующие\n          изменения в прайс-лист, при этом стоимость оказываемой услуги определяется на момент\n          совершения заказа и является неизменной до момента оказания услуги в полном объеме.\n          Моментом совершения заказа считается акцепт Заказчика оферты путем, указанным п. 9.1 настоящей оферты.\n        "
              )
            ]),
            _c("li", [
              _vm._v("Все расчеты по Договору производятся в рублях РФ.")
            ]),
            _c("li", [
              _vm._v(
                "\n          Оплата Информационных услуг, оказываемых Исполнителем, производится в виде предварительной оплаты в размере 100% стоимости с использованием банковских карт, безналичных платежей или иных форм расчетов, допустимых на территории РФ.\n        "
              )
            ]),
            _c("li", [
              _vm._v(
                "\n          Заказчик не вправе запросить у Исполнителя возврат оплаты в случае отсутствия в Реестрах запрашиваемых сведений, поскольку получение отказа является, в том числе, результатом оказания услуги. В случае наличия аванса, возврат переведенных в качестве аванса средств производится в срок до 30 рабочих дней тем же способом, что и было осуществлено пополнение счета.\n        "
              )
            ])
          ])
        ]),
        _vm._m(5),
        _c("li", [
          _c("strong", [_vm._v("Срок действия и изменение условий оферты")]),
          _c("ol", [
            _c("li", [
              _vm._v(
                "\n          Настоящий договор вступает в силу с момента опубликования на Сайте по адресу\n          "
              ),
              _c("a", { attrs: { target: "_blank", href: _vm.webSite } }, [
                _vm._v(_vm._s(_vm.webSite))
              ]),
              _vm._v(
                ".\n          и действует до момента отзыва Исполнителем.\n        "
              )
            ]),
            _c("li", [
              _vm._v(
                "\n          Исполнитель оставляет за собой право внести изменения в условия Договора и/или отозвать Оферту в любой момент по своему усмотрению. В случае внесения Исполнителем изменений в Договор, такие изменения вступают в силу с момента опубликования на Сайте, если иной срок вступления изменений в силу не определен дополнительно при их публикации.\n        "
              )
            ]),
            _c("li", [
              _vm._v(
                "\n          В случае подписания настоящего договора сторонами на бумажном носителе, изменения, определенные пунктом 10.1 и 10.2 становятся обязательными для сторон по истечение 10 дней после направления Исполнителем в адрес Заказчика соответствующего уведомления по электронной почте и заказным письмом при отсутствии возражений со стороны Заказчика или его молчания. В случае, если после направления уведомления Заказчик продолжает пользоваться услугами сервиса на новых условиях, изменения считаются согласованными сторонами и стороны не могут ссылаться на незаключенность договора и внесенных в него изменений.\n        "
              )
            ]),
            _c("li", [
              _vm._v(
                "\n          Исполнитель вправе на свое личное усмотрение проводить различные акции, как разовые, так и длящиеся, стимулировать Заказчика иным способом пользоваться Информационными услугами на более выгодных условиях, чем предусмотрено настоящей Офертой и согласованными расценками.\n        "
              )
            ])
          ])
        ]),
        _vm._m(6)
      ]),
      _c("h5", [_vm._v("Реквизиты")]),
      _vm._v(_vm._s(_vm.company) + "\n  "),
      _vm.isFizCheck
        ? [
            _c("br"),
            _vm._v("ИНН 1674004902 | КПП 167401001 | ОГРН 1231600023643\n    "),
            _c("br"),
            _vm._v(
              "Адрес: 423825, Республика Татарстан, с. Большая Шильна, ул. Шоссейная, д. 62Б\n  "
            )
          ]
        : [
            _c("br"),
            _vm._v("ИНН 1650367985 | КПП 165001001 | ОГРН 1181690067932\n    "),
            _c("br"),
            _vm._v(
              "Адрес: 423800, г.Набережные Челны, ул.Машиностроительная, 91, пом.59\n  "
            )
          ],
      _c("br"),
      _vm._v("E-mail:\n  "),
      _c("a", { attrs: { href: "mailto:" + _vm.email } }, [
        _vm._v(" " + _vm._s(_vm.email))
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Предмет договора")]),
      _c("ol", [
        _c("li", [
          _vm._v(
            "\n          Исполнитель разрабатывает программное обеспечение и обязуется предоставить доступ к нему Заказчику, в результате чего он получит необходимую информацию, а также иные услуги, запрашиваемые в заявке Заказчика, оставляемой на сайте.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Информация, запрашиваемая в заявке Заказчика, предоставляется Исполнителем в виде документа (документов) в формате, определяемом Заказчиком (в т.ч. с применением электронной подписи), в соответствии с деталями запроса, указанными Заказчиком при заполнении заявки (адрес объекта, вид документа, кадастровый номер), в форме отправки ссылки на файл на e-mail, указанный Заказчиком или в иных формах, которые позволяют однозначно определить, что Заказчик получил запрошенную информацию.\n        "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Условия оказания услуг")]),
      _c("ol", [
        _c("li", [
          _vm._v(
            "\n          Исполнитель не отвечает за полную или временную неработоспособность телематических сервисов Заказчика, связанных с Услугой, указанной в п. 3.1 настоящей оферты и не может гарантировать 100% работоспособность указанных сервисов при оказании услуги, которые могут повлиять на предоставление и направление конечного результата в адрес Заказчика.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Услуга, предусмотренная п. 3.1. настоящей оферты, оказывается в срок, не более чем 7 рабочих дней, исчисляемых с момента акцепта оферты в порядке, предусмотренном пунктом 9.1 настоящего договора.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Исполнитель дополнительно по запросу Заказчика оказывает ему Аналитические услуги по\n          обработке первичной информации, полученной Заказчиком в результате выполнения Услуги 4.1 и\n          предоставляет результат оказания такой услуги в виде аналитического документа (документов)\n          в формате справки, содержащей предположительные выводы, основанные на анализе полученной информации\n          с применением методов математического и статистического моделирования. Исполнитель оставляет за собой\n          право определить используемые модели для оказания услуг.\n\n          "
          ),
          _c("br"),
          _vm._v(
            "Информация и выводы, полученные на основании обработки первичной информации носят\n          вероятностный характер и не могут быть абсолютно верными. Процент допустимой погрешности выводов\n          соответствует или более погрешности математической или статистической модели построения итогов.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Исполнитель считается исполнившим свои обязательства с момента направления запрашиваемой информации Заказчику, при этом не имеет значения дата получения указанной информации Заказчиком, а также понимание (или его отсутствие) в отношении предоставленной аналитической информации.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Полный перечень предоставляемых информационных услуг определяется их подробным описанием, представленным на соответствующей странице Сайта. В случае, если какая-то информация несет в себе разночтения, содержит элементы неопределенности или иным образом может быть трактована двояко, сторона обязана воздержаться от заказа такой услуги до момента получения разъяснения от Исполнителя. Если сторона воспользуется услугой до получения разъяснений, то Заказчик и Исполнитель считают, что стороны получили полную информацию относительно услуги и не вправе ссылаться на то, что услуга оказана некорректно (некачественно).\n        "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Права и обязанности исполнителя")]),
      _c("ol", [
        _c("li", [
          _vm._v(
            "\n          Исполнитель вправе самостоятельно определять способы выполнения задания Клиента,\n          используемую математическую, статистическую или иные формы моделирования или прогнозирования\n          результатов, самостоятельно организовывать всю работу по исполнению настоящего договора,\n          определять непосредственных исполнителей и распределять между ними обязанности.\n          Исполнитель имеет право привлекать к выполнению Заказа третьих лиц при условии,\n          что это не влияет на результат оказания Услуги. Исполнитель имеет право использовать для\n          работы любые не запрещенные законодательством РФ источники информации (в т.ч. закрытые источники\n          информации с ограниченным доступом сторонних потребителей) для исполнения настоящего договора.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Исполнитель вправе пользоваться услугами любых физических и юридических лиц в целях своевременного и качественного исполнения обязательств по договору. Привлечение к выполнению Услуг третьих лиц, в т.ч. в тот момент, когда услуга уже оказывается, не требует дополнительного согласования с Заказчиком.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Исполнитель вправе требовать и получать от Заказчика документы и информацию, необходимые для выполнения поручения, в том числе в ходе исполнения Задания. При предоставлении Заказчиком недостоверной информации, которая необходима для оказания услуги, оплаченная услуга считается оказанной независимо от полученного результата. В случае отказа от предоставлении информации не по вине Заказчика, Исполнитель возвращает денежные средства за услугу за вычетом фактически понесенных расходов на услуги, уже оказанные Заказчику. При направлении запроса на получение дополнительных документов срок, определенный п. 4.2 настоящей Оферты продлевается на срок, необходимый Заказчику на предоставление указанных документов.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Исполнитель обязуется оказать услуги, предусмотренные п.3.1. настоящего договора с соблюдением интересов Заказчику добросовестно, качественно и в пределах срока, который является разумным.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Исполнитель гарантирует, что представленные ему сведения не будут им использованы во вред интересам Заказчика или переданы третьим лицам иначе, чем для исполнения поставленной перед ним задачи.\n        "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Права и обязанности заказчика")]),
      _c("ol", [
        _c("li", [
          _vm._v(
            "\n          Заказчик обязан оплатить услуги исполнителя в порядке, указанном при заказе запрашиваемой информации.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Заказчик обязан предоставить Исполнителю полную и достоверную информацию, необходимую для исполнения настоящего договора.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "Заказчик имеет право требовать у Исполнителя сведения о ходе исполнения договора."
          )
        ]),
        _c("li", [
          _vm._v(
            "Заказчик подтверждает, что им получено согласие физического лица (далее — субъекта персональных данных), чьи персональные данные указываются в форме - электронной заявке на проверку собственника, на обработку его персональных данных, в том числе согласие на обработку данных Исполнителем. Информация, предусмотренная п. 3. ст. 18 Федерального закона от 27.07.2006 года №152-ФЗ «О персональных данных» субъекту предоставлена."
          )
        ]),
        _c("li", [
          _vm._v(
            "Заказчик подтверждает о своей осведомленности, что в случае направления запроса на предоставление ему аналитической информации, полученной на основании, в том числе предоставляемой самим Заказчиком, выписки из Единого государственного реестра недвижимости (далее- ЕГРН) или иной, предоставленной Заказчиком информации, услуги в соответствии с настоящей офертой предоставляются не с целью перепродажи сведений из ЕГРН, а с целью предоставления конечного результата, указанного в п. 3.1 настоящей оферты,  на основе сведений, полученных легальным способом путем совершения официальных запросов в соответствующие органы, в том числе полученных,но уже известных заранее Заказчику сведений из выписки из ЕГРН."
          )
        ]),
        _c("li", [
          _vm._v(
            "Заказчик подтверждает, что обязан по требованию Исполнителя, в связи с поступлением запроса от уполномоченного органа по защите прав субъектов персональных данных или суда, незамедлительно предоставить согласия субъектов персональных данных."
          )
        ]),
        _c("li", [
          _vm._v(
            "Заказчик обязуется не размещать заведомо недостоверную информацию, уведомлен и соглашается с тем, что Исполнитель не несет ответственность за возможное нецелевое использование персональной информации, произошедшее из-за технических неполадок в программном обеспечении, серверах, компьютерных сетях, находящихся вне контроля Исполнителя, или в результате противоправных действий третьих лиц, также Исполнитель не несет ответственность за возможное предоставление некорректного отчета. Заказчик подтверждает и соглашается с поручением на обработку персональных данных, указанных в соглашении о политике о персональных данных Исполнителя."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Ответственность сторон")]),
      _c("ol", [
        _c("li", [
          _vm._v(
            "\n          За неисполнение или ненадлежащее исполнение условий настоящего договора Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации и условиями настоящего договора.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Исполнитель не несет ответственности за недостоверность, неполноту, содержание информации, предоставленной органом кадастрового учета, указанном в ст. 3 Федерального закона «О государственной регистрации кадастре недвижимости» № 218-ФЗ от 13.07.2015 года.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Стороны не несут ответственность за неисполнение условий настоящего договора, если такое неисполнение явилось следствием наступления обстоятельств непреодолимой силы, предусмотреть и/или предотвратить которые разумными мерами не могла ни одна из Сторон.\n        "
          )
        ]),
        _c("li", [
          _vm._v("Бремя доказывания убытков лежит на потерпевшей Стороне.")
        ]),
        _c("li", [
          _vm._v(
            "\n          В случае предоставления аналитической информации на основании полученной выписки из ЕГРН или иной, предоставленной Заказчиком информации, Заказчик считается уведомленным, что все предоставленные ему аналитические рекомендации носят исключительно вероятностный характер, полученный на основании анализа большого объема однотипных данных, и степень наступления последствий или наличия указанных проблем не носят 100% вероятности их наступления, а также не могут являться основанием для предъявления к Исполнителю материальных или иных требований, связанных с принятыми на основании этих данных решений, в т.ч. требующих оказания любых дополнительных услуг.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Стороны согласовали, что на основании положения ст. 400 ГК РФ максимальный размер всей ответственности за нарушение условий настоящего договора не может превышать стоимость услуги, по которой одна из сторон требует компенсировать ей неустойку. Стороны согласовали тот факт, что максимальный размер неустойки, определенный настоящей статьей, включает в себя все пени, штрафы, неустойки и убытки, которые могут быть заявлены стороной для компенсации.\n        "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Акцепт оферты и заключение публичного договора")]),
      _c("ol", [
        _c("li", [
          _vm._v(
            "\n          Заказчик производит Акцепт Оферты путем оплаты Информационных услуг, в отношении которых заключается сделка, в течение срока для Акцепта с учетом условий гл. 8, 9 настоящего договора.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Срок для Акцепта составляет 1 (один) рабочий день с момента выставления для оплаты суммы, указанной в пункте 8.3 настоящего договора, на оплату Информационных услуг.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          В случае если Заказчик произвел Акцепт Оферты в срок, превышающий указанный в п. 9.2. Оферты, Исполнитель имеет право, по своему усмотрению, принять такой Акцепт и приступить к оказанию Информационных услуг, либо отказаться от принятия такого Акцепта, возвратив Заказчику сумму предоплаты.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          Стороны оставляют за собой право подписать настоящий договора, скрепив его подписями и печатями сторон, при этом все условия, указанные в настоящем договоре, приобретают условия индивидуально согласованных (утрата публичности договора). В указанном в настоящем пункте случае датой заключения договора будет являться дата подписания договора второй стороной, при этом стороны согласовали, что договор может быть подписан, в т.ч., в электронной форме с использованием средств электронной подписи.\n        "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Форс-мажор")]),
      _c("ol", [
        _c("li", [
          _vm._v(
            "\n          Невозможность надлежащего оказания Услуги вследствие наступления форс-мажорных обстоятельств, по условиям данного Соглашения это не следует рассматривать как нарушение Исполнителем обязательств по отношению к Заказчику.\n        "
          )
        ]),
        _c("li", [
          _vm._v(
            "\n          К форс-мажорным обстоятельствам, прямо или косвенно влияющим на выполнение Исполнителем настоящего Соглашения относятся стихийные бедствия, воздействия сил или причин за пределами разумного контроля Исполнителя, включая, но не ограничиваясь: отключение интернета, компьютеров, телекоммуникаций или любых других отказов оборудования, отключения электропитания, действия органов государственной власти, в том числе решения отечественных или зарубежных судов или трибуналов или неисполнение обязательств третьими лицами, также сбои и технические работы в ЕГРН.\n        "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }