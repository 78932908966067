





import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component({
  name: 'AppCollapse',

  props: {
    value: {
      type: Array,
      default: (): string[] => [],
    },
    accordion: Boolean,
  },

  provide() {
    return {
      Collapse: this,
    };
  },
})
export default class Collapse extends Vue {
  // props
  value: string[];
  accordion: boolean;
  // props end

  // data()
  activeItems: string[] = [].concat(this.value);
  // data end

  @Watch('value')
  valueHandler(value: string[]) {
    this.activeItems = [].concat(value);
  }

  setActiveItem(item: string) {
    const activeItems = this.activeItems.slice();

    if (this.accordion) {
      if (activeItems[0] === item) {
        activeItems.length = 0;
      } else {
        activeItems[0] = item;
      }
    } else {
      const index = activeItems.findIndex((i: any) => i === item);

      if (index !== -1) {
        activeItems.splice(index, 1);
      } else {
        activeItems.push(item);
      }
    }

    this.activeItems = activeItems;
    this.$emit('input', activeItems);
  }
}
