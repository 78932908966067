var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "app-collapse",
        [
          _c(
            "app-collapse-item",
            {
              staticClass:
                "risk-report-item risk-report-item_conclusion mt-3 mb-3",
              attrs: { opened: "" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "risk-report-item__title-inner row" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "risk-report-item__name col-12 col-md-auto"
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-balance-scale text-primary mr-2"
                              }),
                              _vm._v(" Заключение и рекомендации\n          ")
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "risk-report-item__status pr-4 text-danger col-12 col-md-auto pl-4 pl-md-0"
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-exclamation-circle"
                              }),
                              _vm._v(" Возможны риски\n          ")
                            ]
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("div", { staticClass: "pt-3" }, [
                _vm._v(
                  "\n        Результат проверки на риски, рекомендации по их уменьшению "
                ),
                _c("br"),
                _c(
                  "span",
                  {
                    staticClass: "risk-report-item__after-order",
                    on: { click: _vm.showDialog }
                  },
                  [
                    _c("i", { staticClass: "fas fa-lock fa-xs fa-fw mr-1" }),
                    _vm._v(" доступно после заказа")
                  ]
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }