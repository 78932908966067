import { Item } from 'photoswipe';
import { staticServerPath } from 'shared/plugins/staticServerPath.plugin';

declare interface PswpItem extends Item {
  title?: string;
}
const riskItems: PswpItem[] = [];

for (let i = 1; i < 12; i++) {
  riskItems.push({
    src: staticServerPath(`/images/risk/example_${i}.jpg`),
    w: i <= 2 ? 1656 : 1700,
    h: i <= 2 ? 2342 : 2200,
  });
}

export const RISK_EXAMPLES: PswpItem[] = riskItems;

export const EGRN_EXAMPLES: PswpItem[] = [
  {
    src: staticServerPath('/images/statement_example/EGRN-page1.png'),
    w: 1260,
    h: 569,
    title: 'Отчет об объекте недвижимости. Страница - 1',
  },
  {
    src: staticServerPath('/images/statement_example/EGRN-page2.png'),
    w: 1262,
    h: 611,
    title: 'Отчет об объекте недвижимости. Страница - 2',
  },
];

export const RIGHT_LIST_EXAMPLES: PswpItem[] = [
  {
    src: staticServerPath('/images/statement_example/RightList.png'),
    w: 594,
    h: 673,
    title: 'Отчет о переходе прав на объект недвижимости',
  },
];

export const EXAMPLE_TYPES: SimpleObject<PswpItem[]> = {
  risk: RISK_EXAMPLES,
  egrn: EGRN_EXAMPLES,
  rightList: RIGHT_LIST_EXAMPLES,
};
