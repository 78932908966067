









































































































































import Vue from 'vue';
import Component from 'vue-class-component';

import ActivatePromoCode from 'shared/components/Product/ActivatePromoCode.vue';
import AppYandexMarketFeedback from '@/components/layout/YandexMarketFeedback/YandexMarketFeedback.vue';

@Component({
  name: 'AppUserMenu',
  components: { AppYandexMarketFeedback },
  props: {
    showWidget: Boolean,
  },
})
export default class UserMenu extends Vue {
  // data()
  vkInited: boolean = false;

  // computed
  get user(): User {
    return this.$store.state.auth.user;
  }

  get username(): string {
    return this.user.name || (this.user.email || '').split('@')[0];
  }

  // lifecycle
  mounted() {
    this.initVkOpenApi();
  }

  // methods
  openDialog() {
    this.$dialog.open({
      component: ActivatePromoCode,
    });
  }

  initVkOpenApi() {
    if (!document.getElementById('vk_openapi')) {
      (window as any).vkAsyncInit = this.initGroup;

      const script = document.createElement('script');

      script.id = 'vk_openapi';
      script.type = 'text/javascript';
      script.src = 'https://vk.com/js/api/openapi.js?160';
      script.async = true;
      document.body.appendChild(script);
    } else {
      this.initGroup();
    }
  }

  initGroup() {
    if (
      this.$refs.vkWidget &&
      !(this.$refs.vkWidget as HTMLElement).childElementCount &&
      !this.vkInited
    ) {
      this.vkInited = true;
      VK.Widgets.Group(
        'vk-widget',
        { mode: 1, no_cover: 1, width: '240px', color3: '2281f9' },
        164242898,
      );
    }
  }

  goToArticle() {
    this.$router.push({ name: 'risk' });
  }
}
