export const NULL_UUID = '00000000-0000-0000-0000-000000000000';

export const OFFICIAL_DOCUMENTS = 'official_documents';

export enum Role {
  Admin = 0,
  Client = 1,
  Realtor = 2,
  Agent = 3,
  Partner = 4,
  Customer = 5,
  Seller = 6,
  HeadOfAgency = 7,
  Appraiser = 8,
  Lawyer = 9,
  EstateDeveloper = 10,
}

export const RoleNames: SimpleObject<string> = {
  [Role.Admin]: 'Админ',
  [Role.Client]: 'Клиент',
  [Role.Realtor]: 'Риэлтор',
  [Role.Agent]: '<Бухгалтер>',
  [Role.Partner]: 'Партнер',
  [Role.Customer]: 'Покупатель',
  [Role.Seller]: 'Продавец',
  [Role.HeadOfAgency]: 'Руководитель Агентства Недвижимости',
  [Role.Appraiser]: 'Оценщик',
  [Role.Lawyer]: 'Юрист',
  [Role.EstateDeveloper]: 'Застройщик',
};
