import Vue from 'vue';
import { RealtyCloudApi } from './api';
import { AxiosResponse, AxiosError } from 'axios';

function mapResponseData(response: AxiosResponse<ApiRawResponse>) {
  const axiosResponseData = response.data;

  if (axiosResponseData) {
    return axiosResponseData.data || {};
  }

  return axiosResponseData || {};
}

export class ApiService extends RealtyCloudApi {
  public raw: RealtyCloudApi = new RealtyCloudApi();

  public setToken(token: string) {
    super.setToken(token);
    this.raw.setToken(token);
  }

  public setSourceSite(sourceSite: string) {
    super.setSourceSite(sourceSite);
    this.raw.setSourceSite(sourceSite);
  }

  public HttpError<T = AxiosError>(error: T): Promise<T> {
    console.error(error);
    return Promise.reject(error);
  }

  public _getErrorMessage(axiosError: AxiosError): string {
    const data = axiosError.response ? axiosError.response.data : {};

    return data.error;
  }

  public getErrorMessage(axiosError: AxiosError): string {
    let message = this._getErrorMessage(axiosError);

    if (message === 'file not found') {
      message = 'Файл не найден';
    }

    if (message === 'maximum edit limit reached: 1' || message === 'maximum edit limit reached: 2') {
      message = 'Превышен лимит редактирования данных. Необходимо создать новый заказ.';
    }

    if (message === 'Suspicious activity detected') {
      return message;
    }

    if (message === 'Невалидный e-mail') {
      return message;
    }

    if (message === 'Такой e-mail не существует') {
      return message;
    }

    if (!/[а-яА-Я]/.test(message)) {
      message = 'Что то пошло не так, неизвестная ошибка';
    }

    return message;
  }
}

export const Api = new ApiService(mapResponseData);

declare module 'vue/types/vue' {
  interface Vue {
    $api: ApiService;
  }
}

export function installApiPlugin(_Vue: typeof Vue, options: { source?: string } = {}) {
  _Vue.prototype.$api = Api;

  if (options.source) {
    Api.setSourceSite(options.source);
  }
}
