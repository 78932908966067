import Vue from 'vue';
import _ from 'lodash';
import qs from 'query-string';

import './inject-external-scripts';
import './registerPlugins';
import App from './App.vue';
import router from './router';
import store from './store';

import AppPaymentSuccessDialog from '@/components/layout/Dialogs/PaymentSuccess.vue';
import { AuthTypes } from 'shared/store/modules/auth/types';

Vue.config.productionTip = false;

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    validations?: any;
  }
}

new Vue({
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'Заказать выписку из Росреестра ЕГРН и ЕГРП. Узнать информацию о собственности дома. Узнать информацию о собственности квартиры. Узнать информацию о собственности земельного участка и недвижимости. Отчет о переходе прав',
      },
    ],
  },
  router,
  store,
  async created() {
    const query = qs.parse(location.search);

    this.$store.commit('setQuery', query);

    if (query.utm_campaign) {
      this.$store.commit('setUtmCampaign', query.utm_campaign);
    }

    this.$store.dispatch(AuthTypes.actions.CHECK_URL_QUERY);
    this.$store.dispatch('marketing/checkCampaign');
    this.$store.dispatch('getOrdersDoneCount');
    this.$store.dispatch('settings/getItems');

    await Promise.all([
      this.$store.dispatch(AuthTypes.actions.CHECK_TOKEN),
      this.$store.dispatch('getProducts', this.$store.state.marketing.campaign),
    ]);
    const auth = this.$store.state.auth;
    if (auth.balanceDiff > 0) {
      this.$dialog.open({
        component: AppPaymentSuccessDialog,
        props: {
          text: `
            Баланс успешно пополнен на <b>${auth.balanceDiff} руб.</b> <br />
            Текущий баланс: <b>${auth.balance} руб.</b> <br />
            По мере заказа услуг, денежные средства будут списаны с баланса.`,
        },
      });
    }

    const id = _.get(this.$store.state, 'auth.user.id', '');

    if (id) {
      this.$analytics.ym('setUserID', id);
      this.$analytics.ym('userParams', { UserID: id, user_id: id });
    }
  },
  render: (h) => h(App),
}).$mount('#app');
