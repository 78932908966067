














































































import Vue from 'vue';
import { mapState } from 'vuex';
import Component from 'vue-class-component';

import { AuthTypes } from 'shared/store/modules/auth/types';

import AppUserMenu from '@/components/layout/UserMenu/UserMenu.vue';
import AppActivatePromoCodeDialog from 'shared/components/Product/ActivatePromoCode.vue';

@Component({
  name: 'AppMobileMenu',

  components: { AppUserMenu },

  popupOptions: {
    name: 'popup_menu popup_default',
  },

  computed: {
    ...mapState('auth', [ 'user', 'balance' ]),
  },
})
export default class MobileMenu extends Vue {
  // computed
  user: User;

  get username(): string {
    if (this.user) {
      return this.user.name || (this.user.email || '').split('@')[0];
    }

    return '';
  }

  // methods
  onMenuClick(event: MouseEvent) {
    const target: Element = event.target as Element;

    if (target.matches('[data-menu-close]')) {
      this.$dialog.close();
    }
  }

  showActivatePromoCodeDialog() {
    this.$dialog.open({
      component: AppActivatePromoCodeDialog,
    });
  }

  logout() {
    this.$store.dispatch(AuthTypes.actions.LOGOUT);
    this.$dialog.close();
  }
}
