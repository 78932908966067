import { Module } from 'vuex';

import { Api } from 'shared/api';

export const adminModule: Module<AdminState, RootState> = {
  namespaced: true,

  state: {
    user: null,
  },

  actions: {
    async getPersonInfo({ commit }, userId: string) {
      try {
        const user = await Api.admin.GetUserInfo(userId);

        commit('setUser', user);
      } catch (error) {
        console.error(error);
      }
    },
  },

  mutations: {
    setUser(state, user: User | null) {
      state.user = user;
    },
  },
};
