import { VuexModule, Module, Action, RegisterOptions } from 'vuex-class-modules';
import store from '../index';

import { Api } from 'shared/api';

@Module({ generateMutationSetters: true })
class VuexPartnerModule extends VuexModule {
  stats: PartnerStats = null;

  @Action
  async fetchStats() {
    if (this.stats) {
      return;
    }

    try {
      const stats = await Api.partner.Stats();

      this.stats = stats;
    } catch (error) {
      console.error(error);
    }
  }
}

const PartnerModule = new VuexPartnerModule({ name: 'partner', store });

export default PartnerModule;
