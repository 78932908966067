import cloneDeep from 'lodash/cloneDeep';

export default function removeTypesNamespace<T>(namespace: string, types: T): T {
  const typesWithoutNamespace = cloneDeep(types);

  const removeNamespace = (obj: any) => {
    for (const typeKey in obj) {
      const element: string | Record<string, any> = obj[typeKey];

      if (typeof element === 'object') {
        removeNamespace(element);
      } else {
        obj[typeKey] = element.replace(namespace, '');
      }
    }
  };

  removeNamespace(typesWithoutNamespace);

  return typesWithoutNamespace;
}
