






























import Vue from 'vue';
import Component from 'vue-class-component';

import ProductEmitter, { ProductEmitterEvents } from 'shared/components/Product/productEmitter';

@Component({
  components: {
  },
})
export default class OwnerRiskReportExample extends Vue {
  // props

  // lifecycle hooks

  // methods
  showDialog() {
    ProductEmitter.emit(ProductEmitterEvents.showDialog, 'product-list');
  }
}
