var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("app-poll", {
        attrs: { items: _vm.polls },
        scopedSlots: _vm._u([
          {
            key: "poll-complete",
            fn: function() {
              return [
                _c("img", {
                  staticClass: "poll__complete-img",
                  attrs: { src: "/images/icons/complete.svg" }
                }),
                _c(
                  "div",
                  { staticClass: "poll__complete-text" },
                  [
                    _c("strong", [_vm._v("Спасибо")]),
                    _vm._v(
                      "\n        Что ответили на вопросы. Подробнее об отчете можете почитать тут:\n        "
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "risk-questionnaire",
                            query: { query: _vm.query }
                          }
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.goToArticle($event)
                          }
                        }
                      },
                      [_vm._v("\n          Отчет о рисках\n        ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }