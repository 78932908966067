var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "risk-user-form" },
    [
      _c("app-dialog-close"),
      _c(
        "div",
        { staticClass: "popup__body" },
        [
          _vm._m(0),
          _c("p", [
            _vm._v(
              "\n      Мы заказали необходимые документы из Росреестра.\n    "
            )
          ]),
          _vm._m(1),
          _c(
            "app-collapse",
            [
              _c(
                "app-collapse-item",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("h6", { staticClass: "m-0" }, [
                            _vm._v(
                              "\n            Зачем они нужны и где их взять?\n          "
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("div", { staticClass: "pt-3" }, [
                    _vm._v(
                      "\n          Эти данные нужны для организации проверки по базам МВД, Судебным приставам, реестра о\n          банкротстве и других базах. Паспортные данные иногда отражены в договоре купли-продаже,\n          свидетельстве о праве собственности и иных документах. Если вы планируете купить\n          недвижимость, то важно запросить документы у собственника, чтобы удостовериться, что\n          сделку ведет действительно собственник.\n        "
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.hideForm
        ? [
            _c(
              "div",
              { staticClass: "popup__body border-top" },
              [
                _c(
                  "app-button",
                  {
                    attrs: { size: "lg", block: "" },
                    on: { click: _vm.hideDialog }
                  },
                  [_vm._v("\n        Добавить собственника\n      ")]
                ),
                _c(
                  "app-button",
                  {
                    attrs: { variant: "white", size: "lg", block: "" },
                    on: { click: _vm.closeDialog }
                  },
                  [_vm._v("\n        Позже\n      ")]
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center pb-3" }, [
      _c("h5", { staticClass: "mb-0" }, [
        _vm._v("\n        Добавьте информацию о собственниках\n      ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      Для того чтобы проверить собственников объекта нам нужны данные о владельцах (дата рождения,\n      паспортные данные). Сколько владельцев, столько и документов нужно. Если под рукой нет\n      документов,\n      "
      ),
      _c("strong", { staticClass: "text-success font-weight-500" }, [
        _vm._v("к заполнению этой формы можно вернуться позже.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }