import Vue from 'vue';
import formatDate from 'date-fns/format';
import locale from 'date-fns/locale/ru';

export default function dateFilter(_Vue: typeof Vue) {
  _Vue.filter('date', (date: number | string | Date, format: string) => {
    // https://date-fns.org/v1.30.1/docs/format#description
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return formatDate(date, format, { locale });
  });
}
