








import Vue from 'vue';

let player: any;

function apiLoadCallback() {
  player = new (window as any).YT.Player('video-player', {
    videoId: 'cRLoXg_rB7Y',
    width: '100%',
    height: '100%',
  });
}

(window as any).onYouTubeIframeAPIReady = apiLoadCallback;

export default Vue.extend({
  name: 'AppVideoAboutServiceDialog',
  mounted() {
    this.loadYoutubeApi();
  },
  beforeDestroy() {
    if (player) {
      if ((window as any).YT.PlayerState.PLAYING) {
        player.pauseVideo();
      }

      player.destroy();
    }

    player = null;
  },
  methods: {
    loadYoutubeApi() {
      const selector = 'youtube-iframe-api';
      if (document.getElementById(selector)) {
        return apiLoadCallback();
      }

      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      tag.id = selector;
      document.head.appendChild(tag);
    },
  },
});
