














import Vue from 'vue';
import AppActivatePromoCodeForm from 'shared/components/Product/ActivatePromoCodeForm.vue';

export default Vue.extend({
  name: 'AppActivatePromoCodeDialog',
  components: { AppActivatePromoCodeForm },
  props: {
    user: Object || undefined,
    onLogin: Function,
    fromDashboard: {
      type: Boolean,
      default: false,
    },
  },

  popupOptions: {
    size: 'xs',
  },
});
