import Vue from 'vue';

import CollapseVue from './Collapse.vue';
import CollapseItemVue from './CollapseItem.vue';
import CollapseContentVue from './CollapseContent.vue';

export default function collapseComponent(_Vue: typeof Vue) {
  _Vue.component('AppCollapse', CollapseVue);
  _Vue.component('AppCollapseItem', CollapseItemVue);
  _Vue.component('AppCollapseContent', CollapseContentVue);
}
