import { DirectiveOptions, VNode, VueConstructor } from 'vue';

import { DirectiveBinding } from 'vue/types/options';

type OutsideClickHandler = (mouseup: MouseEvent, mousedown: MouseEvent) => void;

const nodeList: any[] = [];
const ctx = '@@clickoutsideContext';

let startClick: MouseEvent;
let seed = 0;

document.addEventListener('mousedown', (e: MouseEvent) => (startClick = e));

document.addEventListener('mouseup', (e: MouseEvent) => {
  nodeList.forEach((node: any) => node[ctx].documentHandler(e, startClick));
});

function createDocumentHandler(
  el: any,
  binding: DirectiveBinding,
  vnode: any,
): OutsideClickHandler {
  return (mouseup: MouseEvent, mousedown: MouseEvent) => {
    if (
      !vnode ||
      !vnode.context ||
      !mouseup.target ||
      !mousedown.target ||
      el.contains(mouseup.target) ||
      el.contains(mousedown.target) ||
      el === mouseup.target
    ) {
      return;
    }

    if (binding.expression && el[ctx].methodName && vnode.context[el[ctx].methodName]) {
      vnode.context[el[ctx].methodName]();
    } else if (el[ctx].bindingFn) {
      el[ctx].bindingFn();
    }
  };
}

const clickOutsideDirective: DirectiveOptions = {
  bind(el: any, binding: DirectiveBinding, vnode: VNode): void {
    nodeList.push(el);
    const id: number = seed++;
    el[ctx] = {
      id,
      documentHandler: createDocumentHandler(el, binding, vnode),
      methodName: binding.expression,
      bindingFn: binding.value,
    };
  },

  update(el: any, binding: DirectiveBinding, vnode: VNode): void {
    el[ctx].documentHandler = createDocumentHandler(el, binding, vnode);
    el[ctx].methodName = binding.expression;
    el[ctx].bindingFn = binding.value;
  },

  unbind(el: any): void {
    const len: number = nodeList.length;

    for (let i = 0; i < len; i++) {
      if (nodeList[i][ctx].id === el[ctx].id) {
        nodeList.splice(i, 1);
        break;
      }
    }
    delete el[ctx];
  },
};

export default function installClickOutsideDirective(Vue: VueConstructor) {
  Vue.directive('click-outside', clickOutsideDirective);
}
