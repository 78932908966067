































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppDiscountSuccessDialog',
  popupOptions: {
    size: 'sm',
  },
})
export default class DiscountSuccessDialog extends Vue {
  // methods
  closeDialog() {
    this.$dialog.close();
  }
}

