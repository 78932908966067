var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "report-item row no-gutters flex-nowrap" }, [
    _c("i", {
      staticClass: "report-item__icon col-auto fa-fw mr-3 mt-1",
      class: _vm.iconStyle + " fa-" + _vm.icon
    }),
    _c("div", { staticClass: "report-item__body col" }, [
      _c("div", { staticClass: "report-item__head" }, [
        _c("span", { staticClass: "report-item__title font-weight-500" }, [
          _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
        ]),
        _vm.$slots.status
          ? _c(
              "small",
              { staticClass: "ml-2 small" },
              [_vm.isCompleted ? [_vm._t("status")] : _vm._e()],
              2
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "report-item__content" },
        [
          _vm.isCompleted
            ? [_vm._t("default")]
            : _vm.isNotRequired
            ? [
                _vm.missingRequirements
                  ? _c(
                      "span",
                      { staticClass: "font-weight-500 text-muted" },
                      [
                        _vm._v("\n          Нужно добавить:\n          "),
                        _vm._l(_vm.missingRequirements, function(item, i) {
                          return _c(
                            "span",
                            { key: item },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item) +
                                  "\n            "
                              ),
                              i !== _vm.missingRequirements - 1 &&
                              _vm.missingRequirements > 1
                                ? [_vm._v("\n              ,\n            ")]
                                : _vm._e()
                            ],
                            2
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._t("not-required", [
                  !_vm.missingRequirements
                    ? _c("span", { staticClass: "text-warning" }, [
                        _vm._v(" Недостаточно данных ")
                      ])
                    : _vm._e()
                ])
              ]
            : _vm.isNotPaid
            ? [
                _c("span", { staticClass: "font-weight-500 text-muted" }, [
                  _vm._v("\n          Доступно после заказа\n        ")
                ])
              ]
            : [
                _vm._m(0),
                _vm.isCanceled
                  ? _c("div", { staticClass: "font-weight-500 text-muted" }, [
                      _vm._v(
                        "\n          Сервис временно недоступен. Проверка будет завершена позднее.\n        "
                      )
                    ])
                  : _vm._e()
              ]
        ],
        2
      )
    ]),
    _vm.images && _vm.images.length && !_vm.$isServer
      ? _c("div", { staticClass: "col-auto" }, [
          _c("i", {
            staticClass:
              "report-item__photo-icon fa-fw mr-2 mr-md-3 fas fa-camera",
            on: { click: _vm.showImage }
          })
        ])
      : _vm._e(),
    _vm.source && !_vm.$isServer
      ? _c("div", { staticClass: "col-auto" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { placement: "top-end", interactive: true },
                  expression: "{ placement: 'top-end', interactive: true }"
                }
              ],
              staticClass: "report-item__source-icon"
            },
            [
              _c("i", { staticClass: "fa fa-sm fa-info-circle" }),
              _c("div", { attrs: { "data-tippy-content": "hide" } }, [
                _vm._v("\n        Источник "),
                _c(
                  "a",
                  { attrs: { href: _vm.source.link, target: "_blank" } },
                  [_vm._v(_vm._s(_vm.source.domain))]
                )
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-muted" }, [
      _vm._v("\n          Проверка в данный момент находится "),
      _c("b", [_vm._v("в работе")]),
      _c("i", { staticClass: "fas fa-spinner fa-sm fa-spin text-info ml-2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }