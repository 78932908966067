var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c("app-header", {
        class: { "bg-white": _vm.whiteBg },
        attrs: { "hide-search": _vm.hideSearch }
      }),
      _c("router-view"),
      _c("app-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }