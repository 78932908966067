import { RealtyCloudApi } from '../api';

interface WholesaleCredentials {
  email: string;
  phone: string;
}

interface SendEmailData {
  template: string;
  email: string;
  title: string;
}

export interface ApiEventsMethods {
  WholesaleRequest<T>(data: WholesaleCredentials): Promise<T>;
  SendEmail<T>(data: SendEmailData): Promise<T>;
}

export function eventsMethods(this: RealtyCloudApi): ApiEventsMethods {
  return {
    WholesaleRequest: (data: WholesaleCredentials) => {
      return this.request({
        url: '/events/opt',
        method: 'POST',
        data,
      });
    },

    SendEmail: (data: SendEmailData) => {
      const messageTaskData = {
        name: data.title,
        description: 'send email',
        job_type: 'send_mail',
        type_id: '7132e8cf-9be2-4566-9f30-3b9e6300d71c', // hardcode
        status_id: '9d9ed6cd-e2f7-4162-96e9-0a55756e2029', // hardcode
        metadata: {
          0: process.env.NODE_ENV === 'development' ? 'prozaak@rambler.ru' : data.email,
          1: data.title,
          2: data.template,
          3: false,
        },
      };

      return this.request({
        method: 'POST',
        url: '/task',
        data: messageTaskData,
      });
    },
  };
}
