








import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppCarouselItem',

  inject: [ 'Carousel' ],
})
export default class CarouselItem extends Vue {
  Carousel: CarouselProvider;
  // props
  // props end
  // data()
  index: number = 0;
  // data() end
  // computed
  get visible(): boolean {
    return this.Carousel.current === this.index;
  }

  // lifecycle hooks
  created() {
    this.index = this.Carousel.itemsCount;
    this.Carousel.itemsCount += 1;
  }
  // methods
}
