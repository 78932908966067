var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "control" }, [
    _c("input", {
      staticClass: "control__input",
      attrs: { disabled: _vm.disabled, type: "checkbox" },
      domProps: { checked: _vm.isChecked },
      on: { change: _vm.onChange }
    }),
    _c("span", {
      class: [
        "control__indicator",
        "control__indicator_checkbox",
        _vm.size && "control__indicator_" + _vm.size
      ]
    }),
    _vm.$slots.default || _vm.label
      ? _c(
          "span",
          { staticClass: "control__text" },
          [_vm._t("default", [_vm._v(_vm._s(_vm.label))])],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }