


















import Vue from 'vue';

export default Vue.extend({
  name: 'AppCollapseContent',

  props: {
    visible: Boolean,
  },

  methods: {
    start(el: HTMLElement) {
      el.style.height = el.scrollHeight + 'px';
    },

    end(el: HTMLElement) {
      el.style.height = '';
    },
  },
});
