var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h5", { staticClass: "text-center mb-4" }, [
        _vm._v("\n    Добавление кадастрового номера\n  ")
      ]),
      _c(
        "app-form",
        {
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.model,
            rules: _vm.validationRules
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    { attrs: { type: "submit", loading: loading, block: "" } },
                    [_vm._v("\n        Добавить\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "app-form-group",
            { attrs: { "model-name": "kadastrNumber" } },
            [
              _c("app-input", {
                attrs: {
                  "input-class": "text-center",
                  placeholder: "Введите кадастровый номер"
                },
                model: {
                  value: _vm.model.kadastrNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "kadastrNumber", $$v)
                  },
                  expression: "model.kadastrNumber"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }