

































































































import Vue from 'vue';
import orderBy from 'lodash/orderBy';

interface State {
  sortBy: {
    prop?: string;
    direction: 'desc' | 'asc';
  };
}

export default Vue.extend({
  name: 'AppDataTable',

  props: {
    itemKey: {
      type: String,
      default: 'id',
    },
    headers: {
      type: [ Array, Object ],
      default: (): any[] => [],
    },
    items: {
      type: [ Array, Object ],
      default: (): any[] => [],
    },
    pagination: Object,
    loading: Boolean,
    disableHeader: Boolean,
    hover: Boolean,
    small: Boolean,
    borderless: Boolean,
    bordered: Boolean,
    striped: Boolean,
    headColor: String,
    emptyText: {
      type: String,
      default: 'По выбранному фильтру элементы не найдены',
    },
  },

  data(): State {
    return {
      sortBy: {
        prop: '',
        direction: 'desc',
      },
    };
  },

  computed: {
    sortedItems(): any[] {
      if (!this.sortBy.prop) {
        return this.items;
      }

      const { prop, direction } = this.sortBy;

      return orderBy(
        this.items,
        (item: any) => {
          const value = item[prop];

          if (typeof value === 'string') {
            return value.toLowerCase();
          }
        },
        direction,
      );
    },

    filteredItems(): any[] {
      return [];
    },
  },

  methods: {
    toggleSort(header: TableHeaderItem) {
      let direction = this.sortBy.direction;
      let prop = header.sortProp;

      if (this.sortBy.prop === prop) {
        if (direction === 'asc') {
          prop = '';
        }

        direction = this.sortBy.direction === 'asc' ? 'desc' : 'asc';
      }

      this.sortBy.prop = prop;
      this.sortBy.direction = direction;
    },
  },
});
