var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "header d-print-none" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "header__inner" },
          [
            _c(
              "div",
              { staticClass: "header__logo-wrap", on: { click: _vm.toHome } },
              [
                _c("img", {
                  staticClass: "header__logo",
                  attrs: { src: "/images/logo.svg", alt: "RealtyCloud" }
                })
              ]
            ),
            !_vm.hideSearch
              ? _c("app-search", { staticClass: "header-search" })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "header__auth" },
              [
                !_vm.user
                  ? _c(
                      "app-button",
                      {
                        attrs: { size: "lg", variant: "outline-primary" },
                        on: {
                          click: function($event) {
                            return _vm.$dialog.open("auth")
                          }
                        }
                      },
                      [_vm._v("\n            Войти\n          ")]
                    )
                  : [
                      _c(
                        "app-dropdown",
                        {
                          staticClass: "header-user",
                          attrs: { direction: "right", "close-on-select": "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "header-user__inner",
                              attrs: { "data-dropdown-trigger": "" }
                            },
                            [
                              _c("div", { staticClass: "header-user__body" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "header-user__username text-truncate"
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.user.email) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "header-user__balance" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm._f("currency")(_vm.balance)
                                        ) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "svg",
                                {
                                  staticClass: "header-user__expand",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 24 24"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: { d: "M0 0h24v24H0z", fill: "none" }
                                  })
                                ]
                              )
                            ]
                          ),
                          _c(
                            "app-dropdown-menu",
                            { staticClass: "header-user__menu" },
                            [
                              _vm.isAdmin || _vm.isPartner || _vm.isPartnerRisk
                                ? [
                                    _c(
                                      "h6",
                                      { staticClass: "dropdown-header" },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-user-secret"
                                        }),
                                        _vm._v(
                                          " CRM панель\n                  "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: { name: "admin-orders" } }
                                      },
                                      [
                                        _c("app-menu-item", [
                                          _vm._v(
                                            "\n                      Заказы\n                    "
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm.isPartner
                                      ? _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "admin-partner-finance"
                                              }
                                            }
                                          },
                                          [
                                            _c("app-menu-item", [
                                              _vm._v(
                                                "\n                      Финансы\n                    "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isPartner
                                      ? _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name:
                                                  "admin-partner-instruments"
                                              }
                                            }
                                          },
                                          [
                                            _c("app-menu-item", [
                                              _vm._v(
                                                "\n                      Инструменты\n                    "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isAdmin
                                      ? [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: { name: "admin-search" }
                                              }
                                            },
                                            [
                                              _c("app-menu-item", [
                                                _vm._v(
                                                  "\n                        Поиск\n                      "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: { name: "admin-stats" }
                                              }
                                            },
                                            [
                                              _c("app-menu-item", [
                                                _vm._v(
                                                  "\n                        Статистика\n                      "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: { name: "admin-control" }
                                              }
                                            },
                                            [
                                              _c("app-menu-item", [
                                                _vm._v(
                                                  "\n                        Управление\n                      "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: { name: "admin-settings" }
                                              }
                                            },
                                            [
                                              _c("app-menu-item", [
                                                _vm._v(
                                                  "\n                        Настройки\n                      "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e(),
                                    _c("div", {
                                      staticClass: "dropdown-divider"
                                    })
                                  ]
                                : _vm._e(),
                              _c(
                                "router-link",
                                { attrs: { to: { name: "user-profile" } } },
                                [
                                  _c("app-menu-item", [
                                    _vm._v(
                                      "\n                    Мои заказы\n                  "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "router-link",
                                { attrs: { to: { name: "user-settings" } } },
                                [
                                  _c("app-menu-item", [
                                    _vm._v(
                                      "\n                    Настройки\n                  "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("div", { staticClass: "dropdown-divider" }),
                              _c(
                                "app-menu-item",
                                {
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.showBalancePopup($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  Пополнить баланс\n                "
                                  )
                                ]
                              ),
                              _c(
                                "router-link",
                                { attrs: { to: { name: "package" } } },
                                [
                                  _c("app-menu-item", [
                                    _vm._v(
                                      "\n                    Выписки оптом\n                  "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "app-menu-item",
                                {
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.showActivatePromoCodeDialog(
                                        $event
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  Активировать купон\n                "
                                  )
                                ]
                              ),
                              _c(
                                "router-link",
                                { attrs: { to: { name: "wholesale-order" } } },
                                [
                                  _c("app-menu-item", [
                                    _vm._v(
                                      "\n                    Оптовый заказ\n                  "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("div", { staticClass: "dropdown-divider" }),
                              _c(
                                "app-menu-item",
                                {
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.logout($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  Выход\n                "
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "header-toggler",
                          on: { click: _vm.toggleUserMenu }
                        },
                        [
                          _c("div", { staticClass: "header-toggler__box" }, [
                            _c("div", { staticClass: "header-toggler__line" })
                          ])
                        ]
                      )
                    ]
              ],
              2
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }