var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$dadata.suggestion },
    [
      _c(
        "div",
        { class: _vm.label ? "rc-form-group rc-form-group--search" : "" },
        [
          _c("app-input", {
            ref: "input",
            class: _vm.$dadata["suggestion-input"],
            attrs: {
              value: _vm.value,
              placeholder: _vm.placeholder,
              label: _vm.label,
              size: _vm.size,
              type: _vm.type,
              spellcheck: "false"
            },
            on: {
              input: _vm.onSearch,
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp"
                    ])
                  ) {
                    return null
                  }
                  return _vm.preventCursorMove($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown"
                    ])
                  ) {
                    return null
                  }
                  return _vm.preventSubmit($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.preventSubmit($event)
                }
              ],
              keyup: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onSelect(_vm.highlightIndex)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp"
                    ])
                  ) {
                    return null
                  }
                  return _vm.onKeyUp($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown"
                    ])
                  ) {
                    return null
                  }
                  return _vm.onKeyDown($event)
                }
              ],
              focus: _vm.onFocus,
              blur: _vm.onBlur
            }
          })
        ],
        1
      ),
      _c(
        "transition",
        {
          attrs: { name: "suggest" },
          on: {
            enter: _vm.animationEnter,
            "after-enter": _vm.animationAfterEnter
          }
        },
        [
          _vm.isVisible
            ? _c("div", { class: _vm.$dadata["suggestion-list-wrap"] }, [
                _c("div", { class: _vm.$dadata["suggestion-list-title"] }, [
                  _vm._v(
                    "\n        Выберите вариант или продолжите ввод\n      "
                  )
                ]),
                _c(
                  "div",
                  { class: _vm.$dadata["suggestion-list"] },
                  _vm._l(_vm.suggestions, function(suggestion, i) {
                    var _obj
                    return _c(
                      "div",
                      {
                        key: suggestion.value + i,
                        class: [
                          _vm.$dadata["suggestion-item"],
                          ((_obj = {}),
                          (_obj[_vm.$dadata["suggestion-item_highlight"]] =
                            i === _vm.highlightIndex),
                          _obj)
                        ],
                        on: {
                          mousedown: function($event) {
                            return _vm.onSelect(i)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(suggestion.value) +
                            "\n          "
                        ),
                        _vm.searchBy === "company"
                          ? [
                              suggestion.data.inn
                                ? _c(
                                    "span",
                                    { staticClass: "small text-muted" },
                                    [
                                      _vm._v(
                                        "\n              ИНН: " +
                                          _vm._s(suggestion.data.inn) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              suggestion.data.management
                                ? _c(
                                    "span",
                                    { staticClass: "small text-muted" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            suggestion.data.management.name
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  0
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }