import Vue from 'vue';

import DropdownVue from './Dropdown.vue';
import DropdownMenuVue from './DropdownMenu.vue';
import DropdownMenuItemVue from './DropdownMenuItem.vue';

export default function dropdownComponent(_Vue: typeof Vue): void {
  _Vue.component('AppDropdown', DropdownVue);
  _Vue.component('AppDropdownMenu', DropdownMenuVue);
  _Vue.component('AppDropdownMenuItem', DropdownMenuItemVue);
  _Vue.component('AppMenuItem', DropdownMenuItemVue);
}
