import { Module } from 'vuex';
import { filter, find, mergeWith, orderBy, keyBy, get } from 'lodash';
import { Api } from 'shared/api';
import { AuthTypes } from 'shared/store/modules/auth/types';

import { _ObjectTypes } from './types';
import { ProductTypes } from 'shared/store/modules/products/types';
import { isCadastralNumber } from 'shared/utils/cadastralNumber';

function validateLevelProp(obj: RealtyObject): void {
  const level = parseInt(obj.Level as any, 10);
  obj.Level = isNaN(level) ? ('' as any) : (level.toString() as any);
}

interface ObjectDetailsModuleOptions {
  mapProduct?: (product: Product, index?: number, array?: Product[]) => void;
}

export default function objectDetailModule(options: ObjectDetailsModuleOptions = {}): Module<ObjectDetailState, RootState> {
  return {
    namespaced: true,

    state: {
      info: null,
      infoLoading: true,
      purchases: [],
      purchasesByProductName: {},
      loading: true,
      averageTime: 0,
      ordersExecutionTime: null,
      riskReportV2: null,
    },

    getters: {
      [_ObjectTypes.getters.FILTER_PURCHASES](state): (filter: Product) => Product[] {
        return (productFilter: Product): Product[] => {
          return filter(state.purchases, productFilter) as Product[];
        };
      },

      [_ObjectTypes.getters.PURCHASED_ITEM](state): (filter: Product) => Product | null {
        return (filter: Product): Product | null => find(state.purchases, filter) as Product;
      },

      [_ObjectTypes.getters.EGRN_OBJECT_DIVERGENCE](state): boolean {
        const egrnObject = state.purchases.find((elem) => elem.product_name === 'EgrnObject');
        if (egrnObject && egrnObject.data && egrnObject.data.difference) {
          return egrnObject.data.difference.is_checked;
        } else if (egrnObject && egrnObject.metadata && egrnObject.metadata.diffStatement) {
          if (egrnObject.metadata.diffStatement.amount_of_owners ||
              // egrnObject.metadata.diffStatement.area ||
              // egrnObject.metadata.diffStatement.cadastral_value ||
              egrnObject.metadata.diffStatement.date_rights ||
              egrnObject.metadata.diffStatement.registration_number_of_rights ||
              egrnObject.metadata.diffStatement.encumbrance) {
            return true;
          }
        }
        return false;
      },
    },

    actions: {
      async [_ObjectTypes.actions.GET_OBJECT_INFO]({ dispatch, commit, state, rootState }, payload: GetObjectInfoPayload = {}): Promise<void> {
        let objectKey, id;

        if (payload) {
          id = payload.id;
          objectKey = payload.objectKey;
        }

        if (!objectKey && state.info) {
          objectKey = state.info.Number || state.info.Address;
        }

        if (!objectKey) {
          return;
        }

        objectKey = objectKey.replace(/\//g, '%2F');

        // if (id) {
        //   commit(AuthTypes.mutations.SET_TOKEN, null, { root: true });
        // }

        try {
          let data: ObjectInfoResponse;
          let jwt: JWT;

          // Загружаем статичные данные если это демо страница
          if (objectKey === 'demo') {
            const response = await Api._axios.get('/data/object-new-demo.json');
            data = response.data;
          } else {
            const response = await Api.raw.object.Info<ApiRawResponse<ObjectInfoResponse>>(
              objectKey,
              { id }, // OrderItemId если он передаётся, то в ответе будет присутсвовать jwt купившего этот продукт
            );

            jwt = response.jwt;
            data = response.data;

            if (isCadastralNumber(data.object.Number)) {
              const utmCoupon = rootState.auth.utmCoupon || rootState.query.utm_coupon;
              const partnerID = rootState.auth.partner.id;
              const productItems = await Api.products.List(
                utmCoupon,
                {
                  kadastrNumber: data.object.Number,
                  partnerID,
                },
              );
              productItems.forEach((item: Product, index: number, array: Product[]) => {
                // Хардкодим и меняем заголовок, описание вручную
                if (options.mapProduct) {
                  options.mapProduct(item, index, array);
                }
              });
              commit(ProductTypes.mutations.SET_PRODUCTS, productItems, { root: true });
            }
          }

          const { products, object, average_time } = data;

          if (((object.Area || object.AreaOKC) && object.Level) || !object.Number) {
            validateLevelProp(object);
            commit(_ObjectTypes.mutations.TOGGLE_INFO_LOADING, false);
          }

          commit(_ObjectTypes.mutations.SET_INFO, {
            info: object,
            averageTime: average_time,
          });
          commit(_ObjectTypes.mutations.TOGGLE_LOADING, false);
          commit(_ObjectTypes.mutations.SET_PRODUCTS, products);

          // Если есть jwt, то добавляем всё в auth модуль
          if (jwt) {
            commit(AuthTypes.mutations.SET_USER, jwt.person, { root: true });
            commit(AuthTypes.mutations.SET_TOKEN, jwt.token, { root: true });
            await dispatch(AuthTypes.actions.CHECK_TOKEN, null, { root: true });

            // После авторизации надо запросить продукты, т.к. у авторизованного пользователя могут быть другие цены
            await dispatch(ProductTypes.actions.GET_PRODUCTS, null, { root: true });
          }
        } catch (error) {
          return Api.HttpError(error);
        }
      },

      async [_ObjectTypes.actions.GET_OBJECT_FULL_INFO]({ commit, state }, objectKey: string): Promise<void> {
        if (objectKey === 'demo') {
          return;
        }

        try {
          const fullInfo = await Api.object.InfoFull<RealtyObject>(objectKey, { timeout: 4e4 });
          const { info, averageTime } = state;

          validateLevelProp(fullInfo);

          const mergedInfo = mergeWith(info, fullInfo, (infoValue: any, fullInfoValue: any, key: keyof RealtyObject): RealtyObject => {
            if (key === 'Area' || key === 'AreaOKC') {
              if (infoValue === 'площадь не определена') {
                return fullInfoValue;
              }
            }

            return fullInfoValue || infoValue;
          });

          commit(_ObjectTypes.mutations.SET_INFO, { info: mergedInfo, averageTime });
        } catch (error) {
          Api.HttpError(error);
        } finally {
          commit(_ObjectTypes.mutations.TOGGLE_INFO_LOADING, false);
        }
      },

      async [_ObjectTypes.actions.GET_RISK_REPORT_V2]({ commit }, id: any): Promise<void> {
        try {
          let response: GetRiskReportV2Response;
          if (typeof id === 'string') {
            response = await Api.risk.GetRiskReportV2<GetRiskReportV2Response>(id);
          } else {
            response = await Api.risk.GetRiskReportV2<GetRiskReportV2Response>(id.orderItemId, {
              headers: {
                forceConclusion: 'true',
              },
            });
          }
          const checkOwnerNew = response as CheckOwnerReportNew;
          let result: any = response;
          const tasksList = [
            'owner_check_individual_bankrupt_rc_data_api',
            'legal_entity_bankrupt_check',
            'owner_check_individual_debt',
            'owner_check_individual_inn',
            'owner_check_individual_bailiff_debt',
            'legal_entity_fssp_check',
            'owner_check_individual_judicial_acts',
            'legal_entity_check_sudrf',
            'owner_zachestnyibiznes_check_analyze',
            'owner_check_individual_ceo',
            'owner_kad_arbitr_check',
            'legal_entity_check_arbitration_courts',
            'mosgorsud',
          ];
          if (checkOwnerNew && checkOwnerNew.owner) {
            const owner: any = checkOwnerNew.owner;
            owner.isNewVersion = true;
            owner.owner_id = checkOwnerNew.owner.id;
            const ownerTasks = checkOwnerNew.tasks.map((elem: CheckOwnerReportTask) => {
              if (tasksList.includes(elem.task_type)) {
                if (typeof elem.task_result.result === 'string') {
                  elem.task_result = {
                    result: elem.task_result.result,
                    text: elem.task_result.text,
                    images: elem.task_result.images,
                  };
                } else {
                  elem.task_result = {
                    ...elem.task_result.result,
                    text: elem.task_result.text,
                    images: elem.task_result.images,
                  };
                }
              }
              return {
                task_id: elem.task_id,
                task_result: elem.task_result,
                task_name: '',
                missing_requirements: elem.missing_requirements || [],
                task_type: elem.task_type,
                status: elem.status,
                number_of_starts: elem.number_of_starts,
                in_progress_started_time: elem.started_at,
                order_item_id: '00000000-0000-0000-0000-000000000000',
              };
            });
            result = {
              conclusions: checkOwnerNew.conclusions,
              global_data: checkOwnerNew.global_data,
              order: {
                isNewVersion: true,
                order_item_id: checkOwnerNew.owner.order_item_id,
                status: checkOwnerNew.status,
                input_data: {
                  product_name: 'CheckOwnerV2',
                  kad_number: '',
                  order_id: checkOwnerNew.owner.order_id,
                },
                created_at: checkOwnerNew.started_at,
              },
              owners: [
                {
                  owner: owner,
                  tasks: ownerTasks,
                  surveys: null as any,
                },
              ],
              tasks_general: null as any,
              tasks_parse_xml: null as any,
              difference_in_reports: null as any,
            };
          } else if (response && [ 'V2.1' ].includes((response as LawInformation).order.input_data.version)) {
            result.order.isNewVersion = true;
            result.owners.forEach((elem: any) => {
              elem.owner.isNewVersion = true;
              elem.owner.owner_id = elem.id;
              elem.tasks = elem.tasks.map((task: any) => {
                task.in_progress_started_time = task.started_at;
                if (tasksList.includes(task.task_type)) {
                  if (typeof task.task_result.result === 'string') {
                    task.task_result = {
                      result: task.task_result.result,
                      text: task.task_result.text,
                      images: task.task_result.images,
                    };
                  } else {
                    task.task_result = {
                      ...task.task_result.result,
                      text: task.task_result.text,
                      images: task.task_result.images,
                    };
                  }
                }
                return task;
              });
            });
            const mapTasks = (elem: any) => {
              elem.meta = Object.assign({}, elem.task_result);
              elem.task_result = elem.task_result.result;
              return elem;
            };
            result.tasks_general = result.tasks_general.map(mapTasks);
            result.tasks_parse_xml = result.tasks_parse_xml.map(mapTasks);
          }
          commit(_ObjectTypes.mutations.SET_RISK_REPORT_V2, result);
        } catch (error) {
          Api.HttpError(error);
        }
      },
    },

    mutations: {
      [_ObjectTypes.mutations.SET_INFO](state, { info, averageTime }: InfoMutationPayload): void {
        state.info = info;
        state.averageTime = averageTime;
      },

      [_ObjectTypes.mutations.TOGGLE_LOADING](state, loading: boolean): void {
        state.loading = loading;
      },

      [_ObjectTypes.mutations.TOGGLE_INFO_LOADING](state, loading: boolean): void {
        state.infoLoading = loading;
      },

      [_ObjectTypes.mutations.SET_PRODUCTS](state, purchasedProducts: Product[]): void {
        // Хелпер для получения даты
        const getTimeFromString = (p: Product) => {
          const date = new Date(get(p, 'created_date', Date.now()));

          return date.getTime();
        };

        if (options.mapProduct) {
          purchasedProducts.forEach(options.mapProduct);
        }

        // Сортируем по дате, в ui будет отображаться сверху последние покупки
        const orderedByCreatedAt = orderBy(purchasedProducts, getTimeFromString, 'desc');

        // Сортируем по статусу, если есть несколько продуктов с одним названием, то последними будут со статусом done
        // Нужно чтобы keyBy в приоритете выдавал именно со статусом done
        const orderedByStatus = orderBy(purchasedProducts, 'status', 'desc');

        state.purchasesByProductName = keyBy(orderedByStatus, 'product_name');
        state.purchases = orderedByCreatedAt;
      },

      [_ObjectTypes.mutations.PUSH_PRODUCTS](state, products: Product[]): void {
        state.purchases = products.concat(state.purchases);
      },

      [_ObjectTypes.mutations.SET_RISK_REPORT_V2](state, params: LawInformation): void {
        state.riskReportV2 = params;
      },
    },
  };
}
