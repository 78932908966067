import { Module } from 'vuex';
import { Api } from 'shared/api';
import router from '@/router';

export const searchModule: Module<SearchState, RootState> = {
  namespaced: true,

  state: {
    loading: false,
    items: [],
    query: '',
    filter: '',
  },

  getters: {
    filteredItems({ items, filter }) {
      if (!filter || filter === 'all') {
        return items;
      }

      if (filter === 'other') {
        return items.filter((searchItem) => {
          return !searchItem.ObjectType;
        });
      }

      return items.filter((searchItem) => {
        return new RegExp(filter, 'i').test(searchItem.ObjectType as string);
      });
    },
  },

  actions: {
    searchObjects: {
      root: true,
      async handler({ commit }, query: string) {
        const searchTimeStart: number = Date.now();
        let searchTime = 0;

        commit('searchStart', query);

        const createDummyData = (): any[] => {
          return [ { Address: query } ];
        };

        const searchEnd = () => {
          searchTime = (Date.now() - searchTimeStart) / 1000;
        };

        try {
          let result = await Api.object.Search<RealtyObject[]>(query, { timeout: 4e4 });
          searchEnd();

          if (result && result.length === 1) {
            const resultItem = result[0];

            // Поймали крайний случай, когда вернулись данные, но не было ключей Number и Address
            if (!resultItem.Address && !resultItem.Number) {
              resultItem.Address = query;
            }

            router.push({
              name: 'object-detail',
              params: { objectKey: resultItem.Number || resultItem.Address },
            });
          }

          if (!result || !result.length) {
            result = createDummyData();
          }

          commit('searchEnd', result);
          return result.length;
        } catch (error) {
          searchEnd();
          commit('searchEnd', createDummyData());

          return Api.HttpError(error);
        }
      },
    },
  },

  mutations: {
    setQuery(state, query: string) {
      state.query = query;
    },

    searchStart(state: SearchState, query: string) {
      state.query = query;
      state.loading = true;
    },

    searchEnd(state: SearchState, searchResult: any[]) {
      state.loading = false;
      state.items = searchResult;
    },

    changeFilter(state: SearchState, filter: string) {
      if (filter === 'Все') {
        filter = 'all';
      }

      if (filter === 'Прочее') {
        filter = 'other';
      }

      state.filter = filter || 'all';
    },
  },
};
