


























































import Vue from 'vue';
import Component from 'vue-class-component';
import noop from 'lodash/noop';
import AppImageUploader from 'shared/components/ui/ImageUploader/ImageUploader.vue';
import AppRiskAddOwnerForm from 'shared/components/RiskNew/AddOwnerForm/AddOwnerForm.vue';

import DialogEventBus from 'shared/components/ui/Dialog/dialogEventBus';
import LocStorage from 'shared/utils/localStorage';

@Component({
  name: 'AppRiskAddOwnerFormDialog',
  components: { AppImageUploader, AppRiskAddOwnerForm },
  popupOptions: {
    name: 'popup_default popup_risk-form',
  },

  props: {
    orderItemId: String,
    requestUsers: Boolean,
    hideForm: Boolean,
    scrollTo: String,
    onAddOwner: {
      type: Function,
      default: noop,
    },
  },
})
export default class RiskUserInfoForm extends Vue {
  // props
  orderItemId: string;
  requestUsers: boolean;
  scrollTo: string;
  hideForm: boolean;
  onAddOwner: () => void;

  // data()
  model: SimpleObject = {
    images: [],
  };

  formSending = false;
  tab = 'form';
  userInfo: Partial<OwnerInfoBase>[] = [];

  // lifecycle hooks
  async mounted() {
    DialogEventBus.once('close', () => {
      LocStorage.push('riskForm', this.orderItemId);
    });
  }

  // methods

  closeDialog() {
    this.$dialog.close();
  }

  hideDialog() {
    this.$dialog.close();

    if (this.scrollTo) {
      const btn: HTMLButtonElement = document.querySelector('#risk-add-owner-btn');
      const el = document.querySelector(this.scrollTo);

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }

      if (btn) {
        requestAnimationFrame(() => {
          btn.click();
        });
      }
    }
  }
}
