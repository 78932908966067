import { FIZCHECK_DOMAIN, FIZCHECK_PRO_DOMAIN } from 'shared/utils/constants';

const environment = {
  apiUrl: 'https://api-test.realtycloud.ru',
  advertApiUrl: 'https://api-dom.realtycloud.ru',
  rcDataUrl: 'https://rc-cloud.ru',
  yandexMapApiKey: 'ff82ecae-0fa5-43d9-8793-9b87787697b7',
  cloudpaymentsApiKey: 'pk_5d2c1524cbcc49a9bb3e883524eca',
};

let isFizcheck = false;

if (typeof window === 'object') {
  isFizcheck = [ FIZCHECK_DOMAIN, FIZCHECK_PRO_DOMAIN ].includes(window.location.hostname);
}

if (process.env.NODE_ENV === 'production') {
  environment.apiUrl = 'https://api.realtycloud.ru';
  // environment.apiUrl = 'https://mirror.fizcheck.ru';
  // environment.cloudpaymentsApiKey = 'pk_8841645ce8ca3d4c831d682c90ddd';

  if (isFizcheck) {
    environment.cloudpaymentsApiKey = 'pk_5e8973cedf2f28dec4be1f6818514';
  } else {
    environment.cloudpaymentsApiKey = 'pk_8841645ce8ca3d4c831d682c90ddd';
  }
}

if (process.env.BACKEND_URL) {
  environment.apiUrl = process.env.BACKEND_URL;
  environment.cloudpaymentsApiKey = 'pk_8841645ce8ca3d4c831d682c90ddd';
}

export { environment };
