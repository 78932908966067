var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "startpage container" }, [
    _c("div", { staticClass: "startpage-hero" }, [
      _vm._m(0),
      _c("div", { staticClass: "startpage-search" }, [
        _c(
          "div",
          { staticClass: "startpage-search__field" },
          [_c("app-search")],
          1
        ),
        _c("div", { staticClass: "startpage-search__hint" }, [
          _vm._v("\n        Например: адрес\n        "),
          _c(
            "strong",
            {
              on: {
                click: function($event) {
                  return _vm.$store.commit(
                    "search/setQuery",
                    "Москва, Рязанский пр-кт, д 74, кв 2"
                  )
                }
              }
            },
            [
              _vm._v(
                "\n          Москва, Рязанский пр-кт, д 74, кв 2\n        "
              )
            ]
          ),
          _vm._v("\n        или номер\n        "),
          _c(
            "strong",
            {
              on: {
                click: function($event) {
                  return _vm.$store.commit(
                    "search/setQuery",
                    "77:04:0002010:1101"
                  )
                }
              }
            },
            [_vm._v("77:04:0002010:1101")]
          )
        ])
      ]),
      _c("div", { staticClass: "startpage-hero__img-wrap" }, [
        _c("div", { staticClass: "startpage-hero__img" }),
        _vm.$store.state.ordersDoneCount
          ? _c(
              "div",
              { staticClass: "startpage-hero__statement-info text-center" },
              [
                _vm._v("\n        Обработано\n        "),
                _c(
                  "strong",
                  { staticClass: "text-primary border-bottom border-primary" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$store.state.ordersDoneCount) +
                        " выписок\n        "
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "startpage-section" }, [
      _c("h3", { staticClass: "startpage-section__title" }, [
        _vm._v("\n      Зачем и когда нужен отчет ЕГРН\n    ")
      ]),
      _c(
        "div",
        { staticClass: "list-info list-info_half" },
        _vm._l(_vm.answerList, function(answ, i) {
          return _c("div", { key: i, staticClass: "list-info-item" }, [
            _c("div", { staticClass: "list-info-item__icon text-success" }, [
              _c("svg", [
                _c("use", { attrs: { "xlink:href": "#icon-checkmark-circle" } })
              ])
            ]),
            _c("div", { staticClass: "list-info-item__text" }, [
              _c("div", { staticClass: "list-info-item__title" }, [
                _vm._v("\n            " + _vm._s(answ.title) + "\n          ")
              ]),
              answ.text
                ? _c("div", { staticClass: "list-info-item__body" }, [
                    _vm._v(
                      "\n            " + _vm._s(answ.text) + "\n          "
                    )
                  ])
                : _vm._e()
            ])
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "info__buttons text-center mt-4" },
        [
          _c(
            "a",
            {
              staticClass: "btn btn-lg btn-primary m-2",
              attrs: { href: "#startpage-header" }
            },
            [_vm._v("Заказать отчет")]
          ),
          _c(
            "app-button",
            {
              staticClass: "m-2",
              attrs: { size: "lg", variant: "white" },
              on: { click: _vm.showExamples }
            },
            [_vm._v("\n        Образцы отчетов\n      ")]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "startpage-section" }, [
      _c("h3", { staticClass: "startpage-section__title" }, [
        _vm._v("\n      Как это работает\n    ")
      ]),
      _c("div", { staticClass: "startpage-steps" }, [
        _vm._m(1),
        _vm._m(2),
        _c("div", { staticClass: "startpage-steps__item" }, [
          _c("img", {
            staticClass: "startpage-steps__icon",
            attrs: { src: "/images/icons/pdf-document.svg" }
          }),
          _c("div", { staticClass: "startpage-steps__text" }, [
            _vm._v(
              "\n          Получите документы на e-mail в формате PDF\n          "
            ),
            _c(
              "span",
              { staticClass: "btn-link", on: { click: _vm.showExamples } },
              [_vm._v("Образец")]
            )
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "startpage-section" },
      [
        _c("h3", { staticClass: "startpage-section__title" }, [
          _vm._v("\n      Частые вопросы\n    ")
        ]),
        _c(
          "app-collapse",
          [
            _c("app-collapse-item", [
              _c(
                "h5",
                {
                  staticClass: "mb-0",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v("\n          Что такое отчет из ЕГРН?\n        ")]
              ),
              _c("p", [
                _vm._v(
                  "\n          При оформлении сделок с недвижимостью может понадобиться выписка из Единого\n          государственного реестра прав. Этот документ гарантия того, что продавец действительно\n          имеет право распоряжаться недвижимостью, что права собственника не ограничены судебными\n          решениями, залогом в банке или другими собственниками.\n        "
                )
              ]),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n          Отчет из ЕГРН гарантирует, что предстоящая сделка законна. Заказав выписку перед\n          сделкой, можно оградить себя от мошенников и недобросовестных продавцов.\n        "
                )
              ])
            ]),
            _c("app-collapse-item", [
              _c(
                "h5",
                {
                  staticClass: "mb-0",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v("\n          Зачем нужен отчет из ЕГРН?\n        ")]
              ),
              _c("p", [
                _vm._v(
                  "\n          С 15 июля 2016 года отчет из ЕГРН является единственным документом, который\n          подтверждает право собственности и содержит всю информацию по переходу прав и\n          обременениям на объект недвижимости. Бумажные свидетельства Росреестр больше не выдаёт.\n        "
                )
              ]),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n          Все новые сведения о недвижимости сразу вносятся в базу реестра, поэтому в выписке будет\n          самая свежая и актуальная информация, а свидетельство могло быть выдано десятки лет\n          назад и устареть. Владельцы недвижимости могут доказать свое право выпиской из ЕГРН, что\n          уменьшит риск мошенничества в операциях с недвижимостью.\n        "
                )
              ])
            ]),
            _c("app-collapse-item", [
              _c(
                "h5",
                {
                  staticClass: "mb-0",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [
                  _vm._v(
                    "\n          Что можно узнать из отчета ЕГРН?\n        "
                  )
                ]
              ),
              _c("ul", { staticClass: "mb-0 pl-4" }, [
                _c("li", [
                  _vm._v(
                    "\n            Наименование органа, который осуществляет государственную регистрацию прав\n          "
                  )
                ]),
                _c("li", [_vm._v("Дату подписания выписки и исходящий номер")]),
                _c("li", [
                  _vm._v(
                    "Кадастровый (или условный) номер объекта недвижимости"
                  )
                ]),
                _c("li", [
                  _vm._v(
                    "\n            Назначение объекта, его адрес и площадь, (для зданий — этажность и материалы)\n          "
                  )
                ]),
                _c("li", [
                  _vm._v("Данные о правообладателях — ФИО или название юрлица")
                ]),
                _c("li", [_vm._v("Вид зарегистрированного права")]),
                _c("li", [
                  _vm._v(
                    "\n            Ограничения (обременения) права, дата и номер их государственной регистрации, сроки на\n            которые они установлены, а также данные о лицах, в пользу которых они установлены.\n          "
                  )
                ])
              ])
            ]),
            _c("app-collapse-item", [
              _c(
                "h5",
                {
                  staticClass: "mb-0",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v("\n          Как оплатить счет?\n        ")]
              ),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n          После ввода адреса и выбора типа выписки нужно нажать кнопку «Заказать». Далее вы будете\n          перенаправлены на платежную страницу, где указываете данные банковской карты для оплаты.\n        "
                )
              ])
            ])
          ],
          1
        ),
        _vm._m(3)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "startpage-hero__head text-center" }, [
      _c(
        "h1",
        {
          staticClass: "startpage-hero__title",
          attrs: { id: "startpage-header" }
        },
        [_vm._v("\n        Срочный заказ отчета из ЕГРН\n      ")]
      ),
      _c("h3", { staticClass: "startpage-hero__subtitle" }, [
        _vm._v(
          "\n        Проверьте собственников и недвижимость в Росреестре\n      "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "startpage-steps__item" }, [
      _c("img", {
        staticClass: "startpage-steps__icon",
        attrs: { src: "/images/icons/house-mark.svg" }
      }),
      _c("div", { staticClass: "startpage-steps__text" }, [
        _vm._v("\n          Найдите нужный объект\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "startpage-steps__item" }, [
      _c("img", {
        staticClass: "startpage-steps__icon",
        attrs: { src: "/images/icons/list-checkmark.svg" }
      }),
      _c("div", { staticClass: "startpage-steps__text" }, [
        _vm._v(
          "\n          Отметьте документы, необходимые из Росреестра\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center mt-4" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-lg btn-primary",
          attrs: { href: "#startpage-header" }
        },
        [_vm._v("\n        Заказать выписку сейчас\n      ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }