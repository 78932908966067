const EARTH_RADIUS = 6372795;

export function degreeToRadian(coords: number[]): number[] {
  const radians: number[] = [];

  for (const degree of coords) {
    radians.push((degree * Math.PI) / 180);
  }

  return radians;
}

export function calcDistanceByCoords(coordsOne: number[], coordsTwo: number[]): number {
  const [ lat1, long1 ] = degreeToRadian(coordsOne);
  const [ lat2, long2 ] = degreeToRadian(coordsTwo);
  const delta: number = long2 - long1;
  const sin: SimpleObject<number> = {
    delta: Math.sin(delta),
    lat1: Math.sin(lat1),
    lat2: Math.sin(lat2),
  };

  const cos: SimpleObject<number> = {
    delta: Math.cos(delta),
    lat1: Math.cos(lat1),
    lat2: Math.cos(lat2),
  };

  const x = sin.lat1 * sin.lat2 + cos.lat1 * cos.lat2 * cos.delta;
  const y = Math.sqrt(
    Math.pow(cos.lat2 * sin.delta, 2) +
      Math.pow(cos.lat1 * sin.lat2 - sin.lat1 * cos.lat2 * cos.delta, 2),
  );

  return Math.round(EARTH_RADIUS * Math.atan2(y, x));
}
