import Vue from 'vue';

export default function floatFilter(_Vue: typeof Vue) {
  _Vue.filter('float', (value: string) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      return value;
    }

    return parsedValue.toFixed(2);
  });

  _Vue.filter('formatPassport', (value: string, isPrint: boolean): string => {
    if (isPrint) {
      value = value.slice(0, 4);
    }

    return value.replace(/(\d{2})(\d{2})(\d+)?/, '$1 $2 $3');
  });

  _Vue.filter('hideSymbols', (value: string) => {
    if (!value || typeof value !== 'string') return value; // Проверка на корректность входных данных
    const lengthToHide = Math.ceil(value.length * 0.3); // 30% от длины строки
    const visiblePart = value.slice(0, value.length - lengthToHide); // Видимая часть строки
    const hiddenPart = '*'.repeat(lengthToHide); // Заменяемая часть строкой из звездочек
    return visiblePart + hiddenPart; // Объединяем видимую и скрытую части
  });
}
