var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "popup-transition", duration: 300, appear: "" } },
    [
      _c("div", { staticClass: "popup" }, [
        _c("div", { staticClass: "popup__inner" }, [
          _c("div", { staticClass: "popup__content" }, [_vm._t("default")], 2)
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }