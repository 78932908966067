var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "auth-head" }, [
        _c("div", { staticClass: "auth-head__icon" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 60.51 65.72"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M24.17 56.5H.75a.76.76 0 0 1-.75-.75v-8.11c0-3.1 2.31-5.9 6.67-8.08l11.46-5.72a.76.76 0 0 1 1 .34.75.75 0 0 1-.34 1L7.34 40.9c-2.66 1.34-5.84 3.58-5.84 6.74V55h22.67a.75.75 0 0 1 0 1.5zM27.2 31.23A12 12 0 0 1 20.4 29c-4.28-3-6.2-7.4-6.2-14.36C14.2 7.37 18.66 0 27.17 0s13 7.37 13 14.65c0 6.88-1.81 11.14-6.05 14.24a11.61 11.61 0 0 1-6.92 2.34zm0-29.73c-7.56 0-11.5 6.62-11.5 13.15s1.66 10.43 5.56 13.12a9.8 9.8 0 0 0 11.93-.09c3.87-2.83 5.44-6.6 5.44-13S34.69 1.5 27.17 1.5zM43.13 49.1h-8.29a.75.75 0 0 1 0-1.5h8.29a.75.75 0 0 1 0 1.5z"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M51.42 49.1h-8.29a.75.75 0 0 1 0-1.5h8.29a.75.75 0 0 1 0 1.5z"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M43.13 57.39a.75.75 0 0 1-.75-.75v-8.29a.75.75 0 0 1 1.5 0v8.29a.74.74 0 0 1-.75.75z"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M43.13 49.1a.76.76 0 0 1-.75-.75v-8.29a.75.75 0 0 1 1.5 0v8.29a.75.75 0 0 1-.75.75z"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M43.13 65.72a17.38 17.38 0 1 1 17.38-17.37 17.39 17.39 0 0 1-17.38 17.37zm0-33.25A15.88 15.88 0 1 0 59 48.35a15.9 15.9 0 0 0-15.87-15.88z"
                }
              })
            ]
          )
        ]),
        _c("h4", { staticClass: "auth-head__title" }, [
          _vm._v("\n      Регистрация\n    ")
        ])
      ]),
      _c(
        "app-form",
        {
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.model,
            rules: _vm.validationRules
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "app-button",
                        {
                          attrs: {
                            loading: loading,
                            type: "submit",
                            size: "lg",
                            variant: "info",
                            block: ""
                          }
                        },
                        [_vm._v("\n          Зарегистрироваться\n        ")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "app-form-group",
            { attrs: { "model-name": "name" } },
            [
              _c("app-input", {
                attrs: { placeholder: "Имя", size: "lg" },
                model: {
                  value: _vm.model.name,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "name", $$v)
                  },
                  expression: "model.name"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { attrs: { "model-name": "email" } },
            [
              _c("app-input", {
                attrs: { type: "email", placeholder: "E-mail", size: "lg" },
                model: {
                  value: _vm.model.email,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "email", $$v)
                  },
                  expression: "model.email"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { attrs: { "model-name": "phone" } },
            [
              _c("app-phone-number-input", {
                attrs: { placeholder: "Телефон", size: "lg" },
                model: {
                  value: _vm.model.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "phone", $$v)
                  },
                  expression: "model.phone"
                }
              })
            ],
            1
          ),
          _c("app-form-group", { attrs: { "model-name": "person_role" } }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.model.person_role,
                    expression: "model.person_role",
                    modifiers: { number: true }
                  }
                ],
                staticClass: "custom-select custom-select-lg",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.$set(
                      _vm.model,
                      "person_role",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v("\n          Выберите роль\n        ")
                ]),
                _vm._l(_vm.roles, function(roleName, roleId) {
                  return _c(
                    "option",
                    { key: roleId, domProps: { value: roleId } },
                    [_vm._v("\n          " + _vm._s(roleName) + "\n        ")]
                  )
                })
              ],
              2
            )
          ]),
          _c(
            "app-form-group",
            { attrs: { "model-name": "password" } },
            [
              _c("app-input", {
                attrs: { type: "password", placeholder: "Пароль", size: "lg" },
                model: {
                  value: _vm.model.password,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "password", $$v)
                  },
                  expression: "model.password"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { attrs: { "model-name": "password" } },
            [
              _c("app-input", {
                attrs: {
                  type: "password",
                  placeholder: "Повторите пароль",
                  size: "lg"
                },
                model: {
                  value: _vm.passwordConfirm,
                  callback: function($$v) {
                    _vm.passwordConfirm = $$v
                  },
                  expression: "passwordConfirm"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { attrs: { "model-name": "privacy" } },
            [
              _c(
                "app-checkbox",
                {
                  model: {
                    value: _vm.model.privacy,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "privacy", $$v)
                    },
                    expression: "model.privacy"
                  }
                },
                [
                  _c("span", [_vm._v("Согласен с ")]),
                  _c(
                    "span",
                    {
                      staticClass: "auth-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showDialog($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          политикой о персональных данных\n        "
                      )
                    ]
                  ),
                  _vm._v("\n        и\n        "),
                  _c(
                    "span",
                    {
                      staticClass: "auth-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.showOfferDialog($event)
                        }
                      }
                    },
                    [_vm._v("\n          офертой\n        ")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "text-center" }, [
        _c("span", [_vm._v("Уже есть аккаунт? ")]),
        _c(
          "span",
          {
            staticClass: "auth-link",
            on: {
              click: function($event) {
                return _vm.changeForm("login")
              }
            }
          },
          [_vm._v("Войти")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }