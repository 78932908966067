import { RealtyCloudApi } from 'shared/api/api';

export interface ApiEstimateMethods {
  Calc<T = EstimateResponse>(params: EstimateRequestBody): Promise<T>;
  Rate<T = any>(metadata: RateEstimateMetadata): Promise<T>;
}

export function estimateMethods(this: RealtyCloudApi) {
  return {
    Calc: (data: EstimateRequestBody) => {
      return this.request({
        url: '/estimate',
        method: 'POST',
        data,
      });
    },
    Rate: (metadata: RateEstimateMetadata) => {
      return this.request({
        url: '/task',
        method: 'POST',
        data: {
          name: 'rate_cost_assessment',
          task_type_id: '7132e8cf-9be2-4566-9f30-3b9e6300d71c',
          job_type: 'job_rate_cost_assessment',
          metadata,
        },
      });
    },
  };
}
