import { Module } from 'vuex';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import { Api } from 'shared/api';
import { AuthTypes } from 'shared/store/modules/auth/types';
import { isCadastralNumber } from 'shared/utils/cadastralNumber';

interface GetObjectInfoPayload {
  objectKey?: string;
  id?: string;
}

interface InfoMutationPayload {
  info: RealtyObject;
  averageTime: number;
  ordersExecutionTime: null | OrderExecutionTime[];
  useMerge?: boolean;
}

function validateLevelProp(obj: RealtyObject) {
  const level = parseInt(obj.Level as any, 10);
  obj.Level = isNaN(level) ? ('' as any) : (level.toString() as any);
}

export const objectDetail: Module<ObjectDetailState, RootState> = {
  namespaced: true,

  state: {
    info: null,
    infoLoading: true,
    purchases: [],
    loading: true,
    averageTime: 0,
    ordersExecutionTime: null,
    riskReportV2: null,
  },

  getters: {
    filterPurchases: (state) => (productFilter: Product) => {
      return filter(state.purchases, productFilter);
    },

    purchasedItem: (state) => (filter: Product) => {
      return find(state.purchases, filter);
    },

    executionTime: (state) => (filter: Product) => {
      return find(state.ordersExecutionTime, filter);
    },
  },

  actions: {
    async getObjectInfo({ commit, state, dispatch }, payload: GetObjectInfoPayload = {}) {
      let objectKey, id;

      if (payload) {
        id = payload.id;
        objectKey = payload.objectKey;
      }

      if (!objectKey && state.info) {
        objectKey = state.info.Number || state.info.Address;
      }

      if (!objectKey) {
        return;
      }

      objectKey = objectKey.replace(/\//g, '%2F');

      if (id) {
        commit(AuthTypes.mutations.SET_TOKEN, null, { root: true });
      }

      try {
        const { data, jwt } = await Api.raw.object.Info<ApiRawResponse<ObjectInfoResponse>>(
          objectKey,
          { id },
        );

        if (isCadastralNumber(data.object.Number)) {
          const productItems = await Api.products.List(null, { kadastrNumber: data.object.Number });
          commit('getProducts', productItems, { root: true });
        }

        const { products, object, average_time } = data;

        if (((object.Area || object.AreaOKC) && object.Level) || !object.Number) {
          validateLevelProp(object);
          commit('toggleInfoLoading', false);
        }

        products.sort((a) => {
          return a.product_name === 'RiskAssessment' ? -1 : 0;
        });

        if (jwt) {
          commit(AuthTypes.mutations.SET_USER, jwt.person, { root: true });
          commit(AuthTypes.mutations.SET_TOKEN, jwt.token, { root: true });
          dispatch(AuthTypes.actions.CHECK_TOKEN, null, { root: true });
          dispatch('getProducts', null, { root: true });
        }

        commit('setInfo', {
          info: object,
          averageTime: average_time,
          useMerge: true,
        } as InfoMutationPayload);
        commit('toggleLoading', false);
        commit('setProducts', products);
      } catch (error) {
        return Api.HttpError(error);
      }
    },

    async getObjectFullInfo({ commit, state }, objectKey: string) {
      try {
        const info = await Api.object.InfoFull<RealtyObject>(objectKey, { timeout: 4e4 });
        const { averageTime, ordersExecutionTime } = state;

        validateLevelProp(info);

        commit('setInfo', {
          info,
          averageTime,
          ordersExecutionTime,
          useMerge: true,
        } as InfoMutationPayload);
      } catch (error) {
        return Api.HttpError(error);
      } finally {
        commit('toggleInfoLoading', false);
      }
    },
  },

  mutations: {
    setInfo(state, { info, averageTime, useMerge }: InfoMutationPayload) {
      let infoObj = info;
      if (useMerge) {
        infoObj = mergeWith({}, state.info, info, (infoValue, fullInfoValue, key) => {
          if (key === 'UpdatedAt') {
            return fullInfoValue;
          }

          // eslint-disable-next-line
          if (fullInfoValue && fullInfoValue != infoValue) {
            return fullInfoValue;
          }

          return infoValue;
        });
      }

      state.info = infoObj;
      state.averageTime = averageTime;
    },

    toggleLoading(state, loading: boolean) {
      state.loading = loading;
    },

    toggleInfoLoading(state, loading: boolean) {
      state.infoLoading = loading;
    },

    setProducts(state, purchasedProducts: Product[]) {
      purchasedProducts.sort(
        (prev: any, next: any) =>
          new Date(get(prev, 'created_at')).getTime() - new Date(get(next, 'created_at')).getTime(),
      );
      state.purchases = purchasedProducts;
    },

    pushProducts(state, products: Product[]) {
      state.purchases = products.concat(state.purchases);
    },
  },
};
