var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product-item product-item_default",
      class: { "product-item_selected": _vm.checkedStatus >= 1 }
    },
    [
      _c("div", { staticClass: "product-item__left" }, [
        _c(
          "div",
          {
            staticClass: "custom-control custom-checkbox",
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.toggleSelect()
              }
            }
          },
          [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: { type: "checkbox", id: _vm.item.default.product_id },
              domProps: { checked: _vm.checkedStatus >= 1 }
            }),
            _c("label", {
              staticClass: "custom-control-label",
              attrs: { for: _vm.item.default.product_id }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "product-item__body" }, [
        _c("div", { staticClass: "product-item__name" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.item.default.product_name_ru) + "\n      "
          ),
          _vm.checkedStatus === 2
            ? _c("span", { staticClass: "badge badge-danger" }, [
                _vm._v("\n        Срочный\n      ")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "product-item__desc" }, [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(_vm.item.default.product_description)
            }
          })
        ]),
        _vm.item.fast && _vm.allowedFastOrder
          ? _c(
              "div",
              { staticClass: "product-item__desc product-item__desc_adv" },
              [
                _c(
                  "div",
                  {
                    staticClass: "custom-control custom-checkbox",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.toggleSelect(true)
                      }
                    }
                  },
                  [
                    _c("input", {
                      staticClass: "custom-control-input",
                      attrs: { type: "checkbox", id: _vm.item.fast.product_id },
                      domProps: { checked: _vm.checkedStatus === 2 }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label text-danger",
                        attrs: { for: _vm.item.fast.product_id }
                      },
                      [
                        _vm._v(
                          "Заказать срочно за ~ " +
                            _vm._s(
                              _vm.item.fast.product_name == "RiskAssessmentFast"
                                ? "50"
                                : "30"
                            ) +
                            " мин."
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "product-item__right" }, [
        _c(
          "div",
          { staticClass: "product-item__info" },
          [
            _c(
              "app-button",
              {
                attrs: { variant: "white", size: "sm" },
                on: { click: _vm.openInfoDialog }
              },
              [_vm._v("\n        Подробнее\n      ")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "product-item__price" }, [
          _vm.coupon
            ? _c(
                "div",
                { staticClass: "badge badge-danger product-item__badge" },
                [
                  _vm._v(
                    "\n        -" + _vm._s(_vm.coupon.description) + "\n      "
                  )
                ]
              )
            : _vm._e(),
          _c("span", { staticClass: "product-item__price-original" }, [
            _vm._v("\n        " + _vm._s(_vm.price) + "\n        "),
            _c("i", { staticClass: "fas fa-ruble-sign" })
          ]),
          _c("div", { staticClass: "product-item__time" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tippy",
                    rawName: "v-tippy",
                    value: {
                      placement: "top-end",
                      content: _vm.tooltipContent
                    },
                    expression:
                      "{\n            placement: 'top-end',\n            content: tooltipContent,\n          }"
                  }
                ],
                staticClass: "d-inline-block"
              },
              [
                _c("span", { staticClass: "align-middle" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.executionTimeString) +
                      "\n          "
                  )
                ]),
                _c(
                  "svg",
                  {
                    staticClass: "align-middle",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 24 24"
                    }
                  },
                  [
                    _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
                    _c("path", {
                      attrs: {
                        d:
                          "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }