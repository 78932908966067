import qs from 'query-string';
import Cookies from 'js-cookie';

type Statuses = 'loading' | 'callback' | 'cache' | 'error';
export interface RoistatCallbackMetadata {
  visitId: string | null;
  status?: Statuses;
}

export interface RoistatMetadata extends RoistatCallbackMetadata {
  timeLoad: number;
}

const query = qs.parse(location.search);

if (query.roistat_visit) {
  Cookies.set('roistat_visit', query.roistat_visit);
}

class Roistat {
  private _status: Statuses = 'loading';
  private resolve: any;

  get status() {
    return this._status;
  }

  set status(status) {
    this._status = status;
    if (this.resolve) {
      this.resolve();
    }
  }

  waitLoading() {
    return new Promise((resolve) => {
      this.resolve = resolve;
    }).then(() => {
      this.resolve = null;
    });
  }
}

export const RoistatState = new Roistat();

export default async function getRoistatMetadata(): Promise<RoistatMetadata> {
  const w: any = window;
  const start = Date.now();

  console.log('roistat', w.roistat && w.roistat.getVisit());

  if (RoistatState.status === 'loading') {
    await RoistatState.waitLoading();
  }

  return new Promise((resolve) => {
    let metadata: RoistatMetadata;

    if (RoistatState.status !== 'error' && typeof w.roistat !== 'undefined') {
      const visitId: string = w.roistat.getVisit();

      metadata = {
        visitId,
        status: RoistatState.status,
        timeLoad: Date.now() - start,
      };
    } else {
      metadata = {
        timeLoad: 0,
        visitId: null,
        status: 'error',
      };
    }

    resolve(metadata);
  });
}
