
































































































import isEmpty from 'lodash/isEmpty';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    icon: String,
    title: String,
    iconStyle: {
      type: String,
      default: 'fas',
    },
    source: Object,
    checkFms: Boolean,
    checkInn: Boolean,
    checkPassport: Boolean,
    status: String,
    missingRequirements: Array,
    images: {
      type: Array,
      default: (): any => {
        return [];
      },
    },
  },
  inject: [ 'ReportProvider' ],
})
export default class ReportItemComponent extends Vue {
  // props
  // status: ExecutionStatusList;
  status: string;
  checkPassport: boolean;
  images: string[];
  source: any;

  // injected
  ReportProvider!: any;

  get isCompleted(): boolean {
    return this.status === 'done';
  }

  get isNotRequired(): boolean {
    return this.status === 'not required';
  }

  get isNotPaid(): boolean {
    return this.status === 'not paid';
  }

  get isCanceled(): boolean {
    if (this.$isServer) {
      if (this.status === 'failed' || this.status === 'soft_failed') {
        return true;
      } else {
        return false;
      }
    }

    return this.status === 'failed';
  }

  get innNotFound(): boolean {
    if (!this.ReportProvider.owner) {
      return false;
    }

    const inn = this.ReportProvider.owner.inn;
    const status = inn.executionStatus;

    return isEmpty(inn.number) && (status === 'complete' || status === 'cancel');
  }

  get passportInvalid(): boolean {
    if (!this.ReportProvider.owner) {
      return false;
    }

    const passportValid: PassportValidation = this.ReportProvider.owner.passport.valid;

    return passportValid.status === false && passportValid.executionStatus === 'complete';
  }

  get passportIsEmpty(): boolean {
    if (this.checkPassport && this.ReportProvider.owner) {
      return isEmpty(this.ReportProvider.owner.passport.full);
    }

    return false;
  }

  showImage() {
    const args = this.images.map((elem: string) => {
      return { src: elem, w: 0, h: 0 };
    });
    this.$photoSwipe(args, true);
  }
}
