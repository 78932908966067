




















































import Component, { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';

import AppProductItem from 'shared/components/Product/Item/ProductItem';
import AppPurchasedItem from '@/views/ObjectDetail/components/ProductItem/PurchasedProductItem.vue';

import BaseOrderMixin from '../BaseOrder.mixin';
import { OFFICIAL_DOCUMENTS } from '@/core/utils/constants';
import checkCadastralRegions from 'shared/utils/cadastralNumber/checkCadastralRegions';

function productsFilter(item: Product) {
  return (
    item.product_type === OFFICIAL_DOCUMENTS ||
    _.startsWith(item.product_name, 'RiskAssessment') ||
    _.includes(item.product_name, 'Fast')
  );
}

@Component({
  name: 'AppProductList',

  components: {
    AppProductItem,
    AppPurchasedItem,
  },

  // props: {
  //   // products: Array,
  //   info: Object,
  //   // purchases: Array as () => Product[],
  // },

  computed: {
    ...mapGetters('order', [ 'totalAmount', 'itemsCount' ]),
    ...mapState([ 'products' ]),
    ...mapState('order', [ 'items' ]),
  },
})
export default class ProductList extends mixins(BaseOrderMixin) {
  // props
  products: Product[];
  info: RealtyObject;

  // data()
  showAllPurchases: boolean = false;
  allowedFastOrder: boolean = false;
  productsListMap: { [key: string]: string } = {
    EgrnObject: 'egrn',
    EgrnObjectFast: 'egrn',
    EgrnRightList: 'rightList',
    EgrnRightListFast: 'rightList',
    RiskAssessment: 'risk',
    RiskAssessmentFast: 'risk',
  };
  // ['RiskAssessment', 'EgrnObject', 'EgrnRightList'];

  // computed
  totalAmount: number;
  itemsCount: number;
  get purchases(): OfficialProductMapItem[] {
    const purchases = this.$store.getters['details/filterPurchases'](productsFilter);

    return purchases.map((item: Product) => ({ default: item }));
  }

  get purchasesSlice() {
    if (this.purchases.length > 4 && !this.showAllPurchases) {
      return this.purchases.slice(0, 4);
    }

    return this.purchases;
  }

  get officialDocuments(): OfficialProductMap {
    const productsMap: OfficialProductMap = {};

    _.forEach(this.products, (product: Product) => {
      if (product.product_name in this.productsListMap) {
        const groupName = this.productsListMap[product.product_name];

        if (!(groupName in productsMap)) {
          productsMap[groupName] = { default: null, fast: null };
        }

        if (_.includes(product.product_name, 'Fast')) {
          productsMap[groupName].fast = product;
        } else {
          productsMap[groupName].default = product;
        }
      }
    });

    return productsMap;
  }

  // lifecycle
  created() {
    // Если регион доступен для заказа через spv.kadastr.ru, то добавляем продукт для быстрого заказа
    if (this.info.Number && checkCadastralRegions(this.info.Number)) {
      this.allowedFastOrder = true;
    }

    const { product_id: productId } = _.find(this.products, { product_name: 'EgrnObject' });

    this.$store.commit('order/changeItem', [ productId ]);
  }

  mounted() {
    if (this.$route.query.redirect === 'order') {
      if (this.$route.query.product) {
        const product: Product = this.$store.getters.getProduct({
          product_name: this.$route.query.product,
        });

        console.log(product);

        if (product) {
          this.$store.commit('order/changeItem', [ product.product_id ]);
        }
      }

      this.checkout('pay_statement', 'redirect');
    }
  }

  // methods
  toggleExpand() {
    this.showAllPurchases = !this.showAllPurchases;
  }

  selectProducts(products: UUID[]) {
    this.$store.commit('order/changeItem', products);
  }
}
