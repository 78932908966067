import { render, staticRenderFns } from "./ProductItem.vue?vue&type=template&id=43b16d60&"
import script from "./ProductItem.ts?vue&type=script&lang=ts&"
export * from "./ProductItem.ts?vue&type=script&lang=ts&"
import style0 from "./ProductItem.style.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/realtycloud/rcfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('43b16d60', component.options)
    } else {
      api.reload('43b16d60', component.options)
    }
    module.hot.accept("./ProductItem.vue?vue&type=template&id=43b16d60&", function () {
      api.rerender('43b16d60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "rcshare/components/Product/Item/ProductItem.vue"
export default component.exports