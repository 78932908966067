/**
 * Хелпер для асинхронных функций
 * используется с await чтобы сделать задержку
 *
 * В параметр передаются секунды, а не миллисекунды
 */
export default function sleep(seconds: number = 1): Promise<any> {
  return new Promise(resolve => {
    setTimeout(resolve, seconds * 1000);
  });
}
