









































































import Vue from 'vue';
import Component from 'vue-class-component';

interface FeedbackItem {
  worth?: string;
  limitations?: string;
  comment?: string;
  date?: string;
  expanded?: boolean;
}

@Component({
  name: 'AppYandexMarketFeedback',
})
export default class YandexMarketFeedback extends Vue {
  // data()
  currentIndex = 0;
  feedbackItems: FeedbackItem[] = [
    {
      worth: 'быстро дешево',
      limitations: 'не нашел',
      comment: 'заказал выписку утром к обеду выписка была уже готова',
      date: 'Wed, 24 Oct 2018 21:00:00 GMT',
    },
    {
      worth: 'В отличии от других отчет был полон и с однозначными выводами!',
      limitations: 'Нет',
      comment: [
        'Заказывал отчет на квартиру перед ее покупкой.',
        'Сам Я из Мурманска, а квартиру смотрел в Краснодаре.',
        'После выбора обратился в агенство для проверки, но все просят большую предоплату...',
        'Тут мне проверили квартиру всего за 2 дня.',
        'Очень понравилось, что указали не только на спорные моменты, но и способы их решения! Рекомендую',
      ].join(' '),
      expanded: false,
      date: 'Mon, 29 Oct 2018 21:00:00 GMT',
    },
  ];

  // computed
  get feedback(): FeedbackItem {
    return this.feedbackItems[this.currentIndex];
  }

  // methods
  truncateText(propertyName: keyof FeedbackItem): string {
    const text = this.feedback[propertyName] as string;

    if (text.length > 120 && !this.feedback.expanded) {
      return text.slice(0, 80) + '...';
    }

    return text;
  }

  expandText() {
    // this.feedbackItems[this.currentIndex].expanded = true;
    this.feedback.expanded = true;
  }
}
