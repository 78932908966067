






























import Vue from 'vue';
export default Vue.extend({
  props: {
    info: Object as () => RealtyObject,
    checkout: Function,
  },

  data() {
    return {
      keysGroup: [
        [ 'TypeOKC', 'Info', 'Number', 'Region', 'Address', 'Level' ],
        [ 'Area', 'DateOfKadReg', 'UpdatedAt' ],
        [ 'KadPrice', 'PriceDeterminationDate', 'DateOfPriceAdded', 'PriceApprovalDate' ],
      ],
      titles: {
        Region: 'Регион',
        Level: 'Этаж',
        // ObjectType: 'Тип объекта',
        Address: 'Адрес',
        Info: 'Статус',
        Number: 'Кадастровый номер',
        KadPrice: 'Кадастровая стоимость',
        Area: 'Площадь',
        TypeOKC: 'Тип',
        PriceApprovalDate: 'Дата утверждения стоимости',
        DateOfPriceAdded: 'Дата внесения стоимости в базу',
        PriceDeterminationDate: 'Дата определения стоимости',
        UpdatedAt: 'Дата обновления информации',
        DateOfKadReg: 'Дата постановки на учёт',
        OwnersCount: 'Всего количество владельцев',
        AreaOKC: 'Кадастровый район',
        KadEngineer: 'Кадастровый инженер',
      },
    };
  },
});
