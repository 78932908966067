var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.owner.owner_type === 0
        ? _c(
            "div",
            {
              staticClass: "form-row",
              class: { "is-underage": _vm.isUnderage }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 add-owner-item__input add-owner-item__group-input"
                },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "owner-form-input",
                      attrs: { "model-id": _vm.index, "model-name": "surname" }
                    },
                    [
                      _c("app-input", {
                        attrs: { label: "Фамилия*", size: "sm" },
                        on: {
                          input: function($event) {
                            _vm.owner.surname = _vm.capitalize(
                              _vm.owner.surname
                            )
                          }
                        },
                        model: {
                          value: _vm.owner.surname,
                          callback: function($$v) {
                            _vm.$set(_vm.owner, "surname", $$v)
                          },
                          expression: "owner.surname"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "app-form-group",
                    {
                      staticClass: "owner-form-input",
                      attrs: { "model-id": _vm.index, "model-name": "first" }
                    },
                    [
                      _c("app-input", {
                        attrs: { label: "Имя*", size: "sm" },
                        on: {
                          input: function($event) {
                            _vm.owner.first = _vm.capitalize(_vm.owner.first)
                          }
                        },
                        model: {
                          value: _vm.owner.first,
                          callback: function($$v) {
                            _vm.$set(_vm.owner, "first", $$v)
                          },
                          expression: "owner.first"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "app-form-group",
                    {
                      staticClass: "owner-form-input",
                      attrs: {
                        "model-id": _vm.index,
                        "model-name": "patronymic"
                      }
                    },
                    [
                      _c("app-input", {
                        attrs: { label: "Отчество", size: "sm" },
                        on: {
                          input: function($event) {
                            _vm.owner.patronymic = _vm.capitalize(
                              _vm.owner.patronymic
                            )
                          }
                        },
                        model: {
                          value: _vm.owner.patronymic,
                          callback: function($$v) {
                            _vm.$set(_vm.owner, "patronymic", $$v)
                          },
                          expression: "owner.patronymic"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-4 col-sm-6 add-owner-item__input" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "owner-form-input",
                      attrs: { "model-id": _vm.index, "model-name": "birthday" }
                    },
                    [
                      _c("app-date-picker", {
                        attrs: {
                          label: "Дата рождения",
                          options: _vm.datePickerOptions,
                          placeholder: "ДД.ММ.ГГГГ"
                        },
                        model: {
                          value: _vm.owner.birthday,
                          callback: function($$v) {
                            _vm.$set(_vm.owner, "birthday", $$v)
                          },
                          expression: "owner.birthday"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-md-8",
                  class: {
                    "add-owner-item__underage": _vm.isUnderage
                  }
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-sm-6 add-owner-item__input enterPassportData",
                        class: {
                          hidden: _vm.isFormOnLanding && !_vm.enterPassportData
                        }
                      },
                      [
                        _c(
                          "app-form-group",
                          {
                            staticClass: "owner-form-input",
                            attrs: {
                              "model-id": _vm.index,
                              "model-name": "passport"
                            }
                          },
                          [
                            _c("the-mask", {
                              staticClass: "form-control form-control-sm",
                              attrs: {
                                mask: ["## ## ######"],
                                placeholder: "__ __ ______"
                              },
                              model: {
                                value: _vm.owner.passport,
                                callback: function($$v) {
                                  _vm.$set(_vm.owner, "passport", $$v)
                                },
                                expression: "owner.passport"
                              }
                            }),
                            _c(
                              "label",
                              { class: [{ active: _vm.owner.passport }] },
                              [
                                _vm._t("passportInputLabel", [
                                  _vm._v("Серия и № паспорта")
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.isFormOnLanding
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-sm-6 add-owner-item__input enterPassportDataCheckbox"
                          },
                          [
                            _c(
                              "app-checkbox",
                              {
                                model: {
                                  value: _vm.enterPassportData,
                                  callback: function($$v) {
                                    _vm.enterPassportData = $$v
                                  },
                                  expression: "enterPassportData"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            Паспортные данные\n          "
                                )
                              ]
                            ),
                            _c("span", {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: {
                                    placement: "top-end",
                                    content:
                                      "Чем больше заполнено полей, тем выше точность и больше источников будут задействованы"
                                  },
                                  expression:
                                    "{\n              placement: 'top-end',\n              content: 'Чем больше заполнено полей, тем выше точность и больше источников будут задействованы',\n            }"
                                }
                              ],
                              staticClass: "enterPassportDataTooltip"
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 add-owner-item__input" },
                      [
                        !_vm.isOdyssey
                          ? _c(
                              "app-form-group",
                              {
                                staticClass: "owner-form-input",
                                attrs: {
                                  "model-id": _vm.index,
                                  "model-name": "region"
                                }
                              },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.owner.region,
                                        expression: "owner.region"
                                      }
                                    ],
                                    staticClass:
                                      "custom-select custom-select-sm",
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.owner,
                                          "region",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("option", {
                                      attrs: { value: "", hidden: "" }
                                    }),
                                    _vm._l(_vm.regions, function(reg) {
                                      return _c(
                                        "option",
                                        {
                                          key: reg.id,
                                          domProps: { value: reg.id }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(reg.name) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                ),
                                _c(
                                  "label",
                                  { class: [{ active: _vm.owner.region }] },
                                  [
                                    _vm._t("regionInputLabel", [
                                      _vm._v(
                                        "\n                Регион регистр./проживания\n              "
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ]
                            )
                          : _c(
                              "app-form-group",
                              {
                                staticClass: "owner-form-input",
                                attrs: {
                                  "model-id": _vm.index,
                                  "model-name": "region"
                                }
                              },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.owner.region,
                                        expression: "owner.region"
                                      }
                                    ],
                                    staticClass:
                                      "custom-select custom-select-sm",
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.owner,
                                          "region",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("option", {
                                      attrs: { value: "", hidden: "" }
                                    }),
                                    _vm._l(_vm.checkTargets, function(reg) {
                                      return _c(
                                        "option",
                                        {
                                          key: reg.value,
                                          domProps: { value: reg.value }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(reg.text) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                ),
                                _c(
                                  "label",
                                  { class: [{ active: _vm.owner.region }] },
                                  [
                                    _vm._t("regionInputLabel", [
                                      _vm._v(
                                        "\n                Цель проверки\n              "
                                      )
                                    ])
                                  ],
                                  2
                                )
                              ]
                            )
                      ],
                      1
                    )
                  ])
                ]
              ),
              (_vm.isEditing && !_vm.isOdyssey && _vm.isCheckPerson) ||
              _vm.isAdmin
                ? _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-md-4 add-owner-item__input"
                        },
                        [
                          _c(
                            "app-form-group",
                            {
                              staticClass: "owner-form-input",
                              attrs: {
                                "model-id": _vm.index,
                                "model-name": "inn"
                              }
                            },
                            [
                              _c("app-input", {
                                attrs: {
                                  label: "ИНН",
                                  type: "number",
                                  size: "sm"
                                },
                                model: {
                                  value: _vm.owner.inn,
                                  callback: function($$v) {
                                    _vm.$set(_vm.owner, "inn", $$v)
                                  },
                                  expression: "owner.inn"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-md-4 add-owner-item__input"
                        },
                        [
                          _c(
                            "app-form-group",
                            {
                              staticClass: "owner-form-input",
                              attrs: {
                                "model-name": "snils",
                                "model-id": _vm.index
                              }
                            },
                            [
                              _c("the-mask", {
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  mask: ["###-###-### ##"],
                                  placeholder: "___-___-___ __"
                                },
                                model: {
                                  value: _vm.owner.snils,
                                  callback: function($$v) {
                                    _vm.$set(_vm.owner, "snils", $$v)
                                  },
                                  expression: "owner.snils"
                                }
                              }),
                              _c(
                                "label",
                                { class: [{ active: _vm.owner.snils }] },
                                [
                                  _vm._v(
                                    "\n              Номер СНИЛС\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-md-4 add-owner-item__input"
                        },
                        [
                          _c(
                            "app-form-group",
                            {
                              staticClass: "owner-form-input",
                              attrs: { "model-name": "phone" }
                            },
                            [
                              _c("app-phone-number-input", {
                                attrs: {
                                  label: "Телефон",
                                  placeholder: "+7 (___) ___ __ __",
                                  size: "sm"
                                },
                                model: {
                                  value: _vm.owner.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.owner, "phone", $$v)
                                  },
                                  expression: "owner.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              (_vm.isEditing && _vm.isCheckPerson) || _vm.isAdmin
                ? _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-md-6 add-owner-item__input"
                        },
                        [
                          _c(
                            "app-form-group",
                            {
                              staticClass: "owner-form-input",
                              attrs: {
                                "model-id": _vm.index,
                                "model-name": "license"
                              }
                            },
                            [
                              _c("the-mask", {
                                staticClass: "form-control form-control-sm",
                                attrs: {
                                  mask: ["## ## ######"],
                                  placeholder: "__ __ ______"
                                },
                                model: {
                                  value: _vm.owner.license,
                                  callback: function($$v) {
                                    _vm.$set(_vm.owner, "license", $$v)
                                  },
                                  expression: "owner.license"
                                }
                              }),
                              _c(
                                "label",
                                { class: [{ active: _vm.owner.license }] },
                                [
                                  _vm._v(
                                    "\n              Водительское удостоверение\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-md-6 add-owner-item__input"
                        },
                        [
                          _c(
                            "app-form-group",
                            {
                              staticClass: "owner-form-input",
                              attrs: {
                                "model-id": _vm.index,
                                "model-name": "driver_license_at"
                              }
                            },
                            [
                              _c("app-date-picker", {
                                attrs: {
                                  label: "Дата выдачи В/У",
                                  options: _vm.datePickerOptions,
                                  placeholder: "ДД.ММ.ГГГГ"
                                },
                                model: {
                                  value: _vm.owner.driver_license_at,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.owner,
                                      "driver_license_at",
                                      $$v
                                    )
                                  },
                                  expression: "owner.driver_license_at"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              !_vm.isCheckPerson
                ? _c("div", { staticClass: "col-12" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "row sm-gutters" }, [
                      _vm.rightsList
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-md-6 add-owner-item__input"
                            },
                            [
                              _c(
                                "app-form-group",
                                {
                                  staticClass: "owner-form-input",
                                  attrs: { "model-id": _vm.index }
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.rightSelect,
                                          expression: "rightSelect"
                                        }
                                      ],
                                      staticClass:
                                        "custom-select custom-select-sm",
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.rightSelect = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                          _vm.rightSelectHandler
                                        ]
                                      }
                                    },
                                    [
                                      _c("option", {
                                        attrs: { value: "", hidden: "" }
                                      }),
                                      _vm._l(_vm.rightsList, function(reg) {
                                        return _c(
                                          "option",
                                          {
                                            key: reg.number,
                                            domProps: { value: reg.number }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(reg.number) +
                                                "\n                "
                                            ),
                                            reg.type === "Аренда"
                                              ? [
                                                  _vm._v(
                                                    "\n                  (Арендатор)\n                "
                                                  )
                                                ]
                                              : _vm._e(),
                                            reg.type ===
                                            "Доверительное управление"
                                              ? [
                                                  _vm._v(
                                                    "\n                  (Управляющий)\n                "
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      }),
                                      _c(
                                        "option",
                                        { attrs: { value: "other" } },
                                        [
                                          _vm._v(
                                            "\n                Другой номер\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "notRequired" } },
                                        [
                                          _vm._v(
                                            "\n                Права не зарегистрированы\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  ),
                                  _c(
                                    "label",
                                    { class: [{ active: _vm.rightSelect }] },
                                    [
                                      _vm._t("regionInputLabel", [
                                        _vm._v(
                                          "\n                Выбрать номер права\n              "
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.rightSelect === "other" ||
                      !_vm.rightsList ||
                      (_vm.isEditing && _vm.owner.registration_number)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-md-6 add-owner-item__input"
                            },
                            [
                              _c(
                                "app-form-group",
                                {
                                  staticClass: "owner-form-input",
                                  attrs: {
                                    "model-id": _vm.index,
                                    "model-name": "registration_number"
                                  }
                                },
                                [
                                  _c("app-input", {
                                    attrs: { label: "Номер права", size: "sm" },
                                    model: {
                                      value: _vm.owner.registration_number,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.owner,
                                          "registration_number",
                                          $$v
                                        )
                                      },
                                      expression: "owner.registration_number"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm.owner.owner_type === 1
        ? [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "col-md-7 add-owner-item__input" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "owner-form-input",
                      attrs: { "model-id": _vm.index, "model-name": "first" }
                    },
                    [
                      _c("app-input", {
                        attrs: { label: "Название организации", size: "sm" },
                        model: {
                          value: _vm.owner.first,
                          callback: function($$v) {
                            _vm.$set(_vm.owner, "first", $$v)
                          },
                          expression: "owner.first"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-5 add-owner-item__input" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "owner-form-input",
                      attrs: { "model-id": _vm.index, "model-name": "inn" }
                    },
                    [
                      _c("app-input", {
                        attrs: { label: "ИНН", type: "number", size: "sm" },
                        model: {
                          value: _vm.owner.inn,
                          callback: function($$v) {
                            _vm.$set(_vm.owner, "inn", $$v)
                          },
                          expression: "owner.inn"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "col-md-7 add-owner-item__input" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "owner-form-input",
                      attrs: { "model-id": _vm.index, "model-name": "region" }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.owner.region,
                              expression: "owner.region"
                            }
                          ],
                          staticClass: "custom-select custom-select-sm",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.owner,
                                "region",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }),
                          _vm._l(_vm.regions, function(reg) {
                            return _c(
                              "option",
                              { key: reg.id, domProps: { value: reg.id } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(reg.name) +
                                    "\n            "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("label", { class: [{ active: _vm.owner.region }] }, [
                        _vm._v("Территориальные органы")
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-2" }, [
      _c("span", { staticClass: "owner-form-input__header" }, [
        _vm._v("\n          Укажите данные о правах*\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }