var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "poll" },
    [
      _vm.step < _vm.polls.length
        ? [
            _vm._t("info", [
              _c("div", { staticClass: "alert alert-info small" }, [
                _vm._v(
                  "\n        Ответьте пожалуйста на вопросы - это поможет сделать наш сервис еще лучше для вас.\n      "
                )
              ])
            ]),
            _c(
              "div",
              { ref: "wrapper", staticClass: "poll__wrapper" },
              [
                _c(
                  "transition-group",
                  {
                    attrs: { name: "poll", mode: "out-in", tag: "div" },
                    on: { enter: _vm.animationEnter }
                  },
                  _vm._l(_vm.polls, function(item, index) {
                    return _c("app-poll-item", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: index === _vm.step,
                          expression: "index === step"
                        }
                      ],
                      key: item.question,
                      attrs: { item: item },
                      on: { selected: _vm.answerSelected }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "poll__footer" },
              [
                _c(
                  "app-button",
                  { staticClass: "px-4", on: { click: _vm.next } },
                  [_vm._v("\n        Продолжить\n      ")]
                )
              ],
              1
            )
          ]
        : [
            _c(
              "div",
              { staticClass: "poll__complete" },
              [_vm._t("poll-complete")],
              2
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }