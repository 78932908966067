
























































































































































































































































import { FIZCHECK_DOMAIN, FIZCHECK_PRO_DOMAIN } from 'shared/utils/constants';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      webSite: location.origin,
    };
  },
  computed: {
    isFizCheck() {
      return [ FIZCHECK_DOMAIN, FIZCHECK_PRO_DOMAIN ].includes(window.location.hostname);
    },
    email(): string {
      return this.isFizCheck ? 'help@fizcheck.ru' : 'help@realtycloud.ru ';
    },
    company(): string {
      return this.isFizCheck ? 'ООО «Физчек»' : 'ООО «Реалти Клауд»';
    },
  },
});
