var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "popup-root",
      on: { mousedown: _vm.outsideClickStart, click: _vm.outsideClick }
    },
    _vm._l(_vm.dialogs, function(dialog, i) {
      return _c(
        "app-dialog",
        _vm._b(
          { key: i, attrs: { index: i } },
          "app-dialog",
          _vm.getAttributes(dialog.component),
          false
        ),
        [
          _c(
            dialog.component,
            _vm._b({ tag: "component" }, "component", dialog.props, false)
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }