var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth" },
    [
      _c(
        "div",
        {
          staticClass: "auth-close",
          on: {
            click: function($event) {
              return _vm.$dialog.close(_vm.index)
            }
          }
        },
        [
          _c("svg", { attrs: { viewBox: "0 0 268.832 268.832" } }, [
            _c("path", {
              attrs: {
                d:
                  "M265.171 125.577l-80-80c-4.881-4.881-12.797-4.881-17.678 0-4.882 4.882-4.882 12.796 0 17.678l58.661 58.661H12.5c-6.903 0-12.5 5.597-12.5 12.5 0 6.902 5.597 12.5 12.5 12.5h213.654l-58.659 58.661c-4.882 4.882-4.882 12.796 0 17.678 2.44 2.439 5.64 3.661 8.839 3.661s6.398-1.222 8.839-3.661l79.998-80c4.882-4.882 4.882-12.796 0-17.678z"
              }
            })
          ])
        ]
      ),
      _c(_vm.currentFormComponent, {
        tag: "component",
        attrs: {
          "change-form": _vm.changeForm,
          "registration-success": _vm.onRegistrationSuccess
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }