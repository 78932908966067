






import Vue from 'vue';
import AuthLogin from '@/components/layout/Auth/AuthLogin.vue';
export default Vue.extend({
  components: { AuthLogin },
});
