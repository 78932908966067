




































































import Vue from 'vue';
import omit from 'lodash/omit';
import { RoleNames, Role } from 'shared/utils/constants';
import { Validators } from 'shared/utils/validator';
import { AuthTypes } from 'shared/store/modules/auth/types';
import noop from 'lodash/noop';
import AppDiscountSuccessDialog from 'shared/components/User/DiscountSuccessDialog.vue';
import LocStorage from 'shared/utils/localStorage';

export default Vue.extend({
  name: 'AppChooseRoleDialog',

  props: {
    firstShow: {
      type: Boolean,
      default: false,
    },
    onRoleChanged: {
      type: Function,
      default: noop,
    },
  },

  popupOptions: {
    name: 'popup_default popup_chooseRole',
  },

  data() {
    return {
      model: {
        phone: this.$store.state.auth.user.phone,
        person_role: (this as any).getRole(),
      },
      // rolesWithPhone: [
      //   Role.Realtor,
      //   Role.Agent,
      //   Role.Customer,
      //   Role.HeadOfAgency,
      //   Role.Lawyer ], // роли для которых отображается поле Телефон: риелтор (2), агент(3), покупатель(5), руководитель АН(7), юрист(9)
    };
  },

  methods: {
    validationRules(): ValidationConfig {
      const config: ValidationConfig = {
        person_role: [ Validators.required ],
        phone: [],
      };
      return config;
    },

    async onSubmit() {
      this.user.person_role = this.model.person_role;
      this.user.phone = this.model.phone;
      try {
        await this.$store.dispatch('user/updateInfo', this.user);
        this.giveDiscount();
      } catch (error) {
        const text = (error as Error).message;
        this.$noty.error({ text });
      }
    },

    giveDiscount() {
      this.$noty.info({ text: 'Профиль успешно обновлен' });
      this.$dialog.close();
      this.$store.commit(AuthTypes.mutations.SET_UTM_COUPON, 'discount20egrn');
      this.$dialog.open({
        component: AppDiscountSuccessDialog,
      });
      LocStorage.setItem('DiscountSuccessDialogHasShown', true);
    },

    getRole(): number {
      if (!this.isAdmin && [ 0, 1, 4 ].includes(this.$store.state.auth.user.person_role)) {
        return null;
      }
      // return this.$store.state.auth.user.person_role;
    },
  },

  computed: {
    isAdmin(): boolean {
      return this.$store.getters[AuthTypes.getters.IS_ADMIN];
    },
    roles() {
      return { ...omit(RoleNames, Role.Admin, Role.Client, Role.Partner) };
    },
    user(): User {
      return this.$store.state.auth.user;
    },
  },
});
