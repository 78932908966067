





















































































































































































import Vue from 'vue';

import {
  RISK_EXAMPLES,
  EGRN_EXAMPLES,
  RIGHT_LIST_EXAMPLES,
} from 'shared/utils/constants/examples';
import startsWith from 'lodash/startsWith';

export default Vue.extend({
  name: 'AppProductInfoDialog',

  props: {
    product: Object as () => Product,
    onCheckout: Function,
  },

  data: function() {
    return {
      tipContent: `<div class="text-left p-3"><b>Почему я сам должен добавить эти данные?</b><br>
        Эти данные нужны для организации проверки по базам МВД,
        Судебным приставам, реестра о банкротстве и других базах.
        Паспортные данные иногда отражены в договоре купли-продажи,
        свидетельстве о праве собственности и иных документах.
        Если вы планируете купить недвижимость, то важно запросить документы у собственника,
        чтобы удостовериться, что сделку ведет действительно собственник.
        При аренде - важно узнать информацию о арендодателе.</div>`,
      fioTipContent: `С 1 марта 2023 года вступил в силу запрет на передачу персональных данных из
        ЕГРН без согласия правообладателя. Поэтому ФИО собственников скрыты Росреестром.
        Для того чтобы сведения о собственниках в выписке из ЕГРН были доступны, владелец
        недвижимости должен открыть сведения о своих ФИО и дате рождения, подав
        специальное заявление в Росреестр, в котором разрешит предоставлять их третьим лицам.`,
    };
  },

  computed: {
    isRiskProduct(): boolean {
      return this.product.product_name.indexOf('RiskAssessment') !== -1;
    },

    coupon(): Coupon | null {
      if (this.product.coupons) {
        return this.product.coupons[0];
      }

      return null;
    },

    time(): string {
      switch (this.product.product_name) {
        case 'EgrnObjectFast':
        case 'EgrnRightListFast':
          return '~ 5 мин.';
        case 'RiskAssessmentFast':
        case 'RiskAssessmentFastV2':
          return '~ 15 мин.';
        case 'RiskAssessment':
        case 'RiskAssessmentV2':
        case 'RiskAssessmentAdvanced':
          return '~60 мин.';
        default:
          return '~30 мин.';
      }
    },

    descriptionByProduct(): string {
      switch (this.product.product_name) {
        case 'EgrnObjectFast':
        case 'EgrnObject':
          return 'Данный аналитический документ содержит в себе всю необходимую информацию о владельце недвижимости. В нем также указано наличие обременений на недвижимое имущество.';
        case 'EgrnRightListFast':
        case 'EgrnRightList':
          return 'Данный отчёт содержит полную информацию о бывших владельцах, а также даты перехода прав и причины их смены (при наличии сведений и если информация открыта последним правообладателем).';
        case 'RiskAssessmentAdvanced':
        case 'RiskAssessmentFast':
        case 'RiskAssessment':
        case 'RiskAssessmentV2':
        case 'RiskAssessmentFastV2':
          return 'Данный отчет содержит аналитическую информацию, полученную в результате изучения следующих сведений о недвижимости и собственниках (при наличии сведений и если информация открыта последним правообладателем)*:';
        default:
          return '';
      }
    },

    infoByProduct(): string[] {
      switch (this.product.product_name) {
        case 'EgrnObjectFast':
        case 'EgrnObject': {
          return [
            'Основные характеристики (адрес, площадь, тип, кадастр. стоимость...)',
            'ФИО настоящего владельца (если права зарегистрированы и сведения открыты правообладателем)',
            'Вид права на недвижимость, серию и номер свидетельства на собственность',
            'Наличие / отсутствие обременений',
            'Информация о правопритязаниях (при наличии прекращения)',
          ];
        }
        case 'EgrnRightListFast':
        case 'EgrnRightList': {
          return [
            'ФИО всех собственников с 1998 года',
            'Серию и номер свидетельства о регистрации',
            'Даты перехода прав',
            'Дополнительная информация',
            'Данные о каждом из Правообладателей.',
          ];
        }
        case 'RiskAssessmentFast':
        case 'RiskAssessmentV2':
        case 'RiskAssessmentFastV2':
        case 'RiskAssessmentAdvanced':
        case 'RiskAssessment': {
          return [
            'Проверка недвижимости по Росреестру, МинЖКХ и другим источникам',
            'Информация о собственниках',
            'Обременения (арест/залог/ипотека)',
            'Основание возникновения прав (при наличии)',
            'Задолженность собственников',
            'Проверка действительности паспорта',
            'Проверка на банкротство собственника',
            'Наличие судебных разбирательств как по объекту, так и по собственнику',
            'Проверка объекта на наличие в списке на снос',
            'План объекта недвижимости (при наличии сведений)',
            'Проверка на массовую регистрацию по адресу',
            'Наличие в реестре объектов культурного наследия',
            'Проверка на запрет регистрационных действий',
            'И еще 15+ проверок',
          ];
        }

        default:
          return [];
      }
    },

    tooltipContent(): string {
      const isRiskProduct = startsWith(this.product.product_name, 'RiskAssessment');
      const isFast = /Fast/.test(this.product.product_name);

      if (isFast) {
        if (isRiskProduct) {
          return 'Заказ будет обработан в приоритетном порядке. В случае превышения 30 минут - возвратим оплаченную сумму за срочность и заказ будет обработан по сумме обычного заказа.';
        }

        return 'Заказ будет обработан в приоритетном порядке. В случае превышения 30 минут - возвратим оплаченную сумму за срочность и заказ будет обработан по сумме обычного заказа.';
      }

      if (isRiskProduct) {
        return 'Отчёт формируется в течение 40-60 минут (в нерабочее время сроки могут быть увеличены)';
      }

      return 'Отчет готовится от 30 до 1.5 часов (в нерабочее время сроки могут быть увеличены)';
    },

    productName() {
      return startsWith(this.product.product_name, 'RiskAssessment')
        ? 'Проверка недвижимости и собственников на риски'
        : this.product.product_name_ru;
    },
  },
  methods: {
    checkout() {
      this.$dialog.close();
      this.onCheckout(this.product);
    },

    showExamples(exampleType: string) {
      const examples: SimpleObject<any[]> = {
        risk: RISK_EXAMPLES,
        egrn: EGRN_EXAMPLES,
        rightList: RIGHT_LIST_EXAMPLES,
      };

      this.$photoSwipe(examples[exampleType]);
    },
  },
});
