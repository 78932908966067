import Vue from 'vue';
import { Item } from 'photoswipe';
import * as PswpWrapper from './photoswipe-wrapper';

// let PswpWrapper: any;

declare module 'vue/types/vue' {
  interface Vue {
    $photoSwipe: (items: Item[], dynamicSize?: boolean, options?: any) => void;
  }
}

export default function installPhotoSwipePlugin(_Vue: typeof Vue) {
  _Vue.prototype.$photoSwipe = (items: Item[], dynamicSize?: boolean, options?: any) => {
    PswpWrapper.initPhotoSwipe(items, dynamicSize, options);
  };
}
